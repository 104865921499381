// <button type="button" onClick={() => Util.modal(state, setState)}>팝업 열기</button>

// @state : 팝업 state
// @open : 팝업 열기/닫기 Util.modal() 사용
// @confirm : 완료 이후 function()
// - ex) () => Util.modal(state, setState)
// @title : 팝업 제목
// @html : 팝업 내용
// - ex) <component /> 또는 function() 또는 문자열: '<p>팝업 내용</p>', '팝업 내용'
// @btnCancelText: 좌측 버튼 텍스트
// @btnConfirmText: 우측 버튼 텍스트

// <FullScreenDialog
//   state={state}
//   open={() => Util.modal(state, setState)}
//   confirm={function()}
//   title={'팝업 제목'}
//   title={'<p>팝업 제목</p>'}
//   html={function()}
//   html={<component />}
//   html={'<p>팝업 내용</p>'}
//   btnCancelText={'취소'}
//   btnConfirmText={'확인'}
// />

const FullScreenDialog = (props) => {
  return (
    <>
      {
        props.state &&
        <div className={`layer${props.state ? " _open" : ""}`}>
          <div className="pop-container pop-full">
            <div className="pop-content">
              <div className="pop-inner" data-action="popAppend">
                <div className="pop-header" dangerouslySetInnerHTML={{ __html: props.title }}></div>
                {
                  typeof props.html === 'string'
                    ? <div className="dec-wrap" dangerouslySetInnerHTML={{ __html: props.html }}></div>
                    : <div className="dec-wrap">{props.html}</div>
                }
                <ul className="btn-flex t2">
                  <li className="col1"><button type="button" className="btn c-gray s0" onClick={props.cancel}>{props.btnCancelText}</button></li>
                  <li className="col2"><button type="button" className="btn c-sky s0" onClick={props.confirm}>{props.btnConfirmText}</button></li>
                </ul>
              </div>
              <button type="button" className="pop-close" onClick={props.open}><span className="a11y">팝업닫기</span></button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default FullScreenDialog;