import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import moment from 'moment';
import ServiceService from '../../services/ServiceService';
import RequestService from '../../services/RequestService';
import RegFinish from "../../layout/stock/RegFinish";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const ReturnList = () => {
  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = useState(false);
  const [baseInput, setBaseInput] = useState(false);
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", "", "", ""]);
  const [keyword, setKeyword] = useState("");
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [saveLoading, setSaveLoading] = useState(false);  // 저장로딩여부
  const [list, setList] = useState([]); // 리스트 
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [requestSeq, setRequestSeq] = useState(null); // 삭제 요청 SEQ
  const [checkList, setCheckList] = useState([]); // 요청 체크 리스트
  const [finishReg, setFinishReg] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: location.state ? location.state.product_group : '',
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [location]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    if (location.state && location.state.model_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        upper_child_product_code: location.state ? location.state.model_code : '',
        level: 1
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setProductList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    if (location.state && location.state.product_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.product_code : '',
        level: 2
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setAssyList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    if (location.state && location.state.assy_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.assy_code : '',
        level: 3
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setPartList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 목록 상세 보기
  const detailOpen = (event) => {
    const $over = document.querySelectorAll(".over-container");
    const $overItem = event.target.closest(".over-container");
    const $open = event.target.closest("._open");

    $over.forEach((item) => {
      item.classList.remove("_open");
    });

    if ($open) {
      $overItem.classList.remove("_open");
    } else {
      $overItem.classList.add("_open");
    }
  }

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);
    let locationState = location.state || {};

    if (idx === 0) { // 발송일
      locationState.search_start_date = '';
      locationState.search_end_date = '';
    }
    if (idx === 1) { // 상태
      locationState.request_status = '';
    }
    if (idx === 2) { // 품목군
      locationState.product_group = '';
    }
    if (idx === 3) { // 단계
      locationState.level = '';
    }
    if (idx === 4) { // 모델
      locationState.model_code = '';
    }
    if (idx === 5) { // 제품
      locationState.product_code = '';
    }
    if (idx === 6) { // 아세이
      locationState.assy_code = '';
    }
    if (idx === 7) { // 부품
      locationState.part_code = '';
    }
    if (idx === 8) { // 키워드
      locationState.search_word = '';
    }

    if (idx === 6) {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 5) {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 4) {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    setBaseInput(false);
    const $check = document.querySelector("input[data-action=date]");
    $check.checked = false;

    history.replace({
      pathname: '/stock/return/list',
      state: {
        search_start_date: '',
        search_end_date: '',
        request_status: '',
        product_group: '',
        level: '',
        model_code: '',
        product_code: '',
        assy_code: '',
        part_code: '',
        search_word: ''
      }
    });
  }, [history]);

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
      setCheckList([]);
    }
    const param = {
      request_type: 'R',
      current_page: currentPage,
      page_size: pageSize
    };
    Object.assign(param, location.state);
    try {
      const result = await RequestService.fetchItems(param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(thisState => {
            return thisState + 1
          });
        }
      }
    }
  }, [loading, totalCount, currentPage]);

  // 필터 검색하기
  const onFilterSearch = () => {
    filterOpen();
  }

  // 필터 레이어 팝업
  const filterOpen = () => {
    const $body = document.body;

    if (filter) {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
      window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
        if (currentPage === 1) {
          searchList();
        } else {
          setCurrentPage(1);
        }
      }
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, '');
      $body.classList.remove("_noscroll");
      setFilter(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
          if (currentPage === 1) {
            searchList();
          } else {
            setCurrentPage(1);
          }
        }
        $body.classList.remove("_noscroll");
        setFilter(false);
      }
      $body.classList.add("_noscroll");
      setFilter(true);
    }
  }

  // 발송 시작일 날짜 선택(변경)
  const onChangeSearchStartDate = (event) => {
    let locationState = location.state || {};

    history.replace({
      pathname: '/stock/return/list',
      state: {
        search_start_date: moment(event).format('YYYY-MM-DD'),
        search_end_date: locationState.search_end_date,
        request_status: locationState.request_status || '',
        product_group: locationState.product_group || '',
        level: locationState.level || '',
        model_code: locationState.model_code || '',
        product_code: locationState.product_code || '',
        assy_code: locationState.assy_code || '',
        part_code: locationState.part_code || '',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 발송 종료일 날짜 선택(변경)
  const onChangeSearchEndDate = (event) => {
    let locationState = location.state || {};

    history.replace({
      pathname: '/stock/return/list',
      state: {
        search_start_date: locationState.search_start_date,
        search_end_date: moment(event).format('YYYY-MM-DD'),
        request_status: locationState.request_status || '',
        product_group: locationState.product_group || '',
        level: locationState.level || '',
        model_code: locationState.model_code || '',
        product_code: locationState.product_code || '',
        assy_code: locationState.assy_code || '',
        part_code: locationState.part_code || '',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 발송일 미입력
  const resetSearchDate = (event) => {
    const $rdt = document.querySelectorAll(".rdt");
    if (baseInput) {
      $rdt.forEach((item) => {
        item.classList.remove("disabled");
      })
      setBaseInput(false);
    } else {
      $rdt.forEach((item) => {
        item.classList.add("disabled");
      })
      setBaseInput(true);
      let locationState = location.state || {};

      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: '',
          search_end_date: '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};
    const name = event.target.name;
    locationState[name] = event.target.value;

    if (name === 'assy_code') {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (name === 'product_code') {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (name === 'model_code') {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          request_status: locationState.request_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 체크박스 선택
  const onCheck = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) { // 추가
      setCheckList(thisState => {
        let state = [...thisState];
        state.push(value);
        return state;
      });
    } else {  // 제거
      setCheckList(thisState => {
        let state = [...thisState];
        state.splice(state.indexOf(value), 1);
        return state;
      });
    }
  }

  // 저장 콜백 함수
  const onSaveCallback = () => {
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  // 발송하기 모달 열기/닫기
  const finishOpen = () => {
    if (finishReg) {
      setFinishReg(false);
    } else {
      setFinishReg(true);
    }
  }

  // 삭제
  const onSave = async () => {
    setSaveLoading(true);
    var param = {
    };
    try {
      await RequestService.deleteRequest(requestSeq, param);
      confirmOpen();
      confirmAlertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setSaveLoading(false);
    }
  }

  // 발송완료 컨펌창 열기/닫기
  const confirmOpen = (request_seq) => {
    if (confirm) {
      setRequestSeq(null);
      setConfirm(false);
    } else {
      setRequestSeq(request_seq);
      setConfirm(true);
    }
  }

  // 저장 완료 알럿
  const confirmAlertOpen = () => {
    if (confirmAlert) {
      setConfirmAlert(false);

      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    } else {
      setConfirmAlert(true);
    }
  }

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      onMore(); // 더보기
    }
  }, [onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/stock/return/list',
        state: {
          search_start_date: '',
          search_end_date: '',
          request_status: '',
          product_group: '',
          level: '',
          model_code: '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: ''
        }
      });
    }

    if (state) {
      const searchStartDate = state.search_start_date;
      const searchEndDate = state.search_end_date;
      const requestStatus = state.request_status;
      const productGroup = state.product_group;
      const level = state.level;
      const modelCode = state.model_code;
      const productCode = state.product_code;
      const assyCode = state.assy_code;
      const partCode = state.part_code;
      const searchWord = state.search_word;

      // 발송일
      if (searchStartDate || searchEndDate) {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = searchStartDate + ' ~ ' + searchEndDate;
          return state;
        });
      } else {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 상태
      if (requestStatus) {
        let requestStatusName = '';
        switch (requestStatus) {
          case 'R':
            requestStatusName = '발송대기';
            break;
          case 'C':
            requestStatusName = '발송완료';
            break;
          default:
            break;
        }

        const id = 1;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = requestStatusName;
          return state;
        });
      } else {
        const id = 1;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 품목군
      if (productGroup) {
        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = productGroup;
          return state;
        });
      } else {
        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 단계
      if (level) {
        let levelName = '';
        switch (level) {
          case '1':
            levelName = '제품';
            break;
          case '2':
            levelName = '아세이';
            break;
          case '3':
            levelName = '부품';
            break;
          default:
            break;
        }

        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = levelName;
          return state;
        });

      } else {
        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 모델
      if (modelCode) {
        const $model_code = document.querySelector("[name=model_code]");

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $model_code.options[$model_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 제품
      if (productCode) {
        const $product_code = document.querySelector("[name=product_code]");

        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $product_code.options[$product_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 모델
      if (assyCode) {
        const $assy_code = document.querySelector("[name=assy_code]");

        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $assy_code.options[$assy_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 부품
      if (partCode) {
        const $part_code = document.querySelector("[name=part_code]");

        const id = 7;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $part_code.options[$part_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 7;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 키워드
      if (searchWord) {
        const id = 8;
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = searchWord;
          return state;
        });
      } else {
        setKeyword(searchWord);
        const id = 8;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }
    }
  }, [location, history, modelList, productList, assyList, partList]);

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.search_word = event.target.value;

    history.replace({
      pathname: '/stock/return/list',
      state: {
        search_start_date: locationState.search_start_date || '',
        search_end_date: locationState.search_end_date || '',
        request_status: locationState.request_status || '',
        product_group: locationState.product_group || '',
        level: locationState.level || '',
        model_code: locationState.model_code || '',
        product_code: locationState.product_code || '',
        assy_code: locationState.assy_code || '',
        part_code: locationState.part_code || '',
        search_word: locationState.search_word || ''
      }
    });
  }

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회 
    searchAssyList(); // 아세이 리스트 조회
    searchPartList(); // 부품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList, searchAssyList, searchPartList]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
  }, []);

  return (
    <>
      <div style={{ paddingBottom: "85px" }}>
        <div className="top-filter">
          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>
          <button type="button" className="btn c-black-line" onClick={() => Util.modal(filter, setFilter)}><span className="txt ft">필터</span></button>

          <div className={`filter-container ${filter ? '_openFilter' : null}`}>
            <header className="filter-header flex">
              <strong className="tit">필터</strong>
              <nav>
                <button type="button" className="btn c-black-line" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
                <button type="button" className="btn-close" onClick={() => Util.modal(filter, setFilter)}><span className="a11y">닫기</span></button>
              </nav>
            </header>


            <div className="select-container" data-selector="selectContainer">
              {
                searchTag.join("") === ""
                  ? <p className="dec">선택 필터가 없습니다.</p>
                  : (
                    searchTag.map((item, index) => {
                      return (
                        item
                          ? (
                            <span className="tag" data-selector="tag" key={index}>
                              {item}
                              <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                            </span>
                          ) : null
                      );
                    })
                  )
              }
            </div>

            <div className="dl-wrap t2">
              <dl className="dl-item">
                <dt>발송일</dt>
                <dd className="flex">
                  <div className="col">
                    <Datetime
                      inputProps={{
                        id: "startDate",
                        name: "startDate",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      onChange={onChangeSearchStartDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.search_start_date} disabled={baseInput} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                  <div className="col">
                    <Datetime
                      inputProps={{
                        id: "endDate",
                        name: "endDate",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      onChange={onChangeSearchEndDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.search_end_date} disabled={baseInput} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                  <div className="col w100">
                    <label className="chk-label">
                      <input type="checkbox" data-action="date" onClick={resetSearchDate} />
                      <span className="txt">미입력</span>
                    </label>
                  </div>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>상태</dt>
                <dd className="flex chk-flex">
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="request_status"
                        onChange={onChangeFilter}
                        value=""
                        checked={location.state && location.state.request_status === "" ? true : false} />
                      <span className="txt">전체</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="request_status"
                        onChange={onChangeFilter}
                        value="R"
                        checked={location.state && location.state.request_status && location.state.request_status.indexOf('R') > -1 ? true : false} />
                      <span className="txt">발송대기</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="request_status"
                        onChange={onChangeFilter}
                        value="C"
                        checked={location.state && location.state.request_status && location.state.request_status.indexOf('C') > -1 ? true : false} />
                      <span className="txt">발송완료</span>
                    </label>
                  </div>
                </dd>
              </dl>

              <div className="dl-group">
                <dl className="dl-item">
                  <dt>품목군</dt>
                  <dd>
                    <select onChange={onChangeFilter} name="product_group" value={location.state && location.state.product_group}>
                      <option value="">전체</option>
                      {
                        !productGroupList.length ?
                          <></>
                          :
                          productGroupList.map((item, index) => {
                            return (
                              <option value={item.product_group} key={index}>{item.product_group}</option>
                            );
                          })
                      }
                    </select>
                  </dd>
                </dl>

                <dl className="dl-item">
                  <dt>단계</dt>
                  <dd>
                    <select onChange={onChangeFilter} name="level" value={location.state && location.state.level}>
                      <option value="">전체</option>
                      <option value="1">제품</option>
                      <option value="2">아세이</option>
                      <option value="3">부품</option>
                    </select>
                  </dd>
                </dl>
              </div>

              <dl className="dl-item">
                <dt>모델명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="model_code" value={location.state && location.state.model_code}>
                    <option value="">전체</option>
                    {
                      !modelList.length ?
                        <></>
                        :
                        modelList.map((item, index) => {
                          return (
                            <option value={item.parent_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>제품명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="product_code" value={location.state && location.state.product_code}>
                    <option value="" data-level="">{location.state && location.state.model_code ? '전체' : '모델명을 선택하세요.'}</option>
                    {
                      !productList.length ?
                        <></>
                        :
                        productList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} data-level={item.level} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.crm_product_name + ' ' + item.product_standard
                              }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>아세이명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="assy_code" value={location.state && location.state.assy_code}>
                    <option value="">{location.state && location.state.product_code ? '전체' : '제품명을 선택하세요.'}</option>
                    {
                      !assyList.length ?
                        <></>
                        :
                        assyList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.crm_product_name + ' ' + item.product_standard
                              }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>부품명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="part_code" value={location.state && location.state.part_code}>
                    <option value="">{location.state && location.state.assy_code ? '전체' : '부품명을 선택하세요.'}</option>
                    {
                      !partList.length ?
                        <></>
                        :
                        partList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.crm_product_name + ' ' + item.product_standard
                              }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>키워드</dt>
                <dd>
                  <input type="text" placeholder="품목명 또는 규격 입력 후 검색하세요." onChange={(event) => setKeyword(event.target.value)} onBlur={onBlurKeyword} value={keyword} />
                </dd>
              </dl>

              <button type="button" data-action="search" className="btn c-blue s1" onClick={onFilterSearch}>검색하기</button>
            </div>
          </div>
        </div>

        <div className="prd-list t2">
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <dl className="prd-dl" key={index}>
                    <dt>
                      <label className={item.request_status === 'R' && "chk-label"}>
                        {
                          item.request_status === 'R' && // 발송대기
                          <input type="checkbox" value={item.request_seq} onChange={(event) => onCheck(event)}
                          />
                        }
                        <span className="txt">
                          <p>품목군</p>
                          <strong className="prd">{item.product_group}</strong>
                        </span>
                      </label>
                    </dt>
                    <dd>
                      <div className="over-container">
                        <div className="over-wrap return">
                          <dl className="dl-wrap">
                            <dt>구분</dt>
                            <dd>
                              {item.return_type === 'G' && '양품반품'}
                              {item.return_type === 'B' && '불량반품'}
                            </dd>
                          </dl>
                          <dl className="dl-wrap">
                            <dt>품목</dt>
                            <dd dangerouslySetInnerHTML={{
                              __html: item.crm_product_name ? item.crm_product_name : '-'
                            }}></dd>
                          </dl>
                          <dl className="dl-wrap">
                            <dt>규격</dt>
                            <dd dangerouslySetInnerHTML={{
                              __html: item.product_standard ? item.product_standard : '-'
                            }}></dd>
                          </dl>
                          {
                            item.level === '1' ?
                              <dl className="dl-wrap">
                                <dt>S/N</dt>
                                <dd dangerouslySetInnerHTML={{
                                  __html: item.product_serial_number ? item.product_serial_number : '-'
                                }}></dd>
                              </dl>
                              :
                              <dl className="dl-wrap">
                                <dt>수량</dt>
                                <dd dangerouslySetInnerHTML={{
                                  __html: item.quantity ? item.quantity : '-'
                                }}></dd>
                              </dl>
                          }
                          <dl className="dl-wrap">
                            <dt>상태</dt>
                            <dd>
                              {item.request_status === 'R' && <strong className="t-red">발송대기</strong>}
                              {item.request_status === 'C' && <strong className="t-sky">발송완료</strong>}
                            </dd>
                          </dl>
                          {
                            item.request_status === 'C' && // 발송완료
                            <>
                              <dl className="dl-wrap">
                                <dt>출고일</dt>
                                <dd>{Util.formatDate(item.shipping_date)}</dd>
                              </dl>
                              <dl className="dl-wrap">
                                <dt>택배사</dt>
                                <dd dangerouslySetInnerHTML={{
                                  __html: item.delivery_company
                                }}></dd>
                              </dl>
                              <dl className="dl-wrap">
                                <dt>운송장번호</dt>
                                <dd>{item.delivery_number}</dd>
                              </dl>
                            </>
                          }
                        </div>
                        <div className="over-btt">
                          {
                            item.request_status === 'C' && // 발송완료
                            <button type="button" className="btn-over" onClick={(event) => detailOpen(event)}><span className="a11y">더보기</span></button>
                          }
                          <dl className="dl-wrap">
                            <dt dangerouslySetInnerHTML={{
                              __html: item.reg_user_name ? item.reg_user_name : '-'
                            }}></dt>
                            <dd>{Util.formatDateTimeHHMM(item.reg_datetime)}</dd>
                          </dl>
                          {
                            item.request_status === 'R' && // 발송대기
                            <button type="button" className="btn-del" onClick={() => confirmOpen(item.request_seq)}><span className="a11y">삭제</span></button>
                          }
                        </div>
                      </div>
                    </dd>
                  </dl>
                )
              })
          }
          {
            loading &&
            <Progress
              loading={loading}
              fixed={filter}
            />
          }
        </div>
        <div className="btn-wrap fixed">
          <ul className="btn-flex">
            <li><Link to="/stock/return/reg" className="btn c-sky">등록하기</Link></li>
            <li><button type="button" className={`btn${checkList.length > 0 ? " c-sky" : " c-gray"}`} onClick={finishOpen} disabled={checkList.length > 0 ? false : true}>발송하기</button></li>
          </ul>
        </div>
      </div>

      {/* 반품 요청 완료 레이어 팝업 */}
      {
        finishReg &&
        <RegFinish
          requestList={list}
          prodStatus="return"
          finishReg={finishReg}
          setFinishReg={setFinishReg}
          finishOpen={finishOpen}
          checkList={checkList}
          onSaveCallback={onSaveCallback}
        />
      }
      {/* ./반품 요청 완료 레이어 팝업 */}

      {
        confirm &&
        <Confirm
          dec="삭제하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={onSave}
        />
      }

      {
        confirmAlert &&
        <Alert
          dec="저장되었습니다."
          alert={confirmAlert}
          alertOpen={confirmAlertOpen}
        />
      }

      <Progress
        loading={saveLoading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default ReturnList;