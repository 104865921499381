import { useEffect, useContext } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import qs from 'query-string';

import Header from '../layout/Header';
import SubHeader from '../layout/SubHeader';

import LoginRouter from './LoginRouter';
import HomeRouter from './HomeRouter';
import ServiceRouter from './ServiceRouter';
import StockRouter from './StockRouter';
import OrderRouter from './OrderRouter';
import MypageRouter from './MypageRouter';
import NotificationRouter from './NotificationRouter';
import BoardRouter from './BoardRouter';
import SettingRouter from './SettingRouter';
import ErrorRouter from './ErrorRouter';

import { AppContext } from '../AppContext';

const RootRouter = () => {
  const { auth } = useContext(AppContext);
  const location = useLocation();
  const { search } = location;
  const params = qs.parse(decodeURI(search));

  /* 바로가기 설정 */
  // 공지사항
  if (params.notice_seq) {
    window.localStorage.setItem('notice_seq', params.notice_seq);
  }
  // 서비스
  if (params.service_seq) {
    window.localStorage.setItem('service_seq', params.service_seq);
    window.localStorage.setItem('service_status', params.service_status);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Route
        render={({ location, history }) => {
          const pathName = location.pathname;
          return (
            pathName.indexOf('/home') > -1
              ? <Header auth={auth} />
              : pathName.indexOf('/login') < 0 ?
                <SubHeader auth={auth} location={location} history={history} />
                : <></>
          )
        }}
      ></Route>

      <div id="container">
        <div id="content">
          <Route
            render={({ location }) => {
              return (
                <Switch location={location}>
                  <Route path="/login" component={LoginRouter} />
                  <Route path="/home" component={HomeRouter} />
                  <Route path="/service" component={ServiceRouter} />
                  <Route path="/stock" component={StockRouter} />
                  <Route path="/order" component={OrderRouter} />
                  <Route path="/mypage" component={MypageRouter} />
                  <Route path="/notification" component={NotificationRouter} />
                  <Route path="/board/notice" component={BoardRouter} />
                  <Route path="/setting/terms" component={SettingRouter} />
                  <Route path="/error" component={ErrorRouter} />
                  <Redirect exact path="/" to="/home" />
                  <Redirect path="/*" to="/error" />
                </Switch>
              );
            }}
          ></Route>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default RootRouter;
