import Service from './Index';

const StockService = {
  // 재고 리스트 조회
  fetchItems: (param) => {
    return Service.get('stock', param);
  },

  // 재고 상세 리스트 조회
  fetchItem: (child_product_code, param) => {
    return Service.get('stock/' + child_product_code, param);
  },

  // 입고대기 리스트 조회
  fetchReleaseItems: (param) => {
    return Service.get('incoming/release', param);
  },

  // 출고 리스트 조회
  fetchReleaseAllItems: (param) => {
    return Service.get('incoming/release/all', param);
  },

  // 입고완료 리스트 조회
  fetchIncomingItems: (param) => {
    return Service.get('incoming/incoming', param);
  },

  // 입고완료 상세 리스트 조회
  fetchIncomingDetailItems: (param) => {
    return Service.get('incoming/incoming/detail', param);
  },

  // 입고 등록(제품)
  registProduct: (param) => {
    return Service.post('incoming/product', param);
  },

  // 입고 등록(부품)
  registParts: (param) => {
    return Service.post('incoming/parts', param);
  },

  // 재고 리스트 조회(요청등록)
  fetchRequestItems: (param) => {
    return Service.get('stock/request', param);
  },
};

export default StockService;

