import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from '../../AppContext';
import ClientService from '../../services/ClientService';
import ServiceService from '../../services/ServiceService';
import PostCode from "../../layout/PostCode";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Util from "../../helpers/Util";
import Progress from "../../layout/Progress";

const ServiceReg = () => {
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const [keyword, setKeyword] = useState('');
  const [clientSearch, setClientSearch] = useState(false);
  const [post, setPost] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");
  const [completeAlert, setCompleteAlert] = useState(false);
  const [client, setClient] = useState(null);
  const [clientSeq, setClientSeq] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [counselingType, setCounselingType] = useState("");
  const [isEmergency, setIsEmergency] = useState(false);
  const [contractSeq, setContractSeq] = useState("");
  const [productSeq, setProductSeq] = useState("");
  const [firstChildProductCode, setfirstChildProductCode] = useState("");
  const [secondChildProductCode, setSecondChildProductCode] = useState("");
  const [salesSeq, setSalesSeq] = useState("");
  const [productName, setProductName] = useState("");
  const [firstInstallDatetime, setFirstInstallDatetime] = useState("");
  const [counselingContent, setCounselingContent] = useState("");
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델(모품목) 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [productGroup, setProductGroup] = useState("");
  const [modelChildProductCode, setModelChildProductCode] = useState("");

  // 고객 리스트 조회
  const searchClientList = useCallback(async () => {
    setLoading(true);
    const param = {
      searchText: keyword,
      is_del: 0
    };
    try {
      const result = await ClientService.fetchItems(param);
      const data = result.data;
      const list = data.list;
      list.forEach(item => {
        item.quantity = 0;
      });

      setClientList(list);

      if (list.length === 0) {
        setClientSearch(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [keyword]);

  // 계약 리스트 조회
  const searchContractList = useCallback(async () => {
    if (client) {
      setLoading(true);
      const param = {
        client_seq: client.client_seq
      };
      try {
        const result = await ClientService.fetchContracts(param);
        const data = result.data;
        const list = data.list;
        list.forEach(item => {
          item.quantity = 0;
        });

        setContractList(list);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, [client]);

  // 검색어 입력
  const onChangeKeyword = (event) => {
    setKeyword(event.target.value);
  }

  // 검색 버튼 핸들러
  const onClickClientSearch = () => {
    if (keyword.length > 1) {
      searchClientList();
      setClientSearch(true);
    } else {
      setAlertDec('검색어는 2글자 이상 입력하세요.');
      alertOpen();
    }
  }

  // 검색 결과 선택
  const selectInfo = (item) => {
    setClient(item);
    setClientSeq(item.client_seq);
    setContact(item.contacts.split(',')[0]);
    setAddress(Util.unescapeHtml(item.address));
    setOrgAddress(Util.unescapeHtml(item.address));
    setZipCode(item.zip_code);
    setClientSearch(false);
    setContractSeq('');
    setSalesSeq('');
    setProductSeq('');
    setfirstChildProductCode('');
    setSecondChildProductCode('');
    setProductName('');
    setFirstInstallDatetime('');
    setCounselingType('');
  }

  // 계약 선택
  const onChangeContract = (event) => {
    let seq = event.target.value;
    setContractSeq(seq);
    const result = contractList.filter((element) => {
      return element.contract_seq === Number(seq);
    });
    if (result.length) {
      setProductSeq(result[0].product_seq);
      setfirstChildProductCode(result[0].first_child_product_code);
      setSecondChildProductCode(result[0].second_child_product_code);
      setProductName(result[0].crm_product_name);
      setFirstInstallDatetime(result[0].first_install_datetime);
      setSalesSeq(result[0].sales_seq);
    }
  }

  // 주소 검색 레이어 팝업
  const postOpen = () => {
    if (post) {
      setPost(false);
    } else {
      setPost(true);
    }
  };

  // 주소 입력
  const onChangeAddress = (event) => {
    setAddress(event.target.value);
  }

  // 주소 검색 결과
  const postResult = (val, zip) => {
    setAddress(thisState => {
      let result = val;
      let tempOrgAddress = thisState.replace(/ /gi, "").toUpperCase();
      let daumAddress = val.replace(/ /gi, "").toUpperCase();
      if (tempOrgAddress.indexOf(daumAddress) > 0) {
        result = tempOrgAddress;
      }
      return result;
    });
    setZipCode(zip);
  };

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: productGroup,
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [productGroup]);

  // 자품목 리스트 조회
  const searchProductList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: productGroup,
      upper_child_product_code: modelChildProductCode,
      level: 1
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setProductList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [productGroup, modelChildProductCode]);

  // 품목군 변경 핸들러
  const onChangeProductGroup = (event) => {
    setProductGroup(event.target.value);
    setProductSeq('');
  }

  // 모델명 변경 핸들러
  const onChangeModel = (event) => {
    setModelChildProductCode(event.target.selectedOptions[0].dataset.code);
    setProductSeq(event.target.value);
  }

  // 저장 버튼 핸들러
  const onClickSave = () => {
    // 검증
    if (clientSeq === '') {
      setAlertDec('고객을 선택하세요.');
      setAlert(true);
      return;
    }
    if (!address) {
      setAlertDec('주소를 입력하세요.');
      setAlert(true);
      return;
    }
    if (!zipCode) {
      setAlertDec('우편번호 정보가 없습니다.\n주소를 검색하세요.');
      setAlert(true);
      return;
    }
    if (!contractSeq) {
      setAlertDec('계약번호를 선택하세요.');
      setAlert(true);
      return;
    }

    if (contractSeq === 'UNKWON') {
      if (!productSeq) {
        setAlertDec('제품을 선택하세요.');
        setAlert(true);
        return;
      }
      if (!firstChildProductCode) {
        setAlertDec('1차기 제품을 선택하세요.');
        setAlert(true);
        return;
      }
      if (!secondChildProductCode) {
        setAlertDec('2차기 제품을 선택하세요.');
        setAlert(true);
        return;
      }
    } else {
      if (!productSeq) {
        setAlertDec('제품정보가 없습니다.');
        setAlert(true);
        return;
      }
    }

    if (!counselingType) {
      setAlertDec('접수 유형을 선택하세요.');
      setAlert(true);
      return;
    }
    if (!counselingContent) {
      setAlertDec('상담내용을 입력하세요.');
      setAlert(true);
      return;
    }

    confirmOpen();  // 컨펌창 열기
  }

  // 취소
  const resetReg = () => {
    setKeyword("");
    setClient(null);
    setClientSearch(false);
  }

  // 컨펌창 열기
  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  // 저장
  const saveCounseling = async () => {
    setConfirm(false);
    setLoading(true);
    let param = {
      address: address,
      centerSeq: user.center_seq,
      clientSeq: clientSeq,
      cnsltContent: counselingContent,
      cnsltDiv: 'S',
      cnsltStatus: 'R',
      cnsltType: counselingType,
      contractSeq: contractSeq,
      isEmerg: isEmergency ? '1' : '0',
      salesSeq: salesSeq,
      productSeq: productSeq,
      firstChildProductCode: firstChildProductCode,
      secondChildProductCode: secondChildProductCode,
      regType: 'C',
      zipCode: zipCode
    };
    try {
      await ServiceService.newCounseling(param);
      setCompleteAlert(true);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 알럿창 열기
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }

  // 저장 완료 후 초기화
  const onCmpleteAlert = () => {
    setKeyword('');
    setClient(null);
    setClientSeq("");
    setContact("");
    setAddress("");
    setZipCode("");
    setCounselingType("");
    setIsEmergency(false);
    setContractSeq("");
    setProductSeq("");
    setSalesSeq("");
    setProductName("");
    setCounselingContent("");
    setCompleteAlert(false);
  }

  useEffect(() => {
    searchContractList(); // 고객 계약 리스트 조회
  }, [searchContractList]);

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
  }, [])

  return (
    <>
      <div className={`search-container${clientList.length ? " _result" : ""}`} data-selector="searchContainer">
        <div className="form-container">
          <label className="search-label">
            <input type="text" placeholder="고객명 또는 연락처 또는 주소 검색" value={keyword} onChange={onChangeKeyword} />
            <button type="button" className="btn-search" onClick={onClickClientSearch} ><span className="a11y">검색</span></button>
          </label>

          <div className={`result-container${clientSearch ? " _active" : ""}`} data-selector="resultContainer">
            {clientList.length
              ? (
                <ul className="chk-list" data-selector="resultAppend">
                  {
                    clientList.map((item, index) => {
                      return (
                        <li key={index}>
                          <label className="chk-label t2">
                            <input type="radio" name="search" data-sid={item.client_seq} onClick={() => selectInfo(item)} />
                            <address className="txt">
                              <small className="info">{item.name[0]}** {Util.formatHiddenPhoneNumber(item.contacts.split(',')[0])}</small>
                              <p className="add" dangerouslySetInnerHTML={{
                                __html: item.address
                              }}></p>
                            </address>
                          </label>
                        </li>
                      )
                    })
                  }
                </ul>
              ) : null
            }
          </div>
        </div>
        {
          client
            ? (
              <div className="service-reg">
                <div className="form-wrap">
                  <dl className="form-dl">
                    <dt><span className="req">고객명</span></dt>
                    <dd>
                      <input type="text" value={client.name[0] + '**'} readOnly />
                    </dd>
                  </dl>

                  <dl className="form-dl">
                    <dt><span className="req">연락처</span></dt>
                    <dd>
                      <input type="text" value={Util.formatHiddenPhoneNumber(contact)} readOnly />
                    </dd>
                  </dl>

                  <dl className="form-dl">
                    <dt><span className="req">주소</span></dt>
                    <dd>
                      <label className="search-label">
                        <input type="text" value={address} onChange={onChangeAddress} />
                        <button type="button" className="btn-search" onClick={postOpen}><span className="a11y">검색</span></button>
                      </label>
                      <p dangerouslySetInnerHTML={{
                        __html: orgAddress
                      }}></p>
                    </dd>
                  </dl>

                  <dl className="form-dl">
                    <dt><span className="req">계약번호</span></dt>
                    <dd>
                      <div>
                        <select onChange={(event) => onChangeContract(event)} value={contractSeq}>
                          <option value="">선택</option>
                          {
                            contractList.length > 0 ?
                              contractList.map((item, index) => {
                                return (
                                  <option key={index} value={item.contract_seq}>{item.contract_number}{item.approval_datetime ? '' : '(설치이력없음)'}</option>
                                );
                              })
                              :
                              <option value="UNKWON">확인불가</option>
                          }
                        </select>
                      </div>
                    </dd>
                  </dl>

                  {
                    contractSeq === 'UNKWON' ?
                      <>
                        <dl className="form-dl">
                          <dt>품목군</dt>
                          <dd>
                            <select onChange={onChangeProductGroup} value={productGroup}>
                              <option value="">전체</option>
                              {
                                productGroupList.length &&
                                productGroupList.map((item, index) => {
                                  return (
                                    <option value={item.product_group} key={index}>{item.product_group}</option>
                                  );
                                })
                              }
                            </select>
                          </dd>
                        </dl>
                        <dl className="form-dl">
                          <dt><span className="req">제품</span></dt>
                          <dd>
                            <select onChange={onChangeModel} value={productSeq}>
                              <option value="">전체</option>
                              {
                                modelList.length &&
                                modelList.map((item, index) => {
                                  return (
                                    <option value={item.product_seq} key={index} data-code={item.child_product_code} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name + ' ' + item.product_standard
                                    }} >
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </dd>
                        </dl>
                        <dl className="form-dl">
                          <dt><span className="req">1차기 제품</span></dt>
                          <dd>
                            <select onChange={event => setfirstChildProductCode(event.target.value)} value={firstChildProductCode}>
                              <option value="">전체</option>
                              {
                                productList.map((item, index) => {
                                  return (
                                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name + ' ' + item.product_standard
                                    }} >
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </dd>
                        </dl>
                        <dl className="form-dl">
                          <dt><span className="req">2차기 제품</span></dt>
                          <dd>
                            <select onChange={event => setSecondChildProductCode(event.target.value)} value={secondChildProductCode}>
                              <option value="">전체</option>
                              {
                                productList.map((item, index) => {
                                  return (
                                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name + ' ' + item.product_standard
                                    }} >
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </dd>
                        </dl>
                      </>
                      :
                      <dl className="form-dl">
                        <dt>제품</dt>
                        <dd>
                          <p className="dec">
                            {productName ? productName : '-'}
                          </p>
                        </dd>
                      </dl>
                  }
                  <dl className="form-dl">
                    <dt>최초설치일</dt>
                    <dd>
                      <p className="dec">
                        {firstInstallDatetime ? Util.formatDate(firstInstallDatetime) : '-'}
                      </p>
                    </dd>
                  </dl>

                  <dl className="form-dl">
                    <dt><span className="req">접수 유형</span></dt>
                    <dd>
                      <div className="type-flex flex">
                        <select onChange={event => setCounselingType(event.target.value)} value={counselingType}>
                          <option value="">선택</option>
                          <option value="04">AS요청</option>
                          <option value="05">철거요청</option>
                          {/* <option value="06">이전요청</option> */}
                          <option value="08">이전설치요청</option>
                          <option value="09">이전철거요청</option>
                        </select>
                        <label className="chk-label">
                          <input type="checkbox" onChange={event => setIsEmergency(event.target.checked)} value={isEmergency} />
                          <span className="txt">긴급</span>
                        </label>
                      </div>
                    </dd>
                  </dl>

                  <dl className="form-dl">
                    <dt><span className="req">상담내용</span></dt>
                    <dd>
                      <textarea placeholder="상담 내용을 입력하세요." onChange={(event) => setCounselingContent(event.target.value)} autoCapitalize="off" value={counselingContent}></textarea>
                    </dd>
                  </dl>
                </div>

                <ul className="btn-flex">
                  <li className="col1"><button type="button" className="btn c-gray" onClick={resetReg}>취소</button></li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={onClickSave}>저장</button></li>
                </ul>
              </div>
            )
            : <div className="no-data" data-selector="noData">
              <p className="dec">
                고객 정보를 검색 후<br />선택하세요.
              </p>
            </div>
        }
      </div>

      {/* 주소 검색 레이어 팝업 */}
      {
        post &&
        <PostCode
          post={post}
          address={address}
          postResult={postResult}
          postOpen={postOpen}
        />
      }
      {/* ./주소 검색 레이어 팝업 */}

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec="저장하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={saveCounseling}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
      {/* ./알럿창 */}

      {/* 알럿창 */}
      {
        completeAlert &&
        <Alert
          dec='저장되었습니다.'
          alert={completeAlert}
          alertOpen={onCmpleteAlert}
        />
      }
      {/* ./알럿창 */}

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true} // 화면 전체 레이어로 덮는 형태
        />
      }
    </>
  )
}

export default ServiceReg;