import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import RegReceivingTempList from "../../layout/stock/RegReceivingTempList";
import ProductAdd from "../../layout/stock/ProductAdd";
import FilterItem from "../../layout/FilterItem";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import ServiceService from '../../services/ServiceService';
import StockService from '../../services/StockService';

const ReceivingList = () => {
  const history = useHistory();
  const location = useLocation();
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", "", ""]);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState(false);
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트
  const [quantity, setQuantity] = useState(0);
  const [prodStatus, setProdStatus] = useState("");
  const [prodAdd, setProdAdd] = useState(false);
  const [prodCode, setProdCode] = useState("");
  const [releaseSeq, setReleaseSeq] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState('');
  const [item, setItem] = useState(null);
  const [scanList, setScanList] = useState([]);
  const [tempListReg, setTempListReg] = useState(false);

  // 검색버튼 클릭 핸들러
  const onClickSearch = () => {
    setCurrentPage(1);
  }

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: location.state ? location.state.query_product_group : '',
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [location]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    if (location.state && location.state.query_model_name) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.query_product_group : '',
        upper_child_product_code: location.state ? location.state.query_model_name : '',
        level: 1
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setProductList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    if (location.state && location.state.query_product_name) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.query_product_group : '',
        parent_product_code: location.state ? location.state.query_model_name : '',
        upper_child_product_code: location.state ? location.state.query_product_name : '',
        level: 2
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setAssyList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    if (location.state && location.state.query_asei_name) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.query_product_group : '',
        parent_product_code: location.state ? location.state.query_model_name : '',
        upper_child_product_code: location.state ? location.state.query_asei_name : '',
        level: 3
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setPartList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize,
      incoming_status: "I"
    };
    Object.assign(param, location.state);
    try {
      let result;

      // 입고 상태에 따른 api 호출
      switch (location.state.receive_status) {
        case "R": //입고대기
          result = await StockService.fetchReleaseItems(param);
          break;
        case "I": //입고완료
          result = await StockService.fetchIncomingItems(param);
          break;
        default:
          result = await StockService.fetchReleaseItems(param);
          break;
      }

      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 출고시작일
  const onChangeStartDate = (event) => {
    history.replace({
      pathname: '/stock/receiving/list',
      state: {
        query_start_release: Util.formatDate(event), //출고시작일
        query_end_release: location.state.query_end_release || '', //출고종료일
        receive_status: location.state.receive_status || '', //상태
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '', //단계(레벨)
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 출고종료일
  const onChangeEndDate = (event) => {
    history.replace({
      pathname: '/stock/receiving/list',
      state: {
        query_start_release: location.state.query_start_release, //출고시작일
        query_end_release: Util.formatDate(event) || '', //출고종료일
        receive_status: location.state.receive_status || '', //상태
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '', //단계(레벨)
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 상태
  const onChangeStatus = (event) => {
    setCurrentPage(1);
    const name = event.target.name;

    if (event.target.value) {
      location.state[name] = event.target.value;
    } else {
      location.state[name] = '';
    }

    history.replace({
      pathname: '/stock/receiving/list',
      state: {
        query_start_release: location.state.query_start_release || '', //출고시작일
        query_end_release: location.state.query_end_release || '', //출고종료일
        receive_status: location.state.receive_status || '', //상태
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '', //단계(레벨)
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);

    let locationState = location.state || {};

    if (idx === 0) { //시작, 종료일
      locationState.query_start_release = '';
      locationState.query_end_release = '';
    }
    if (idx === 1) { // 품목군
      locationState.query_product_group = '';
    }
    if (idx === 2) { // 단계
      locationState.query_level = '';
    }
    if (idx === 3) { // 모델
      locationState.query_model_name = '';
    }
    if (idx === 4) { // 제품
      locationState.query_product_name = '';
    }
    if (idx === 5) { // 아세이
      locationState.query_asei_name = '';
    }
    if (idx === 6) { // 부품
      locationState.query_part_name = '';
    }
    if (idx === 7) { // 키워드
      locationState.query_release_keyword = '';
    }

    if (idx === 5) {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: locationState.query_product_name || '',
          query_asei_name: locationState.query_asei_name || '',
          query_part_name: '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    } else if (idx === 4) {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: locationState.query_product_name || '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    } else if (idx === 3) {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: locationState.query_product_name || '',
          query_asei_name: locationState.query_asei_name || '',
          query_part_name: locationState.query_part_name || '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    }
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    history.replace({
      pathname: '/stock/receiving/list',
      state: {
        query_start_release: '', //요청시작일
        query_end_release: '', //요청종료일
        query_product_group: '', //품목군
        query_level: '', //단계(레벨)
        query_model_name: '', //모델명
        query_product_name: '', //제품명
        query_asei_name: '', //아세이명
        query_part_name: '', //부품명
        query_release_keyword: '' //키워드
      }
    });
  }, [history]);

  // 입고등록
  const receiveOpen = (event, item) => {
    setItem(item);
    // 단계1 네이티브 연속 바코드 스캐너 기능
    if (Number(item.level) === 1) {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.OPEN_MULTI_SCANNER, {
          name: '입고 스캔',
          product_group: (item && item.product_group) || '',
          product_name: (item && item.crm_product_name) || '',
          standard: (item && item.product_standard) || '',
          image: (item && item.thmbn_file_url) || ''
        }, window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER);
        window[window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER] = function (response) {
          setScanList(response.list);
          tempListOpen();
        }
      }
    } else {
      // 단계2~3 아세이, 부품일 경우
      addOpen(event, item);
    }
  }

  // 바코드 리스트 저장 콜백 함수
  const onBarcodeSaveCallback = () => {
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  // 부품 수량입력 레이어 팝업
  const addOpen = (event, item) => {
    setProdStatus('receive');
    setQuantity(0);

    if (item) {
      setProdCode(item.child_product_code);
      setReleaseSeq(item.release_seq);
    }

    if (prodAdd) {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: false
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        }
      }
      setProdAdd(false);
    } else {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          setProdAdd(false);
        }
      }
      setProdAdd(true);
    }
  }

  // 입고 완료
  const onSaveReceiving = () => {
    setAlertDec('');
    if (quantity > 0) {
      confirmOpen();
    } else {
      setAlertDec('수량을 입력하세요.');
      alertOpen();
    }
  }

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.query_release_keyword = event.target.value;

    history.replace({
      pathname: '/stock/receiving/list',
      state: {
        query_start_release: location.state.query_start_release || '', //출고시작일
        query_end_release: location.state.query_end_release || '', //출고종료일
        receive_status: location.state.receive_status || '', //상태
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '', //단계(레벨)
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 바코드 리스트 레이어 팝업
  const tempListOpen = () => {
    const $body = document.body;

    if (tempListReg) {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: false
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        }
      }
      $body.classList.remove("_noscroll");
      setTempListReg(false);
    } else {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          $body.classList.remove("_noscroll");
          setTempListReg(false);
        }
      }
      $body.classList.add("_noscroll");
      setTempListReg(true);
    }
  }

  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      if (quantity > 0) {
        regProduct();
        setProdAdd(false);

        if (currentPage === 1) {
          searchList();
        } else {
          setCurrentPage(1);
        }
      }
      setConfirm(false);
      setAlert(true);
    }
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};
    let name = '';

    switch (event.target.name) {
      case 'product_group':
        name = 'query_product_group';
        break;
      case 'level':
        name = 'query_level';
        break;
      case 'model_code':
        name = 'query_model_name';
        break;
      case 'product_code':
        name = 'query_product_name';
        break;
      case 'assy_code':
        name = 'query_asei_name';
        break;
      case 'part_code':
        name = 'property';
        break;
      default:
        break;
    }

    locationState[name] = event.target.value;

    if (name === 'product_group') {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: '',
          query_product_name: '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else if (name === 'assy_code') {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: location.state.query_product_name || '',
          query_asei_name: location.state.query_asei_name || '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else if (name === 'product_code') {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: location.state.query_product_name || '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else if (name === 'model_code') {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: location.state.query_product_name || '',
          query_asei_name: location.state.query_asei_name || '',
          query_part_name: location.state.query_part_name || '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    }
  }

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회 
    searchAssyList(); // 아세이 리스트 조회
    searchPartList(); // 부품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList, searchAssyList, searchPartList]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(thisState => {
            return thisState + 1
          });
        }
      }
    }
  }, [loading, totalCount, currentPage]);

  // 입고완료 상세
  const receiveInfoOpen = (info) => {
    if (location.state && location.state.receive_status === "I") {
      if (!info) {
        return
      }
      history.push({
        pathname: '/stock/receiving/info',
        state: {
          release_seq: info.release_seq,
          incoming_center_seq: info.incoming_center_seq,
          release_date: Util.formatDate(info.release_date),
          child_product_code: info.child_product_code,
          product_group: info.product_group,
          crm_product_name: info.crm_product_name,
          product_standard: info.product_standard,
          quantity: info.quantity,
          thmbn_file_url: info.thmbn_file_url,
          level: info.level
        }
      });
    }
  }

  // 스크롤 최상단으로 이동
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      const scrollT = ('scroll', e.srcElement.scrollingElement.scrollTop);
      setScroll({ scrollT });
      onMore(); // 더보기
    }
  }, [setScroll, onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  // 입고 완료 (단계 2~3)
  const regProduct = async () => {
    const param = {
      child_product_code: prodCode,
      quantity: quantity,
      release_seq: releaseSeq
    };

    setLoading(true);
    try {
      const result = await StockService.registParts(param);
      setAlertDec('');

      if (result.result === 0) {
        setAlertDec('저장되었습니다.');
        setQuantity(0);

        if (currentPage === 1) {
          searchList();
        } else {
          setCurrentPage(1);
        }
      } else {
        setAlertDec('다시 시도해주세요.');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/stock/receiving/list',
        state: {
          query_start_release: '', //출고시작일
          query_end_release: '', //출고종료일
          receive_status: '', //상태
          query_product_group: '', //품목군
          query_level: '', //단계(레벨)
          query_model_name: '', //모델명
          query_product_name: '', //제품명
          query_asei_name: '', //아세이명
          query_part_name: '', //부품명
          query_release_keyword: '' //키워드
        }
      });
    }

    if (state) {
      if (!state.receive_status) {
        history.replace({
          pathname: '/stock/receiving/list',
          state: {
            query_start_release: state.query_start_release || '', //출고시작일
            query_end_release: state.query_end_release || '', //출고종료일
            receive_status: state.receive_status || 'R', //상태
            query_product_group: state.query_product_group || '', //품목군
            query_level: state.query_level || '', //단계(레벨)
            query_model_name: state.query_model_name || '', //모델명
            query_product_name: state.query_product_name || '', //제품명
            query_asei_name: state.query_asei_name || '', //아세이명
            query_part_name: state.query_part_name || '', //부품명
            query_release_keyword: state.query_release_keyword || '' //키워드
          }
        });
      }

      const startIncoming = state.query_start_release;
      const endIncoming = state.query_end_release;
      const productGroup = state.query_product_group;
      const level = state.query_level;
      const modelCode = state.query_model_name;
      const productCode = state.query_product_name;
      const assyCode = state.query_asei_name;
      const partCode = state.query_part_name;
      const releaseKeyword = state.query_release_keyword;

      // 입고일
      if (startIncoming && endIncoming) {
        let receiveDate = '';
        receiveDate = `출고일 ${startIncoming + ' ~ ' + endIncoming}`;

        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = receiveDate;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = '';
          return state;
        });
      }

      // 품목군
      if (productGroup) {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = location.state.query_product_group;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = '';
          return state;
        });
      }

      // 단계
      if (level) {
        let levelName = '';
        switch (level) {
          case '1':
            levelName = '제품';
            break;
          case '2':
            levelName = '아세이';
            break;
          case '3':
            levelName = '부품';
            break;
          default:
            levelName = '';
            break;
        }

        setSearchTag(thisState => {
          let state = [...thisState];
          state[2] = levelName;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[2] = '';
          return state;
        });
      }

      // 모델
      if (modelCode) {
        const $model_code = document.querySelector("[name=model_code]");

        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $model_code.options[$model_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 제품
      if (productCode) {
        const $product_code = document.querySelector("[name=product_code]");

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $product_code.options[$product_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 아세이
      if (assyCode) {
        const $assy_code = document.querySelector("[name=assy_code]");

        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $assy_code.options[$assy_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 부품
      if (partCode) {
        const $part_code = document.querySelector("[name=part_code]");

        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $part_code.options[$part_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 키워드
      if (releaseKeyword) {
        setKeyword(releaseKeyword);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[7] = releaseKeyword;
          return state;
        });
      } else {
        setKeyword(releaseKeyword);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[7] = '';
          return state;
        });
      }
    }
  }, [location, history, modelList, productList, assyList, partList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
  }, []);

  return (
    <>
      <div className="top-filter">
        <div className="select-container">
          {
            searchTag.join("") === ""
              ? <p className="dec">선택 필터가 없습니다.</p>
              : (
                searchTag.map((item, index) => {
                  return (
                    item
                      ? (
                        <span className="tag" data-selector="tag" key={index}>
                          {item}
                          <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                        </span>
                      ) : null
                  );
                })
              )
          }
        </div>
        <button type="button" className="btn c-black-line" onClick={() => Util.modal(filter, setFilter)}><span className="txt ft">필터</span></button>

        <div className={`filter-container ${filter ? '_openFilter' : null}`}>
          <header className="filter-header flex">
            <strong className="tit">필터</strong>
            <nav>
              <button type="button" className="btn c-black-line" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
              <button type="button" className="btn-close" onClick={() => Util.modal(filter, setFilter)}><span className="a11y">닫기</span></button>
            </nav>
          </header>

          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>

          <div className="dl-wrap t2">
            <dl className="dl-item">
              <dt>출고일</dt>
              <dd className="flex">
                <div className="col">
                  <Datetime
                    inputProps={{
                      id: "startDate",
                      name: "startDate",
                      readOnly: true
                    }}
                    locale="kr"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    closeOnSelect
                    onChange={onChangeStartDate}
                    renderInput={(props, openCalendar, closeCalendar) => {
                      return <>
                        <input {...props} value={location.state && location.state.query_start_release} />
                        <button type="button" className="btn-close" onClick={closeCalendar}>
                          <span className="a11y">날짜 선택 닫기</span>
                        </button>
                      </>
                    }}
                  />
                </div>
                <div className="col">
                  <Datetime
                    className={(location.state && location.state.query_start_release) ? "" : "disabled"}
                    inputProps={{
                      id: "endDate",
                      name: "endDate",
                      readOnly: true,
                      disabled: (location.state && location.state.query_start_release) ? false : true
                    }}
                    locale="kr"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    closeOnSelect
                    isValidDate={(event) => Util.validDt(event, location.state && location.state.query_start_release)}
                    onChange={onChangeEndDate}
                    renderInput={(props, openCalendar, closeCalendar) => {
                      return <>
                        <input {...props} value={location.state && location.state.query_end_release} />
                        <button type="button" className="btn-close" onClick={closeCalendar}>
                          <span className="a11y">날짜 선택 닫기</span>
                        </button>
                      </>
                    }}
                  />
                </div>
              </dd>
            </dl>

            <FilterItem
              productGroup={location.state && location.state.query_product_group}
              productGroupList={productGroupList}
              modelList={modelList}
              productList={productList}
              assyList={assyList}
              partList={partList}
              level={location.state && location.state.query_level}
              model={location.state && location.state.query_model_name}
              product={location.state && location.state.query_product_name}
              assy={location.state && location.state.query_asei_name}
              part={location.state && location.state.query_part_name}
              keyword={keyword}
              setKeyword={setKeyword}
              onBlurKeyword={onBlurKeyword}
              onChangeFilter={onChangeFilter}
            />

            <button type="button" data-action="search" className="btn c-blue s1" onClick={() => { Util.modal(filter, setFilter); onClickSearch() }}>검색하기</button>
          </div>
        </div>
      </div>

      <dl className="dl-item dl-tab">
        <dt className="a11y">상태</dt>
        <dd className="flex chk-flex">
          <div className="col">
            <label className="chk-label t-btn">
              <input type="radio" name="receive_status" onChange={onChangeStatus} value="R" checked={location.state && location.state.receive_status === "R" ? true : false} />
              <span className="txt">입고대기</span>
            </label>
          </div>
          <div className="col">
            <label className="chk-label t-btn">
              <input type="radio" name="receive_status" onChange={onChangeStatus} value="I" checked={location.state && location.state.receive_status === "I" ? true : false} />
              <span className="txt">입고완료</span>
            </label>
          </div>
        </dd>
      </dl>

      {/* 20개씩 페이징 처리 */}
      <div className="prd-list t2 receive">
        {
          !list.length ?
            loading ? <></>
              :
              <p className="no-data">검색 결과가 없습니다.</p>
            :
            list.map((item, index) => {
              return (
                <dl className="prd-dl" key={index} onClick={() => receiveInfoOpen(item)}>
                  <dt className="flex">
                    <dl className="dl-wrap">
                      <dt>출고일</dt>
                      <dd>{item.release_date ? Util.formatDate(item.release_date) : '-'}</dd>
                    </dl>
                    <dl className="dl-wrap">
                      <dt>상태</dt>
                      <dd>
                        {
                          (location.state && location.state.receive_status === "R") && <p className="t-red">입고대기</p>
                        }
                        {
                          (location.state && location.state.receive_status === "I") && <p className="t-sky">입고완료</p>
                        }
                      </dd>
                    </dl>
                  </dt>
                  <dd>
                    <div className="container">
                      <dl className="dl-wrap">
                        <dt>품목</dt>
                        <dd dangerouslySetInnerHTML={{
                          __html: item.crm_product_name ? item.crm_product_name : '-'
                        }}></dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>규격</dt>
                        <dd>{item.product_standard ? item.product_standard : '-'}</dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>수량</dt>
                        <dd>{item.quantity}</dd>
                      </dl>

                      { // 입고대기
                        (location.state && location.state.receive_status === "R") &&
                        <div className="barcode-wrap">
                          <button type="button" className="btn c-sky" onClick={(event) => receiveOpen(event, item)}>
                            <span className="barcode"><span className="a11y">바코드</span></span>
                            <span className="txt">입고등록</span>
                          </button>
                        </div>
                      }

                      { // 입고완료
                        (location.state && location.state.receive_status === "I") &&
                        <div className="over-btt">
                          <dl className="dl-wrap">
                            <dt dangerouslySetInnerHTML={{
                              __html: item.name ? item.name : '-'
                            }}></dt>
                            <dd>{Util.formatDateTimeHHMM(item.reg_datetime)}</dd>
                          </dl>
                        </div>
                      }
                    </div>
                  </dd>
                </dl>
              )
            })
        }
      </div>
      {/* ./20개씩 페이징 처리 */}

      {
        scroll.scrollT > 0 &&
        <button type="button" className="btn-page-top" onClick={scrollTop}><span className="a11y">최상단으로 이동</span></button>
      }

      {
        loading &&
        <Progress
          loading={loading}
          fixed={filter} // 화면 전체 레이어로 덮는 형태
        />
      }

      {/* 단계 1 수량 입력 */}
      {
        tempListReg &&
        <RegReceivingTempList
          item={item}
          scanList={scanList}
          tempListReg={tempListReg}
          setTempListReg={setTempListReg}
          finishOpen={tempListOpen}
          onSaveCallback={onBarcodeSaveCallback}
        />
      }
      {/* 단계 1 수량 입력 */}

      {/* 단계 2~3 수량 입력 */}
      {
        prodAdd &&
        <ProductAdd
          item={item}
          setQuantity={setQuantity}
          quantity={quantity}
          prodAdd={prodAdd}
          prodStatus={prodStatus}
          addOpen={addOpen}
          finishOpen={onSaveReceiving}
        />
      }
      {/* ./단계 2~3 수량 입력 */}

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec="입고 완료하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={alertOpen}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
      {/* ./알럿창 */}
    </>
  )
}

export default ReceivingList;