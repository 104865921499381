import React, { useContext, useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { AppContext } from '../../AppContext';
import ServiceCounselingReg from "../../layout/service/ServiceCounselingReg";
import ManagerReg1 from "../../layout/service/ManagerReg1";
import Alert from "../../layout/Alert";
import ServiceService from '../../services/ServiceService';
import Util from "../../helpers/Util";
import Progress from "../../layout/Progress";

const ServiceListInfo = (props) => {
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const history = useHistory();
  const location = useLocation();
  const service_seq = location.state.service_seq;
  const [reg1, setReg1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceCounseling, setServiceCounseling] = useState(false);
  const [serviceInfo, setServiceInfo] = useState(null);
  const [unnormalAlert, setUnnnormalAlert] = useState(false);

  // 서비스 상세정보 조회
  const searchServiceInfo = useCallback(async () => {
    setLoading(true);
    try {
      const result = await ServiceService.fetchItem(service_seq);
      setServiceInfo(result.data);
      const serviceStatus = result.data.service_status;
      if (serviceStatus === 'C' || serviceStatus === 'I') {
        history.replace("/service/result", {
          service_seq: service_seq
        });
      }

    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [service_seq, history]);

  // 전화
  const onCallClient = (contact) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
      tel: contact
    }, "");
  }

  // 네이버 지도 열기
  const onNaverMap = (address) => {
    if (address) {
      window.nativeApi.execute(window.NativeApiMethod.OPEN_NAVER_MAP, {
        address: encodeURI(address)
      }, "");
    }
  }

  // 담당자 개별 등록 레이어 팝업
  const regOpen1 = () => {
    Util.modal(reg1, setReg1);
  }

  // 담당자 개별 등록 저장
  const onRegSave1 = () => {
    searchServiceInfo(); // 서비스 상세정보 조회
  }

  // 상담 결과 등록 레이어 팝업
  const serviceCounselingOpen = (item) => {
    Util.modal(serviceCounseling, setServiceCounseling);
  }

  // 서비스 상담 결과 등록
  const onServiceCounselingSave = () => {
    searchServiceInfo(); // 서비스 상세정보 조회
  }

  // 서비스 시작하기
  const goServiceStart = () => {
    history.replace("/service/start", {
      service_seq: serviceInfo.service_seq
    });
  }

  // 비정상 접근 알럿 열기/닫기
  const unnormalAlertOpen = useCallback(() => {
    if (unnormalAlert) {
      setUnnnormalAlert(false);
      history.goBack();
    } else {
      setUnnnormalAlert(true);
    }
  }, [unnormalAlert, history])

  useEffect(() => {
    if (serviceInfo && user) {
      if (user.app_user_grade === 'S' || user.app_user_grade === 'E') {
        if (Number(serviceInfo.center_seq) !== Number(user.center_seq)) {
          setUnnnormalAlert(true);
        }
      }
    }
  }, [serviceInfo, user])

  useEffect(() => {
    searchServiceInfo();  // 서비스 상세정보 조회
  }, [searchServiceInfo]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      searchServiceInfo();
    }
  }, [searchServiceInfo])

  return (
    <>
      <div className="page-service t-detail fixed">
        <div className="dl-container">
          <div className="dl-wrap">
            <strong className="tit">기본정보</strong>
            <dl className="dl-item">
              <dt>접수번호</dt>
              <dd>{serviceInfo && serviceInfo.reception_number ? serviceInfo.reception_number : '-'}</dd>
            </dl>
            <dl className="dl-item">
              <dt>서비스번호</dt>
              <dd>{serviceInfo && serviceInfo.service_number ? serviceInfo.service_number : '-'}</dd>
            </dl>
            <dl className="dl-item">
              <dt>고객명</dt>
              <dd>{serviceInfo && serviceInfo.name ? serviceInfo.name : '-'}</dd>
            </dl>
            <div className="dl-item address-wrap">
              <dl>
                <dt>연락처</dt>
                <dd>{serviceInfo && serviceInfo.contacts ? Util.formatPhoneNumber(serviceInfo.contacts.split(',')[0]) : '-'}</dd>
              </dl>
              <button type="button" className="btn btn-tel" onClick={() => onCallClient(serviceInfo.contacts ? serviceInfo.contacts.split(',')[0] : '')}><span className="a11y">통화</span></button>
            </div>
            <div className="dl-item address-wrap">
              <dl>
                <dt>주소</dt>
                <dd>
                  <p className="address" dangerouslySetInnerHTML={{
                    __html: serviceInfo && serviceInfo.address ? serviceInfo.address : '-'
                  }}></p>
                </dd>
              </dl>
              <button type="button" className="btn btn-map" onClick={() => onNaverMap(serviceInfo.address)}><span className="a11y">지도</span></button>
            </div>
            <dl className="dl-item">
              <dt>모델</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.product_seq ? serviceInfo.crm_product_name + (serviceInfo.product_standard ? ' / ' + serviceInfo.product_standard : '') + ' / ' + Util.unescapeHtml(serviceInfo.app_exposure_sales) : '-'
              }}></dd>
            </dl>
            <dl className="dl-item">
              <dt>보증만료일</dt>
              <dd><p className="t-blue">{serviceInfo && serviceInfo.warranty_date ? Util.formatDate(serviceInfo.warranty_date) : '-'}</p></dd>
            </dl>
            <dl className="dl-item">
              <dt>최초설치일</dt>
              <dd><p className="t-blue">{serviceInfo && serviceInfo.first_install_datetime ? Util.formatDate(serviceInfo.first_install_datetime) : '-'}</p></dd>
            </dl>
            <div className="dl-item address-wrap">
              <dl>
                <dt>접수 유형</dt>
                <dd>
                  <strong className={serviceInfo && serviceInfo.is_emergency ? 't-red' : ''}>
                    {serviceInfo && serviceInfo.is_emergency ? '[긴급]' : ''}
                    {serviceInfo && serviceInfo.counseling_type_name + ' / ' + Util.unescapeHtml(serviceInfo.counseling_status_name)}
                  </strong>
                </dd>
              </dl>
              {
                serviceInfo && serviceInfo.is_emergency ?
                  <span className="emergency btn"><span className="a11y">긴급</span></span> : <></>
              }
            </div>
            <dl className="dl-item">
              <dt>접수일</dt>
              <dd>{serviceInfo && serviceInfo.counseling_datetime ? Util.formatDate(serviceInfo.counseling_datetime) : '-'}</dd>
            </dl>
            <dl className="dl-item">
              <dt>접수내용</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.counseling_content ? serviceInfo.counseling_content.replace(/\n/g, '<br/>') : '-'
              }}></dd>
            </dl>
            <dl className="dl-item">
              <dt>특이사항</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.special_note ? serviceInfo.special_note.replace(/\n/g, '<br/>') : '-'
              }}></dd>
            </dl>
            <dl className="dl-item">
              <dt>고장증상</dt>
              <dd>{serviceInfo && serviceInfo.counseling_broken_symptom_name ? serviceInfo.counseling_broken_symptom_name : '-'}</dd>
            </dl>
            <div className="dl-item address-wrap">
              <dl>
                <dt>담당자</dt>
                <dd dangerouslySetInnerHTML={{
                  __html: serviceInfo && serviceInfo.manager_seq ? serviceInfo.manager_name : '-'
                }}></dd>
              </dl>
              {
                user.app_user_grade !== 'E' ?
                  serviceInfo &&
                    (serviceInfo.service_status !== 'C' && serviceInfo.service_status !== 'I') && serviceInfo.is_approval === false ?
                    <button type="button" className="btn c-blue-line" onClick={() => regOpen1(serviceInfo)}>
                      {serviceInfo && serviceInfo.manager_seq ? "변경" : "배정"}
                    </button>
                    : null
                  :
                  null
              }
            </div>
          </div>

          <div className="dl-wrap">
            <strong className="tit">서비스 상담 정보</strong>
            <div className="dl-item address-wrap">
              <dl>
                <dt>예정일시</dt>
                <dd>{serviceInfo && serviceInfo.visit_datetime ? Util.formatDateTimeHHMM(serviceInfo.visit_datetime) : '-'}</dd>
              </dl>
              {
                serviceInfo &&
                  (serviceInfo.service_status !== 'C' && serviceInfo.service_status !== 'I') && serviceInfo.is_approval === false ?
                  serviceInfo.manager_seq ?
                    <button type="button" className="btn c-blue-line" onClick={serviceCounselingOpen} >
                      {serviceInfo && serviceInfo.service_counseling_seq ? "변경" : "입력"}
                    </button>
                    :
                    null
                  :
                  null
              }
            </div>
            <dl className="dl-item">
              <dt>상담결과</dt>
              <dd>{serviceInfo && serviceInfo.service_counseling_type_name ? Util.unescapeHtml(serviceInfo.service_counseling_type_name) : '-'}</dd>
            </dl>
            <dl className="dl-item">
              <dt>상담내용</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.service_counseling_content ? serviceInfo.service_counseling_content.replace(/\n/g, '<br/>') : '-'
              }}>
              </dd>
            </dl>
            <div className="dl-item flex">
              <dl className="col1">
                <dt>등록 / 수정일시</dt>
                <dd>{serviceInfo && serviceInfo.service_counseling_datetime ? Util.formatDateTimeHHMM(serviceInfo.service_counseling_datetime) : '-'}</dd>
              </dl>
              <dl className="col2">
                <dt>등록 / 수정자</dt>
                <dd dangerouslySetInnerHTML={{
                  __html: serviceInfo && serviceInfo.service_counseling_reg_user_name ? serviceInfo.service_counseling_reg_user_name : '-'
                }}></dd>
              </dl>
            </div>
          </div>

          <div className="dl-wrap">
            <strong className="tit">서비스 진행 정보</strong>
            <dl className="dl-item">
              <dt>유형 / 상태</dt>
              <dd>
                <strong>
                  {serviceInfo && serviceInfo.service_type_name + ' / ' + serviceInfo.service_status_name}
                </strong>
              </dd>
            </dl>
            <dl className="dl-item">
              <dt>내용</dt>
              <dd>{serviceInfo && serviceInfo.service_content ? serviceInfo.service_content.replace(/\n/g, '<br/>') : '-'}</dd>
            </dl>
            <div className="dl-item flex">
              <dl className="col1">
                <dt>등록 / 수정일시</dt>
                <dd>{serviceInfo && serviceInfo.mod_datetime ? Util.formatDateTimeHHMM(serviceInfo.mod_datetime) : '-'}</dd>
              </dl>
              <dl className="col2">
                <dt>등록 / 수정자</dt>
                <dd dangerouslySetInnerHTML={{
                  __html: serviceInfo && serviceInfo.mod_user_seq ? serviceInfo.mod_user_name : '-'
                }}></dd>
              </dl>
            </div>
          </div>
        </div>
        {
          user.app_user_grade === 'S' || user.app_user_grade === 'E' ?
            serviceInfo && !serviceInfo.is_cancel ? (
              (serviceInfo.service_status !== 'C' && serviceInfo.service_status !== 'I') && serviceInfo.is_approval === false ?
                serviceInfo.manager_seq ? (
                  serviceInfo.visit_datetime ?
                    <button type="button" className="btn c-blue s1" onClick={goServiceStart}>서비스 진행하기</button>
                    :
                    <button type="button" className="btn c-blue s1" onClick={serviceCounselingOpen}>서비스 상담 결과 등록하기</button>
                ) : <button type="button" className="btn c-blue s1" onClick={regOpen1}>담당자 배정하기</button>
                :
                null
            )
              :
              null
            :
            null
        }
      </div>

      {/* 담당자 개별 등록 */}
      {
        reg1 &&
        <ManagerReg1
          reg={reg1}
          regOpen={regOpen1}
          onRegSave={onRegSave1}
          serviceInfo={serviceInfo}
        />
      }
      {/* ./담당자 개별 등록 */}

      {/* 상담 결과 등록 */}
      {
        serviceCounseling &&
        <ServiceCounselingReg
          serviceCounseling={serviceCounseling}
          serviceCounselingOpen={serviceCounselingOpen}
          onServiceCounselingSave={onServiceCounselingSave}
          serviceInfo={serviceInfo}
        />
      }
      {/* ./상담 결과 등록 */}

      {/* 비정상접속 알럿창 */}
      <Alert
        dec="비정상적인 접속입니다."
        alert={unnormalAlert}
        alertOpen={unnormalAlertOpen}
      />
      {/* ./비정상접속 알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default ServiceListInfo;