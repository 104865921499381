import React, { useState, useEffect, useCallback } from "react";
import OrderService from '../../services/OrderService';
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";

const OrderEdit = (props) => {
  const [loading, setLoading] = useState(false); // 로딩여부
  const [list, setList] = useState("");
  const [usage, setUsage] = useState(""); // 용도
  const [isFree, setIsFree] = useState(""); // 매입/위탁
  const [quantity, setQuantity] = useState(0); // 수량
  const [remark, setRemark] = useState(""); // 비고
  const [alert, setAlert] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);
  const [saveAlert, setSaveAlert] = useState('');
  const [delAlert, setDelAlert] = useState('');
  const [saveAlertDec, setSaveAlertDec] = useState('');
  const [alertDec, setAlertDec] = useState('');
  const order_seq = props.editData.order_seq;
  let editData = props.editData || {};

  // 삭제
  const delConfirmOpen = () => {
    if (delConfirm) {
      setDelConfirm(false);
    } else {
      setDelConfirm(true);
    }
  }

  const delAlertOpen = () => {
    if (!delAlert) {
      setDelConfirm(false);
      onDeleteOrder();
    }
  }

  const delAlertClose = () => {
    if (delAlert) {
      document.body.classList.remove("_noscroll");
      props.orderEditOpen();
      props.onListUpdate();
    }
  }

  // 저장
  const saveConfirmOpen = () => {
    if (saveConfirm) {
      setSaveConfirm(false);
    } else {
      saveValid();
    }
  }

  const saveAlertOpen = () => {
    if (saveAlert) {
      setSaveAlert(false);
    } else {
      setSaveConfirm(false);
      setSaveAlert(true);
    }
  }

  const alertOpen = () => {
    if (alert) {
      setAlert(false);
      document.body.classList.remove("_noscroll");
      props.onListUpdate();
      props.orderEditOpen();
    } else {
      setAlert(true);
    }
  }

  // 저장
  const saveValid = () => {
    if (!usage) {
      setSaveAlertDec('용도를 선택하세요');
      saveAlertOpen();
      return
    }
    if (isFree === '') {
      setSaveAlertDec('매입/위탁을 선택하세요');
      saveAlertOpen();
      return
    }
    if (!editData.child_product_code && !editData.crm_product_name) {
      setSaveAlertDec('품목명을 입력하세요');
      saveAlertOpen();
      return
    }
    if (!Number(quantity) && Number(quantity) < 1) {
      setSaveAlertDec('수량을 입력하세요');
      saveAlertOpen();
      return
    }

    setSaveConfirm(true);
  }

  // 제품 발주 저장
  const onSaveOrder = async () => {
    setLoading(true);
    const param = {
      child_product_code: editData.child_product_code,
      is_free: isFree ? '1' : '0',
      order_usage_type: usage,
      quantity: quantity,
      remark: remark
    };
    try {
      const result = await OrderService.updateOrder(order_seq, param);
      if (result.result === 0) {
        setSaveConfirm(false);
        setAlertDec('저장되었습니다');
        alertOpen();
      } else {
        setSaveConfirm(false);
        setSaveAlertDec(result.message);
        alertOpen();
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 제품 발주 삭제
  const onDeleteOrder = async () => {
    setLoading(true);
    const param = {}
    try {
      const result = await OrderService.deleteOrder(order_seq, param);
      if (result.result === 0) {
        setDelAlert(true);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await OrderService.fetchItem(order_seq);
      const data = result.data;
      setList(data);
      setUsage(data.order_usage_type);
      setIsFree(data.is_free);
      setRemark(data.remark);
      setQuantity(data.quantity);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [order_seq]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  return (
    <>
      <div className={`load-container${props.orderEdit ? " _open" : ""}`}>
        <p className="page-tit">제품발주 요청 수정</p>
        <button type="button" className="btn-close" onClick={() => Util.modal(props.orderEdit, props.setOrderEdit)}>
          <span className="a11y">닫기</span>
        </button>

        <div className="page-stock">
          <div className="order-wrap">
            <div className="sn-wrap">
              <dl className="sn-dl col1">
                <dt><strong className="req">용도</strong></dt>
                <dd>
                  <select name="order_usage_type_name" onChange={(event) => { setUsage(event.target.value) }} value={usage}>
                    <option value="I">설치</option>
                    <option value="A">AS</option>
                  </select>
                </dd>
              </dl>
              <dl className="sn-dl col2">
                <dt><strong className="req">매입/위탁</strong></dt>
                <dd>
                  <select name="is_free" onChange={(event) => { setIsFree(event.target.value) }} value={isFree ? "1" : "0"}>
                    <option value="0">매입</option>
                    <option value="1">위탁</option>
                  </select>
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong className="req">품목명</strong></dt>
                <dd>
                  <label className="search-label">
                    <input type="text" value={list && Util.unescapeHtml(list.crm_product_name)} readOnly />
                  </label>
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong>규격</strong></dt>
                <dd>
                  <input type="text" value={list && list.product_standard ? list.product_standard : '-'} readOnly />
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong>공급가</strong></dt>
                <dd>
                  <label className="txt-label">
                    <input type="text" value={list && Util.formatNumber(Number(list.supply_price))} readOnly />
                    <span className="bar">원</span>
                  </label>
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong className="req">수량</strong></dt>
                <dd>
                  <input type="number" pattern="\d*" value={quantity} maxLength="4"
                    onKeyUp={(event) => Util.onlyNumber(event)}
                    onChange={(event) => { Util.numberMaxLength(event); setQuantity(event.target.value) }}
                  />
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong>비고</strong></dt>
                <dd>
                  <textarea placeholder="내용을 입력하세요."
                    onChange={(event) => { Util.numberMaxLength(event); setRemark(event.target.value) }}
                    value={remark} maxLength="1000" autoCapitalize="off"
                  ></textarea>
                </dd>
              </dl>
            </div>

            <ul className="btn-flex t3">
              <li><button type="button" className="btn c-gray" onClick={() => Util.modal(props.orderEdit, props.setOrderEdit)}>취소</button></li>
              <li><button type="button" className="btn c-black" onClick={() => delConfirmOpen()}>삭제</button></li>
              <li><button type="button" className="btn c-sky" onClick={() => saveConfirmOpen()}>저장</button></li>
            </ul>
          </div>
        </div>
      </div>

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true} // 화면 전체 레이어로 덮는 형태
        />
      }

      {/* 저장 컨펌창 */}
      {
        delConfirm &&
        <Confirm
          dec='삭제하시겠습니까?'
          confirm={delConfirm}
          confirmOpen={delConfirmOpen}
          alertOpen={delAlertOpen}
        />
      }
      {/* ./저장 컨펌창 */}

      {/* 저장 컨펌창 */}
      {
        saveConfirm &&
        <Confirm
          dec='저장하시겠습니까?'
          confirm={saveConfirm}
          confirmOpen={saveConfirmOpen}
          alertOpen={onSaveOrder}
        />
      }
      {/* ./저장 컨펌창 */}

      {/* 삭제 알럿창 */}
      {
        delAlert &&
        <Alert
          dec='삭제되었습니다'
          alert={delAlert}
          alertOpen={delAlertClose}
        />
      }
      {/* ./삭제 알럿창 */}

      {/* 저장 알럿창 */}
      {
        saveAlert &&
        <Alert
          dec={saveAlertDec}
          alert={saveAlert}
          alertOpen={saveAlertOpen}
        />
      }
      {/* ./저장 알럿창 */}

      {/* 저장 완료알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
      {/* ./저장 완료 알럿창 */}

    </>
  )
}

export default OrderEdit;