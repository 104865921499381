import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import ProductReg from "../../layout/service/ProductReg";
import StartFinish from "../../layout/service/StartFinish";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import FullScreenDialog from "../../layout/FullScreenDialog";
import MarketingTerms from "../../layout/service/MarketingTerms";
import Progress from "../../layout/Progress";
import CodeService from '../../services/CodeService';
import ServiceService from '../../services/ServiceService';
import Util from '../../helpers/Util';

const ServiceStart = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const serviceSeq = location.state.service_seq;
  const [step, setStep] = useState(1);
  const [serviceInfo, setServiceInfo] = useState(null);
  const [serviceTypeList, setServiceTypeList] = useState([]); // 서비스 유형 리스트
  const [brokenSymptomList, setBrokenSymptomList] = useState([]); // 고장증상 리스트
  const [serviceStatusList, setServiceStatusList] = useState([]); // 서비스 상태 리스트
  const [guide, setGuide] = useState(false);
  const [scan1st, setScan1st] = useState("");
  const [isScan1st, setIsScan1st] = useState(false);
  const [scan2nd, setScan2nd] = useState("");
  const [isScan2nd, setIsScan2nd] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [brokenSymptom, setBrokenSymptom] = useState([]);
  const [productList, setProductList] = useState([]);
  const [changeScan1st, setChangeScan1st] = useState("");
  const [isChangeScan1st, setIsChangeScan1st] = useState(false);
  const [changeScan2nd, setChangeScan2nd] = useState("");
  const [isChangeScan2nd, setIsChangeScan2nd] = useState(false);
  const [serviceContent, setServiceContent] = useState("");
  const [prodChange, setProdChange] = useState(false);
  const [prod1stChange, setProd1stChange] = useState(false);
  const [prod2ndChange, setProd2ndChange] = useState(false);
  const [attachedFileList, setAttachedFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);
  const [photoFileList, setPhotoFileList] = useState([]);
  const [materialCost, setMaterialCost] = useState(0);
  const [travelCost, setTravelCost] = useState(0);
  const [laborCost, setLaborCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [isFree, setIsFree] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [serviceStatus, setServiceStatus] = useState(0);
  const [signature, setSignature] = useState("");
  const [collectScan1st, setCollectScan1st] = useState("");
  const [isCollectScan1st, setIsCollectScan1st] = useState(false);
  const [collectType1st, setCollectType1st] = useState("");
  const [collectScan2nd, setCollectScan2nd] = useState("");
  const [isCollectScan2nd, setIsCollectScan2nd] = useState(false);
  const [collectType2nd, setCollectType2nd] = useState("");
  const [isMarketing, setIsMarketing] = useState(false);
  const [gps, setGps] = useState(["", ""]);
  const [address, setAddress] = useState("");
  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const [isDemolition, setIsDemolition] = useState(false);
  const [prodReq, setProdReq] = useState(false);
  const [finish, setFinish] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");
  const [saveAlert, setSaveAlert] = useState(false);
  const [exitConfirm, setExitConfirm] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [cancelAlertDec, setCancelAlertDec] = useState("");
  const [marketingDialog, setMarketingDialog] = useState(false);

  const [selectModelList, setSelectModelList] = useState([]);

  const [select1stProductList, setSelect1stProductList] = useState([]);
  const [select2ndProductList, setSelect2ndProductList] = useState([]);
  const [select1stModel, setSelect1stModel] = useState('');
  const [select2ndModel, setSelect2ndModel] = useState('');
  const [select1stProduct, setSelect1stProduct] = useState('');
  const [select2ndProduct, setSelect2ndProduct] = useState('');
  const model1stSelect = useRef(null);
  const product1stSelect = useRef(null);
  const model2ndSelect = useRef(null);
  const product2ndSelect = useRef(null);

  const [exchangeSelect1stProductList, setExchangeSelect1stProductList] = useState([]);
  const [exchangeSelect1stModel, setExchangeSelect1stModel] = useState('');
  const [exchangeSelect1stProduct, setExchangeSelect1stProduct] = useState('');
  const [exchangeSelect2ndProductList, setExchangeSelect2ndProductList] = useState([]);
  const [exchangeSelect2ndModel, setExchangeSelect2ndModel] = useState('');
  const [exchangeSelect2ndProduct, setExchangeSelect2ndProduct] = useState('');
  const exchangeModel1stSelect = useRef(null);
  const exchangeProduct1stSelect = useRef(null);
  const exchangeModel2ndSelect = useRef(null);
  const exchangeProduct2ndSelect = useRef(null);

  // const [collectSelect1stProductList, setCollectSelect1stProductList] = useState([]);
  // const [collectSelect1stModel, setCollectSelect1stModel] = useState('');
  // const [collectSelect1stProduct, setCollectSelect1stProduct] = useState('');
  // const [collectSelect2ndProductList, setCollectSelect2ndProductList] = useState([]);
  // const [collectSelect2ndModel, setCollectSelect2ndModel] = useState('');
  // const [collectSelect2ndProduct, setCollectSelect2ndProduct] = useState('');
  // const collectModel1stSelect = useRef(null);
  // const collectProduct1stSelect = useRef(null);
  // const collectModel2ndSelect = useRef(null);
  // const collectProduct2ndSelect = useRef(null);

  // 이전 버튼 핸들러
  const onGoback = () => {
    setStep(thisState => { return thisState - 1 });
  }

  // 서비스 상세정보 조회
  const searchServiceInfo = useCallback(async () => {
    setLoading(true);
    try {
      const result = await ServiceService.fetchItem(serviceSeq);
      const data = result.data;
      setServiceInfo(data);

    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [serviceSeq]);

  // 서비스 유형 리스트 조회
  const searchServiceTypeList = useCallback(async () => {
    const param = {
      group_codes: 'SERVICE_TYPE'
    };

    setLoading(true);
    try {
      const result = await CodeService.fetchItems(param);
      const data = result.data;

      setServiceTypeList(data.service_type_list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // 서비스 상태 리스트 조회
  const searchServiceStatusList = useCallback(async () => {
    const param = {
      group_codes: 'SERVICE_STATUS'
    };

    setLoading(true);
    try {
      const result = await CodeService.fetchItems(param);
      const data = result.data;

      setServiceStatusList(data.service_status_list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // 고장증상 리스트 조회
  const searchBrokenSymptomList = useCallback(async () => {
    const param = {
      group_codes: 'BROKEN_SYMPTOM'
    };

    setLoading(true);
    try {
      const result = await CodeService.fetchItems(param);
      const data = result.data;

      setBrokenSymptomList(data.broken_symptom_list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // 제품 모델 리스트 조회
  const searchSelectModelList = useCallback(async () => {
    const param = {
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setSelectModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
    }
  }, []);

  // 1차기 제품 리스트 조회
  const searchSelect1stProductList = useCallback(async () => {
    const param = {
      level: 1,
      upper_child_product_code: select1stModel
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setSelect1stProductList(data.list);
      if (serviceInfo.first_child_product_code) {
        setSelect1stProduct(serviceInfo.first_child_product_code);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
    }
  }, [select1stModel, serviceInfo]);

  // 2차기 제품 리스트 조회
  const searchSelect2ndProductList = useCallback(async () => {
    const param = {
      level: 1,
      upper_child_product_code: select2ndModel
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setSelect2ndProductList(data.list);
      if (serviceInfo.second_child_product_code) {
        setSelect2ndProduct(serviceInfo.second_child_product_code);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
    }
  }, [select2ndModel, serviceInfo]);

  // 교환 1차기 제품 리스트 조회
  const searchExchangeSelect1stProductList = useCallback(async () => {
    const param = {
      level: 1,
      upper_child_product_code: exchangeSelect1stModel
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setExchangeSelect1stProductList(data.list);
      if (serviceInfo.change_first_child_product_code) {
        setExchangeSelect1stProduct(serviceInfo.change_first_child_product_code);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
    }
  }, [exchangeSelect1stModel, serviceInfo]);

  // 교환 2차기 제품 리스트 조회
  const searchExchangeSelect2ndProductList = useCallback(async () => {
    const param = {
      level: 1,
      upper_child_product_code: exchangeSelect2ndModel
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setExchangeSelect2ndProductList(data.list);
      if (serviceInfo.change_second_child_product_code) {
        setExchangeSelect2ndProduct(serviceInfo.change_second_child_product_code);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
    }
  }, [exchangeSelect2ndModel, serviceInfo]);

  // 수거 1차기 제품 리스트 조회
  // const searchCollectSelect1stProductList = useCallback(async () => {
  //   const param = {
  //     level: 1,
  //     upper_child_product_code: collectSelect1stModel
  //   };
  //   try {
  //     const result = await ServiceService.fetchChildProducts(param);
  //     const data = result.data;
  //     setCollectSelect1stProductList(data.list);
  //     if (serviceInfo.first_child_product_code) {
  //       setCollectSelect1stProduct(serviceInfo.first_child_product_code);
  //     }
  //   } catch (error) {
  //     const result = error.response.data.result;
  //     const message = error.response.data.message;
  //     if (result < 0) {
  //       console.error(message);
  //     }
  //   } finally {
  //   }
  // }, [collectSelect1stModel, serviceInfo]);

  // 수거 2차기 제품 리스트 조회
  // const searchCollectSelect2ndProductList = useCallback(async () => {
  //   const param = {
  //     level: 1,
  //     upper_child_product_code: collectSelect2ndModel
  //   };
  //   try {
  //     const result = await ServiceService.fetchChildProducts(param);
  //     const data = result.data;
  //     setCollectSelect2ndProductList(data.list);
  //     if (serviceInfo.second_child_product_code) {
  //       setCollectSelect2ndProduct(serviceInfo.second_child_product_code);
  //     }
  //   } catch (error) {
  //     const result = error.response.data.result;
  //     const message = error.response.data.message;
  //     if (result < 0) {
  //       console.error(message);
  //     }
  //   } finally {
  //   }
  // }, [collectSelect2ndModel, serviceInfo]);

  // 1차기 제품 모델 변경 핸들러
  const onChangeSelect1stModel = (event) => {
    setSelect1stProduct('');
    setSelect1stModel(event.target.value);
    model1stSelect.current.disabled = false;
    product1stSelect.current.disabled = false;
  }

  // 2차기 제품 모델 변경 핸들러
  const onChangeSelect2ndModel = (event) => {
    setSelect2ndProduct('');
    setSelect2ndModel(event.target.value);
    model2ndSelect.current.disabled = false;
    product2ndSelect.current.disabled = false;
  }

  // 교환 1차기 제품 모델 변경 핸들러
  const onChangeExchangeSelect1stModel = (event) => {
    setExchangeSelect1stProduct('');
    setExchangeSelect1stModel(event.target.value);
    exchangeModel1stSelect.current.disabled = false;
    exchangeProduct1stSelect.current.disabled = false;
  }

  // 교환 2차기 제품 모델 변경 핸들러
  const onChangeExchangeSelect2ndModel = (event) => {
    setExchangeSelect2ndProduct('');
    setExchangeSelect2ndModel(event.target.value);
    exchangeModel2ndSelect.current.disabled = false;
    exchangeProduct2ndSelect.current.disabled = false;
  }

  // 수거 1차기 제품 모델 변경 핸들러
  // const onChangeCollectSelect1stModel = (event) => {
  //   setCollectSelect1stProduct('');
  //   setCollectSelect1stModel(event.target.value);
  //   collectModel1stSelect.current.disabled = false;
  //   collectProduct1stSelect.current.disabled = false;
  // }

  // 수거 2차기 제품 모델 변경 핸들러
  // const onChangeCollectSelect2ndModel = (event) => {
  //   setCollectSelect2ndProduct('');
  //   setCollectSelect2ndModel(event.target.value);
  //   collectModel2ndSelect.current.disabled = false;
  //   collectProduct2ndSelect.current.disabled = false;
  // }

  // 전화
  const onCallClient = (contact) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
      tel: contact
    }, "");
  }

  // 네이버 지도 열기
  const onNaverMap = (address) => {
    if (address) {
      window.nativeApi.execute(window.NativeApiMethod.OPEN_NAVER_MAP, {
        address: encodeURI(address)
      }, "");
    }
  }

  // 스캔버튼 클릭 핸들러
  const onClickScan = (degree) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_SCANNER, {
      name: degree + "차기 제품 S/N"
    }, window.NativeApiCallbackMethod.OPEN_SCANNER);
    window[window.NativeApiCallbackMethod.OPEN_SCANNER] = function (response) {
      searchChildProductCodeBySnInstock('I', degree, response.barcode);  // 재고에서 S/N으로 자품목코드 조회

      if (degree === 1) {
        setScan1st(response.barcode);
        setIsScan1st(response.is_scan);
        if (serviceType !== '01') {
          setCollectScan1st(response.barcode);
          setIsCollectScan1st(response.is_scan);
        }
      }
      if (degree === 2) {
        setScan2nd(response.barcode);
        setIsScan2nd(response.is_scan);
        if (serviceType !== '01') {
          setCollectScan2nd(response.barcode);
          setIsCollectScan2nd(response.is_scan);
        }
      }
    }
  }

  // 재고에서 S/N으로 자품목코드 조회
  const searchChildProductCodeBySnInstock = async (type, degree, barcode) => {
    setLoading(true);
    // type(I): 설치
    if (type === 'I') {
      if (degree === 1) {
        setSelect1stModel('');
        setSelect1stProduct('');
      }
      if (degree === 2) {
        setSelect2ndModel('');
        setSelect2ndProduct('');
      }
    }
    // type(E): 교환
    if (type === 'E') {
      if (degree === 1) {
        setExchangeSelect1stModel('');
        setExchangeSelect1stProduct('');
      }
      if (degree === 2) {
        setExchangeSelect2ndModel('');
        setExchangeSelect2ndProduct('');
      }
    }
    // type(C): 수거
    // if (type === 'C') {
    //   if (degree === 1) {
    //     setCollectSelect1stModel('');
    //     setCollectSelect1stProduct('');
    //   }
    //   if (degree === 2) {
    //     setCollectSelect2ndModel('');
    //     setCollectSelect2ndProduct('');
    //   }
    // }
    const param = {
      product_serial_number: barcode,
      center_seq: serviceInfo.center_seq
    };
    try {
      const result = await ServiceService.fetchChildProductCode(param);
      const child_product_code = result.data.child_product_code;
      // type(I): 설치
      if (type === 'I') {
        if (child_product_code) {
          if (degree === 1) {
            setSelect1stProduct(child_product_code);
            model1stSelect.current.disabled = true;
            product1stSelect.current.disabled = true;
          }
          if (degree === 2) {
            setSelect2ndProduct(child_product_code);
            model2ndSelect.current.disabled = true;
            product2ndSelect.current.disabled = true;
          }
        } else {
          if (degree === 1) {
            model1stSelect.current.disabled = false;
            product1stSelect.current.disabled = false;
            setSelect1stModel(serviceInfo.child_product_code);
            // setSelect1stProduct(serviceInfo.first_child_product_code);
          }
          if (degree === 2) {
            model2ndSelect.current.disabled = false;
            product2ndSelect.current.disabled = false;
            setSelect2ndModel(serviceInfo.child_product_code);
            // setSelect2ndProduct(serviceInfo.second_child_product_code);
          }
        }
      }

      // type(E): 교환
      if (type === 'E') {
        if (child_product_code) {
          if (degree === 1) {
            setExchangeSelect1stProduct(child_product_code);
            exchangeModel1stSelect.current.disabled = true;
            exchangeProduct1stSelect.current.disabled = true;
          }
          if (degree === 2) {
            setExchangeSelect2ndProduct(child_product_code);
            exchangeModel2ndSelect.current.disabled = true;
            exchangeProduct2ndSelect.current.disabled = true;
          }
        } else {
          if (degree === 1) {
            exchangeModel1stSelect.current.disabled = false;
            exchangeProduct1stSelect.current.disabled = false;
            setExchangeSelect1stModel(serviceInfo.child_product_code);
            // setExchangeSelect1stProduct(serviceInfo.first_child_product_code);
          }
          if (degree === 2) {
            exchangeModel2ndSelect.current.disabled = false;
            exchangeProduct2ndSelect.current.disabled = false;
            setExchangeSelect2ndModel(serviceInfo.child_product_code);
            // setExchangeSelect2ndProduct(serviceInfo.second_child_product_code);
          }
        }
      }

      // type(C): 수거
      // if (type === 'C') {
      //   if (child_product_code) {
      //     if (degree === 1) {
      //       setCollectSelect1stProduct(child_product_code);
      //       collectModel1stSelect.current.disabled = true;
      //       collectProduct1stSelect.current.disabled = true;
      //     }
      //     if (degree === 2) {
      //       setCollectSelect2ndProduct(child_product_code);
      //       collectModel2ndSelect.current.disabled = true;
      //       collectProduct2ndSelect.current.disabled = true;
      //     }
      //   } else {
      //     if (degree === 1) {
      //       collectModel1stSelect.current.disabled = false;
      //       collectProduct1stSelect.current.disabled = false;
      //       setCollectSelect1stModel('');
      //       setCollectSelect1stProduct('');
      //     }
      //     if (degree === 2) {
      //       collectModel2ndSelect.current.disabled = false;
      //       collectProduct2ndSelect.current.disabled = false;
      //       setCollectSelect2ndModel('');
      //       setCollectSelect2ndProduct('');
      //     }
      //   }
      // }

    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 서비스 유형 변경 핸들러
  const onChangeServiceType = (event) => {
    setServiceType(event.target.value);
  }

  // 고장증상 체크박스 클릭
  const onClickBrokenSymptom = (event) => {
    const value = event.target.value;
    if (brokenSymptom.indexOf(value) > -1) {
      setBrokenSymptom(thisState => {
        let state = [...thisState];
        state.splice(state.indexOf(value), 1);
        return state;
      });
    } else {
      setBrokenSymptom(thisState => {
        let state = [...thisState];
        state.push(value);
        return state;
      });
    }
  }

  // 제품 인증 안내 레이어 팝업
  const guideOpen = () => {
    const $body = document.body;
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: true
    }, window.NativeApiCallbackMethod.IS_LAYER);
    window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      $body.classList.remove("_noscroll");
      setGuide(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
    }
    $body.classList.add("_noscroll");
    setGuide(true);
  }

  // 서비스 > 품목 검색 레이어 팝업
  const reqOpen = () => {
    const $body = document.body;

    if (prodReq) {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
      $body.classList.remove("_noscroll");
      setProdReq(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        $body.classList.remove("_noscroll");
        setProdReq(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          setExitConfirm(true);
        }
      }
      $body.classList.add("_noscroll");
      setProdReq(true);
    }
  }

  // 서비스 > 품목 검색 레이어 팝업 완료 버튼 핸들러
  const onClickCompleteAddProduct = (selectedList) => {
    setProductList(selectedList);
    reqOpen();
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: true
    }, window.NativeApiCallbackMethod.IS_LAYER);
    window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      setExitConfirm(true);
    }
  }

  const finishOpen = () => {
    if (finish) {
      setFinish(false);
    } else {
      setFinish(true);
    }
  }

  // 제품 개별 삭제
  const onDeleteServiceProduct = (index) => {
    setProductList(thisState => {
      thisState[index].quantity = 0;
      let state = [
        ...thisState
      ];

      state.sort((a, b) => {
        return b.quantity - a.quantity;
      });

      return state;
    });
  }

  // 제품교환 여부 스캔버튼 클릭 핸들러
  const onClickExchangeScan = (degree) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_SCANNER, {
      name: degree + "차기 제품 S/N"
    }, window.NativeApiCallbackMethod.OPEN_SCANNER);
    window[window.NativeApiCallbackMethod.OPEN_SCANNER] = function (response) {
      searchChildProductCodeBySnInstock('E', degree, response.barcode);  // 재고에서 S/N으로 자품목코드 조회

      if (degree === 1) {
        setChangeScan1st(response.barcode);
        setIsChangeScan1st(response.is_scan);
      }
      if (degree === 2) {
        setChangeScan2nd(response.barcode);
        setIsChangeScan2nd(response.is_scan);
      }
    }
  }

  // 수거제품 스캔버튼 클릭 핸들러
  // const onClickCollectScan = (degree) => {
  //   window.nativeApi.execute(window.NativeApiMethod.OPEN_SCANNER, {
  //     name: degree + "차기 제품 S/N"
  //   }, window.NativeApiCallbackMethod.OPEN_SCANNER);
  //   window[window.NativeApiCallbackMethod.OPEN_SCANNER] = function (response) {
  //     searchChildProductCodeBySnInstock('C', degree, response.barcode);  // 재고에서 S/N으로 자품목코드 조회

  //     if (degree === 1) {
  //       setCollectScan1st(response.barcode);
  //       setIsCollectScan1st(response.is_scan);
  //     }
  //     if (degree === 2) {
  //       setCollectScan2nd(response.barcode);
  //       setIsCollectScan2nd(response.is_scan);
  //     }
  //   }
  // }

  // 사진 선택
  const onPhoto = (event) => {
    new Promise(() => {
      let files = event.target.files;
      let length = event.target.files.length;

      if (attachedFileList.length + photoFileList.length + length > 10) {
        setAlertDec('사진 첨부는 10개까지 가능합니다.');
        alertOpen();
        return;
      }

      setLoading(true);
      for (let i = 0; i < length; i++) {
        let reader = new FileReader();
        reader.onloadend = () => {
          setPhotoFileList(thisState => {
            files[i].result = reader.result;
            let state = [
              ...thisState,
              files[i]
            ];
            return state;
          });
        }
        reader.readAsDataURL(files[i]);
      }
    });
    setTimeout(() => {
      setLoading(false);
    }, [1500]);
  }

  // 이미 첨부된 파일 삭제
  const onDelelteAttachedFile = (attached_file_seq) => {
    setDeleteFileList(thisState => {
      let state = [
        ...thisState,
        attached_file_seq
      ];
      return state;
    });
    setAttachedFileList(thisState => {
      let deleteIndex = 0;
      thisState.forEach((item, index) => {
        if (item.attached_file_seq === attached_file_seq) {
          deleteIndex = index;
          return;
        }
      });
      thisState.splice(deleteIndex, 1);
      let state = [
        ...thisState
      ];
      return state;
    });
  }

  // 사진 삭제
  const onDeleltePhoto = (index) => {
    setPhotoFileList(thisState => {
      let state = [...thisState];
      state.splice(index, 1);
      return state;
    });
  }

  // 고객 서명
  const onSign = () => {
    if (!serviceStatus) {  // 서비스 상태
      setAlertDec('서비스 결과를 선택하세요.');
      alertOpen();
      return;
    }

    // marketingDialogOpen();
    window.nativeApi.execute(window.NativeApiMethod.OPEN_SIGNATURE, {}, window.NativeApiCallbackMethod.OPEN_SIGNATURE);
    window[window.NativeApiCallbackMethod.OPEN_SIGNATURE] = function (response) {
      setSignature(response.svg);
    }

  }

  const cancelAlertOpen = useCallback(() => {
    if (cancelAlert) {
      setCancelAlert(false);
      history.goBack();
    } else {
      setCancelAlert(true);
    }
  }, [cancelAlert, history])

  // 다음
  const onNext = () => {
    if (!serviceStatus) {  // 서비스 결과
      setAlertDec('서비스 결과를 선택하세요.');
      alertOpen();
      return;
    }

    saveConfirmOpen();
  }

  // 알럿
  const alertOpen = useCallback(() => {
    if (alert) {
      setAlert(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
    } else {
      setAlert(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setAlert(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          setExitConfirm(true);
        }
      }
    }
  }, [alert]);

  // 마케팅 동의여부 저장
  const saveIsMarketing = useCallback(async (isAree) => {
    let param = { is_marketing: isAree ? '1' : '0' };
    setLoading(true);
    // return;
    try {
      const result = await ServiceService.updateServiceInfo(serviceSeq, param);
      if (result.result < 0) {
        setCancelAlertDec(result.message);
        cancelAlertOpen();
        return;
      }
      guideOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [alertOpen, cancelAlertOpen, isMarketing, serviceSeq]);

  // 다음(저장)
  const onSave = useCallback(async (step) => {
    let param = {};

    switch (step) {
      case 2: // 인증 단계
        if (!scan1st) {
          setAlertDec('1차기 제품 S/N 를 인증하세요.');
          alertOpen();
          return;
        }
        if (!select1stProduct) {
          setAlertDec('1차기 제품을 선택하세요.');
          alertOpen();
          return;
        }
        if (!scan2nd) {
          setAlertDec('2차기 제품 S/N 를 인증하세요.');
          alertOpen();
          return;
        }
        if (!select2ndProduct) {
          setAlertDec('2차기 제품을 선택하세요.');
          alertOpen();
          return;
        }
        if (scan1st.replace(/ /gi, '') !== '확인불가' && scan2nd.replace(/ /gi, '') !== '확인불가' && scan1st === scan2nd) {
          setAlertDec('1차기, 2차기 제품 S/N 가 같습니다. 다시 인증하세요.');
          alertOpen();
          return;
        }

        param = {
          first_child_product_code: select1stProduct,
          first_serial_number: scan1st,
          first_is_scan: scan1st ? (isScan1st ? "1" : "0") : "None",
          second_child_product_code: select2ndProduct,
          second_serial_number: scan2nd,
          second_is_scan: scan2nd ? (isScan2nd ? "1" : "0") : "None"
        };
        break;

      case 3: // 서비스 단계
        if (!serviceType) {
          setAlertDec('서비스 유형을 선택하세요.');
          alertOpen();
          return;
        } else {

          let serviceProductList = productList.filter(element => {
            return element.quantity !== 0;
          });

          if (serviceType === '02' || serviceType === '04' || serviceType === '05') {  // 02:AS, 04:이전설치, 05:이전철거
            if (prod1stChange) {
              if (!changeScan1st) {
                setAlertDec('1차기 교환 제품 S/N을 인증하세요.');
                alertOpen();
                return;
              }
              if (!exchangeSelect1stProduct) {
                setAlertDec('1차기 교환 제품을 선택하세요.');
                alertOpen();
                return;
              }
            }
            if (prod2ndChange) {
              if (!changeScan2nd) {
                setAlertDec('2차기 교환 제품 S/N을 인증하세요.');
                alertOpen();
                return;
              }
              if (!exchangeSelect2ndProduct) {
                setAlertDec('2차기 교환 제품을 선택하세요.');
                alertOpen();
                return;
              }
            }
          }

          // 공통
          if (!serviceContent) {  // 서비스 내용
            setAlertDec('서비스 내용을 입력하세요.');
            alertOpen();
            return;
          }

          // 사진 첨부
          if (!attachedFileList.length && !photoFileList.length) {
            setAlertDec('사진을 첨부하세요.');
            alertOpen();
            return;
          }

          // 사진 삭제/업로드
          setLoading(true);
          let formData = new FormData();
          formData.append('service_seq', serviceSeq);
          let attached_files = '';
          attachedFileList.forEach((item, index) => {
            if (index !== 0) {
              attached_files += ',';
            }
            attached_files += item.attached_file_seq;
          });
          formData.append('attached_files', attached_files);
          formData.append('delete_files', deleteFileList.join(','));
          photoFileList.forEach((item, index) => {
            formData.append('files', item);
          });
          try {
            const result = await ServiceService.updateServicePhoto(formData);
            if (result.result < 0) {
              setLoading(false);
              setAlertDec(result.message);
              alertOpen();
              return;
            }
          } catch (error) {
            console.error('사진 업로드 삭제/오류발생');
            return;
          } finally {
          }

          // 첨부파일 여부 체크위한 서비스 조회
          try {
            const result = await ServiceService.fetchItem(serviceSeq);
            const data = result.data;
            if (!data.attached_file_list.length) {
              setAlertDec('사진이 첨부되지 않았습니다.');
              alertOpen();
              return;
            }
          } catch (error) {
          } finally {
          }

          let serviceProductSeq = '';
          let serviceProductQuantity = '';
          let serviceProductAmount = '';
          let serviceMaterialCost = 0;
          serviceProductList.forEach((item, index) => {
            if (index) {
              serviceProductSeq += ',';
              serviceProductQuantity += ',';
              serviceProductAmount += ',';
            }
            serviceProductSeq += item.product_seq;
            serviceProductQuantity += item.quantity;
            serviceProductAmount += (Number(item.quantity) * Number(item.consumer_price.replace(/,/gi, "")));
            serviceMaterialCost += (Number(item.quantity) * Number(item.consumer_price.replace(/,/gi, "")));
          });

          param = {
            service_type: serviceType,
            broken_symptom: serviceType === '02' ? brokenSymptom.join(',') : '',
            service_content: serviceContent
          };

          if (serviceProductList.length) { // 소요부품
            param.is_service_product = 1;
            param.service_product_seq = serviceProductSeq;
            param.service_product_quantity = serviceProductQuantity;
            param.service_product_amount = serviceProductAmount;
          } else {
            param.is_service_product = 1;
            param.service_product_seq = '';
            param.service_product_quantity = '';
            param.service_product_amount = '';
          }

          if (serviceType === '02' || serviceType === '04' || serviceType === '05') {  // 02:AS, 04:이전설치, 05:이전철거
            if (prodChange) {
              param.is_change = '1';
              param.change_first_child_product_code = exchangeSelect1stProduct;
              param.change_first_serial_number = changeScan1st;
              param.change_first_is_scan = changeScan1st ? (isChangeScan1st ? '1' : '0') : 'None';
              param.change_second_child_product_code = exchangeSelect2ndProduct;
              param.change_second_serial_number = changeScan2nd;
              param.change_second_is_scan = changeScan2nd ? (isChangeScan2nd ? '1' : '0') : 'None';
            } else {
              param.is_change = '0';
              param.change_first_child_product_code = '';
              param.change_first_serial_number = 'None';
              param.change_first_is_scan = 'None';
              param.change_second_child_product_code = '';
              param.change_second_serial_number = 'None';
              param.change_second_is_scan = 'None';
            }
          }

          setMaterialCost(serviceMaterialCost);
        }
        break;

      case 4: // 요금 단계
        param = {
          material_cost: materialCost,
          travel_cost: travelCost,
          labor_cost: laborCost,
          shipping_cost: shippingCost,
          total_cost: totalCost,
          is_free: isFree ? 1 : 0
        };
        break;
      default:
        break;
    }
    setLoading(true);
    try {
      const result = await ServiceService.updateServiceInfo(serviceSeq, param);
      if (result.result < 0) {
        setCancelAlertDec(result.message);
        cancelAlertOpen();
        return;
      }
      setStep(step + 1);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [alertOpen, deleteFileList, cancelAlertOpen, brokenSymptom, photoFileList, changeScan1st, attachedFileList, changeScan2nd, prodChange, isChangeScan1st, isChangeScan2nd, isFree, select1stProduct, select2ndProduct, isScan1st, isScan2nd, laborCost, materialCost, productList, scan1st, scan2nd, serviceContent, serviceSeq, serviceType, shippingCost, totalCost, travelCost, prod1stChange, prod2ndChange, exchangeSelect1stProduct, exchangeSelect2ndProduct]);


  // 1차기 제품 교환 체크박스 클릭 핸들러
  const onProd1stChange = () => {
    if (prod1stChange) {
      setProd1stChange(false);
      setChangeScan1st('');
      setIsChangeScan1st(false);
    } else {
      setProd1stChange(true);
    }
  }

  // 2차기 제품 교환 체크박스 클릭 핸들러
  const onProd2ndChange = () => {
    if (prod2ndChange) {
      setProd2ndChange(false);
      setChangeScan2nd('');
      setIsChangeScan2nd(false);
    } else {
      setProd2ndChange(true);
    }
  }

  // 서비스 완료 버튼 클릭 핸들러
  const onClickServiceComplete = () => {
    if (serviceType === '01') { // 설치
      if (serviceInfo.contract_seq) {  // 계약이 있음
        confirmOpen();
      } else {  // 계약이 없음(진짜 신규설치)
        finishOpen(); // 서비스 완료 팝업 열기
      }
    } else {
      // if (collectType1st && !collectSelect1stProduct) {
      //   setAlertDec('1차기 제품을 선택하세요.');
      //   alertOpen();
      //   return;
      // }
      // if (collectType2nd && !collectSelect2ndProduct) {
      //   setAlertDec('2차기 제품을 선택하세요.');
      //   alertOpen();
      //   return;
      // }
    }

    if (serviceType === '02' || serviceType === '04') { // 02:AS, 04:이전설치
      confirmOpen();
    }
    if (serviceType === '03') { // 03:철거
      finishOpen(); // 서비스 완료 팝업 열기
    }
    if (serviceType === '05') { // 05:이전철거
      confirmOpen();
    }
  }

  // GPS 가져오기
  const getGps = () => {
    setLoading(true);
    window.nativeApi.execute(window.NativeApiMethod.GET_GPS, {}, window.NativeApiCallbackMethod.GET_GPS);
    window[window.NativeApiCallbackMethod.GET_GPS] = function (response) {
      if (response.latitude && response.longitude) {
        setGps([response.latitude, response.longitude]);
      }
    }
  }

  // 저장컨펌 열기/닫기
  const saveConfirmOpen = () => {
    if (saveConfirm) {
      setSaveConfirm(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
    } else {
      setSaveConfirm(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setSaveConfirm(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          setExitConfirm(true);
        }
      }
    }
  }

  // 완료컨펌 열기/닫기
  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
    } else {
      setConfirm(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setConfirm(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          setExitConfirm(true);
        }
      }
    }
  }

  // 저장컨펌 확인 처리
  const saveConfirmAlertOpen = async () => {
    if (saveConfirm) {
      // 저장처리
      let param = {
        service_status: serviceStatus
      };

      try {
        const result = await ServiceService.updateServiceInfo(serviceSeq, param);
        if (result.result < 0) {
          setCancelAlertDec(result.message);
          cancelAlertOpen();
          return;
        }
        setSaveConfirm(false);
        setSaveAlert(true);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  // 저장알럿
  const saveAlertOpen = () => {
    if (saveAlert) {
      setSaveAlert(false);
      if (serviceStatus === 'I') {
        history.replace('/service/result', {
          service_seq: serviceSeq
        });
      } else {
        history.replace('/service/list/info', {
          service_seq: serviceSeq
        });
      }
    }
  }

  // 완료컨펌 확인 처리
  const confirmAlertOpen = () => {
    if (confirm) {
      setConfirm(false);
      getGps(); // GPS 가져오기
    }
  }

  // 서비스 완료
  const completeService = useCallback(async () => {
    let param = {
      client_seq: serviceInfo.client_seq,
      counseling_seq: serviceInfo.counseling_seq,
      service_type: serviceType,
      service_status: serviceStatus,
      product_seq: serviceInfo.product_seq,
      // is_marketing: isMarketing ? '1' : '0',
      signature: signature,
      is_new: serviceType === '01' || (serviceType === '02' && (changeScan1st || changeScan2nd)) ? '1' : '0',
      complete_latitude: gps[0],
      complete_longitude: gps[1],
      complete_address: address || 'unknown',
      is_demolition: serviceType === '03' ? isDemolition ? '1' : '0' : '',
    };

    if (serviceType !== '01') {
      if (collectType1st || collectType2nd) {
        param.is_collect = '1';
        param.collect_first_type = collectType1st === 'AS' ? 'A' : collectType1st === 'RT' ? 'R' : 'None';
        // param.collect_first_child_product_code = collectSelect1stProduct;
        // param.collect_first_serial_number = collectScan1st;
        // param.collect_first_is_scan = collectScan1st ? (isCollectScan1st ? '1' : '0') : '';
        param.collect_second_type = collectType2nd === 'AS' ? 'A' : collectType2nd === 'RT' ? 'R' : 'None';
        // param.collect_second_child_product_code = collectSelect2ndProduct;
        // param.collect_second_serial_number = collectScan2nd;
        // param.collect_second_is_scan = collectScan2nd ? (isCollectScan2nd ? '1' : '0') : '';
      } else {
        param.is_collect = '0';
        param.collect_first_type = 'None';
        // param.collect_first_child_product_code = '';
        // param.collect_first_serial_number = 'None';
        // param.collect_first_is_scan = 'None';
        param.collect_second_type = 'None';
        // param.collect_second_child_product_code = '';
        // param.collect_second_serial_number = 'None';
        // param.collect_second_is_scan = 'None';
      }
    }

    try {
      const result = await ServiceService.completeServiceInfo(serviceSeq, param);
      if (result.result < 0) {
        setCancelAlertDec(result.message);
        cancelAlertOpen();
        return;
      }
      setIsCompleteOpen(true);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [address, gps, serviceSeq, serviceStatus, signature, isMarketing, changeScan1st, changeScan2nd, serviceType, collectType1st, collectType2nd, serviceInfo, isDemolition, cancelAlertOpen]);
  // }, [address, gps, serviceSeq, serviceStatus, signature, isMarketing, changeScan1st, changeScan2nd, serviceType, collectType1st, collectType2nd, serviceInfo, isDemolition, cancelAlertOpen]);

  // 유무상 선택
  const onIsFree = (is) => {
    if (!is) {
      let serviceProductList = productList.filter(element => {
        return element.quantity !== 0;
      });
      let serviceMaterialCost = 0;
      serviceProductList.forEach((item, index) => {
        serviceMaterialCost += (Number(item.quantity) * Number(item.consumer_price.replace(/,/gi, "")));
      });
      setMaterialCost(serviceMaterialCost);
    }
    setIsFree(is);
  }

  // 마케팅동의 팝업 열기/닫기
  const marketingDialogOpen = () => {
    if (marketingDialog) {
      setMarketingDialog(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
    } else {
      setMarketingDialog(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setMarketingDialog(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          setExitConfirm(true);
        }
      }
    }
  }

  // 마케팅 동의 처리
  const marketingAgree = () => {
    setIsMarketing(true);
    marketingDialogOpen();

    // window.nativeApi.execute(window.NativeApiMethod.OPEN_SIGNATURE, {}, window.NativeApiCallbackMethod.OPEN_SIGNATURE);
    // window[window.NativeApiCallbackMethod.OPEN_SIGNATURE] = function (response) {
    //   setSignature(response.svg);
    // }
    saveIsMarketing(true);
  }

  // 마케팅 미동의 처리
  const marketingDisAgree = () => {
    setIsMarketing(false);
    marketingDialogOpen();

    // window.nativeApi.execute(window.NativeApiMethod.OPEN_SIGNATURE, {}, window.NativeApiCallbackMethod.OPEN_SIGNATURE);
    // window[window.NativeApiCallbackMethod.OPEN_SIGNATURE] = function (response) {
    //   setSignature(response.svg);
    // }
    saveIsMarketing(false);
  }

  // 종료 알럿
  const exitConfirmOpen = useCallback(() => {
    if (exitConfirm) {
      setExitConfirm(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExitConfirm(true);
      }
    } else {
      setExitConfirm(true);
    }
  }, [exitConfirm]);

  // 종료
  const onExitConfirm = async () => {
    history.goBack();
    exitConfirmOpen();
  }

  useEffect(() => {
    if (address) {
      completeService();  // 서비스 완료
    }
  }, [address, completeService])

  useEffect(() => {
    if (gps[0] && gps[1]) {
      // 주소 조회
      let geocoder = new window.kakao.maps.services.Geocoder();
      geocoder.coord2Address(gps[1], gps[0], (result, status) => {
        if (status === window.kakao.maps.services.Status.OK) {
          setAddress(result[0].road_address ? result[0].road_address.address_name : result[0].address.address_name);
        }
      });
    }
  }, [gps]);

  useEffect(() => {
    if (signature) {
      setStep(thisState => { return thisState + 1 });
    }
  }, [signature]);

  useEffect(() => {
    if (guide && step !== 1) {
      const $body = document.body;
      $body.classList.remove("_noscroll");
      setGuide(false);
    }
  }, [step, guide]);

  useEffect(() => {
    if (step === 2) {
      searchSelectModelList();
      searchSelect1stProductList();
      searchSelect2ndProductList();
    }
    if (step === 3) {
      searchSelectModelList();
      searchExchangeSelect1stProductList();
      searchExchangeSelect2ndProductList();
    }
    if (step === 6) {
      searchSelectModelList();
      // searchCollectSelect1stProductList();
      // searchCollectSelect2ndProductList();
    }
    // }, [step, searchSelectModelList, searchSelect1stProductList, searchSelect2ndProductList, searchExchangeSelect1stProductList, searchExchangeSelect2ndProductList, searchCollectSelect1stProductList, searchCollectSelect2ndProductList]);
  }, [step, searchSelectModelList, searchSelect1stProductList, searchSelect2ndProductList, searchExchangeSelect1stProductList, searchExchangeSelect2ndProductList]);


  useEffect(() => {
    if (serviceInfo) {
      setServiceType(serviceInfo.service_type);
      setScan1st(Util.unescapeHtml(serviceInfo.first_serial_number));
      setIsScan1st(serviceInfo.first_is_scan);
      setScan2nd(Util.unescapeHtml(serviceInfo.second_serial_number));
      setIsScan2nd(serviceInfo.second_is_scan);
      setProdChange(serviceInfo.is_change);
      setProd1stChange(serviceInfo.change_first_serial_number ? true : false);
      setProd2ndChange(serviceInfo.change_second_serial_number ? true : false);
      setChangeScan1st(Util.unescapeHtml(serviceInfo.change_first_serial_number));
      setIsChangeScan1st(serviceInfo.change_first_is_scan);
      setChangeScan2nd(Util.unescapeHtml(serviceInfo.change_second_serial_number));
      setIsChangeScan2nd(serviceInfo.change_second_is_scan);
      setBrokenSymptom(serviceInfo.broken_symptom ? serviceInfo.broken_symptom.split(',') : []);
      setProductList(serviceInfo.service_product_list);
      setServiceContent(serviceInfo.service_content);
      setAttachedFileList(serviceInfo.attached_file_list);
      setDeleteFileList([]);
      setPhotoFileList([]);
      setTravelCost(Number(serviceInfo.travel_cost));
      setLaborCost(Number(serviceInfo.labor_cost));
      setShippingCost(Number(serviceInfo.shipping_cost));
      if (serviceInfo.is_free) {
        setIsFree(true);
        setMaterialCost(0);
      } else {
        setIsFree(false);
      }
    }
  }, [serviceInfo]);

  useEffect(() => {
    if (serviceInfo) {
      if (serviceType !== '01') {
        setCollectScan1st(Util.unescapeHtml(serviceInfo.first_serial_number));
        setIsCollectScan1st(serviceInfo.first_is_scan);
        setCollectScan2nd(Util.unescapeHtml(serviceInfo.second_serial_number));
        setIsCollectScan2nd(serviceInfo.second_is_scan);
      }
    }
  }, [serviceType, serviceInfo])

  useEffect(() => {
    if (prod1stChange || prod2ndChange) {
      setProdChange(true);
    } else {
      setProdChange(false);
    }
  }, [prod1stChange, prod2ndChange])

  useEffect(() => {
    searchServiceInfo();
  }, [step, searchServiceInfo])

  useEffect(() => {
    setTotalCost(materialCost + travelCost + laborCost + shippingCost);
  }, [materialCost, travelCost, laborCost, shippingCost])

  useEffect(() => {
    if (isFree) {
      setMaterialCost(0);
    }
  }, [isFree])

  useEffect(() => {
    searchServiceInfo();  // 서비스 상세정보 조회
    searchServiceTypeList();  // 서비스 상담 리스트 조회
    searchBrokenSymptomList();  // 고장증상 리스트 조회
    searchServiceStatusList();  // 서비스 상태 리스트 조회
  }, [searchServiceInfo, searchServiceTypeList, searchBrokenSymptomList, searchServiceStatusList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: true
    }, window.NativeApiCallbackMethod.IS_LAYER);
    window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      exitConfirmOpen();
    }
  }, [exitConfirmOpen])

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
  }, [])

  return (
    <>
      <div className={`page-service t-detail${step === 1 ? " fixed" : ""}`}>
        {
          step !== 7 && (
            <div className="step-container">
              <ul className="flex">
                <li className={`${(step === 1) ? "_active" : ""}`}>기본정보</li>
                <li className={`${(step === 2) ? "_active" : ""}`}>인증</li>
                <li className={`${(step === 3) ? "_active" : ""}`}>서비스</li>
                <li className={`${(step === 4) ? "_active" : ""}`}>요금</li>
                <li className={`${(step === 5) ? "_active" : ""}`}>서명</li>
                <li className={`${(step === 6) ? "_active" : ""}`}>완료</li>
              </ul>
            </div>
          )
        }
        {/* 기본정보 */}
        {
          step === 1 && (
            <>
              <div className="dl-container">
                <div className="dl-wrap">
                  <strong className="tit">기본정보</strong>
                  <dl className="dl-item">
                    <dt>접수번호</dt>
                    <dd>{serviceInfo && serviceInfo.reception_number ? serviceInfo.reception_number : '-'}</dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>서비스번호</dt>
                    <dd>{serviceInfo && serviceInfo.service_number ? serviceInfo.service_number : '-'}</dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>고객명</dt>
                    <dd>{serviceInfo && serviceInfo.name ? serviceInfo.name : '-'}</dd>
                  </dl>
                  <div className="dl-item address-wrap">
                    <dl>
                      <dt>연락처</dt>
                      <dd>{serviceInfo && serviceInfo.contacts ? Util.formatPhoneNumber(serviceInfo.contacts.split(',')[0]) : '-'}</dd>
                    </dl>
                    <button className="btn btn-tel" onClick={() => onCallClient(serviceInfo.contacts ? serviceInfo.contacts.split(',')[0] : '')}><span className="a11y">통화</span></button>
                  </div>
                  <div className="dl-item address-wrap">
                    <dl>
                      <dt>주소</dt>
                      <dd>
                        <p className="address" dangerouslySetInnerHTML={{
                          __html: serviceInfo && serviceInfo.address ? serviceInfo.address : '-'
                        }}></p>
                      </dd>
                    </dl>
                    <button className="btn btn-map" onClick={() => onNaverMap(serviceInfo.address)}><span className="a11y">지도</span></button>
                  </div>
                  <dl className="dl-item">
                    <dt>모델</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.product_seq ? serviceInfo.crm_product_name + (serviceInfo.product_standard ? ' / ' + serviceInfo.product_standard : '') + ' / ' + Util.unescapeHtml(serviceInfo.app_exposure_sales) : '-'
                    }}></dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>보증만료일</dt>
                    <dd><p className="t-blue">{serviceInfo && serviceInfo.warranty_date ? Util.formatDate(serviceInfo.warranty_date) : '-'}</p></dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>최초설치일</dt>
                    <dd><p className="t-blue">{serviceInfo && serviceInfo.first_install_datetime ? Util.formatDate(serviceInfo.first_install_datetime) : '-'}</p></dd>
                  </dl>
                  <div className="dl-item address-wrap">
                    <dl>
                      <dt>접수 유형</dt>
                      <dd>
                        <strong className={serviceInfo && serviceInfo.is_emergency ? 't-red' : ''}>
                          {serviceInfo && serviceInfo.is_emergency ? '[긴급]' : ''}
                          {serviceInfo && serviceInfo.counseling_type_name + ' / ' + Util.unescapeHtml(serviceInfo.counseling_status_name)}
                        </strong>
                      </dd>
                    </dl>
                    {
                      serviceInfo && serviceInfo.is_emergency ?
                        <span className="emergency btn"><span className="a11y">긴급</span></span> : <></>
                    }
                  </div>
                  <dl className="dl-item">
                    <dt>접수일</dt>
                    <dd>{serviceInfo && serviceInfo.counseling_datetime ? Util.formatDate(serviceInfo.counseling_datetime) : '-'}</dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>접수내용</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.counseling_content ? serviceInfo.counseling_content : '-'
                    }}></dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>특이사항</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.special_note ? serviceInfo.special_note : '-'
                    }}></dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>고장증상</dt>
                    <dd>{serviceInfo && serviceInfo.counseling_broken_symptom_name ? serviceInfo.counseling_broken_symptom_name : '-'}</dd>
                  </dl>
                  <div className="dl-item address-wrap">
                    <dl>
                      <dt>담당자</dt>
                      <dd dangerouslySetInnerHTML={{
                        __html: serviceInfo && serviceInfo.manager_seq ? serviceInfo.manager_name : '-'
                      }}></dd>
                    </dl>
                  </div>
                </div>

                <div className="dl-wrap">
                  <strong className="tit">서비스 상담 정보</strong>
                  <div className="dl-item address-wrap">
                    <dl>
                      <dt>예정일시</dt>
                      <dd>{serviceInfo && serviceInfo.visit_datetime ? Util.formatDateTimeHHMM(serviceInfo.visit_datetime) : '-'}</dd>
                    </dl>
                  </div>
                  <dl className="dl-item">
                    <dt>상담결과</dt>
                    <dd>{serviceInfo && serviceInfo.service_counseling_type_name ? Util.unescapeHtml(serviceInfo.service_counseling_type_name) : '-'}</dd>
                  </dl>
                  <dl className="dl-item">
                    <dt>상담내용</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.service_counseling_content ? serviceInfo.service_counseling_content : '-'
                    }}></dd>
                  </dl>
                  <div className="dl-item flex">
                    <dl className="col1">
                      <dt>등록 / 수정일시</dt>
                      <dd>{serviceInfo && serviceInfo.service_counseling_datetime ? Util.formatDateTimeHHMM(serviceInfo.service_counseling_datetime) : '-'}</dd>
                    </dl>
                    <dl className="col2">
                      <dt>등록 / 수정자</dt>
                      <dd dangerouslySetInnerHTML={{
                        __html: serviceInfo && serviceInfo.service_counseling_reg_user_name ? serviceInfo.service_counseling_reg_user_name : '-'
                      }}></dd>
                    </dl>
                  </div>
                </div>
              </div>

              <button type="button" className="btn c-blue s1" onClick={marketingDialogOpen}>{location.state.mode === 'MOD' ? '서비스 수정하기' : '서비스 시작하기'}</button>
            </>
          )
        }
        {/* ./기본정보 */}

        {/* 인증 */}
        {
          step === 2 && (
            <div className="sn-container">
              <p className="dec">제품 인증이 필요합니다.<br /> 1차기 및 2차기 제품을 인증하세요.</p>
              <dl className="sn-dl">
                <dt><strong className="req">1차기 제품 S/N</strong></dt>
                <dd>
                  <div className="sn-wrap">
                    <input type="text" placeholder="시리얼 넘버를 스캔하세요." readOnly={true} value={scan1st} onChange={event => setScan1st(event.target.value)} />
                    <select data-action="type" onChange={(event) => onChangeSelect1stModel(event)} value={select1stModel} ref={model1stSelect} >
                      <option value="">모델 선택</option>
                      {
                        selectModelList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }}></option>
                          );
                        })
                      }
                    </select>
                    <select data-action="type" onChange={(event) => setSelect1stProduct(event.target.value)} value={select1stProduct} ref={product1stSelect} >
                      <option value="">제품 선택</option>
                      {
                        select1stProductList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }}></option>
                          );
                        })
                      }
                    </select>
                    <div className="btn-wrap">
                      <button type="button" className="btn-scanner" onClick={() => onClickScan(1)}><span className="a11y">스캔</span></button>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl className="sn-dl">
                <dt><strong className="req">2차기 제품 S/N</strong></dt>
                <dd>
                  <div className="sn-wrap">
                    <input type="text" placeholder="시리얼 넘버를 스캔하세요." readOnly={true} value={scan2nd} onChange={event => setScan2nd(event.target.value)} />
                    <select data-action="type" onChange={(event) => onChangeSelect2ndModel(event)} value={select2ndModel} ref={model2ndSelect} >
                      <option value="">모델 선택</option>
                      {
                        selectModelList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }}></option>
                          );
                        })
                      }
                    </select>
                    <select data-action="type" onChange={(event) => setSelect2ndProduct(event.target.value)} value={select2ndProduct} ref={product2ndSelect} >
                      <option value="">제품 선택</option>
                      {
                        select2ndProductList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }}></option>
                          );
                        })
                      }
                    </select>
                    <div className="btn-wrap">
                      <button type="button" className="btn-scanner" onClick={() => onClickScan(2)}><span className="a11y">스캔</span></button>
                    </div>
                  </div>
                </dd>
              </dl>

              <ul className="btn-flex t2">
                <li className="col1"><button type="button" onClick={onGoback} className="btn c-gray">이전</button></li>
                <li className="col2"><button type="button" className="btn c-sky" onClick={() => onSave(2)}>다음(임시저장)</button></li>
              </ul>
            </div>
          )
        }
        {/* ./인증 */}

        {/* 서비스 */}
        {
          step === 3 && (
            <div className="sn-container t-start3">
              <dl className="sn-dl">
                <dt><strong className="req">서비스 유형</strong></dt>
                <dd>
                  <select data-action="type" onChange={(event) => onChangeServiceType(event)} value={serviceType}>
                    <option value="">선택</option>
                    {
                      serviceTypeList.map((item, index) => {
                        return (
                          <option value={item.code} key={index}>{item.code_name}</option>
                        );
                      })
                    }
                  </select>
                </dd>
              </dl>

              {
                serviceType === '02' && ( // 02 : AS
                  <dl className="sn-dl" data-selector="type" data-sid="6">
                    <dt><strong >고장 증상(복수 선택 가능)</strong></dt>
                    <dd>
                      <div className="sn-wrap">
                        <ul className="chk-list">
                          {
                            brokenSymptomList.map((item, index) => {
                              return (
                                <li key={index}>
                                  <label className="chk-label">
                                    <input type="checkbox" value={item.code} onChange={(event) => onClickBrokenSymptom(event)} checked={brokenSymptom.indexOf(item.code) > -1} />
                                    <span className="txt">{item.code_name}</span>
                                  </label>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    </dd>
                  </dl>
                )
              }
              {
                (serviceType === '02' || serviceType === '04' || serviceType === '05') && ( // 02:AS, 04:이전설치, 05:이전철거
                  <dl className="sn-dl" data-selector="type" data-sid="6">
                    <dt><strong>제품 교체</strong></dt>
                    <dd>
                      <div className="sn-wrap _open">
                        <strong className="txt">교환 설치 제품을 선택하세요.</strong>
                        <ul className="chk-list t2" style={{ marginTop: '8px' }}>
                          <li>
                            <label className="chk-label">
                              <input type="checkbox" data-selector="sn" data-sid="1" onChange={(event) => onProd1stChange(event)} checked={prod1stChange} />
                              <span className="txt">1차기 제품 S/N 인증</span>
                            </label>
                            <div className={`barcode-container${prod1stChange ? " _active" : ""}`}>
                              <input type="text" placeholder="시리얼 넘버를 스캔하세요." readOnly={true} value={changeScan1st} onChange={event => setChangeScan1st(event.target.value)} />
                              <select data-action="type" onChange={(event) => onChangeExchangeSelect1stModel(event)} value={exchangeSelect1stModel} ref={exchangeModel1stSelect} >
                                <option value="">모델 선택</option>
                                {
                                  selectModelList.map((item, index) => {
                                    return (
                                      <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                        __html: item.crm_product_name + ' ' + item.product_standard
                                      }}></option>
                                    );
                                  })
                                }
                              </select>
                              <select data-action="type" onChange={(event) => setExchangeSelect1stProduct(event.target.value)} value={exchangeSelect1stProduct} ref={exchangeProduct1stSelect} >
                                <option value="">제품 선택</option>
                                {
                                  exchangeSelect1stProductList.map((item, index) => {
                                    return (
                                      <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                        __html: item.crm_product_name + ' ' + item.product_standard
                                      }}></option>
                                    );
                                  })
                                }
                              </select>
                              <div className="btn-wrap">
                                <button type="button" className="btn-scanner" onClick={() => onClickExchangeScan(1)}><span className="a11y">스캔</span></button>
                              </div>
                            </div>
                          </li>
                          <li>
                            <label className="chk-label">
                              <input type="checkbox" data-selector="sn" data-sid="2" onChange={(event) => onProd2ndChange(event)} checked={prod2ndChange} />
                              <span className="txt">2차기 제품 S/N 인증</span>
                            </label>
                            <div className={`barcode-container${prod2ndChange ? " _active" : ""}`}>
                              <input type="text" placeholder="시리얼 넘버를 스캔하세요." readOnly={true} value={changeScan2nd} onChange={event => setChangeScan2nd(event.target.value)} />
                              <select data-action="type" onChange={(event) => onChangeExchangeSelect2ndModel(event)} value={exchangeSelect2ndModel} ref={exchangeModel2ndSelect} >
                                <option value="">모델 선택</option>
                                {
                                  selectModelList.map((item, index) => {
                                    return (
                                      <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                        __html: item.crm_product_name + ' ' + item.product_standard
                                      }}></option>
                                    );
                                  })
                                }
                              </select>
                              <select data-action="type" onChange={(event) => setExchangeSelect2ndProduct(event.target.value)} value={exchangeSelect2ndProduct} ref={exchangeProduct2ndSelect} >
                                <option value="">제품 선택</option>
                                {
                                  exchangeSelect2ndProductList.map((item, index) => {
                                    return (
                                      <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                        __html: item.crm_product_name + ' ' + item.product_standard
                                      }}></option>
                                    );
                                  })
                                }
                              </select>
                              <div className="btn-wrap">
                                <button type="button" className="btn-scanner" onClick={() => onClickExchangeScan(2)}><span className="a11y">스캔</span></button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </dd>
                  </dl>
                )
              }
              {
                (serviceType === '01' || serviceType === '02' || serviceType === '04') && ( // 01:설치, 02:AS, 04:이전설치
                  <dl className="sn-dl">
                    <dt>
                      <strong>부품 교체</strong>
                      <button type="button" className="btn-search t2" onClick={reqOpen}><span className="txt">검색</span></button>
                    </dt>
                    <dd>
                      <div className="sn-wrap">
                        <ul className="prd-list">
                          {
                            !productList.length || !productList.filter((element) => { return element.quantity !== 0 }).length ?
                              <li className="no-data">제품을 검색하세요.</li>
                              :
                              productList.filter((element) => element.quantity > 0).map((item, index) => {
                                return (
                                  <li key={index}>
                                    <span className="col1" style={{ maxWidth: "50%" }} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name
                                    }}></span>
                                    <span className="col2 t-blue">{item.quantity}개</span>
                                    <span className="col1">{Util.formatNumber(item.quantity * Number(item.consumer_price.replace(/,/gi, "")))} 원</span>
                                    <button type="button" className="btn-close" onClick={() => onDeleteServiceProduct(index)}><span className="a11y">삭제</span></button>
                                  </li>
                                );
                              })
                          }
                        </ul>
                      </div>
                    </dd>
                  </dl>
                )
              }
              {
                serviceType && (
                  <>
                    <dl className="sn-dl">
                      <dt><strong className="req">서비스 내용</strong></dt>
                      <dd>
                        <textarea
                          placeholder="서비스 내용을 입력하세요."
                          onChange={(event) => setServiceContent(event.target.value)}
                          autoCapitalize="off"
                          value={Util.unescapeHtml(serviceContent)}
                        ></textarea>
                      </dd>
                    </dl>

                    <dl className="sn-dl">
                      <dt><strong className="req">사진 첨부</strong></dt>
                      <dd>
                        <ul className="pic-list">
                          <li>
                            <div className="pic-wrap">
                              <input type="file" className="btn-camera" onChange={onPhoto} accept="image/*" />
                            </div>
                          </li>
                          {
                            attachedFileList.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div className="pic-wrap _addPic">
                                    <button type="button" className="btn-camera" data-action="camera"></button>
                                    <div className="img-box" style={{ backgroundImage: `url(${item.thmbn_file_url})` }}>
                                      <button className="btn-close" data-action="decPic" onClick={() => onDelelteAttachedFile(item.attached_file_seq)}><span className="a11y">삭제</span></button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          }
                          {
                            photoFileList.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div className="pic-wrap _addPic">
                                    <button type="button" className="btn-camera" data-action="camera"></button>
                                    <div className="img-box" style={{ backgroundImage: `url(${item.result})` }}>
                                      <button className="btn-close" data-action="decPic" onClick={() => onDeleltePhoto(index)}><span className="a11y">삭제</span></button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </dd>
                    </dl>
                  </>
                )
              }

              <ul className="btn-flex t2">
                <li className="col1"><button type="button" onClick={onGoback} className="btn c-gray">이전</button></li>
                <li className="col2"><button type="button" className="btn c-sky" onClick={() => onSave(3)}>다음(임시저장)</button></li>
              </ul>
            </div>
          )
        }
        {/* ./서비스 */}

        {/* 요금 */}
        {
          step === 4 && (
            <div className="sn-container t-start3">
              {
                (serviceType === '01' || serviceType === '02' || serviceType === '04') && (
                  <dl className="sn-dl">
                    <dt>
                      <strong>소요부품</strong>
                    </dt>
                    <dd>
                      <div className="sn-wrap">
                        <ul className="prd-list">
                          {
                            !productList.length || !productList.filter((element) => { return element.quantity !== 0 }).length ?
                              <li className="no-data">소요된 부품이 없습니다.</li>
                              :
                              productList.map((item, index) => {
                                if (item.quantity > 0) {
                                  return (
                                    <li key={index}>
                                      <span className="col1" dangerouslySetInnerHTML={{
                                        __html: item.crm_product_name
                                      }}></span>
                                      <span className="col2 t-blue">{item.quantity}개</span>
                                      <span className="col1">{Util.formatNumber(item.quantity * Number(item.consumer_price.replace(/,/gi, "")))} 원</span>
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              })
                          }
                        </ul>
                      </div>
                    </dd>
                  </dl>
                )
              }

              <dl className="sn-dl">
                <dt><strong className="req">자재비</strong></dt>
                <dd>
                  <label className="txt-label">
                    <input type="text" data-cost="material" placeholder="" value={Util.formatNumber(materialCost)} readOnly />
                    <span className="bar">원</span>
                  </label>
                </dd>
              </dl>

              <dl className="sn-dl">
                <dt><strong className="req">출장비</strong></dt>
                <dd>
                  <label className="txt-label">
                    <input type="number" data-cost="travel" value={Util.numberCheck(travelCost)} onChange={(event) => setTravelCost(Number(event.target.value))} />
                    <span className="bar">원</span>
                  </label>
                </dd>
              </dl>

              <dl className="sn-dl">
                <dt><strong className="req">공임비</strong></dt>
                <dd>
                  <label className="txt-label">
                    <input type="number" data-cost="transport" value={Util.numberCheck(laborCost)} onChange={(event) => setLaborCost(Number(event.target.value))} />
                    <span className="bar">원</span>
                  </label>
                </dd>
              </dl>

              <dl className="sn-dl">
                <dt><strong className="req">운송비</strong></dt>
                <dd>
                  <label className="txt-label">
                    <input type="number" data-cost="transport" value={Util.numberCheck(shippingCost)} onChange={(event) => setShippingCost(Number(event.target.value))} />
                    <span className="bar">원</span>
                  </label>
                </dd>
              </dl>

              <dl className="tot-dl">
                <dt>합계</dt>
                <dd>
                  <p className="price">{Util.formatNumber(totalCost)}<small>원</small></p>
                </dd>
              </dl>

              <div className="sn-wrap t2">
                <dl>
                  <dt>보증기간</dt>
                  <dd>
                    <span className={
                      serviceInfo && serviceInfo.warranty_date ?
                        Util.formatDate(serviceInfo.warranty_date) + (Util.getUnixTimestamp(Util.todayDt('YYYY-MM-DD'), 'YYYY-MM-DD') > Util.getUnixTimestamp(Util.formatDate(serviceInfo.warranty_date), 'YYYY-MM-DD') ? 'c1' : '')
                        :
                        serviceInfo && serviceInfo.warranty_period ? Util.addMonthsDt('YYYY-MM-DD', serviceInfo.warranty_period) + (Util.getUnixTimestamp(Util.todayDt('YYYY-MM-DD'), 'YYYY-MM-DD') < Util.getUnixTimestamp(Util.addMonthsDt('YYYY-MM-DD', serviceInfo.warranty_period), 'YYYY-MM-DD') ? 'c1' : '') : ''
                    }>
                      {
                        serviceInfo && serviceInfo.warranty_date ?
                          Util.formatDate(serviceInfo.warranty_date) + (Util.getUnixTimestamp(Util.todayDt('YYYY-MM-DD'), 'YYYY-MM-DD') > Util.getUnixTimestamp(Util.formatDate(serviceInfo.warranty_date), 'YYYY-MM-DD') ? ' (만료)' : '')
                          :
                          serviceInfo && serviceInfo.warranty_period ? Util.addMonthsDt('YYYY-MM-DD', serviceInfo.warranty_period) + (Util.getUnixTimestamp(Util.todayDt('YYYY-MM-DD'), 'YYYY-MM-DD') < Util.getUnixTimestamp(Util.addMonthsDt('YYYY-MM-DD', serviceInfo.warranty_period), 'YYYY-MM-DD') ? ' (만료)' : '') : ''
                      }
                    </span>
                  </dd>
                </dl>
                <dl className="t2">
                  <dt>유/무상</dt>
                  <dd>
                    <ul className="btn-flex">
                      <li>
                        <label className="btn">
                          <input type="radio" name="r1" checked={!isFree} onChange={(event) => onIsFree(!event.target.checked)} />
                          <span className="txt">유상</span>
                        </label>
                      </li>
                      <li>
                        <label className="btn">
                          <input type="radio" name="r1" checked={isFree} onChange={(event) => onIsFree(event.target.checked)} />
                          <span className="txt">무상</span>
                        </label>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>

              <ul className="btn-flex t2">
                <li className="col1"><button type="button" onClick={onGoback} className="btn c-gray">이전</button></li>
                <li className="col2"><button type="button" className="btn c-sky" onClick={() => onSave(4)}>다음(임시저장)</button></li>
              </ul>
            </div>
          )
        }
        {/* ./요금 */}

        {/* 서명 */}
        {
          step === 5 && (
            <div className="sn-container t-start5">
              <div className="dl-wrap">
                <dl className="dl-item">
                  <dt>서비스 완료일</dt>
                  <dd>{Util.todayDt('YYYY-MM-DD')}</dd>
                </dl>
                <dl className="dl-item">
                  <dt>서비스 유형</dt>
                  <dd>{serviceTypeList.filter((element) => { return element.code === serviceType })[0].code_name}</dd>
                </dl>
                <dl className="dl-item">
                  <dt>서비스 내용</dt>
                  <dd dangerouslySetInnerHTML={{
                    __html: serviceContent
                  }}></dd>
                </dl>
                <dl className="dl-item">
                  <dt>서비스 요금</dt>
                  <dd><p className="price">{Util.formatNumber(totalCost)} <small>원</small></p></dd>
                </dl>

              </div>

              <div className="dl-wrap">
                <dl className="sn-dl">
                  <dt><strong className="req">서비스 결과</strong></dt>
                  <dd>
                    <select data-action="type" onChange={(event) => setServiceStatus(event.target.value)} value={serviceStatus}>
                      <option value="">선택</option>
                      {
                        serviceStatusList.filter(element => { return element.code !== 'N'; }).map((item, index) => {
                          return (
                            <option value={item.code} key={index}>{item.code_name}</option>
                          );
                        })
                      }
                    </select>
                  </dd>
                </dl>

                <ul className="btn-flex t2">
                  <li className="col1"><button type="button" onClick={onGoback} className="btn c-gray">이전</button></li>
                  {
                    serviceStatus ?
                      serviceStatus === 'C' ?
                        <li className="col2"><button type="button" className="btn c-sky" onClick={onSign}>고객 서명</button></li>
                        :
                        <li className="col2"><button type="button" className="btn c-sky" onClick={onNext}>저장</button></li>
                      :
                      <li className="col2"><button type="button" className="btn c-sky" onClick={onNext}>다음</button></li>
                  }
                </ul>
              </div>
            </div>
          )
        }
        {/* ./서명 */}

        {/* 완료 */}
        {
          step === 6 && (
            <div className="sn-container t-start5 t-start6">
              <div className="dl-wrap">
                <dl className="dl-item">
                  <dt>서비스 완료일</dt>
                  <dd>
                    {
                      serviceTypeList.filter((element) => { return element.code === serviceType }).length &&
                      serviceTypeList.filter((element) => { return element.code === serviceType })[0].code_name
                    }
                  </dd>
                </dl>
                <dl className="dl-item">
                  <dt>서비스 유형</dt>
                  <dd>{serviceTypeList.filter((element) => { return element.code === serviceType })[0].code_name}({serviceStatusList.filter((element) => { return element.code === serviceStatus })[0].code_name})</dd>
                </dl>
                <dl className="dl-item">
                  <dt>서비스 내용</dt>
                  <dd dangerouslySetInnerHTML={{
                    __html: serviceContent
                  }}></dd>
                </dl>
                <dl className="dl-item">
                  <dt>서비스 요금</dt>
                  <dd><p className="price">{Util.formatNumber(totalCost)} <small>원</small></p></dd>
                </dl>
                <dl className="dl-item">
                  <dt>마케팅활용 동의여부</dt>
                  <dd>{isMarketing ? '동의' : '미동의'}</dd>
                </dl>
                <dl className="dl-item">
                  <dt>고객서명</dt>
                  <dd>완료</dd>
                </dl>
              </div>

              {
                serviceInfo && serviceInfo.service_type !== '01' && (
                  <div className="dl-wrap t-blue">
                    <header>
                      제품 중 <br />공장AS 또는 반품 정보가 있나요?
                    </header>
                    <div className="sn-wrap">
                      <dl className="sn-dl">
                        <dt><strong>1차기 제품 S/N</strong></dt>
                        <dd>
                          <div className="barcode-container _active" data-selector="sn" data-sid="1">
                            <input type="text" placeholder="시리얼 넘버를 스캔하세요." readOnly={true} value={collectScan1st} onChange={event => setCollectScan1st(event.target.value)} />
                            {/* <select data-action="type" onChange={(event) => onChangeCollectSelect1stModel(event)} value={collectSelect1stModel} ref={collectModel1stSelect} >
                              <option value="">모델 선택</option>
                              {
                                selectModelList.map((item, index) => {
                                  return (
                                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name
                                    }}></option>
                                  );
                                })
                              }
                            </select>
                            <select data-action="type" onChange={(event) => setCollectSelect1stProduct(event.target.value)} value={collectSelect1stProduct} ref={collectProduct1stSelect} >
                              <option value="">제품 선택</option>
                              {
                                collectSelect1stProductList.map((item, index) => {
                                  return (
                                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name
                                    }}></option>
                                  );
                                })
                              }
                            </select>
                            <div className="btn-wrap">
                              <button type="button" className="btn-scanner" onClick={() => onClickCollectScan(1)}><span className="a11y">스캔</span></button>
                            </div> */}
                          </div>
                          <div className="label-flex flex">
                            <label className="chk-label">
                              <input type="radio" name="b1" checked={collectType1st === 'AS'} onClick={() => { if (collectType1st === 'AS') setCollectType1st('') }} onChange={(event) => setCollectType1st(event.target.checked ? 'AS' : 'RT')} />
                              <span className="txt">공장AS 요청</span>
                            </label>
                            <label className="chk-label">
                              <input type="radio" name="b1" checked={collectType1st === 'RT'} onClick={() => { if (collectType1st === 'RT') setCollectType1st('') }} onChange={(event) => setCollectType1st(event.target.checked ? 'RT' : 'AS')} />
                              <span className="txt">반품 요청</span>
                            </label>
                          </div>
                        </dd>
                      </dl>

                      <dl className="sn-dl">
                        <dt><strong>2차기 제품 S/N</strong></dt>
                        <dd>
                          <div className="barcode-container _active" data-selector="sn" data-sid="1">
                            <input type="text" placeholder="시리얼 넘버를 스캔하세요." readOnly={true} value={collectScan2nd} onChange={event => setCollectScan2nd(event.target.value)} />
                            {/* <select data-action="type" onChange={(event) => onChangeCollectSelect2ndModel(event)} value={collectSelect2ndModel} ref={collectModel2ndSelect} >
                              <option value="">모델 선택</option>
                              {
                                selectModelList.map((item, index) => {
                                  return (
                                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name
                                    }}></option>
                                  );
                                })
                              }
                            </select>
                            <select data-action="type" onChange={(event) => setCollectSelect2ndProduct(event.target.value)} value={collectSelect2ndProduct} ref={collectProduct2ndSelect} >
                              <option value="">제품 선택</option>
                              {
                                collectSelect2ndProductList.map((item, index) => {
                                  return (
                                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name
                                    }}></option>
                                  );
                                })
                              }
                            </select>
                            <div className="btn-wrap">
                              <button type="button" className="btn-scanner" onClick={() => onClickCollectScan(2)}><span className="a11y">스캔</span></button>
                            </div> */}
                          </div>
                          <div className="label-flex flex">
                            <label className="chk-label">
                              <input type="radio" name="b2" checked={collectType2nd === 'AS'} onClick={() => { if (collectType2nd === 'AS') setCollectType2nd('') }} onChange={(event) => setCollectType2nd(event.target.checked ? 'AS' : 'RT')} />
                              <span className="txt">공장AS 요청</span>
                            </label>
                            <label className="chk-label">
                              <input type="radio" name="b2" checked={collectType2nd === 'RT'} onClick={() => { if (collectType2nd === 'RT') setCollectType2nd('') }} onChange={(event) => setCollectType2nd(event.target.checked ? 'RT' : 'AS')} />
                              <span className="txt">반품 요청</span>
                            </label>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                )
              }

              <div className="dl-wrap">
                <ul className="btn-flex t2">
                  <li className="col1"><button type="button" onClick={onGoback} className="btn c-gray">이전</button></li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={onClickServiceComplete}>서비스 완료</button></li>
                </ul>
              </div>
            </div>
          )
        }
        {/* ./완료 */}
      </div >

      {/* 기본정보 > 안내 레이어 팝업 */}
      <div className={`layer${guide ? " _open" : ""}`} data-sid="layer" >
        <div className="pop-container pop-info" data-selector="pop-info">
          <div className="pop-content">
            <header className="pop-header">
              <h2 className="pop-tit">안내</h2>
            </header>
            <div className="pop-inner" data-action="popAppend">
              <strong className="t1">제품 인증이 필요합니다.</strong>
              <p className="dec">
                설치/철거/AS 서비스 제품을 인증하세요.<br /><br />
                고장으로 교환이 필요한 제품은 인증 후 서비스 단계에서 추가 인증이 필요합니다.
              </p>
            </div>
            <ul className="pop-btn">
              <li>
                <button onClick={() => {
                  window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
                    isLayer: true
                  }, window.NativeApiCallbackMethod.IS_LAYER);
                  window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
                    setExitConfirm(true);
                  }
                  setStep(2);
                }} className="btn">
                  확인
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="dim"></div>
      </div >
      {/* ./기본정보 > 안내 레이어 팝업 */}

      {/* 서비스 진행 > 소요부품 검색 레이어 팝업 */}
      {
        prodReq &&
        <ProductReg
          prodReq={prodReq}
          reqOpen={reqOpen}
          productList={productList}
          complete={onClickCompleteAddProduct}
        />
      }
      {/* ./서비스 진행 > 소요부품 검색 레이어 팝업 */}

      {/* 서비스 진행 > 완료 > 서비스유형 레이어 팝업 */}
      {
        <StartFinish
          finish={finish}
          finishOpen={finishOpen}
          complete={getGps}
          isCompleteOpen={isCompleteOpen}
          setIsCompleteOpen={setIsCompleteOpen}
          isDemolition={isDemolition}
          setIsDemolition={setIsDemolition}
          serviceInfo={serviceInfo}
          setExitConfirm={setExitConfirm}
        />
      }
      {/* ./서비스 진행 > 완료 > 서비스유형 레이어 팝업 */}

      {/* 컨펌창 */}
      <Confirm
        dec={`서비스를 ${serviceStatus && serviceStatusList.filter(element => serviceStatus === element.code)[0].code_name} 상태로 저장하시겠습니까?`}
        confirm={saveConfirm}
        confirmOpen={saveConfirmOpen}
        alertOpen={saveConfirmAlertOpen}
      />

      {/* 컨펌창 */}
      <Confirm
        dec="서비스를 완료하시겠습니까?"
        confirm={confirm}
        confirmOpen={confirmOpen}
        alertOpen={confirmAlertOpen}
      />

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />

      {/* 저장알럿 */}
      <Alert
        dec='저장하였습니다.'
        alert={saveAlert}
        alertOpen={saveAlertOpen}
      />

      {/* 컨펌창 */}
      <Confirm
        dec="서비스 진행을 종료하시겠습니까?"
        confirm={exitConfirm}
        confirmOpen={exitConfirmOpen}
        alertOpen={onExitConfirm}
      />

      {/* 알럿창 */}
      <Alert
        alert={cancelAlert}
        alertOpen={cancelAlertOpen}
      />

      <FullScreenDialog
        state={marketingDialog}
        open={marketingDialogOpen}
        cancel={marketingDisAgree}
        confirm={marketingAgree}
        title="마케팅 활용 동의 (선택)"
        html={<MarketingTerms />}
        btnCancelText={'미동의'}
        btnConfirmText={'동의'}
      />

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default ServiceStart;