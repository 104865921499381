import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Gnb from './Gnb';
import MainService from '../services/MainService';
import UserService from '../services/UserService';

const SubHeader = (props) => {
  const [menu, setMenu] = useState(false);
  const [notiCount, setNotiCount] = useState(0);
  const [profile, setProfile] = useState("");

  const menuOpen = () => {
    const $body = document.body;

    if (menu) {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      }
      $body.classList.remove("_noscroll");
      setMenu(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function (response) {
        $body.classList.remove("_noscroll");
        setMenu(false);
      }
      $body.classList.add("_noscroll");
      setMenu(true);
      searchProfile();
    }
  }

  // 프로필 정보 조회
  const searchProfile = useCallback(async () => {
    try {
      const result = await UserService.fetchItems();
      const data = result.data;

      setProfile(data.userInfo.profile_img_url);
    } catch (error) {
    } finally {
    }
  }, []);

  const subTitle = () => {
    const pathName = props.location.pathname;

    if (pathName.indexOf('/service') > -1) {
      if (pathName.indexOf('/list') > -1) {
        if (pathName.indexOf('/info') > -1) {
          return '서비스 상세';
        } else {
          return '서비스 종합';
        }
      } else if (pathName.indexOf('/start') > -1) {
        return '서비스 진행';
      } else if (pathName.indexOf('/reg') > -1) {
        return '서비스 신규 접수';
      } else if (pathName.indexOf('/result') > -1) {
        return '서비스 상세';
      }

    } else if (pathName.indexOf('/stock') > -1) {
      if (pathName.indexOf('/stock/list') > -1) {
        return '재고';
      } else if (pathName.indexOf('/stock/info') > -1) {
        return '재고 상세';
      } else if (pathName.indexOf('/release') > -1) {
        return '출고';
      } else if (pathName.indexOf('/receiving') > -1) {
        if (pathName.indexOf('/tempList') > -1) {
          return '입고 대기';
        } else if (pathName.indexOf('/receiving/info') > -1) {
          return '입고 상세';
        } else {
          return '입고';
        }
      } else if (pathName.indexOf('/as') > -1) {
        if (pathName.indexOf('/tempList') > -1) {
          return '공장AS요청 대기';
        } else if (pathName.indexOf('/reg') > -1) {
          return '공장AS 등록';
        } else {
          return '공장AS요청';
        }
      } else if (pathName.indexOf('/return') > -1) {
        if (pathName.indexOf('/tempList') > -1) {
          return '반품요청 대기';
        } else if (pathName.indexOf('/reg') > -1) {
          return '반품요청 등록';
        } else {
          return '반품요청';
        }
      }

    } else if (pathName.indexOf('/order') > -1) {
      if (pathName.indexOf('/list') > -1) {
        return '제품발주';
      } else if (pathName.indexOf('/reg') > -1) {
        return '제품발주 요청';
      } else if (pathName.indexOf('/edit') > -1) {
        return '제품발주 요청 수정';
      }

    } else if (pathName.indexOf('/mypage') > -1) {
      if (pathName.indexOf('/profile') > -1) {
        return '나의 정보';
      }

    } else if (pathName.indexOf('/notification') > -1) {
      return '알림 내역';

    } else if (pathName.indexOf('/board') > -1) {
      if (pathName.indexOf('/notice') > -1) {
        return '공지사항';
      }

    } else if (pathName.indexOf('/setting') > -1) {
      if (pathName.indexOf('/terms') > -1) {
        return '개인정보취급방침';
      }

    } else {
      return '';
    }
  }

  // 읽지않은 알림 카운트 조회
  const searchNotificationCount = useCallback(async () => {
    window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
    window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
      if (props.auth.isLogin && props.auth.user && !response) {
        props.auth.logout();
      }
    }

    try {
      const result = await MainService.fetchNewNotis();
      const data = result.data;
      setNotiCount(data.new_notis);
    } catch (error) {
    } finally {
    }
  }, [props]);

  const goBack = () => {
    const pathName = props.location.pathname;
    if (pathName.indexOf('/login/terms') > -1) {
      window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
    } else {
      props.history.goBack();
    }
  }

  useEffect(() => {
    searchNotificationCount();
  }, [props.location.pathname, searchNotificationCount])

  return (
    <>
      {props.location.pathname.indexOf('/login/niceAuth') > -1 || props.location.pathname.indexOf('/error') > -1 || props.location.pathname === '/login' ?
        <></>
        :
        <header id="header" className={menu ? "_openMenu" : null}>
          <div className="m-main">
            <h1 className="a11y">SINK LEADER 프리미엄 음식물처리기</h1>
            <button type="button" className="btn-back" onClick={goBack}><span className="a11y">뒤로가기</span></button>
            <h2 className="page-tit">{subTitle()}</h2>
            {
              props.location.pathname.indexOf('/notification') > -1 ||
                props.location.pathname.indexOf('/mypage') > -1 ||
                props.location.pathname.indexOf('/board') > -1 ||
                props.location.pathname.indexOf('/setting') > -1
                ? null
                : <>
                  <Link to="/notification" className={`push ${notiCount ? '_new' : ''}`}><span className="a11y">알림</span></Link>
                  <button type="button" className="all-menu" data-action="allMenu" onClick={menuOpen}><span className="bar"><span className="a11y">menu on / off</span></span></button>
                </>
            }
          </div>
          {
            menu &&
            <Gnb menu={menu} menuOpen={menuOpen} profile={profile} />
          }
        </header>
      }
    </>
  );
}

export default SubHeader;
