import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import OrderEdit from '../../layout/order/OrderEdit';
import OrderReg from '../../layout/order/OrderReg';
import ServiceService from '../../services/ServiceService';
import OrderService from '../../services/OrderService';
import FilterItem from "../../layout/FilterItem";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const OrderList = () => {
  const history = useHistory();
  const location = useLocation();
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", "", "", ""]);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState(false);
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트
  const [orderSeq, setOrderSeq] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [editData, setEditData] = useState({});
  const [orderEdit, setOrderEdit] = useState(false);
  const [orderReg, setOrderReg] = useState(false);

  // 목록 상세 보기
  const detailOpen = (event) => {
    const $over = document.querySelectorAll(".over-container");
    const $overItem = event.target.closest(".over-container");
    const $open = event.target.closest("._open");

    $over.forEach((item) => {
      item.classList.remove("_open");
    });

    if ($open) {
      $overItem.classList.remove("_open");
    } else {
      $overItem.classList.add("_open");
    }
  }

  // 제품발주 요청 수정
  const orderEditOpen = (editData) => {
    const $body = document.body;

    $body.classList.remove("_noscroll");
    if (orderEdit) {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: false
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        }
      }
      setEditData({});
      setOrderEdit(false);
    } else {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          $body.classList.remove("_noscroll");
          setOrderEdit(false);
        }
      }
      $body.classList.add("_noscroll");
      setEditData(editData);
      setOrderEdit(true);
    }
  }

  // 제품발주 요청
  const orderRegOpen = () => {
    const $body = document.body;

    $body.classList.remove("_noscroll");
    if (orderReg) {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: false
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        }
      }
      setOrderReg(false);
    } else {
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          $body.classList.remove("_noscroll");
          setOrderReg(false);
        }
      }
      $body.classList.add("_noscroll");
      setOrderReg(true);
    }
  }

  const onListUpdate = () => {
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  const onClickDelete = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  // 알럿
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
      onDeleteOrder(orderSeq);
    } else {
      setConfirm(false);
      setAlert(true);
    }
  }

  // 제품 발주 삭제
  const onDeleteOrder = async (order_seq) => {
    setLoading(true);
    const param = {}
    try {
      const result = await OrderService.deleteOrder(order_seq, param);
      if (result.result === 0) {
        if (currentPage === 1) {
          searchList();
        } else {
          setCurrentPage(1);
        }
      }

    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: location.state ? location.state.product_group : '',
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [location]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    if (location.state && location.state.model_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        upper_child_product_code: location.state ? location.state.model_code : '',
        level: 1
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setProductList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    if (location.state && location.state.product_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.product_code : '',
        level: 2
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setAssyList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    if (location.state && location.state.assy_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.assy_code : '',
        level: 3
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setPartList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 검색버튼 클릭 핸들러
  const onClickSearch = () => {
    setCurrentPage(1);
  }

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize
    };
    Object.assign(param, location.state);
    try {
      const result = await OrderService.fetchItems(param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 요청시작일
  const onChangeStartDate = (event) => {
    setCurrentPage(1);
    history.replace({
      pathname: '/order/list',
      state: {
        search_start_date: Util.formatDate(event), //요청시작일
        search_end_date: location.state.search_end_date || '', //요청종료일
        order_status: location.state.order_status || '', //상태
        product_group: location.state.product_group || '', //품목군
        level: location.state.level || '', //단계(레벨)
        model_code: location.state.model_code || '', //모델명
        product_code: location.state.product_code || '', //제품명
        assy_code: location.state.assy_code || '', //아세이명
        part_code: location.state.part_code || '', //부품명
        search_word: location.state.search_word || '' //키워드
      }
    });
  }

  // 요청종료일
  const onChangeEndDate = (event) => {
    setCurrentPage(1);
    history.replace({
      pathname: '/order/list',
      state: {
        search_start_date: location.state.search_start_date || '', //요청시작일
        search_end_date: Util.formatDate(event) || '', //요청종료일
        order_status: location.state.order_status || '', //상태
        product_group: location.state.product_group || '', //품목군
        level: location.state.level || '', //단계(레벨)
        model_code: location.state.model_code || '', //모델명
        product_code: location.state.product_code || '', //제품명
        assy_code: location.state.assy_code || '', //아세이명
        part_code: location.state.part_code || '', //부품명
        search_word: location.state.search_word || '' //키워드
      }
    });
  }

  // 상태
  const onChangeStatus = (event) => {
    setCurrentPage(1);
    const name = event.target.name;

    if (event.target.value) {
      location.state[name] = event.target.value;
    } else {
      location.state[name] = '';
    }

    history.replace({
      pathname: '/order/list',
      state: {
        search_start_date: location.state.search_start_date || '', //요청시작일
        search_end_date: location.state.search_end_date || '', //요청종료일
        order_status: location.state.order_status || '', //상태
        product_group: location.state.product_group || '', //품목군
        level: location.state.level || '', //단계(레벨)
        model_code: location.state.model_code || '', //모델명
        product_code: location.state.product_code || '', //제품명
        assy_code: location.state.assy_code || '', //아세이명
        part_code: location.state.part_code || '', //부품명
        search_word: location.state.search_word || '' //키워드
      }
    });
  }

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);
    let locationState = location.state || {};

    if (idx === 0) { //요청시작, 종료일
      locationState.search_start_date = '';
      locationState.search_end_date = '';
    }
    if (idx === 1) { // 상태
      locationState.order_status = '';
    }
    if (idx === 2) { // 품목군
      locationState.product_group = '';
    }
    if (idx === 3) { // 단계
      locationState.level = '';
    }
    if (idx === 4) { // 모델
      locationState.model_code = '';
    }
    if (idx === 5) { // 제품
      locationState.product_code = '';
    }
    if (idx === 6) { // 아세이
      locationState.assy_code = '';
    }
    if (idx === 7) { // 부품
      locationState.part_code = '';
    }
    if (idx === 8) { // 키워드
      locationState.search_word = '';
      setKeyword('');
    }

    if (idx === 6) {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          order_status: locationState.order_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 5) {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          order_status: locationState.order_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 4) {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          order_status: locationState.order_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: locationState.search_start_date || '',
          search_end_date: locationState.search_end_date || '',
          order_status: locationState.order_status || '',
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    history.replace({
      pathname: '/order/list',
      state: {
        search_start_date: '', //요청시작일
        search_end_date: '', //요청종료일
        order_status: '',
        product_group: '', //품목군
        level: '', //단계(레벨)
        model_code: '', //모델명
        product_code: '', //제품명
        assy_code: '', //아세이명
        part_code: '', //부품명
        search_word: '' //키워드
      }
    });
  }, [history]);

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.search_word = event.target.value;

    history.replace({
      pathname: '/order/list',
      state: {
        search_start_date: location.state.search_start_date || '', //요청시작일
        search_end_date: location.state.search_end_date || '', //요청종료일
        order_status: location.state.order_status || '', //상태
        product_group: location.state.product_group || '', //품목군
        level: location.state.level || '', //단계(레벨)
        model_code: location.state.model_code || '', //모델명
        product_code: location.state.product_code || '', //제품명
        assy_code: location.state.assy_code || '', //아세이명
        part_code: location.state.part_code || '', //부품명
        search_word: location.state.search_word || '' //키워드
      }
    });
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};
    const name = event.target.name;
    locationState[name] = event.target.value;

    if (name === 'product_group') {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: location.state.search_start_date || '',
          search_end_date: location.state.search_end_date || '',
          order_status: location.state.order_status || '',
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else if (name === 'assy_code') {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: location.state.search_start_date || '',
          search_end_date: location.state.search_end_date || '',
          order_status: location.state.order_status || '',
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: location.state.product_code || '',
          assy_code: location.state.assy_code || '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else if (name === 'product_code') {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: location.state.search_start_date || '',
          search_end_date: location.state.search_end_date || '',
          order_status: location.state.order_status || '',
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: location.state.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else if (name === 'model_code') {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: location.state.search_start_date || '',
          search_end_date: location.state.search_end_date || '',
          order_status: location.state.order_status || '',
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: location.state.search_start_date || '',
          search_end_date: location.state.search_end_date || '',
          order_status: location.state.order_status || '',
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: location.state.product_code || '',
          assy_code: location.state.assy_code || '',
          part_code: location.state.part_code || '',
          search_word: location.state.search_word || ''
        }
      });
    }
  }

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회 
    searchAssyList(); // 아세이 리스트 조회
    searchPartList(); // 부품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList, searchAssyList, searchPartList]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(thisState => {
            return thisState + 1
          });
        }
      }
    }
  }, [loading, totalCount, currentPage]);

  // 스크롤 최상단으로 이동
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      const scrollT = ('scroll', e.srcElement.scrollingElement.scrollTop);
      setScroll({ scrollT });

      onMore(); // 더보기
    }
  }, [setScroll, onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/order/list',
        state: {
          search_start_date: '', //요청시작일
          search_end_date: '', //요청종료일
          order_status: '', //상태
          product_group: '', //품목군
          level: '', //단계(레벨)
          model_code: '', //모델명
          product_code: '', //제품명
          assy_code: '', //아세이명
          part_code: '', //부품명
          search_word: '' //키워드
        }
      });
    }

    if (state) {
      const startIncoming = state.search_start_date;
      const endIncoming = state.search_end_date;
      const orderStatus = state.order_status;
      const productGroup = state.product_group;
      const level = state.level;
      const modelCode = state.model_code;
      const productCode = state.product_code;
      const assyCode = state.assy_code;
      const partCode = state.part_code;
      const searchWord = state.search_word;

      // 요청일
      if (startIncoming && endIncoming) {
        let orderDate = '';
        orderDate = `요청일 ${startIncoming + ' ~ ' + endIncoming}`;

        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = orderDate;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = '';
          return state;
        });
      }

      if (orderStatus) {
        let statusName = '';
        switch (orderStatus) {
          case 'R':
            statusName = '요청';
            break;
          case 'A':
            statusName = '승인';
            break;
          case 'C':
            statusName = '반려';
            break;
          default:
            statusName = '';
            break;
        }

        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = statusName;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = '';
          return state;
        });
      }

      // 품목군
      if (productGroup) {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[2] = location.state.product_group;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[2] = '';
          return state;
        });
      }

      // 단계
      if (level) {
        let levelName = '';
        switch (level) {
          case '1':
            levelName = '제품';
            break;
          case '2':
            levelName = '아세이';
            break;
          case '3':
            levelName = '부품';
            break;
          default:
            levelName = '';
            break;
        }

        setSearchTag(thisState => {
          let state = [...thisState];
          state[3] = levelName;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[3] = '';
          return state;
        });
      }

      // 모델
      if (modelCode) {
        const $model_code = document.querySelector("[name=model_code]");

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $model_code.options[$model_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 제품
      if (productCode) {
        const $product_code = document.querySelector("[name=product_code]");

        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $product_code.options[$product_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 아세이
      if (assyCode) {
        const $assy_code = document.querySelector("[name=assy_code]");

        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $assy_code.options[$assy_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 부품
      if (partCode) {
        const $part_code = document.querySelector("[name=part_code]");

        const id = 7;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $part_code.options[$part_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 7;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 키워드
      if (searchWord) {
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[8] = searchWord;
          return state;
        });
      } else {
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[8] = '';
          return state;
        });
      }
    }

  }, [location, history, modelList, productList, assyList, partList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
  }, []);

  return (
    <>
      <div style={{ paddingBottom: "85px" }}>
        <div className="top-filter">
          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>
          <button type="button" className="btn c-black-line" onClick={() => Util.modal(filter, setFilter)}><span className="txt ft">필터</span></button>

          <div className={`filter-container ${filter ? '_openFilter' : null}`}>
            <header className="filter-header flex">
              <strong className="tit">필터</strong>
              <nav>
                <button type="button" className="btn c-black-line" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
                <button type="button" className="btn-close" onClick={() => Util.modal(filter, setFilter)}><span className="a11y">닫기</span></button>
              </nav>
            </header>


            <div className="select-container" data-selector="selectContainer">
              {
                searchTag.join("") === ""
                  ? <p className="dec">선택 필터가 없습니다.</p>
                  : (
                    searchTag.map((item, index) => {
                      return (
                        item
                          ?
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                          :
                          null
                      );
                    })
                  )
              }
            </div>

            <div className="dl-wrap t2">
              <dl className="dl-item">
                <dt>요청일</dt>
                <dd className="flex">
                  <div className="col">
                    <Datetime
                      inputProps={{
                        id: "startDate",
                        name: "startDate",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      onChange={onChangeStartDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.search_start_date} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                  <div className="col">
                    <Datetime
                      className={(location.state && location.state.search_start_date) ? "" : "disabled"}
                      inputProps={{
                        id: "endDate",
                        name: "endDate",
                        readOnly: true,
                        disabled: (location.state && location.state.search_start_date) ? false : true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      isValidDate={(event) => Util.validDt(event, location.state && location.state.search_start_date)}
                      onChange={onChangeEndDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.search_end_date} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>상태</dt>
                <dd className="flex chk-flex t2">
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="order_status" data-item="전체" onChange={onChangeStatus} value="" checked={location.state && location.state.order_status === "" ? true : false} />
                      <span className="txt">전체</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="order_status" data-item="요청" onChange={onChangeStatus} value="R" checked={location.state && location.state.order_status === "R" ? true : false} />
                      <span className="txt">요청</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="order_status" data-item="승인" onChange={onChangeStatus} value="A" checked={location.state && location.state.order_status === "A" ? true : false} />
                      <span className="txt">승인</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="chk-label t-btn">
                      <input type="radio" name="order_status" data-item="반려" onChange={onChangeStatus} value="C" checked={location.state && location.state.order_status === "C" ? true : false} />
                      <span className="txt">반려</span>
                    </label>
                  </div>
                </dd>
              </dl>

              <FilterItem
                productGroup={location.state && location.state.product_group}
                productGroupList={productGroupList}
                modelList={modelList}
                productList={productList}
                assyList={assyList}
                partList={partList}
                level={location.state && location.state.level}
                model={location.state && location.state.model_code}
                product={location.state && location.state.product_code}
                assy={location.state && location.state.assy_code}
                part={location.state && location.state.part_code}
                keyword={keyword}
                setKeyword={setKeyword}
                onBlurKeyword={onBlurKeyword}
                onChangeFilter={onChangeFilter}
              />

              <button type="button" data-action="search" className="btn c-blue s1" onClick={() => { Util.modal(filter, setFilter); onClickSearch() }}>검색하기</button>
            </div>
          </div>
        </div>
        {/* 20개씩 페이징 처리 */}
        <div className="prd-list t2">
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <dl className="prd-dl" key={index}>
                    {
                      item.order_status === 'R' // 상태가 요청일때만 수정 가능
                        ? <dt className="flex f2" onClick={() => orderEditOpen(item)}>
                          <strong className="prd label-txt" style={{ 'color': '#04a7df' }} dangerouslySetInnerHTML={{
                            __html: item.crm_product_name
                          }}></strong>
                          <span className="label-cnt">{item.quantity}</span>
                        </dt>
                        : <dt className="flex f2">
                          <strong className="prd label-txt" style={{ 'color': '#000' }} dangerouslySetInnerHTML={{
                            __html: item.crm_product_name
                          }}></strong>
                          <span className="label-cnt">{item.quantity}</span>
                        </dt>
                    }
                    <dd>
                      <div className="over-container t3">
                        <div className="over-wrap">
                          <dl className="dl-wrap t3">
                            <dt>{item.product_standard}</dt>
                            <dd>
                              <dl className="dl-wrap">
                                <dt>용도</dt>
                                <dd>{item.order_usage_type_name}</dd>
                              </dl>
                              <dl className="dl-wrap">
                                <dt>상태</dt>
                                <dd>
                                  {
                                    item.order_status === 'A'
                                      ? <strong className="t-sky">{item.order_status_name}</strong>
                                      : <strong className="t-red">{item.order_status_name}</strong>
                                  }
                                </dd>
                              </dl>
                              <dl className="dl-wrap">
                                <dt>매입/위탁</dt>
                                <dd>
                                  {
                                    item.is_free
                                      ? "위탁"
                                      : "매입"
                                  }
                                </dd>
                              </dl>
                              <dl className="dl-wrap">
                                <dt>공급가</dt>
                                <dd><strong>{item.supply_price ? Util.formatNumber(item.supply_price) : '0'}원</strong></dd>
                              </dl>
                            </dd>
                          </dl>
                          <dl className="dl-wrap t3">
                            <dt>비고</dt>
                            <dd>
                              <div className="editor-wrap" dangerouslySetInnerHTML={{ __html: item.remark ? item.remark.replace(/\n/g, '<br/>') : '-' }}></div>
                            </dd>
                          </dl>
                          {
                            (item.order_status === 'A' || item.order_status === 'C') &&
                            <dl className="dl-wrap t3">
                              <dt>사유</dt>
                              <dd>
                                <div className="editor-wrap" dangerouslySetInnerHTML={{ __html: item.status_content ? item.status_content.replace(/\n/g, '<br/>') : '-' }}></div>
                              </dd>
                            </dl>
                          }
                        </div>
                        <div className="over-btt">
                          <button type="button" className="btn-over" onClick={(event) => detailOpen(event)}><span className="a11y">더보기</span></button>
                          <dl className="dl-wrap">
                            <dt dangerouslySetInnerHTML={{ __html: item.reg_user_name ? item.reg_user_name : '-' }}></dt>
                            <dd>{item.reg_datetime ? Util.formatDateTimeHHMM(item.reg_datetime) : '-'}</dd>
                          </dl>
                          {
                            item.order_status === 'R' && <button type="button" className="btn-del" onClick={() => { onClickDelete(); setOrderSeq(item.order_seq) }}><span className="a11y">삭제</span></button>
                          }
                        </div>
                      </div>
                    </dd>
                  </dl>
                )
              })
          }
        </div>
        {/* ./20개씩 페이징 처리 */}

        <div className="btn-wrap fixed">
          <button type="button" className="btn c-sky w100" onClick={orderRegOpen}>제품발주 요청</button>
        </div>
      </div>

      {
        scroll.scrollT > 0 &&
        <button type="button" className="btn-page-top" onClick={scrollTop} style={{ bottom: '75px' }}><span className="a11y">최상단으로 이동</span></button>
      }

      {/* 제품발주 요청 */}
      {
        orderEdit &&
        <OrderEdit
          orderEdit={orderEdit}
          setOrderEdit={setOrderEdit}
          editData={editData}
          onListUpdate={onListUpdate}
          orderEditOpen={orderEditOpen}
        />
      }
      {/* ./제품발주 요청 */}

      {/* 제품발주 요청 수정 */}
      {
        orderReg &&
        <OrderReg
          orderReg={orderReg}
          setOrderReg={setOrderReg}
          onListUpdate={onListUpdate}
          orderRegOpen={orderRegOpen}
        />
      }
      {/* ./제품발주 요청 수정 */}

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec="삭제하시겠습니까?"
          confirm={confirm}
          confirmOpen={onClickDelete}
          alertOpen={alertOpen}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec="삭제되었습니다"
          alert={alert}
          alertOpen={alertOpen}
        />
      }
      {/* ./알럿창 */}

      {
        loading &&
        <Progress
          loading={loading}
          fixed={filter} // 화면 전체 레이어로 덮는 형태
        />
      }
    </>
  )
}

export default OrderList;