const FilterItem = (props) => {

  return (
    <>
      <div className="dl-group">
        <dl className="dl-item">
          <dt>품목군</dt>
          <dd>
            <select onChange={props.onChangeFilter} name="product_group" value={props.productGroup}>
              <option value="">전체</option>
              {
                !props.productGroupList.length ?
                  <></>
                  :
                  props.productGroupList.map((item, index) => {
                    return (
                      <option value={item.product_group} key={index}>{item.product_group}</option>
                    );
                  })
              }
            </select>
          </dd>
        </dl>
        <dl className="dl-item">
          <dt>단계</dt>
          <dd>
            <select onChange={props.onChangeFilter} name="level" value={props.level}>
              <option value="">전체</option>
              <option value="1">제품</option>
              <option value="2">아세이</option>
              <option value="3">부품</option>
            </select>
          </dd>
        </dl>
      </div>

      <dl className="dl-item">
        <dt>모델명</dt>
        <dd>
          <select onChange={props.onChangeFilter} name="model_code" value={props.model}>
            <option value="">전체</option>
            {
              !props.modelList.length ?
                <></>
                :
                props.modelList.map((item, index) => {
                  return (
                    <option value={item.parent_product_code} key={index} dangerouslySetInnerHTML={{
                      __html: item.crm_product_name + ' ' + item.product_standard
                    }} >
                    </option>
                  );
                })
            }
          </select>
        </dd>
      </dl>

      <dl className="dl-item">
        <dt>제품명</dt>
        <dd>
          <select onChange={props.onChangeFilter} name="product_code" value={props.product}>
            <option value="" data-level="">{props.product ? '전체' : '모델명을 선택하세요.'}</option>
            {
              !props.productList.length ?
                <></>
                :
                props.productList.map((item, index) => {
                  return (
                    <option value={item.child_product_code} data-level={item.level} key={index} dangerouslySetInnerHTML={{
                      __html: item.crm_product_name + ' ' + item.product_standard
                    }} >
                    </option>
                  );
                })
            }
          </select>
        </dd>
      </dl>

      <dl className="dl-item">
        <dt>아세이명</dt>
        <dd>
          <select onChange={props.onChangeFilter} name="assy_code" value={props.assy}>
            <option value="">{props.assy ? '전체' : '제품명을 선택하세요.'}</option>
            {
              !props.assyList.length ?
                <></>
                :
                props.assyList.map((item, index) => {
                  return (
                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                      __html: item.crm_product_name + ' ' + item.product_standard
                    }} >
                    </option>
                  );
                })
            }
          </select>
        </dd>
      </dl>

      <dl className="dl-item">
        <dt>부품명</dt>
        <dd>
          <select onChange={props.onChangeFilter} name="part_code" value={props.part}>
            <option value="">{props.part ? '전체' : '아세이명을 선택하세요.'}</option>
            {
              !props.partList.length ?
                <></>
                :
                props.partList.map((item, index) => {
                  return (
                    <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                      __html: item.crm_product_name + ' ' + item.product_standard
                    }} >
                    </option>
                  );
                })
            }
          </select>
        </dd>
      </dl>

      <dl className="dl-item">
        <dt>키워드</dt>
        <dd>
          <input type="text" placeholder="품목명 또는 규격 입력 후 검색하세요." onChange={(event) => props.setKeyword(event.target.value)} onBlur={props.onBlurKeyword} value={props.keyword} />
        </dd>
      </dl>
    </>
  );
}

export default FilterItem;