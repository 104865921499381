import moment from 'moment';

const Util = {
  nullifyUndifined: (data) => {
    for (const key of Object.keys(data)) {
      if (key in data) {
        data[key] = data[key] === undefined ? null : data[key];
      }
    }
  },

  formatDate: (
    value,
    defaultValue = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    return moment(value).format('YYYY-MM-DD');
  },

  formatYear: (
    value,
    defaultValue = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).format('YYYY');
    } else {
      return value.format('YYYY');
    }
  },

  formatDateTime: (
    value,
    defaultValue = ''
  ) => {
    if (!value) return defaultValue;
    return moment(value).format('YYYY.MM.DD HH:mm:ss');
  },

  formatDateTimeHHMM: (
    value,
    defaultValue = ''
  ) => {
    if (!value) return defaultValue;
    return moment(value).format('YYYY-MM-DD HH:mm');
  },

  formatDateTimeHHMMSS: (
    value,
    defaultValue = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  },

  formatDateRelatively: (
    value,
    defaultValue = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).fromNow();
    } else {
      return value.fromNow();
    }
  },

  getUnixTimestamp: (
    value,
    format
  ) => {
    return moment(value, format).unix();
  },

  formatNumber: (
    value,
    defaultValue = 0
  ) => {
    if (value !== undefined && value !== null) {
      if (typeof value === 'string') {
        value = value.replace(/,/gi, "");
      }
      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return `${defaultValue}`;
    }
  },

  formatPhoneNumber: (phoneNumber) => {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    let matchNew = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    let matchOld = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (matchNew) {
      return matchNew[1] + '-' + matchNew[2] + '-' + matchNew[3]
    } else if (matchOld) {
      return matchOld[1] + '-' + matchOld[2] + '-' + matchOld[3]
    }

    return phoneNumber;
  },

  formatHiddenPhoneNumber: (phoneNumber) => {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    let matchNew = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    let matchOld = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (matchNew) {
      return matchNew[1] + '-' + matchNew[2][0] + matchNew[2][1] + '**-' + matchNew[3][0] + matchNew[3][1] + '**';
    } else if (matchOld) {
      return matchOld[1] + '-' + matchOld[2][0] + '**-' + matchOld[3][0] + matchOld[3][1] + '**';
    }

    return phoneNumber;
  },

  formatEncryptPhoneNumber: (phoneNumber) => {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    let matchNew = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    let matchOld = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);


    if (matchNew) {
      let second_numbers = matchNew[2].substr(0, 1) + '**' + matchNew[2].substr(matchNew[2].length - 1, 1);
      let third_numbers = matchNew[3].substr(0, 1) + '**' + matchNew[3].substr(matchNew[3].length - 1, 1);
      return matchNew[1] + '-' + second_numbers + '-' + third_numbers

    } else if (matchOld) {
      let second_numbers = matchOld[2].substr(0, 1) + '*' + matchOld[2].substr(matchOld[2].length - 1, 1);
      let third_numbers = matchOld[3].substr(0, 1) + '**' + matchOld[3].substr(matchOld[3].length - 1, 1);
      return matchOld[1] + '-' + second_numbers + '-' + third_numbers
    }

    return phoneNumber;
  },

  parseNumbers(
    target,
    source,
    fields,
    defaultValue
  ) {
    for (const field of fields) {
      target[field] = source[field] ? Number(source[field]) : defaultValue;
    }
    return target;
  },

  compareMoments(a, b) {
    if (a && b) {
      return a > b ? 1 : -1;
    } else if (a) {
      return 1;
    } else if (b) {
      return -1;
    } else {
      return 0;
    }
  },

  isDefined: (arg) => {
    return typeof arg !== 'undefined' && arg !== null;
  },

  stringToMoment(value) {
    return value ? moment(value) : undefined;
  },

  stringToHTML(value) {
    let html = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return html.replace(/(https?:\/\/[^\s]+)/g, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  },

  unescapeHtml: (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  },

  todayDt: (format) => {
    return moment().format(format);
  },

  addDaysDt: (format, days) => {
    return moment().add(days, 'days').format(format);
  },

  addMonthsDt: (format, months) => {
    return moment().add(months, 'months').format(format);
  },

  validDt: (event, days) => {
    const minDay = moment(days).subtract(1, 'days');
    return event.isAfter(minDay);
  },

  // 쿠키설정
  setCookie: (idx) => {
    var todayDate = new Date();
    document.cookie = 'banner_' + idx + '=' + escape('Y') + '; expires=' + todayDate.toUTCString() + ';path=/';
  },

  // 쿠키 불러오기
  getCookie: (name) => {
    var obj = name + "=";
    var x = 0;
    while (x <= document.cookie.length) {
      var y = (x + obj.length);
      if (document.cookie.substring(x, y) === obj) {
        var endOfCookie = document.cookie.indexOf(";", y);
        if (endOfCookie === -1) endOfCookie = document.cookie.length;
        return unescape(document.cookie.substring(y, endOfCookie));
      }
      x = document.cookie.indexOf(" ", x) + 1;
      if (x === 0) break;
    }
    return "";
  },

  // 서비스 상태 가져오기
  getServiceStatus: (item) => {
    let status = '';  // default : 설치

    if (item) {
      if (item.service_status === 'C') {
        status = 'end'; // 완료
      } else if (item.service_status === 'I') {
        status = 'cant'; // 진행불가
      } else {
        if (item.visit_datetime && Util.formatDate(item.visit_datetime) < Util.todayDt('YYYY-MM-DD')) {
          status = 'late';  // 지연
        } else {
          switch (item.service_type) {
            case '01':
              status = ''; // 설치
              break;
            case '02':
              status = 'as'; // AS
              break;
            case '03':
              status = 'remove'; // 철거
              break;
            case '04':
              status = 'transfer'; // 이전설치
              break;
            case '05':
              status = 'transfer'; // 이전철거
              break;
            default:
              break;
          }
        }
      }
    }
    return status;
  },

  numberCheck: (value) => {
    let num = value || 0;
    num = num.toString();

    return num === "0" ? "0" : num.replace(/^0+/, '');
  },

  // 서비스 상태명 가져오기
  getServiceStatusName: (item) => {
    let status_name = "";
    if (item) {
      status_name = item.service_type_name;

      if (item.service_status === 'C') {
        status_name += ' 완료'; // 완료
      } else {
        if (item.visit_datetime && Util.formatDate(item.visit_datetime) < Util.todayDt('YYYY-MM-DD')) {
          status_name = '지연된 ' + status_name;  // 지연
        }
      }
    }
    return status_name;
  },

  // 접수 상태명 가져오기
  getCounselingStatusName: (item) => {
    let status_name = "";
    if (item) {
      status_name = item.counseling_type_name;

      if (item.service_status === 'C') {
        status_name += ' 완료'; // 완료
      } else {
        if (item.visit_datetime && Util.formatDate(item.visit_datetime) < Util.todayDt('YYYY-MM-DD')) {
          status_name = '지연된 ' + status_name;  // 지연
        }
      }
    }
    return status_name;
  },

  // 서비스 디데이 가져오기
  getServiceDdayName: (item) => {
    let d_day_name = '';

    if (item) {
      if (item.service_status === 'C') {
        d_day_name += Util.formatDate(item.complete_datetime) + ' 완료'; // 완료
      } else if (item.service_status === 'I') {
        d_day_name += Util.formatDate(item.complete_datetime);
      } else {
        if (item.visit_datetime) {
          var days = moment(moment().format('YYYY-MM-DD')).diff(moment(item.visit_datetime).format('YYYY-MM-DD'), 'days');
          d_day_name = '예정일 ';
          if (days == 0) d_day_name += 'D-day';
          if (days > 0) d_day_name += 'D+' + days + ' (진행중)';
          if (days < 0) d_day_name += 'D' + days;
        }
      }
    }

    return d_day_name;
  },

  // 숫자만 입력
  onlyNumber: (event) => {
    const val = event.target.value.replace(/[^0-9]/g, "");
    event.target.value = val;
  },

  // input[type=number] maxlength 체크
  numberMaxLength: (event) => {
    const max = event.target.maxLength || 0;
    if (event.target.value.length > max) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
    }
  },

  // 모달 (레이어 팝업)
  modal: (state, setState, reloadFunc) => {
    const $body = document.body;

    if (state) {
      // 네이티브 back키 동작
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, { isLayer: false }, null);
      }
      $body.classList.remove("_noscroll");
      setState(false);
    } else {
      // 네이티브 back키 동작
      if (!window.nativeApi.isWeb) {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, null);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, { isLayer: true }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          $body.classList.remove("_noscroll");
          setState(false);
        }
      }
      $body.classList.add("_noscroll");
      setState(true);
    }
  }

};

export default Util;
