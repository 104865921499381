import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from '../../AppContext';
import ServiceService from '../../services/ServiceService';
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const ManagerReg2 = (props) => {
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const serviceList = props.serviceList;
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 담당자 리스트
  const [managerSeq, setManagerSeq] = useState(""); // 담당자
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [alert, setAlert] = useState(false);

  // 담당자 리스트 조회
  const searchList = useCallback(async () => {
    if (serviceList) {
      setLoading(true);
      setManagerSeq("");
      const param = {
        center_seq: user.center_seq
      };
      try {
        const result = await ServiceService.fetchCenterUsers(param);
        const data = result.data;
        setList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [user.center_seq, serviceList]);

  // 담당자 선택
  const onSelectManager = (event) => {
    setManagerSeq(event.target.value);
  }

  // 담당자 배정완료 컨펌창 열기/닫기
  const confirmOpen = () => {
    if (managerSeq) {
      setConfirm(!confirm);
    } else {
      alertOpen();
    }
  }

  // 담당자 등록/수정
  const onAssign = async () => {
    setLoading(true);
    var param = {
      manager_seq: managerSeq,
      service_seq: serviceList.join(",")
    };
    try {
      await ServiceService.updateServiceManager(param);
      confirmOpen();
      confirmAlertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 저장 완료 알럿
  const confirmAlertOpen = () => {
    if (confirmAlert) {
      setConfirmAlert(false);
      props.regOpen();
      props.onRegSave();
    } else {
      setConfirmAlert(true);
    }
  }

  // 알럿
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }

  useEffect(() => {
    searchList(); // 리스트 조회
  }, [searchList]);

  return (
    <>
      <div className={`load-container _t2${props.reg ? " _open" : ""}`}>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-box reg-box">
            <dl className="reg-container">
              <dt>서비스 담당자 등록
                <button type="button" className="btn-close" onClick={props.regOpen}><span className="a11y">닫기</span></button>
              </dt>
              <dd>
                <div className="box">
                  <label className="in-label">
                    <strong className="tit req">담당자 배정</strong>
                    <select onChange={(event) => onSelectManager(event)} value={managerSeq ? managerSeq : ""}>
                      <option value="">선택</option>
                      {list.length &&
                        list.map((item, index) => {
                          return (
                            <option value={item.user_seq} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.name + '(' + Util.formatPhoneNumber(item.cell_phone) + ')'
                              }}></option>
                          );
                        })
                      }
                    </select>
                  </label>
                </div>
              </dd>
            </dl>
            <ul className="btn-flex t2">
              <li className="col1"><button type="button" className="btn c-gray" onClick={props.regOpen}>이전</button></li>
              <li className="col2"><button type="button" className="btn c-sky" onClick={confirmOpen}>배정완료</button></li>
            </ul>
          </section>
        </div>
      </div>

      {/* 컨펌창 */}
      <Confirm
        dec="배정완료 하시겠습니까?"
        confirm={confirm}
        confirmOpen={confirmOpen}
        alertOpen={onAssign}
      />
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      <Alert
        dec="완료되었습니다."
        alert={confirmAlert}
        alertOpen={confirmAlertOpen}
      />
      {/* ./알럿창 */}

      {/* 알럿창 */}
      <Alert
        dec="담당자를 선택하세요."
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default ManagerReg2;