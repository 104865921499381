import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import BoardService from '../../services/BoardService';
import Util from '../../helpers/Util';
import Alert from "../../layout/Alert";

const Notification = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트 
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    const param = {
      current_page: currentPage,
      page_size: pageSize
    };
    try {
      const result = await BoardService.fetchItems('notifications', param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
      if (error.response) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } else {
        setAlertDec('비정상적인 오류가 발생하였습니다. 네트워크를 확인하고 앱을 완전히 종료후 재실행하세요. 재실행 후에도 오류 발생시 관리자에게 문의바랍니다.')
        setAlert(true);
      }
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  // 더보기 버튼 핸들러
  const onMore = () => {
    setCurrentPage(currentPage + 1);
  }

  const onNoti = (item) => {
    const url = item.url;

    if (url) {
      if (url.indexOf('http') > -1) {
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
          url: url
        }, "");

      } else {
        const params = qs.parse(decodeURI(url));
        // 서비스
        const serviceSeq = params.service_seq;
        const serviceStatus = params.service_status;
        if (serviceSeq) {
          if (serviceStatus === 'C') {
            history.push("/service/result", {
              service_seq: serviceSeq
            });
          } else {
            history.push("/service/list/info", {
              service_seq: serviceSeq
            });
          }
        }

      }
    }
  }

  // 알럿 열기/닫기
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }

  useEffect(() => {
    searchList(); // 리스트 조회
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      setCurrentPage(1);
    }
  }, [])

  return (
    <>
      <section className="bbs-container">
        <ul className="bbs-list">
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <li key={index}>
                    <div className={`item ${item.is_receive ? '' : '_new'}`} onClick={() => onNoti(item)}>
                      <p className="tit" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                      <span className="date">{Util.formatDateTimeHHMM(item.send_datetime)}</span>
                    </div>
                  </li>
                );
              })
          }
        </ul>
      </section>

      {
        !loading && totalCount > pageSize * currentPage ?
          <div className="btn-wrap" style={{ marginTop: "30px" }}>
            <button type="button" className="btn c-sky w100" onClick={onMore}>더보기</button>
          </div>
          :
          <></>
      }

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
    </>
  )
}

export default Notification;