import React, { useState, useEffect } from "react";
import 'moment/locale/ko';
import StockService from '../../services/StockService';
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";

const ReceivingTempList = (props) => {
  const [item, setItem] = useState(null);
  const [scanList, setScanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState('');
  const [confirmDec, setConfirmDec] = useState('');

  // 스캔 삭제
  const onDeleteScan = (index) => {
    setScanList(thisState => {
      let state = [...thisState];
      state.splice(index, 1);
      return state;
    });
  }

  // 입고 바코드 스캐너
  const onClickAddScan = () => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_MULTI_SCANNER, {
      name: '입고 스캔',
      product_group: item.product_group,
      product_name: item.crm_product_name,
      standard: item.product_standard,
      image: item.thmbn_file_url,
      list: scanList
    }, window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER);
    window[window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER] = function (response) {
      response.list.forEach((scanItem, index) => {
        response.list[index].return_type = '';
      });
      setScanList(response.list);
    }
  }

  const confirmOpen = () => {
    if (scanList.length) {
      setConfirm(!confirm);
    } else {
      if (!scanList.length) {
        setAlertDec("바코드를 입력하세요.");
      }
      alertOpen();
    }
  }

  const alertOpen = () => {
    if (alert) {
      setAlert(false);
      if (isSave) {
        document.body.classList.remove("_noscroll");
        props.setTempListReg(false);
        props.onSaveCallback();
        setIsSave(false);
      }
    } else {
      setConfirm(false);
      setAlert(true);
    }
  }

  // 입고 완료
  const onSaveReceiving = () => {
    setConfirmDec('');
    setAlertDec('');

    if (scanList.length) {
      if (Number(item.quantity) !== scanList.length) {
        setConfirmDec('공장 출고 정보와<br />입고수량이 다릅니다.<br />수량을 확인하시기 바랍니다.<br />입고 완료하시겠습니까?');
        confirmOpen();
      } else {
        setConfirmDec('입고 완료하시겠습니까?');
        confirmOpen();
      }
    } else {
      setAlertDec('스캔 결과가 없습니다.');
      alertOpen();
    }
  }

  // 입고 완료 (제품)
  const regProduct = async () => {
    // 시리얼넘버 ',' 문자 있는지 확인
    var isOk = false;
    scanList.forEach((scanItem) => {
      if (scanItem.barcode.indexOf(',') > -1) {
        isOk = true;
        return;
      }
    });

    if (isOk) {
      setAlertDec('S/N에 \',\'(콤마) 문자는 사용 할 수 없습니다.');
      alertOpen();
      return;
    }

    let isScanList = scanList.map((scanItem) => { return scanItem.is_scan ? '1' : '0' }).join();
    let serialList = scanList.map((scanItem) => { return scanItem.barcode }).join();

    const param = {
      child_product_code: item.child_product_code,
      is_scan_list: isScanList,
      product_serial_number_list: serialList,
      release_date: Util.formatDate(item.release_date)
    };

    setLoading(true);
    try {
      const result = await StockService.registProduct(param);
      setAlertDec('');
      setAlert(true);

      if (result.result === 0) {
        setAlertDec('저장되었습니다.');
        setIsSave(true);
      } else {
        setAlertDec('다시 시도해주세요.');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setItem(props.item);
    setScanList(props.scanList);
  }, [props.item, props.scanList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
  }, [props.tempListReg]);

  return (
    <>
      <div className={`load-container${props.tempListReg ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={() => Util.modal(props.tempListReg, props.setTempListReg)}>
          <span className="a11y">닫기</span>
        </button>

        <div className="page-stock">
          <div className="prd-list">
            <dl className="prd-dl">
              <dt>
                <div className="tit">
                  <div className="img-box" style={{ backgroundImage: `url(${item ? item.thmbn_file_url : null})` }}></div>
                  {item ? item.product_group : '-'}
                </div>
                <span className="cnt">{scanList.length}</span>
              </dt>
              <dd>
                <dl className="dl-wrap">
                  <dt>품목</dt>
                  <dd dangerouslySetInnerHTML={{
                    __html: item ? item.crm_product_name : '-'
                  }}></dd>
                </dl>
                <dl className="dl-wrap">
                  <dt>규격</dt>
                  <dd dangerouslySetInnerHTML={{
                    __html: item ? item.product_standard : '-'
                  }}></dd>
                </dl>
              </dd>
            </dl>
          </div>

          <dl className="list-dl" style={{ paddingBottom: '55px' }}>
            <dt>목록</dt>
            <dd>
              <ul className="list t3">
                {
                  !scanList.length ?
                    <p className="no-data"> 스캔 결과가 없습니다.</p>
                    :
                    scanList.map((scanItem, index) => {
                      return (
                        <li key={index}>
                          <div className="col-dec">
                            <strong className="tit" dangerouslySetInnerHTML={{
                              __html: scanItem.barcode
                            }}></strong>
                          </div>
                          <button type="button" className="btn-del" onClick={() => { onDeleteScan(index) }}><span className="a11y">삭제</span></button>
                        </li>
                      );
                    })
                }
              </ul>

              <div className="barcode-wrap t2">
                <button type="button" className="barcode" onClick={onClickAddScan}><span className="a11y">바코드</span></button>
              </div>

              <div className="btn-wrap fixed">
                <button type="button" className="btn c-sky w100" onClick={onSaveReceiving}>입고 완료</button>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      {
        loading &&
        <Progress
          loading={loading}
          fixed={true} // 화면 전체 레이어로 덮는 형태
        />
      }

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec={confirmDec}
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={regProduct}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
      {/* ./알럿창 */}
    </>
  )
}

export default ReceivingTempList;