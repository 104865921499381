import Service from './Index';

const ServiceService = {
  // 서비스 리스트 조회
  fetchItems: (param) => {
    return Service.get('service', param);
  },

  // 서비스 리스트 조회
  downloadExcel: (param) => {
    return Service.post('service/excel', param);
  },

  // 서비스 상세 조회
  fetchItem: (service_seq) => {
    return Service.get('service/' + service_seq);
  },

  // 센터별 담당자 리스트 조회
  fetchCenterUsers: (param) => {
    return Service.get('service/centerUsers', param);
  },

  // 서비스 담당자 등록/수정
  updateServiceManager: (param) => {
    return Service.put('service/manager', param);
  },

  // 서비스 상담 등록
  insertServiceCounseling: (param) => {
    return Service.post('service/serviceCounseling', param);
  },

  // S/N으로 제품 조회
  fetchChildProductCode: (param) => {
    return Service.get('service/getChildProductCode', param);
  },

  // 서비스 수정
  updateServiceInfo: (service_seq, param) => {
    return Service.put('service/' + service_seq, param);
  },

  // 서비스 완료
  completeServiceInfo: (service_seq, param) => {
    return Service.put('service/' + service_seq + '/complete', param);
  },

  // 서비스 승인
  approvalServiceInfo: (service_seq, param) => {
    return Service.put('service/' + service_seq + '/approval', param);
  },

  // 품목군 리스트 조회
  fetchProductGroups: (param) => {
    return Service.get('service/productGroup', param);
  },

  // 모품목 리스트 조회
  fetchParentProducts: (param) => {
    return Service.get('service/product', param);
  },

  // 자품목 리스트 조회
  fetchChildProducts: (param) => {
    return Service.get('service/product', param);
  },

  // 서비스 품목(아세이, 부품) 리스트 조회
  fetchProducts: (param) => {
    return Service.get('service/serviceProduct', param);
  },

  // 서비스 품목(제품, 아세이, 부품) 리스트 조회
  fetchAllProducts: (param) => {
    return Service.get('service/serviceAllProduct', param);
  },

  // 서비스 사진 변경
  updateServicePhoto: (FormData) => {
    return Service.put('service/photo', FormData);
  },

  // 신규접수
  newCounseling: (param) => {
    return Service.post('counseling', param);
  },

  // 서비스 등록
  registService: (param) => {
    return Service.post('service', param);
  }
};

export default ServiceService;
