import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './AppContext';
import RootRouter from './router/RootRouter';
import './assets/css/style.css';

const App = () => {
  return (
    <AppContextProvider>
      <Router>
        <RootRouter />
      </Router>
    </AppContextProvider>
  );
}

export default App;