import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Profile from "../pages/mypage/Profile";

function MypageRouter() {
  return (
    <Switch>
      <PrivateRoute path="/mypage/profile" component={Profile} />
      <Redirect path="/mypage/*" to="/mypage/profile" />
    </Switch>
  );
}

export default MypageRouter;
