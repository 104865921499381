import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Terms from "../pages/setting/Terms";

function SettingRouter() {
  return (
    <Switch>
      <Route path="/setting/terms" component={Terms} />
      <Redirect path="/setting/*" to="/setting/terms" />
    </Switch>
  );
}

export default SettingRouter;
