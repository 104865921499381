import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import ServiceService from '../../services/ServiceService';
import RequestService from '../../services/RequestService';
import StockService from '../../services/StockService';
import ProductAdd from "../../layout/stock/ProductAdd";
import RegFinish2 from "../../layout/stock/RegFinish2";
import RegReturnTempList from "../../layout/stock/RegReturnTempList";
import Util from '../../helpers/Util';
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Progress from "../../layout/Progress";

const ReturnReg = () => {
  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = useState(false);
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", ""]);
  const [keyword, setKeyword] = useState("");
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [saveLoading, setSaveLoading] = useState(false);  // 저장로딩여부
  const [list, setList] = useState([]); // 리스트 
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 10;
  const [item, setItem] = useState(null);
  const [scanList, setScanList] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [returnType, setReturnType] = useState("");
  const [prodAdd, setProdAdd] = useState(false);
  const [awaitReg, setAwaitReg] = useState(false);
  const [sendReg, setSendReg] = useState(false);
  const [finishReg, setFinishReg] = useState(false);
  const [tempListReg, setTempListReg] = useState(false);
  const [prodAwait, setProdAwait] = useState(false);
  const [prodSend, setProdSend] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: location.state ? location.state.product_group : '',
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [location]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    if (location.state && location.state.model_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        upper_child_product_code: location.state ? location.state.model_code : '',
        level: 1
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setProductList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    if (location.state && location.state.product_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.product_code : '',
        level: 2
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setAssyList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    if (location.state && location.state.assy_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.assy_code : '',
        level: 3
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setPartList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);

    let locationState = location.state || {};

    if (idx === 0) { // 품목군
      locationState.product_group = '';
    }
    if (idx === 1) { // 단계
      locationState.level = '';
    }
    if (idx === 2) { // 모델
      locationState.model_code = '';
    }
    if (idx === 3) { // 제품
      locationState.product_code = '';
    }
    if (idx === 4) { // 아세이
      locationState.assy_code = '';
    }
    if (idx === 5) { // 부품
      locationState.part_code = '';
    }
    if (idx === 6) { // 키워드
      locationState.search_word = '';
    }

    if (idx === 4) {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 3) {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 2) {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    const $check = document.querySelector("input[data-action=date]");
    $check.checked = false;

    history.replace({
      pathname: '/stock/return/reg',
      state: {
        product_group: '',
        level: '',
        model_code: '',
        product_code: '',
        assy_code: '',
        part_code: '',
        search_word: ''
      }
    });
  }, [history]);

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize
    };
    Object.assign(param, location.state);
    try {
      const result = await StockService.fetchRequestItems(param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(thisState => {
            return thisState + 1
          });
        }
      }
    }
  }, [loading, totalCount, currentPage]);

  // 필터 검색하기
  const onFilterSearch = () => {
    filterOpen();
  }

  // 필터 레이어 팝업
  const filterOpen = () => {
    const $body = document.body;

    if (filter) {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
      window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
        if (currentPage === 1) {
          searchList();
        } else {
          setCurrentPage(1);
        }
      }
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, '');
      $body.classList.remove("_noscroll");
      setFilter(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
          if (currentPage === 1) {
            searchList();
          } else {
            setCurrentPage(1);
          }
        }
        $body.classList.remove("_noscroll");
        setFilter(false);
      }
      $body.classList.add("_noscroll");
      setFilter(true);
    }
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};

    const name = event.target.name;

    locationState[name] = event.target.value;

    if (name === 'assy_code') {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (name === 'product_code') {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (name === 'model_code') {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 반품요청 등록
  const receiveOpen = (item) => {
    // 단계1 네이티브 연속 바코드 스캐너 기능
    // 단계2~3 아셋이 부품일 경우
    setItem(item);
    if (item.level === '1') {  // 제품
      window.nativeApi.execute(window.NativeApiMethod.OPEN_MULTI_SCANNER, {
        name: "반품요청 스캔",
        product_group: item.product_group,
        product_name: item.crm_product_name,
        standard: item.product_standard,
        image: item.thmbn_file_url,
      }, window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER);
      window[window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER] = function (response) {
        if (response) {
          setScanList(response.list);
          tempListOpen();

          window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
        }
      }

    } else {  // 앗세이, 부품
      addOpen();
    }
  }

  // 부품 수량입력 레이어 팝업
  const addOpen = () => {
    const $body = document.body;

    if (prodAdd) {
      setQuantity(0);
      setReturnType("");
      $body.classList.remove("_noscroll");
      setProdAdd(false);
    } else {
      $body.classList.add("_noscroll");
      setProdAdd(true);
    }
  }

  // 발송 대기
  const awaitOpen = () => {
    if (awaitReg) {
      setProdAwait(false);
      setAwaitReg(false);
    } else {
      if (!quantity) {
        setAlertDec("수량을 입력하세요.");
        alertOpen();
      } else {
        if (!returnType) {
          setAlertDec("양품/불량 여부를 선택하세요.");
          alertOpen();
          return
        }
        console.log('item.quantity : ' + item.quantity + ", quantity : " + quantity);
        if (item.quantity < quantity) {

          setAlertDec('출고 수량을 초과할 수 없습니다.');
          alertOpen();
          return
        }
        setProdAwait(true);
        setAwaitReg(true);
      }
    }
  }

  // 요청 등록
  const onSave = async () => {
    setSaveLoading(true);
    var param = {
      request_type: 'R', // R:반품
      request_status: 'R',  // R:대기
      return_type: returnType,
      child_product_code: item.child_product_code,
      quantity: quantity
    };
    try {
      await RequestService.insertRequest(param);
      awaitOpen();
      confirmAlertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setSaveLoading(false);
    }
  }

  // 발송 완료
  const sendOpen = () => {
    if (sendReg) {
      setProdSend(false);
      setSendReg(false);
    } else {
      if (!quantity) {
        setAlertDec("수량을 입력하세요.");
        alertOpen();
      } else {
        if (item.quantity < quantity) {
          setAlertDec('출고 수량을 초과할 수 없습니다.');
          alertOpen();
          return
        }
        setProdSend(true);
        setSendReg(true);
      }
    }
  }

  // 출고 완료 레이어 팝업
  const finishOpen = () => {
    if (finishReg) {
      setFinishReg(false);
    } else {
      setProdSend(false);
      setSendReg(false);
      setFinishReg(true);
    }
  }

  // 바코드 리스트 레이어 팝업
  const tempListOpen = () => {
    if (tempListReg) {
      setScanList([]);
      setTempListReg(false);
    } else {
      setTempListReg(true);
    }
  }

  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  // 알럿
  const alertOpen = () => {
    setAlert(!alert);
  }

  // 저장 완료 알럿
  const confirmAlertOpen = () => {
    if (confirmAlert) {
      setConfirmAlert(false);
      addOpen();

      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    } else {
      setConfirmAlert(true);
    }
  }

  const finishAlertOpen = () => {
    const $body = document.body;

    if (alert) {
      $body.classList.remove("_noscroll");
      setProdAwait(false);
      setAwaitReg(false);
      setProdSend(false);
      setSendReg(false);
      setFinishReg(false);
      setAlert(false);
    } else {
      setProdAdd(false);
      setConfirm(false);
      setAlert(true);
    }
  }

  // 저장 콜백 함수
  const onSaveCallback = () => {
    addOpen();

    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  // 바코드 리스트 저장 콜백 함수
  const onBarcodeSaveCallback = () => {
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      onMore(); // 더보기
    }
  }, [onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/stock/return/reg',
        state: {
          product_group: '',
          level: '',
          model_code: '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: ''
        }
      });
    }

    if (state) {
      const productGroup = state.product_group;
      const level = state.level;
      const modelCode = state.model_code;
      const productCode = state.product_code;
      const assyCode = state.assy_code;
      const partCode = state.part_code;
      const searchWord = state.search_word;

      // 품목군
      if (productGroup) {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = productGroup;
          return state;
        });
      } else {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 단계
      if (level) {
        let levelName = '';
        switch (level) {
          case '1':
            levelName = '제품';
            break;
          case '2':
            levelName = '아세이';
            break;
          case '3':
            levelName = '부품';
            break;
          default:
            break;
        }

        const id = 1;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = levelName;
          return state;
        });

      } else {
        const id = 1;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 모델
      if (modelCode) {
        const $model_code = document.querySelector("[name=model_code]");

        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $model_code.options[$model_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 제품
      if (productCode) {
        const $product_code = document.querySelector("[name=product_code]");

        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $product_code.options[$product_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 모델
      if (assyCode) {
        const $assy_code = document.querySelector("[name=assy_code]");

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $assy_code.options[$assy_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 부품
      if (partCode) {
        const $part_code = document.querySelector("[name=part_code]");

        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $part_code.options[$part_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 키워드
      if (searchWord) {
        const id = 6;
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = searchWord;
          return state;
        });
      } else {
        setKeyword(searchWord);
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }
    }
  }, [location, history, modelList, productList, assyList, partList]);

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.search_word = event.target.value;

    history.replace({
      pathname: '/stock/return/reg',
      state: {
        product_group: locationState.product_group || '',
        level: locationState.level || '',
        model_code: locationState.model_code || '',
        product_code: locationState.product_code || '',
        assy_code: locationState.assy_code || '',
        part_code: locationState.part_code || '',
        search_word: locationState.search_word || ''
      }
    });
  }

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회 
    searchAssyList(); // 아세이 리스트 조회
    searchPartList(); // 부품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList, searchAssyList, searchPartList]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
  }, []);

  return (
    <>
      <div className="page-stock">
        <div className="top-filter">
          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>
          <button type="button" className="btn c-black-line" onClick={() => Util.modal(filter, setFilter)}>
            <span className="txt ft">필터</span>
          </button>

          <div className={`filter-container ${filter ? '_openFilter' : null}`}>
            <header className="filter-header flex">
              <strong className="tit">필터</strong>
              <nav>
                <button type="button" className="btn c-black-line" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
                <button type="button" className="btn-close" onClick={() => Util.modal(filter, setFilter)}>
                  <span className="a11y">닫기</span>
                </button>
              </nav>
            </header>


            <div className="select-container" data-selector="selectContainer">
              {
                searchTag.join("") === ""
                  ? <p className="dec">선택 필터가 없습니다.</p>
                  : (
                    searchTag.map((item, index) => {
                      return (
                        item
                          ? (
                            <span className="tag" data-selector="tag" key={index}>
                              {item}
                              <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                            </span>
                          ) : null
                      );
                    })
                  )
              }
            </div>

            <div className="dl-wrap t2">
              <div className="dl-group">
                <dl className="dl-item">
                  <dt>품목군</dt>
                  <dd>
                    <select onChange={onChangeFilter} name="product_group" value={location.state && location.state.product_group}>
                      <option value="">전체</option>
                      {
                        !productGroupList.length ?
                          <></>
                          :
                          productGroupList.map((item, index) => {
                            return (
                              <option value={item.product_group} key={index}>{item.product_group}</option>
                            );
                          })
                      }
                    </select>
                  </dd>
                </dl>

                <dl className="dl-item">
                  <dt>단계</dt>
                  <dd>
                    <select onChange={onChangeFilter} name="level" value={location.state && location.state.level}>
                      <option value="">전체</option>
                      <option value="1">제품</option>
                      <option value="2">아세이</option>
                      <option value="3">부품</option>
                    </select>
                  </dd>
                </dl>
              </div>

              <dl className="dl-item">
                <dt>모델명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="model_code" value={location.state && location.state.model_code}>
                    <option value="">전체</option>
                    {
                      !modelList.length ?
                        <></>
                        :
                        modelList.map((item, index) => {
                          return (
                            <option value={item.parent_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>제품명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="product_code" value={location.state && location.state.product_code}>
                    <option value="" data-level="">{location.state && location.state.model_code ? '전체' : '모델명을 선택하세요.'}</option>
                    {
                      !productList.length ?
                        <></>
                        :
                        productList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} data-level={item.level} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.crm_product_name
                              }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>아세이명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="assy_code" value={location.state && location.state.assy_code}>
                    <option value="">{location.state && location.state.product_code ? '전체' : '제품명을 선택하세요.'}</option>
                    {
                      !assyList.length ?
                        <></>
                        :
                        assyList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.crm_product_name
                              }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>부품명</dt>
                <dd>
                  <select onChange={onChangeFilter} name="part_code" value={location.state && location.state.part_code}>
                    <option value="">{location.state && location.state.assy_code ? '전체' : '부품명을 선택하세요.'}</option>
                    {
                      !partList.length ?
                        <></>
                        :
                        partList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index}
                              dangerouslySetInnerHTML={{
                                __html: item.crm_product_name
                              }} >
                            </option>
                          );
                        })
                    }
                  </select>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>키워드</dt>
                <dd>
                  <input type="text" placeholder="품목명 또는 규격 입력 후 검색하세요." onChange={(event) => setKeyword(event.target.value)} onBlur={onBlurKeyword} value={keyword} />
                </dd>
              </dl>
            </div>

            <button type="button" data-action="search" className="btn c-blue s1" onClick={onFilterSearch}>검색하기</button>
          </div>
        </div>

        <div className="prd-list">
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <dl className="prd-dl" key={index} onClick={() => receiveOpen(item)}>
                    <dt>
                      <div className="tit">
                        <div className="img-box" style={{ backgroundImage: `url(${item.thmbn_file_url})` }}></div>
                        {item.product_group}
                      </div>
                      <span className="cnt" dangerouslySetInnerHTML={{
                        __html: item.quantity
                      }}></span>
                    </dt>
                    <dd>
                      <dl className="dl-wrap">
                        <dt>품목</dt>
                        <dd dangerouslySetInnerHTML={{
                          __html: item.crm_product_name ? item.crm_product_name : '-'
                        }}></dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>규격</dt>
                        <dd dangerouslySetInnerHTML={{
                          __html: item.product_standard ? item.product_standard : '-'
                        }}></dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>단계</dt>
                        <dd>
                          {
                            item.level === '1' && '제품'
                          }
                          {
                            item.level === '2' && '아세이'
                          }
                          {
                            item.level === '3' && '부품'
                          }
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                )
              })
          }
          {
            loading &&
            <Progress
              loading={loading}
              fixed={filter}
            />
          }
        </div>
      </div>

      {
        prodAdd &&
        <ProductAdd
          item={item}
          setQuantity={setQuantity}
          quantity={quantity}
          setReturnType={setReturnType}
          returnType={returnType}
          prodAdd={prodAdd}
          prodStatus="return"
          addOpen={addOpen}
          finishOpen={sendOpen}
          awaitOpen={awaitOpen}
        />
      }

      {/* 공장 AS 요청 완료 레이어 팝업 */}
      {
        finishReg &&
        <RegFinish2
          item={item}
          quantity={quantity}
          prodStatus="return"
          finishReg={finishReg}
          setFinishReg={setFinishReg}
          finishOpen={finishOpen}
          onSaveCallback={onSaveCallback}
        />
      }
      {/* ./공장 AS 요청 완료 레이어 팝업 */}

      {/* 공장 AS 요청 완료 레이어 팝업 */}
      {
        tempListReg &&
        <RegReturnTempList
          item={item}
          scanList={scanList}
          prodStatus="return"
          tempListReg={tempListReg}
          setTempListReg={setTempListReg}
          finishOpen={tempListOpen}
          onSaveCallback={onBarcodeSaveCallback}
        />
      }
      {/* ./공장 AS 요청 완료 레이어 팝업 */}

      {/* 발송 대기 */}
      {
        awaitReg &&
        <Confirm
          dec="반품 대기 상태로 저장하시겠습니까?"
          confirm={prodAwait}
          confirmOpen={awaitOpen}
          alertOpen={onSave}
        />
      }
      {/* ./발송 대기 */}

      {/* 발송 완료 */}
      {
        sendReg &&
        <Confirm
          dec="반품 요청하시겠습니까?"
          confirm={prodSend}
          confirmOpen={sendOpen}
          alertOpen={finishOpen}
        />
      }
      {/* ./발송 완료 */}

      {
        confirm &&
        <Confirm
          dec="저장하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={finishAlertOpen}
        />
      }

      {
        confirmAlert &&
        <Alert
          dec="저장되었습니다."
          alert={confirmAlert}
          alertOpen={confirmAlertOpen}
        />
      }

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      <Progress
        loading={saveLoading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default ReturnReg;