import React, { useState, useEffect } from "react";
import RequestService from '../../services/RequestService';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import moment from 'moment';
import Util from '../../helpers/Util';
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Progress from "../../layout/Progress";

const RegFinish = (props) => {
  const [requestInfo, setRequestInfo] = useState(null);
  const [shippingDate, setShippingDate] = useState(Util.todayDt('YYYY-MM-DD'));
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  // 발송완료
  const onSave = async () => {
    setLoading(true);
    var param = {
      request_seq_list: props.checkList.join(','),
      shipping_date: shippingDate,
      delivery_company: deliveryCompany,
      delivery_number: deliveryNumber,
      remark: remark
    };
    try {
      await RequestService.completeRequest(param);
      confirmOpen();
      confirmAlertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 발송완료 컨펌창 열기/닫기
  const confirmOpen = () => {
    if (shippingDate && deliveryCompany && deliveryNumber) {
      setConfirm(!confirm);
    } else {
      if (!shippingDate) {
        setAlertDec("상담결과를 선택하세요.");
      } else if (!deliveryCompany) {
        setAlertDec("택배사를 선택하세요.");
      } else if (!deliveryNumber) {
        setAlertDec("운송장번호를 입력하세요.");
      }
      alertOpen();
    }
  }

  // 알럿
  const alertOpen = () => {
    setAlert(!alert);
  }

  // 저장 완료 알럿
  const confirmAlertOpen = () => {
    if (confirmAlert) {
      setConfirmAlert(false);
      props.finishOpen();
      props.onSaveCallback();
    } else {
      setConfirmAlert(true);
    }
  }

  useEffect(() => {
    if (props.checkList.length === 1) {
      const list = props.requestList.filter((item) => {
        return item.request_seq === Number(props.checkList[0])
      });
      if (list.length > 0) {
        setRequestInfo(list[0]);
      }
    }
  }, [props]);

  return (
    <>
      <div className={`load-container${props.finishReg ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={() => Util.modal(props.finishReg, props.setFinishReg)}>
          <span className="a11y">닫기</span>
        </button>
        <div data-selector="load" className="load-wrap">
          <section className="pop-reg-finish">
            {/* 단건일때만 노출 */}
            {
              props.checkList.length === 1
                ? <div className="prd-list">
                  <dl className="prd-dl">
                    <dt>
                      <div className="tit"><div className="img-box" style={{ backgroundImage: `url(${requestInfo ? requestInfo.thmbn_file_url : null})` }}></div> {requestInfo ? requestInfo.product_group : '-'}</div>
                      <span className="cnt">{requestInfo ? requestInfo.quantity : null}</span>
                    </dt>
                    <dd>
                      <dl className="dl-wrap">
                        <dt>품목</dt>
                        <dd dangerouslySetInnerHTML={{
                          __html: requestInfo ? requestInfo.crm_product_name : '-'
                        }}></dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>규격</dt>
                        <dd dangerouslySetInnerHTML={{
                          __html: requestInfo ? requestInfo.product_standard : '-'
                        }}></dd>
                      </dl>
                    </dd>
                  </dl>
                </div>
                : null
            }
            {/* ./단건일때만 노출 */}

            <dl className="list-dl t2 t3">
              <dt>
                {
                  props.prodStatus === "as"
                    ? <strong className="tit">공장 AS 요청을 완료하시겠습니까?</strong>
                    : null
                }
                {
                  props.prodStatus === "return"
                    ? <strong className="tit">반품 요청 수량을 입력하세요.</strong>
                    : null
                }
              </dt>
              <dd>
                <dl className="sn-dl">
                  <dt><strong className="req">출고일</strong></dt>
                  <dd>
                    <Datetime
                      inputprop={{
                        id: "shippingDate",
                        name: "shippingDate"
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      onChange={(event) => setShippingDate(moment(event).format('YYYY-MM-DD'))}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={(shippingDate) ? props.value : ''} readOnly />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                      value={shippingDate}
                    />
                  </dd>
                </dl>
                <dl className="sn-dl">
                  <dt><strong className="req">택배사</strong></dt>
                  <dd>
                    <input type="text" maxLength="10" placeholder="택배사를 입력하세요."
                      onChange={(event) => setDeliveryCompany(event.target.value)} autoCapitalize="off" value={deliveryCompany} />
                  </dd>
                </dl>
                <dl className="sn-dl">
                  <dt><strong className="req">운송장번호</strong></dt>
                  <dd>
                    <input type="text" maxLength="20" placeholder="운송장번호를 입력하세요."
                      onChange={(event) => setDeliveryNumber(event.target.value)} autoCapitalize="off" value={deliveryNumber} />
                  </dd>
                </dl>
                <dl className="sn-dl">
                  <dt><strong>비고</strong></dt>
                  <dd>
                    <textarea style={{ height: '160px', border: '1px solid #333' }} onChange={(event) => setRemark(event.target.value)} autoCapitalize="off" value={remark}></textarea>
                  </dd>
                </dl>

                <ul className="btn-flex t2">
                  <li className="col1">
                    <button type="button" className="btn c-gray" onClick={() => Util.modal(props.finishReg, props.setFinishReg)}>
                      취소
                    </button>
                  </li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={confirmOpen}>발송 완료</button></li>
                </ul>
              </dd>
            </dl>
          </section>
        </div>
      </div>

      {/* 컨펌창 */}
      <Confirm
        dec="저장하시겠습니까?"
        confirm={confirm}
        confirmOpen={confirmOpen}
        alertOpen={onSave}
      />
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      <Alert
        dec="저장되었습니다."
        alert={confirmAlert}
        alertOpen={confirmAlertOpen}
      />
      {/* ./알럿창 */}

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default RegFinish;