import React, { useState, useEffect, useCallback } from "react";
import ServiceService from '../../services/ServiceService';
import CodeService from '../../services/CodeService';
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Util from '../../helpers/Util';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import moment from 'moment';
import Progress from "../../layout/Progress";

const CounselingReg = (props) => {
  const serviceInfo = props.serviceInfo;
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 상담결과 리스트
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");
  const [type, setType] = useState("");
  const [isVisitDate, setIsVisitDate] = useState(false);
  const [date, setDate] = useState(Util.addDaysDt('YYYY-MM-DD', 1));
  const [hours, setHours] = useState("09");
  const [minutes, setMinutes] = useState("00");
  const [content, setContent] = useState("");

  // 상담결과 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    const param = {
      group_codes: 'SERVICE_COUNSELING_TYPE'
    };
    try {
      const result = await CodeService.fetchItems(param);
      const data = result.data;
      setList(data.service_counseling_type_list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // 전화
  const onCallClient = (contact) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
      tel: contact
    }, "");
  }

  // 담당자 배정완료 컨펌창 열기/닫기
  const confirmOpen = () => {
    if (type && date && content) {
      setConfirm(!confirm);
    } else {
      if (!type) {
        setAlertDec("상담결과를 선택하세요.");
      } else if (type === '01' && !date) {
        setAlertDec("예정일시를 선택하세요.");
      } else if (!content) {
        setAlertDec("상담내용을 입력하세요.");
      }
      alertOpen();
    }
  }

  // 상담결과 변경 핸들러
  const onChangeType = (event) => {
    setType(event.target.value);

    const type = event.target.value;
    if (type === '01') {  // 01 : 방문약속완료
      setIsVisitDate(true);
    } else {
      setIsVisitDate(false);
    }
  }

  // 서비스상담 등록
  const onSave = async () => {
    confirmOpen();
    setLoading(true);

    var param = {
      service_seq: serviceInfo.service_seq,
      service_counseling_type: type,
      visit_datetime: type === '01' ? date.replace(/-/gi, '') + hours + minutes : "",
      content: content
    };
    try {
      await ServiceService.insertServiceCounseling(param);

      confirmAlertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 저장 완료 알럿
  const confirmAlertOpen = () => {
    if (confirmAlert) {
      setConfirmAlert(false);
      props.serviceCounselingOpen();
      props.onServiceCounselingSave();
    } else {
      setConfirmAlert(true);
    }
  }

  // 알럿
  const alertOpen = () => {
    setAlert(!alert);
  }

  useEffect(() => {
    if (!props.serviceCounseling) {
      setIsVisitDate(false);
      setType("");
      setDate(Util.addDaysDt('YYYY-MM-DD', 1));
      setHours("09");
      setMinutes("00");
      setContent("");
    }
  }, [props, serviceInfo]);

  useEffect(() => {
    searchList(); // 상담결과 리스트 조회
  }, [searchList]);

  return (
    <>
      <div className={`load-container${props.serviceCounseling ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={props.serviceCounselingOpen}><span className="a11y">닫기</span></button>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-box reg-box1">
            <dl className={`box-wrap${serviceInfo && Util.getServiceStatus(serviceInfo) ? " _" + Util.getServiceStatus(serviceInfo) : ""}`}>
              <dt>
                <span className={`status${serviceInfo && serviceInfo.is_emergency ? " emergency" : ""}`}>{Util.getServiceStatusName(serviceInfo)}</span>
                <span className="date">
                  {serviceInfo && Util.getServiceDdayName(serviceInfo)}
                  {
                    serviceInfo && serviceInfo.status === "cant"
                      ? <span className="txt">진행불가</span>
                      : null
                  }
                </span>
              </dt>
              <dd>
                <button type="button" className="top-wrap" onClick={() => onCallClient(serviceInfo.contacts ? serviceInfo.contacts.split(',')[0] : '')}>
                  <strong className="name">{serviceInfo && serviceInfo.name ? serviceInfo.name : '-'} 고객</strong>
                  <span className="tel">{serviceInfo && serviceInfo.contacts ? Util.formatPhoneNumber(serviceInfo.contacts.split(',')[0]) : '-'}</span>
                </button>
                <div className="dl-wrap">
                  <dl className="dl-item">
                    <dt>모델</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.crm_product_name ? serviceInfo.crm_product_name + (serviceInfo.product_standard ? ' / ' + serviceInfo.product_standard : '') + ' / ' + Util.unescapeHtml(serviceInfo.app_exposure_sales) : '-'
                    }}></dd>
                  </dl>
                  <div className="dl-item address-wrap">
                    <dl>
                      <dt>주소</dt>
                      <dd>
                        <p className="address"
                          dangerouslySetInnerHTML={{
                            __html: serviceInfo && serviceInfo.address ? serviceInfo.address : '-'
                          }}></p>
                      </dd>
                    </dl>
                  </div>
                </div>
              </dd>
            </dl>

            <dl className="reg-container">
              <dt>서비스 상담 결과 등록</dt>
              <dd>
                <label className="in-label">
                  <strong className="tit req">상담결과</strong>
                  <select onChange={(event) => onChangeType(event)} value={type}>
                    <option value="">선택</option>
                    {list.length &&
                      list.map((item, index) => {
                        return (
                          <option value={item.code} key={index}>{Util.unescapeHtml(item.code_name)}</option>
                        );
                      })
                    }
                  </select>
                </label>
                {
                  isVisitDate &&
                  <div className="in-label">
                    <strong className="tit req">예정일시</strong>
                    <ul className="flex">
                      <li>
                        <Datetime
                          locale="kr"
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          closeOnSelect
                          onChange={(event) => setDate(moment(event).format('YYYY-MM-DD'))}
                          renderInput={(props, openCalendar, closeCalendar) => {
                            return <>
                              <input {...props} value={(date) ? props.value : ''} readOnly />
                              <button type="button" className="btn-close" onClick={closeCalendar}>
                                <span className="a11y">날짜 선택 닫기</span>
                              </button>
                            </>
                          }}
                          value={date}
                        />
                      </li>
                      <li>
                        <label>
                          <select onChange={(event) => setHours(event.target.value)} value={hours}>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                          </select>
                          <span className="bar">시</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <select onChange={(event) => setMinutes(event.target.value)} value={minutes}>
                            <option value="00">00</option>
                            <option value="30">30</option>
                          </select>
                          <span className="bar">분</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                }

                <label className="in-label">
                  <strong className="tit req">상담내용</strong>
                  <textarea style={{ height: '160px' }} onChange={(event) => setContent(event.target.value)} autoCapitalize="off" value={content}></textarea>
                </label>
              </dd>
            </dl>

            <ul className="btn-flex t2">
              <li className="col1"><button type="button" className="btn c-gray" onClick={props.serviceCounselingOpen}>이전</button></li>
              <li className="col2"><button type="button" className="btn c-sky" onClick={confirmOpen}>저장</button></li>
            </ul>
          </section>
        </div>
      </div>

      {/* 컨펌창 */}
      <Confirm
        dec="저장하시겠습니까?"
        confirm={confirm}
        confirmOpen={confirmOpen}
        alertOpen={onSave}
      />
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      <Alert
        dec="저장되었습니다."
        alert={confirmAlert}
        alertOpen={confirmAlertOpen}
      />
      {/* ./알럿창 */}

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default CounselingReg;