import React, { useState, useEffect } from "react";
import Util from '../../helpers/Util';
import Alert from "../../layout/Alert";

const ReqProductAdd = (props) => {
  const [count, setCount] = useState(0);
  const [level, setLevel] = useState(0);
  const [alert, setAlert] = useState(false);

  // 감소 버튼 핸들러
  const onDecrease = () => {
    if (count) {
      setCount(count - 1);
    }
  }

  // 증가 버튼 핸들러
  const onIncrease = () => {
    setCount(count + 1);
  }

  // 삭제 버튼 핸들러
  const onDeleteServiceProduct = () => {
    props.product.quantity = 0;
    props.addServiceProduct();
  }

  // 입력 버튼 핸들러
  const onAddServiceProduct = () => {
    if (count === 0) {
      alertOpen();
      return
    }
    props.product.quantity = count;
    props.addServiceProduct();
  }

  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }

  useEffect(() => {
    if (props.product) {
      setLevel(props.product.level);
      setCount(props.product.quantity);
    }
  }, [props.product]);

  return (
    <>
      <div className={`load-container _t2${props.isOpen ? " _open" : ""}`}>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-box reg-box pop-unit-cnt">
            <dl className="reg-container">
              <dt>
                {level === '1' && <span className="a11y">제품 수량</span>}
                {level === '2' && <span className="a11y">아세이 수량</span>}
                {level === '3' && <span className="a11y">부품 수량</span>}
                <button type="button" className="btn-close" onClick={props.addPopOpen}><span className="a11y">닫기</span></button>
              </dt>
              <dd>
                <div className="box">
                  {level === '1' && <strong className="tit">제품 수량을 입력하세요.</strong>}
                  {level === '2' && <strong className="tit">아세이 수량을 입력하세요.</strong>}
                  {level === '3' && <strong className="tit">부품 수량을 입력하세요.</strong>}
                  <label className="in-label">
                    <p className="dec under" dangerouslySetInnerHTML={{
                      __html: props.product && props.product.crm_product_name
                    }}></p>

                    <div className="cnt-container" data-scelector="cntContainer">
                      <input type="number" pattern="\d*" value={Util.numberCheck(count)} maxLength="4"
                        onKeyUp={(event) => Util.onlyNumber(event)}
                        onChange={(event) => { Util.numberMaxLength(event); setCount(Number(event.target.value)) }}
                      />
                      <button type="button" className="btn minus" data-action="cal" data-sid="minus" onClick={onDecrease}><span className="a11y">감소</span></button>
                      <button type="button" className="btn plus" data-action="cal" data-sid="plus" onClick={onIncrease}><span className="a11y">증가</span></button>
                    </div>
                  </label>
                </div>
              </dd>
            </dl>
            <ul className="btn-flex t2">
              <li className="col1"><button type="button" className="btn c-gray" onClick={props.addPopOpen}>취소</button></li>
              <li className="col1"><button type="button" className="btn c-mint" onClick={onDeleteServiceProduct}>삭제</button></li>
              <li className="col2"><button type="button" className="btn c-sky" onClick={onAddServiceProduct}>입력</button></li>
            </ul>
          </section>
        </div>
      </div>

      {
        alert &&
        <Alert
          dec="수량을 입력하세요."
          alert={alert}
          alertOpen={alertOpen}
        />
      }

    </>
  )
}

export default ReqProductAdd;
