import Service from './Index';

const OrderService = {
  // 발주 리스트 조회
  fetchItems: (param) => {
    return Service.get('order', param);
  },

  // 발주 등록
  regOrder: (param) => {
    return Service.post('order', param);
  },

  // 발주 상세정보 조회
  fetchItem: (order_seq) => {
    return Service.get('order/' + order_seq);
  },

  // 발주 수정
  updateOrder: (order_seq, param) => {
    return Service.post('order/' + order_seq, param);
  },

  // 발주 삭제
  deleteOrder: (order_seq, param) => {
    return Service.delete('order/' + order_seq, param);
  },

};

export default OrderService;

