import React, { useContext } from "react";
import { AppContext } from '../../AppContext';
import ModalHeader from '../../layout/ModalHeader';
import ServiceListInfoPage from '../service/ServiceListInfoPage';

const ServiceListInfoModal = (props) => {
  const { auth } = useContext(AppContext);
  return (
    <>
      {
        props.state &&
        <div className={`layer${props.state ? " _open" : ""}`}>
          <div className="pop-container pop-full">
            <div className="pop-content">
              <div className="pop-inner" data-action="popAppend" style={{ padding: 0 }}>
                <ModalHeader className="pop-header" auth={auth} goBack={props.open} title={props.title} />
                <ServiceListInfoPage serviceSeq={props.serviceSeq} setModal={props.setState} setIsUpdate={props.setIsUpdate} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ServiceListInfoModal;