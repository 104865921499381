import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from '../pages/login/Login';
import Join from '../pages/login/Join';
import { AppContext } from '../AppContext';

function LoginRouter() {
  const { auth } = useContext(AppContext);

  return (
    auth.user ?
      <Redirect to="/home" /> :
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/login/join" component={Join} />
        <Redirect path="/login/*" to="/login" />
      </Switch>
  );
}

export default LoginRouter;
