const Confirm = (props) => {
  return (
    <div className={`layer${props.confirm ? " _open" : ""}`}>
      <div className="pop-container pop-confirm " data-selector="pop-confirm">
        <div className="pop-content">
          <div className="pop-inner" data-action="popAppend">
            <div className="dec-wrap" dangerouslySetInnerHTML={{ __html: props.dec }}></div>
            <ul className="btn-flex t2">
              <li className="col1"><button type="button" className="btn c-gray s0" onClick={props.confirmOpen}>취소</button></li>
              <li className="col2"><button type="button" className="btn c-sky s0" onClick={props.alertOpen}>확인</button></li>
            </ul>
          </div>
          <button type="button" className="pop-close" onClick={props.confirmOpen}><span className="a11y">팝업닫기</span></button>
        </div>
      </div>
      <div className="dim"></div>
    </div>
  );
}

export default Confirm;