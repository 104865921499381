import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Error404 from '../pages/error/Error404';

function ErrorRouter() {
  return (
    <Switch>
      <Route path="/error/404" exact component={Error404} />
      <Redirect path="/error" to="/error/404" />
      <Redirect path="/error/*" to="/error/404" />
    </Switch>
  );
}

export default ErrorRouter;
