import Service from './Index';

const ClientService = {
  // 서비스 리스트 조회
  fetchItems: (param) => {
    return Service.get('client/clients', param);
  },

  // 계약 리스트 조회
  fetchContracts: (param) => {
    return Service.get('client/contract', param);
  },

};

export default ClientService;
