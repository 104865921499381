const Alert = (props) => {
  return (
    <div className={`layer${props.alert? " _open" : ""}`}>
      <div className="pop-container pop-confirm " data-selector="pop-confirm">
        <div className="pop-content">
          <div className="pop-inner" data-action="popAppend">
          <div className="dec-wrap">{ props.dec }</div>
            <div className="btn-flex">
              <button type="button" className="btn c-sky s0" onClick={props.alertOpen}>확인</button>
            </div>
          </div>
          <button type="button" className="pop-close" onClick={props.alertOpen}><span className="a11y">팝업닫기</span></button>
        </div>
      </div>
      <div className="dim"></div>
    </div>
  );
}

export default Alert;