import React, { useState, useEffect, useCallback } from "react";
import ProductImage from "../../layout/service/ProductImage";
import ReqProductAdd from "../../layout/service/ReqProductAdd";
import Progress from "../../layout/Progress";
import ServiceService from '../../services/ServiceService';

const ReqProductReg = (props) => {
  const [isAddPopOpen, setIsAddPopOpen] = useState(false);
  const [addProduct, setAddProduct] = useState(null);
  const [isImgPopOpen, setIsImgPopOpen] = useState(false);
  const [imgProduct, setImgProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]); // 품목군 리스트
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트
  const [keyWord, setKeyWord] = useState("");
  const [productGroup, setProductGroup] = useState("");
  const [level, setLevel] = useState("");
  const [model, setModel] = useState("");
  const [product, setProduct] = useState("");
  const [assy, setAssy] = useState("");
  const [part, setPart] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [selectedProductList, setSelectedProductList] = useState(props.productList);

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {
      type: 'AS'
    };
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      type: 'AS',
      product_group: productGroup,
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [productGroup]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    // setLoading(true);
    const param = {
      type: 'AS',
      product_group: productGroup,
      upper_child_product_code: model,
      level: 1
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setProductList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [productGroup, model]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    // setLoading(true);
    const param = {
      type: 'AS',
      product_group: productGroup,
      parent_product_code: model,
      upper_child_product_code: product,
      level: 2
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setAssyList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [productGroup, model, product]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: productGroup,
      parent_product_code: model,
      upper_child_product_code: assy,
      level: 3
    };
    try {
      const result = await ServiceService.fetchChildProducts(param);
      const data = result.data;
      setPartList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [productGroup, model, assy]);

  // 품목군 변경 핸들러
  const onChangeProductGroup = (event) => {
    setProductGroup(event.target.value);
    setCurrentPage(1);
  }

  // 레벨 변경 핸들러
  const onChangeLevel = (event) => {
    setLevel(event.target.value);
    setCurrentPage(1);
  }

  // 모델명 변경 핸들러
  const onChangeModel = (event) => {
    setModel(event.target.value);
    setCurrentPage(1);
  }

  // 제품 변경 핸들러
  const onChangeProduct = (event) => {
    setProduct(event.target.value);
    setCurrentPage(1);
  }

  // 아세이 변경 핸들러
  const onChangeAssy = (event) => {
    setAssy(event.target.value);
    setCurrentPage(1);
  }

  // 부품 변경 핸들러
  const onChangePart = (event) => {
    setPart(event.target.value);
    setCurrentPage(1);
  }

  // 검색버튼 클릭 핸들러
  const onClickSearch = () => {
    setSearchWord(keyWord);
    setCurrentPage(1);
  }

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    const param = {
      type: 'AS',
      product_group: productGroup,
      level: level,
      model_code: model,
      product_code: product,
      assy_code: assy,
      part_code: part,
      search_word: searchWord,
      current_page: currentPage,
      page_size: pageSize
    };
    try {
      const result = await ServiceService.fetchProducts(param);
      const data = result.data;
      setTotalCount(data.total_count);
      const list = data.list;
      list.forEach(item => {
        item.quantity = 0;
      });

      if (currentPage === 1) {
        setList(thisState => {
          const filteredList = list.filter((element) => {
            let isSame = false;
            selectedProductList.forEach((item) => {
              if (element.product_seq === item.product_seq) {
                isSame = true;
              }
            });
            return !isSame;
          });
          return filteredList;
        });
      } else {
        setList(thisState => {
          const filteredList = list.filter((element) => {
            let isSame = false;
            selectedProductList.forEach((item) => {
              if (element.product_seq === item.product_seq) {
                isSame = true;
              }
            });
            return !isSame;
          });

          let state = [
            ...thisState,
            ...filteredList
          ];
          return state;
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, productGroup, level, model, product, assy, part, searchWord, selectedProductList]);

  // 더보기 버튼 핸들러
  const onMore = useCallback((e) => {
    let scrollHeight = Math.round(e.srcElement.scrollHeight);
    let scrollTop = Math.round(e.srcElement.scrollTop);
    let clientHeight = Math.round(e.srcElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(currentPage + 1);
        }
      }
    }
  }, [loading, currentPage, totalCount]);

  const onScroll = useCallback((e) => {
    if (e.srcElement !== undefined) {
      onMore(e); // 더보기
    }
  }, [onMore]);

  // 서비스 > 제품 이미지 레이어 팝업
  const imgPopOpen = (item) => {
    if (isImgPopOpen) {
      setImgProduct(null);
      setIsImgPopOpen(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
        value: 'N'
      }, '');
    } else {
      if (!item.thmbn_file_url) {
        return;
      }
      setImgProduct(item);
      setIsImgPopOpen(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
        value: 'Y'
      }, '');
    }
  }

  // 서비스 >  부품 수량 입력 레이어 팝업
  const addPopOpen = (item) => {
    if (isAddPopOpen) {
      setAddProduct(null);
      setIsAddPopOpen(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
        value: 'N'
      }, '');
    } else {
      setAddProduct(item);
      setIsAddPopOpen(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
        value: 'Y'
      }, '');
    }
  }

  // 제품 추가
  const onAddServiceProduct = () => {
    setSelectedProductList(thisState => {
      let sIndex;
      thisState.forEach((item, index) => {
        if (item.product_seq === addProduct.product_seq) {
          sIndex = index;
        }
      });

      let state = [...thisState];
      if (sIndex !== undefined) {
        thisState[sIndex].quantity = addProduct.quantity;
        state = [
          ...thisState
        ];
      } else {
        if (addProduct.quantity !== 0) {
          state = [
            ...thisState,
            addProduct
          ];
        }
      }

      state.sort((a, b) => {
        return b.quantity - a.quantity;
      });

      return state;
    });

    addPopOpen();
  }

  useEffect(() => {
    setList(thisState => {
      const filteredList = thisState.filter((element) => {
        let isSame = false;
        selectedProductList.forEach((item) => {
          if (element.product_seq === item.product_seq) {
            isSame = true;
          }
        });
        return !isSame;
      });
      return filteredList;
    });
  }, [selectedProductList]);

  // 완료
  const onClickComplete = () => {
    props.complete(selectedProductList);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  // 품목군 변경시
  useEffect(() => {
    setModel("");
    setProduct("");
    setAssy("");
    setPart("");
    setAssyList([]);
    setPartList([]);
  }, [productGroup]);

  // 모델명 변경시
  useEffect(() => {
    setProduct("");
    setAssy("");
    setPart("");
    setAssyList([]);
    setPartList([]);

    if (model) {
      searchProductList();
    } else {
      setProductList([]);
    }

  }, [model, searchProductList]);

  // 제품명 변경시
  useEffect(() => {
    setAssy("");
    setPart("");
    if (product) {
      searchAssyList();
    } else {
      setAssyList([]);
      setPartList([]);
    }
  }, [product, searchAssyList]);

  // 아세이 변경시
  useEffect(() => {
    setPart("");
    if (assy) {
      searchPartList();
    } else {
      setPartList([]);
    }
  }, [assy, searchPartList]);

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchList();
  }, [searchProductGroupList, searchModelList, searchList]);

  return (
    <>
      <div className={`load-container _t3${props.prodReq ? " _open" : ""}`}>
        <div data-selector="load" className="load-wrap">
          <button type="button" className="btn-close" onClick={props.reqOpen}><span className="a11y">닫기</span></button>
          <section className="sec sec-box reg-box">
            <dl className="reg-container">
              <dt>품목 검색</dt>
              <dd>
                <div className="box">
                  <div className="box-group">
                    <label className="in-label">
                      <strong className="tit">품목군</strong>
                      <select onChange={onChangeProductGroup} value={productGroup}>
                        <option value="">전체</option>
                        {
                          productGroupList.length &&
                          productGroupList.map((item, index) => {
                            return (
                              <option value={item.product_group} key={index}>{item.product_group}</option>
                            );
                          })
                        }
                      </select>
                    </label>

                    <label className="in-label">
                      <strong className="tit">단계</strong>
                      <select onChange={onChangeLevel} value={level}>
                        <option value="">전체</option>
                        <option value="2" >아세이</option>
                        <option value="3" >부품</option>
                      </select>
                    </label>
                  </div>

                  <label className="in-label">
                    <strong className="tit">모델명</strong>
                    <select onChange={onChangeModel} value={model}>
                      <option value="">전체</option>
                      {
                        modelList.length &&
                        modelList.map((item, index) => {
                          return (
                            <option value={item.parent_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }} >
                            </option>
                          );
                        })
                      }
                    </select>
                  </label>

                  <label className="in-label">
                    <strong className="tit">제품명</strong>
                    <select onChange={onChangeProduct} value={product}>
                      <option value="" data-level="">{model ? '전체' : '모델명을 선택하세요.'}</option>
                      {
                        productList.length &&
                        productList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} data-level={item.level} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }} >
                            </option>
                          );
                        })
                      }
                    </select>
                  </label>

                  <label className="in-label">
                    <strong className="tit">아세이명</strong>
                    <select onChange={onChangeAssy} value={assy}>
                      <option value="">{product ? '전체' : '제품명을 선택하세요.'}</option>
                      {
                        assyList.length &&
                        assyList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }} >
                            </option>
                          );
                        })
                      }
                    </select>
                  </label>

                  <label className="in-label">
                    <strong className="tit">부품명</strong>
                    <select onChange={onChangePart} value={part}>
                      <option value="">{assy ? '전체' : '부품명을 선택하세요.'}</option>
                      {
                        partList.length &&
                        partList.map((item, index) => {
                          return (
                            <option value={item.child_product_code} key={index} dangerouslySetInnerHTML={{
                              __html: item.crm_product_name + ' ' + item.product_standard
                            }} >
                            </option>
                          );
                        })
                      }
                    </select>
                  </label>

                  <label className="in-label">
                    <strong className="tit">키워드</strong>
                    <label className="search-label">
                      <input type="text" onChange={(event) => setKeyWord(event.target.value)} value={keyWord} placeholder="품목명 또는 규격 입력 후 검색하세요." />
                      <button type="button" className="btn-search" onClick={onClickSearch}><span className="a11y">검색</span></button>
                    </label>
                  </label>
                </div>
                <dl className="sn-dl d2">
                  <dt><strong>검색결과 {totalCount}건</strong></dt>
                  <dd>
                    {
                      selectedProductList.map((item, index) => {
                        return (
                          <div className="result-box" key={index}>
                            <div className="dl-item">
                              <dl className="in-label">
                                <dt>단계</dt>
                                <dd><p className="dec">{item.level === '2' ? '아세이' : item.level === '3' ? '부품' : '-'}</p></dd>
                              </dl>
                              <button type="button" className="btn c-blue-line" onClick={() => addPopOpen(item)}>{(item.quantity) === 0 ? "입력" : item.quantity}</button>
                            </div>

                            <div className="dl-item">
                              <dl className="in-label">
                                <dt>모델명</dt>
                                <dd><p className="dec" dangerouslySetInnerHTML={{
                                  __html: item.model_name
                                }}></p></dd>
                              </dl>
                            </div>

                            <div className={`dl-item${item.thmbn_file_url ? "" : " no-item"}`} onClick={() => imgPopOpen(item)}>
                              <dl className="in-label">
                                <dt>품목명</dt>
                                <dd>
                                  <p className={`dec ${item.thmbn_file_url ? 'line' : ''}`} dangerouslySetInnerHTML={{
                                    __html: item.crm_product_name
                                  }}></p>
                                </dd>
                              </dl>
                              {
                                item.thmbn_file_url && <div className="img-box" style={{ backgroundImage: `url(${item.thmbn_file_url})` }}></div>
                              }
                            </div>

                            <div className="dl-item">
                              <dl className="in-label">
                                <dt>규격</dt>
                                <dd><p className="dec">{item.product_standard ? item.product_standard : '-'}</p></dd>
                              </dl>
                            </div>
                          </div>
                        );
                      })
                    }
                    {
                      !list.length ?
                        loading ? <></>
                          :
                          selectedProductList.length &&
                          <div className="result-box">
                            <p className="no-data">제품을 검색하세요.</p>
                          </div>
                        :
                        list.map((item, index) => {
                          return (
                            <div className="result-box" key={index}>
                              <div className="dl-item">
                                <dl className="in-label">
                                  <dt>단계</dt>
                                  <dd><p className="dec">{item.level === '2' ? '아세이' : item.level === '3' ? '부품' : '-'}</p></dd>
                                </dl>
                                <button type="button" className="btn c-blue-line" onClick={() => addPopOpen(item)}>{(item.quantity) === 0 ? "입력" : item.quantity}</button>
                              </div>

                              <div className="dl-item">
                                <dl className="in-label">
                                  <dt>모델명</dt>
                                  <dd><p className="dec" dangerouslySetInnerHTML={{
                                    __html: item.model_name
                                  }}></p></dd>
                                </dl>
                              </div>

                              <div className={`dl-item${item.thmbn_file_url ? "" : " no-item"}`} onClick={() => imgPopOpen(item)}>
                                <dl className="in-label">
                                  <dt>품목명</dt>
                                  <dd>
                                    <p className={`dec ${item.thmbn_file_url ? 'line' : ''}`} dangerouslySetInnerHTML={{
                                      __html: item.crm_product_name
                                    }}></p>
                                  </dd>
                                </dl>
                                {
                                  item.thmbn_file_url
                                    ? <div className="img-box" style={{ backgroundImage: `url(${item.thmbn_file_url})` }}></div>
                                    : null
                                }
                              </div>

                              <div className="dl-item">
                                <dl className="in-label">
                                  <dt>규격</dt>
                                  <dd><p className="dec">{item.product_standard ? item.product_standard : '-'}</p></dd>
                                </dl>
                              </div>
                            </div>
                          );
                        })
                    }

                    {
                      loading &&
                      <Progress
                        loading={loading}
                        fixed={true}
                      />
                    }
                  </dd>
                </dl>
              </dd>

            </dl>

            <ul className="btn-flex t2 fixed">
              <li className="col1"><button type="button" className="btn c-gray" onClick={props.reqOpen}>닫기</button></li>
              <li className="col2"><button type="button" className="btn c-sky" onClick={onClickComplete}>추가</button></li>
            </ul>
          </section>
        </div>
      </div>

      {/* 서비스 진행 > 소요부팜 > 부품 수량 입력 레이어 팝업 */}
      <ReqProductAdd
        product={addProduct}
        isOpen={isAddPopOpen}
        addServiceProduct={onAddServiceProduct}
        addPopOpen={addPopOpen}
      />
      {/* ./서비스 진행 > 소요부팜 > 부품 수량 입력 레이어 팝업 */}

      {/* 서비스 진행 > 소요부팜 > 제품이미지 레이어 팝업 */}
      <ProductImage
        product={imgProduct}
        isOpen={isImgPopOpen}
        imgPopOpen={imgPopOpen}
      />
      {/* ./서비스 진행 > 소요부팜 > 제품이미지 레이어 팝업 */}


    </>
  )
}

export default ReqProductReg;