import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Gnb from './Gnb';
import MainService from '../services/MainService';
import UserService from '../services/UserService';

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [notiCount, setNotiCount] = useState(0);
  const [profile, setProfile] = useState("");

  const menuOpen = () => {
    const $body = document.body;

    if (menu) {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      }
      $body.classList.remove("_noscroll");
      setMenu(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function (response) {
        $body.classList.add("_noscroll");
        setMenu(false);
      }
      $body.classList.add("_noscroll");
      setMenu(true);
      searchProfile();
    }
  }

  // 프로필 정보 조회
  const searchProfile = useCallback(async () => {
    try {
      const result = await UserService.fetchItems();
      const data = result.data;

      setProfile(data.userInfo.profile_img_url);
    } catch (error) {
    } finally {
    }
  }, []);

  // 읽지않은 알림 카운트 조회
  const searchNotificationCount = useCallback(async () => {
    window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
    window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
      if (props.auth.isLogin && props.auth.user && !response) {
        props.auth.logout();
      }
    }

    if (props.auth.user) {
      try {
        const result = await MainService.fetchNewNotis();
        const data = result.data;
        setNotiCount(data.new_notis);
      } catch (error) {
      } finally {
      }
    }
  }, [props]);

  useEffect(() => {
    searchNotificationCount();
  }, [searchNotificationCount])

  return (
    <header id="header" className={menu ? "_openMenu" : null}>
      <div className="header-container">
        <h1 id="logo">
          <Link to="/" className="img-box logo"><span className="a11y">SINK LEADER 프리미엄 음식물처리기</span></Link>
        </h1>
        <Link to="/notification" className={`push ${notiCount ? '_new' : ''}`}><span className="a11y">알림</span></Link>
        <button type="button" className="all-menu" data-action="allMenu" onClick={menuOpen}><span className="bar"><span className="a11y">메뉴 여닫기</span></span></button>
      </div>
      {
        menu &&
        <Gnb menu={menu} menuOpen={menuOpen} profile={profile} />
      }
    </header>
  );
}

export default Header;