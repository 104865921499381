import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from '../../AppContext';
import { useHistory, useLocation } from "react-router";
import ManagerReg1 from "../../layout/service/ManagerReg1";
import ServiceCounselingReg from "../../layout/service/ServiceCounselingReg";
import Gallery from "../../layout/service/Gallery";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import ServiceService from '../../services/ServiceService';
import Util from "../../helpers/Util";
import Progress from "../../layout/Progress";

const ServiceResult = () => {
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const history = useHistory();
  const location = useLocation();
  const serviceSeq = location.state.service_seq;
  const [serviceInfo, setServiceInfo] = useState(null);
  const [reg1, setReg1] = useState(false);
  const [serviceCounseling, setServiceCounseling] = useState(false);
  const [gallery, setGallery] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [cancelAlertDec, setCancelAlertDec] = useState("");
  const [unnormalAlert, setUnnnormalAlert] = useState(false);

  // 서비스 상세정보 조회
  const searchServiceInfo = useCallback(async () => {
    setLoading(true);
    try {
      const result = await ServiceService.fetchItem(serviceSeq);
      setServiceInfo(result.data);

    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [serviceSeq]);

  // 서비스 사진 갤러리 레이어 팝업
  const galleryOpen = () => {
    if (gallery) {
      setGallery(false);
    } else {
      setGallery(true);
    }
  }

  // 담당자 개별 등록 레이어 팝업
  const regOpen1 = () => {
    const $body = document.body;

    if (reg1) {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      }
      $body.classList.remove("_noscroll");
      setReg1(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        $body.classList.remove("_noscroll");
        setReg1(false);
      }
      $body.classList.add("_noscroll");
      setReg1(true);
    }
  }

  // 담당자 개별 등록 저장
  const onRegSave1 = () => {
    searchServiceInfo(); // 서비스 상세정보 조회
  }

  // 상담 결과 등록 레이어 팝업
  const serviceCounselingOpen = (item) => {
    const $body = document.body;

    if (serviceCounseling) {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function (response) {
      }
      $body.classList.remove("_noscroll");
      setServiceCounseling(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function (response) {
        $body.classList.remove("_noscroll");
        setServiceCounseling(false);
      }
      $body.classList.add("_noscroll");
      setServiceCounseling(true);
    }
  }

  // 서비스 상담 결과 등록
  const onServiceCounselingSave = () => {
    searchServiceInfo(); // 서비스 상세정보 조회
  }

  // 승인완료
  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  // 수정 버튼 핸들러
  const onMod = () => {
    history.replace('/service/start', {
      service_seq: serviceSeq,
      mode: 'MOD'
    });
  }

  // 승인
  const saveConfirm = async () => {
    setConfirm(false);
    setLoading(true);
    let param = {
      is_approval: 1
    }
    try {
      const result = await ServiceService.approvalServiceInfo(serviceSeq, param);
      if (result.result < 0) {
        setCancelAlertDec(result.message);
        cancelAlertOpen();
        return;
      }
      regServiceInfo();
      alertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 서비스 등록
  const regServiceInfo = async () => {
    if (serviceInfo.service_type === '05' && serviceInfo.relocate_install_address) {
      setLoading(true);
      let param = {
        address: serviceInfo.relocate_install_address,
        areaCode: serviceInfo.relocate_install_areacode,
        clientSeq: serviceInfo.client_seq,
        contractSeq: serviceInfo.contract_seq,
        counselingSeq: serviceInfo.counseling_seq,
        productSeq: serviceInfo.product_seq,
        salesSeq: serviceInfo.sales_seq,
        serviceType: '04' // 이전설치
      };
      try {
        await ServiceService.registService(param);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  // 승인완료 알럿
  const alertOpen = () => {
    if (alert) {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      }
      setAlert(false);
      searchServiceInfo();
    } else {
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setAlert(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        }
      }
      setAlert(true);
    }
  }

  // 전화
  const onCallClient = (contact) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
      tel: contact
    }, "");
  }

  // 네이버 지도 열기
  const onNaverMap = (address) => {
    if (address) {
      window.nativeApi.execute(window.NativeApiMethod.OPEN_NAVER_MAP, {
        address: encodeURI(address)
      }, "");
    }
  }

  const cancelAlertOpen = () => {
    if (cancelAlert) {
      setCancelAlert(false);
      history.goBack();
    } else {
      setCancelAlert(true);
    }
  }

  // 비정상 접근 알럿 열기/닫기
  const unnormalAlertOpen = useCallback(() => {
    if (unnormalAlert) {
      setUnnnormalAlert(false);
      history.goBack();
    } else {
      setUnnnormalAlert(true);
    }
  }, [unnormalAlert, history])

  useEffect(() => {
    if (serviceInfo && user) {
      if (user.app_user_grade === 'S' || user.app_user_grade === 'E') {
        if (Number(serviceInfo.center_seq) !== Number(user.center_seq)) {
          setUnnnormalAlert(true);
        }
      }
    }
  }, [serviceInfo, user])

  useEffect(() => {
    searchServiceInfo();  // 서비스 상세정보 조회
  }, [searchServiceInfo]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      searchServiceInfo();
    }
  }, [searchServiceInfo])

  return (
    <>
      <div className="dl-container">
        <div className="dl-wrap">
          <strong className="tit">기본정보</strong>
          <dl className="dl-item">
            <dt>접수번호</dt>
            <dd>{serviceInfo && serviceInfo.reception_number ? serviceInfo.reception_number : '-'}</dd>
          </dl>
          <dl className="dl-item">
            <dt>서비스번호</dt>
            <dd>{serviceInfo && serviceInfo.service_number ? serviceInfo.service_number : '-'}</dd>
          </dl>
          <dl className="dl-item">
            <dt>고객명</dt>
            <dd>{serviceInfo && serviceInfo.name ? serviceInfo.name : '-'}</dd>
          </dl>
          <div className="dl-item address-wrap">
            <dl>
              <dt>연락처</dt>
              <dd>{serviceInfo && serviceInfo.contacts ? Util.formatPhoneNumber(serviceInfo.contacts.split(',')[0]) : '-'}</dd>
            </dl>
            <button type="button" className="btn btn-tel" onClick={() => onCallClient(serviceInfo.contacts ? serviceInfo.contacts.split(',')[0] : '')}><span className="a11y">통화</span></button>
          </div>
          <div className="dl-item address-wrap">
            <dl>
              <dt>주소</dt>
              <dd>
                <p className="address" dangerouslySetInnerHTML={{
                  __html: serviceInfo && serviceInfo.address ? serviceInfo.address : '-'
                }}></p>
              </dd>
            </dl>
            <button type="button" className="btn btn-map" onClick={() => onNaverMap(serviceInfo.address)}><span className="a11y">지도</span></button>
          </div>
          <dl className="dl-item">
            <dt>모델</dt>
            <dd dangerouslySetInnerHTML={{
              __html: serviceInfo && serviceInfo.product_seq ? serviceInfo.crm_product_name + (serviceInfo.product_standard ? ' / ' + serviceInfo.product_standard : '') + ' / ' + Util.unescapeHtml(serviceInfo.app_exposure_sales) : '-'
            }}></dd>
          </dl>
          <dl className="dl-item">
            <dt>보증만료일</dt>
            <dd><p className="t-blue">{serviceInfo && serviceInfo.warranty_date ? Util.formatDate(serviceInfo.warranty_date) : '-'}</p></dd>
          </dl>
          <dl className="dl-item">
            <dt>최초설치일</dt>
            <dd><p className="t-blue">{serviceInfo && serviceInfo.first_install_datetime ? Util.formatDate(serviceInfo.first_install_datetime) : '-'}</p></dd>
          </dl>
          <div className="dl-item address-wrap">
            <dl>
              <dt>접수 유형</dt>
              <dd>
                <strong className={serviceInfo && serviceInfo.is_emergency ? 't-red' : ''}>
                  {serviceInfo && serviceInfo.is_emergency ? '[긴급]' : ''}
                  {serviceInfo && serviceInfo.counseling_type_name + ' / ' + Util.unescapeHtml(serviceInfo.counseling_status_name)}
                </strong>
              </dd>
            </dl>
            {
              serviceInfo && serviceInfo.is_emergency ?
                <span className="emergency btn"><span className="a11y">긴급</span></span> : <></>
            }
          </div>
          <dl className="dl-item">
            <dt>접수일</dt>
            <dd>{serviceInfo && serviceInfo.counseling_datetime ? Util.formatDate(serviceInfo.counseling_datetime) : '-'}</dd>
          </dl>
          <dl className="dl-item">
            <dt>접수내용</dt>
            <dd dangerouslySetInnerHTML={{
              __html: serviceInfo && serviceInfo.counseling_content ? serviceInfo.counseling_content.replace(/\n/g, '<br/>') : '-'
            }}></dd>
          </dl>
          <dl className="dl-item">
            <dt>특이사항</dt>
            <dd dangerouslySetInnerHTML={{
              __html: serviceInfo && serviceInfo.special_note ? serviceInfo.special_note.replace(/\n/g, '<br/>') : '-'
            }}></dd>
          </dl>
          <dl className="dl-item">
            <dt>고장증상</dt>
            <dd>{serviceInfo && serviceInfo.counseling_broken_symptom_name ? serviceInfo.counseling_broken_symptom_name : '-'}</dd>
          </dl>
          <div className="dl-item address-wrap">
            <dl>
              <dt>담당자</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.manager_seq ? serviceInfo.manager_name : '-'
              }}></dd>
              {
                user.app_user_grade !== 'E' ?
                  serviceInfo &&
                    (serviceInfo.service_status === 'P' || serviceInfo.service_status === 'I' || serviceInfo.service_status === 'C') && serviceInfo.is_approval === false ?
                    <button type="button" className="btn c-blue-line" onClick={() => regOpen1(serviceInfo)}>
                      {serviceInfo && serviceInfo.manager_seq ? "변경" : "미배정"}
                    </button>
                    : null
                  :
                  null
              }
            </dl>
          </div>
        </div>

        <div className="dl-wrap">
          <strong className="tit">서비스 상담 정보</strong>
          <div className="dl-item address-wrap">
            <dl>
              <dt>예정일시</dt>
              <dd>{serviceInfo && serviceInfo.visit_datetime ? Util.formatDateTimeHHMM(serviceInfo.visit_datetime) : '-'}</dd>
            </dl>
            {
              serviceInfo &&
              (serviceInfo.service_status === 'P' || serviceInfo.service_status === 'I' || serviceInfo.service_status === 'C') && serviceInfo.is_approval === false &&
              serviceInfo.manager_seq && (
                <button type="button" className="btn c-blue-line" onClick={serviceCounselingOpen} >
                  {serviceInfo && serviceInfo.service_counseling_seq ? "변경" : "미입력"}
                </button>
              )
            }
          </div>
          <dl className="dl-item">
            <dt>상담결과</dt>
            <dd>{serviceInfo && serviceInfo.service_counseling_type_name ? Util.unescapeHtml(serviceInfo.service_counseling_type_name) : '-'}</dd>
          </dl>
          <dl className="dl-item">
            <dt>상담내용</dt>
            <dd dangerouslySetInnerHTML={{
              __html: serviceInfo && serviceInfo.service_counseling_content ? serviceInfo.service_counseling_content.replace(/(?:\r\n|\r|\n)/g, '<br />') : '-'
            }}>
            </dd>
          </dl>
          <div className="dl-item flex">
            <dl className="col1">
              <dt>등록 / 수정일시</dt>
              <dd>{serviceInfo && serviceInfo.service_counseling_datetime ? Util.formatDateTimeHHMM(serviceInfo.service_counseling_datetime) : '-'}</dd>
            </dl>
            <dl className="col2">
              <dt>등록 / 수정자</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.service_counseling_reg_user_name ? serviceInfo.service_counseling_reg_user_name : '-'
              }}></dd>
            </dl>
          </div>
        </div>

        <div className="dl-wrap">
          <strong className="tit">서비스 진행 정보</strong>
          <dl className="dl-item">
            <dt>유형 / 상태</dt>
            <dd>
              <strong>
                {serviceInfo && serviceInfo.service_type_name + ' / ' + serviceInfo.service_status_name}
              </strong>
            </dd>
          </dl>
          <dl className="dl-item">
            <dt>고장증상</dt>
            <dd>{serviceInfo && serviceInfo.broken_symptom_name ? serviceInfo.broken_symptom_name : '-'}</dd>
          </dl>
          <dl className="dl-item">
            <dt>소요부품</dt>
            <dd>
              {
                serviceInfo && (serviceInfo.service_type === '01' || serviceInfo.service_type === '02' || serviceInfo.service_type === '04') && serviceInfo.service_product_list.length ?
                  <div className="sn-wrap">
                    <ul className="prd-list">
                      {
                        serviceInfo.service_product_list.map((item, index) => {
                          return (
                            <li key={index}>
                              <span className="col1" style={{ maxWidth: "50%" }} dangerouslySetInnerHTML={{
                                __html: item.crm_product_name
                              }}></span>
                              <span className="col2 t-blue">{item.quantity}개</span>
                              <span className="col1">{Util.formatNumber(item.amount)} 원</span>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                  :
                  '-'
              }
            </dd>
          </dl>

          <dl className="dl-item">
            <dt>내용</dt>
            <dd dangerouslySetInnerHTML={{
              __html: serviceInfo && serviceInfo.service_content ? serviceInfo.service_content.replace(/(?:\r\n|\r|\n)/g, '<br />') : '-'
            }}></dd>
          </dl>

          <dl className="dl-item">
            <dt>서비스 사진</dt>
            <dd>
              <ul className="pic-list">
                {
                  serviceInfo && serviceInfo.attached_file_list.length ?
                    serviceInfo.attached_file_list.map((item, index) => {
                      return (
                        index < 2
                          ? <li key={index}>
                            <div className="img-wrap">
                              <div className="img-box" style={{ backgroundImage: `url(${item.thmbn_file_url})` }} onClick={galleryOpen}></div>
                            </div>
                          </li>
                          : null
                      )
                    })
                    :
                    '-'
                }
                {
                  serviceInfo && serviceInfo.attached_file_list.length >= 3 && (
                    <li>
                      <button type="button" className="img-wrap" onClick={galleryOpen}><p className="more-txt">{serviceInfo && serviceInfo.attached_file_list.length - 2}+</p></button>
                    </li>
                  )
                }
              </ul>
            </dd>
          </dl>

          <div className="dl-item address-wrap t2">
            <dl>
              <dt>1차기 제품명</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.first_crm_product_name ? serviceInfo.first_crm_product_name : '-'
              }}></dd>
            </dl>
          </div>

          <div className="dl-item address-wrap t2">
            <dl>
              <dt>1차기 제품 S/N</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.first_serial_number ? serviceInfo.first_serial_number : '-'
              }}></dd>
            </dl>
          </div>

          <div className="dl-item address-wrap t2">
            <dl>
              <dt>2차기 제품명</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.second_crm_product_name ? serviceInfo.second_crm_product_name : '-'
              }}></dd>
            </dl>
          </div>

          <div className="dl-item address-wrap t2">
            <dl>
              <dt>2차기 제품 S/N</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.second_serial_number ? serviceInfo.second_serial_number : '-'
              }}></dd>
            </dl>
          </div>
          {
            serviceInfo && serviceInfo.is_change ?
              <>
                <div className="dl-item address-wrap t2">
                  <dl>
                    <dt>교환 1차기 제품명</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.change_first_crm_product_name ? serviceInfo.change_first_crm_product_name : '-'
                    }}></dd>
                  </dl>
                </div>

                <div className="dl-item address-wrap t2">
                  <dl>
                    <dt>교환 1차기 제품 S/N</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.change_first_serial_number ? serviceInfo.change_first_serial_number : '-'
                    }}></dd>
                  </dl>
                </div>

                <div className="dl-item address-wrap t2">
                  <dl>
                    <dt>교환 2차기 제품명</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.change_second_crm_product_name ? serviceInfo.change_second_crm_product_name : '-'
                    }}></dd>
                  </dl>
                </div>

                <div className="dl-item address-wrap t2">
                  <dl>
                    <dt>교환 2차기 제품 S/N</dt>
                    <dd dangerouslySetInnerHTML={{
                      __html: serviceInfo && serviceInfo.change_second_serial_number ? serviceInfo.change_second_serial_number : '-'
                    }}></dd>
                  </dl>
                </div>
              </>
              :
              null
          }

          {
            serviceInfo && serviceInfo.collect_first_serial_number ?
              <dl className="dl-item">
                <dt>{serviceInfo.collect_first_type_name} 요청 1차기 제품S/N</dt>
                <dd>{Util.unescapeHtml(serviceInfo.collect_first_serial_number)}</dd>
              </dl>
              :
              null
          }

          {
            serviceInfo && serviceInfo.collect_second_serial_number ?
              <dl className="dl-item">
                <dt>{serviceInfo.collect_second_type_name} 요청 2차기 제품S/N</dt>
                <dd>{Util.unescapeHtml(serviceInfo.collect_second_serial_number)}</dd>
              </dl>
              :
              null
          }

          <dl className="dl-item">
            <dt>유/무상</dt>
            <dd>{serviceInfo && serviceInfo.is_free ? '무상' : '유상'}</dd>
          </dl>

          <div className="dl-item flex">
            <dl className="col">
              <dt>자재비</dt>
              <dd>{serviceInfo && serviceInfo.material_cost ? Util.formatNumber(serviceInfo.material_cost) + ' 원' : '-'}</dd>
            </dl>
            <dl className="col">
              <dt>출장비</dt>
              <dd>{serviceInfo && serviceInfo.travel_cost ? Util.formatNumber(serviceInfo.travel_cost) + ' 원' : '-'}</dd>
            </dl>
          </div>

          <div className="dl-item flex">
            <dl className="col">
              <dt>공임비</dt>
              <dd>{serviceInfo && serviceInfo.labor_cost ? Util.formatNumber(serviceInfo.labor_cost) + ' 원' : '-'}</dd>
            </dl>
            <dl className="col">
              <dt>운송비</dt>
              <dd>{serviceInfo && serviceInfo.shipping_cost ? Util.formatNumber(serviceInfo.shipping_cost) + ' 원' : '-'}</dd>
            </dl>
          </div>

          {
            serviceInfo && serviceInfo.service_status === 'C' ?
              <dl className="dl-item">
                <dt>고객서명</dt>
                <dd>
                  <div className="sign-wrap"><div className="img-box" style={{ backgroundImage: `url(${serviceInfo && serviceInfo.signature.replace(/ /gi, '+')})` }}></div></div>
                </dd>
              </dl>

              :
              null
          }

          <div className="dl-item flex">
            <dl className="col1">
              <dt>등록 / 수정일시</dt>
              <dd>{serviceInfo && serviceInfo.mod_datetime ? Util.formatDateTimeHHMM(serviceInfo.mod_datetime) : '-'}</dd>
            </dl>
            <dl className="col2">
              <dt>등록 / 수정자</dt>
              <dd dangerouslySetInnerHTML={{
                __html: serviceInfo && serviceInfo.mod_user_name ? serviceInfo.mod_user_name : '-'
              }}></dd>
            </dl>
          </div>
          {
            user.app_user_grade === 'S'
              && serviceInfo && !serviceInfo.is_approval && !serviceInfo.is_cancel
              && (serviceInfo.service_counseling_type === '04' || serviceInfo.service_counseling_type === '05') ?
              <ul className="btn-flex" style={{ marginTop: "25px" }}>
                <li style={{ width: "100%" }}><button type="button" className="btn c-sky" onClick={confirmOpen}>승인완료</button></li>
              </ul>
              :
              null
          }
          {
            user.app_user_grade === 'S'
              && serviceInfo && !serviceInfo.is_approval && !serviceInfo.is_cancel
              && (serviceInfo.service_counseling_type === '01' || serviceInfo.service_counseling_type === '02' || serviceInfo.service_counseling_type === '03') ?
              <ul className="btn-flex" style={{ marginTop: "25px" }}>
                <li><button type="button" className="btn c-blue" onClick={onMod}>수정하기</button></li>
                <li><button type="button" className="btn c-sky" onClick={confirmOpen}>승인완료</button></li>
              </ul>
              :
              null
          }
        </div>
      </div>

      {/* 담당자 개별 등록 */}
      {
        reg1 &&
        <ManagerReg1
          reg={reg1}
          regOpen={regOpen1}
          onRegSave={onRegSave1}
          serviceInfo={serviceInfo}
        />
      }
      {/* ./담당자 개별 등록 */}

      {/* 상담 결과 등록 */}
      {
        serviceCounseling &&
        <ServiceCounselingReg
          serviceCounseling={serviceCounseling}
          serviceCounselingOpen={serviceCounselingOpen}
          onServiceCounselingSave={onServiceCounselingSave}
          serviceInfo={serviceInfo}
        />
      }
      {/* ./상담 결과 등록 */}

      {/* 서비스 사진 갤러리 레이어 팝업 */}
      {
        gallery &&
        <Gallery
          photoData={serviceInfo ? serviceInfo.attached_file_list : []}
          gallery={gallery}
          galleryOpen={galleryOpen}
        />
      }
      {/* ./서비스 사진 갤러리 레이어 팝업 */}

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec="승인하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={saveConfirm}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      <Alert
        dec="승인되었습니다."
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      {/* 알럿창 */}
      <Alert
        dec={cancelAlertDec}
        alert={cancelAlert}
        alertOpen={cancelAlertOpen}
      />
      {/* ./알럿창 */}

      {/* 비정상접속 알럿창 */}
      <Alert
        dec="비정상적인 접속입니다."
        alert={unnormalAlert}
        alertOpen={unnormalAlertOpen}
      />
      {/* ./비정상접속 알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  );
}

export default ServiceResult;
