import Service from './Index';

const BoardService = {
  fetchItems: (board_type, param) => {
    return Service.get('board/' + board_type, param);
  },
  fetchReadNotice: (param) => {
    return Service.put('board/readNotice', param);
  },
};

export default BoardService;
