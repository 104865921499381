import React, { useState, useEffect, useCallback } from 'react';
import UserService from '../../services/UserService';
import MdmService from '../../services/MdmService';
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Progress from "../../layout/Progress";

const Join = () => {
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [confirm, setConfirm] = useState(false);
  const [completeAlert, setCompleteAlert] = useState(false);
  const [validAlertDec, setValidAlertDec] = useState("");
  const [validAlert, setValidAlert] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [centerList, setCenterList] = useState([]);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [name, setName] = useState('');
  const [center, setCenter] = useState('');
  const [grade, setGrade] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');

  // 소속 리스트 조회
  const searchCenterList = useCallback(async () => {
    setLoading(true);
    const param = {};
    try {
      const result = await MdmService.fetchCenters(param);
      const data = result.data;
      const list = data.list;
      list.forEach(item => {
        item.quantity = 0;
      });
      setCenterList(list);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  // 휴대폰번호 변경 핸들러
  const onChangeCellphone = (value) => {
    if (!isNaN(value)) {
      setCellphone(value);
    } else {
      setCellphone(value.replace(/[^0-9]/g, ''));
    }
  }

  /* 저장 버튼 핸들러 */
  const onClickSave = () => {
    const regExp = {
      userId: /^(?=.*[a-zA-Z])([a-zA-Z0-9_]+){5,20}$/,
      pw: /^([0-9]|[a-z]|[A-Z]|[!@#$%^&+=]){6,20}$/,
      email: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    };
    const validMsg = {
      userId: '아이디를 입력하세요.',
      pw: '비밀번호를 입력하세요.',
      pw2: '비밀번호 확인을 입력하세요.',
      name: '이름을 입력하세요.',
      center: '소속을 선택하세요.',
      grade: '권한을 선택하세요.',
      cellphone: '휴대폰번호를 입력하세요.',
      email: '이메일을 입력하세요.',
    }

    // 아이디
    if (!userId) {
      setValidAlertDec(validMsg.userId);
      validAlertOpen();
      return;
    } else {
      if (!regExp.userId.test(userId)) {
        setValidAlertDec('올바른 ' + validMsg.userId);
        validAlertOpen();
        return;
      }
    }

    // 비밀번호
    if (!password) {
      setValidAlertDec(validMsg.pw);
      validAlertOpen();
      return;
    } else {
      if (!regExp.pw.test(password)) {
        setValidAlertDec('올바른 ' + validMsg.pw);
        validAlertOpen();
        return;
      }
    }

    // 비밀번호 확인
    if (!password2) {
      setValidAlertDec(validMsg.pw2);
      validAlertOpen();
      return;
    } else {
      if (!regExp.pw.test(password2)) {
        setValidAlertDec('올바른 ' + validMsg.pw2);
        validAlertOpen();
        return;
      }
    }

    // 비밀번호 일치여부
    if (password !== password2) {
      setValidAlertDec('비밀번호가 일치하지 않습니다.');
      validAlertOpen();
      return;
    }

    // 이름
    if (!name) {
      setValidAlertDec(validMsg.name);
      validAlertOpen();
      return;
    }

    // 소속
    if (!center) {
      setValidAlertDec(validMsg.center);
      validAlertOpen();
      return;
    }

    // 권한
    if (!grade) {
      setValidAlertDec(validMsg.grade);
      validAlertOpen();
      return;
    }

    // 휴대폰번호
    if (!cellphone) {
      setValidAlertDec(validMsg.cellphone);
      validAlertOpen();
      return;
    }

    // 이메일
    if (email) {
      if (!regExp.email.test(email)) {
        setValidAlertDec('올바른 ' + validMsg.email);
        validAlertOpen();
        return;
      }
    }

    confirmOpen();  // 컨펌창 열기
  }

  // 컨펌창 열기
  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  // 회원가입 처리
  const joinUser = async () => {
    setLoading(true);
    setConfirm(false);

    let param = {
      user_id: userId,
      password: password,
      name: name,
      center_seq: center,
      grade: grade,
      cell_phone: cellphone,
      email: email,
    };

    try {
      const result = await UserService.joinUserInfo(param);
      if (result.result === 0) {
        completeAlertOpen();
      } else {
        setValidAlertDec(result.message);
        validAlertOpen();
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 뒤로가기 버튼 핸들러
  const goBack = () => {
    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
  }

  // 취소
  const cancelReg = () => {
    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
  }

  // 저장 완료 알럿
  const completeAlertOpen = () => {
    const $body = document.body;
    if (completeAlert) {
      $body.classList.remove("_noscroll");
      setCompleteAlert(false);
    } else {
      $body.classList.add("_noscroll");
      setCompleteAlert(true);
    }
  }

  // 입력값 검증 오류 알럿
  const validAlertOpen = () => {
    const $body = document.body;
    if (validAlert) {
      $body.classList.remove("_noscroll");
      setValidAlert(false);
    } else {
      $body.classList.add("_noscroll");
      setValidAlert(true);
    }
  }

  // 취소 알럿
  const cancelConfirmOpen = () => {
    const $body = document.body;
    if (cancelConfirm) {
      $body.classList.remove("_noscroll");
      setCancelConfirm(false);
    } else {
      $body.classList.add("_noscroll");
      setCancelConfirm(true);
    }
  }

  useEffect(() => {
    searchCenterList();
  }, [searchCenterList])

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
  }, [])

  return (
    <>
      <header id="header" style={{ height: '63px', lineHeight: '63px' }}>
        <div className="m-main">
          <h1 className="a11y">SINK LEADER 프리미엄 음식물처리기</h1>
          <button type="button" className="btn-back" style={{ width: '28px', height: '22px', top: '53%' }} onClick={goBack}><span className="a11y">뒤로가기</span></button>
          <h2 className="page-tit" style={{ fontSize: '25px', fontWeight: 400, marginLeft: '47px' }}>회원가입</h2>
        </div>
      </header>

      <div className="order-wrap" style={{ marginTop: '13px' }}>
        <div className="form-wrap">
          <dl className="form-dl">
            <dt><span className="req">아이디</span></dt>
            <dd>
              <input type="text" value={userId} onChange={(event) => setUserId(event.target.value)} placeholder="영문, 숫자 조합으로 5~20자를 입력하세요." maxLength="20" />
            </dd>
          </dl>
          <dl className="form-dl">
            <dt><span className="req">비밀번호</span></dt>
            <dd>
              <input type="text" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="6~20자를 입력하세요." maxLength="20" />
            </dd>
          </dl>
          <dl className="form-dl">
            <dt><span className="req">비밀번호 확인</span></dt>
            <dd>
              <input type="text" value={password2} onChange={(event) => setPassword2(event.target.value)} placeholder="6~20자를 입력하세요." maxLength="20" />
            </dd>
          </dl>
          <dl className="form-dl">
            <dt><span className="req">이름</span></dt>
            <dd>
              <input type="text" value={name} onChange={(event) => setName(event.target.value)} maxLength="10" />
            </dd>
          </dl>
          <dl className="form-dl">
            <dt><span className="req">소속</span></dt>
            <dd>
              <div>
                <select onChange={(event) => setCenter(event.target.value)} value={center}>
                  <option value="">선택</option>
                  {
                    centerList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.center_seq}
                          dangerouslySetInnerHTML={{
                            __html: item.center_name
                          }}></option>
                      );
                    })
                  }
                </select>
              </div>
            </dd>
          </dl>
          <dl className="form-dl">
            <dt><span className="req">권한</span></dt>
            <dd>
              <div>
                <select onChange={(event) => setGrade(event.target.value)} value={grade}>
                  <option value="">선택</option>
                  <option value="S">센터/설치점 (관리자)</option>
                  <option value="E">센터/설치점 (기사)</option>
                </select>
              </div>
            </dd>
          </dl>
          <dl className="form-dl">
            <dt><span className="req">휴대폰번호</span></dt>
            <dd>
              <input type="text" onChange={(event) => onChangeCellphone(event.target.value)} value={cellphone} placeholder="'-'을 제외하고 숫자만 입력하세요." maxLength="11" />
            </dd>
          </dl>
          <dl className="form-dl">
            <dt>이메일</dt>
            <dd>
              <input type="text" onChange={(event) => setEmail(event.target.value)} value={email} />
            </dd>
          </dl>

          <ul className="btn-flex">
            <li className="col1"><button type="button" className="btn c-gray" onClick={cancelConfirmOpen}>취소</button></li>
            <li className="col2"><button type="button" className="btn c-sky" onClick={onClickSave}>저장</button></li>
          </ul>
        </div>
      </div>

      {/* 컨펌창 */
        confirm &&
        <Confirm
          dec="회원가입 하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={joinUser}
        />
      }

      {/* 저장완료 알럿창 */
        alert &&
        <Alert
          dec={<>회원가입이 완료되었습니다.<br />관리자의 승인 후,<br />이용 가능합니다.</>}
          alert={completeAlert}
          alertOpen={cancelReg}
        />
      }

      {/* 검증 알럿창 */
        validAlert &&
        <Alert
          dec={validAlertDec}
          alert={validAlert}
          alertOpen={validAlertOpen}
        />
      }

      {/* 취소 알럿창 */
        cancelConfirm &&
        <Confirm
          dec="취소하시겠습니까?"
          confirm={cancelConfirm}
          confirmOpen={cancelConfirmOpen}
          alertOpen={cancelReg}
        />
      }

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true}
        />
      }
    </>
  )
}

export default Join;