import Service from './Index';

const MdmService = {
  // 센터 리스트 조회
  fetchCenters: (param) => {
    return Service.get('mdm/center', param);
  },

};

export default MdmService;
