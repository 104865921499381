import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import ServiceList from "../pages/service/ServiceList";
import ServiceListInfo from "../pages/service/ServiceListInfo";
import ServiceStart from "../pages/service/ServiceStart";
import ServiceResult from "../pages/service/ServiceResult";
import ServiceReg from "../pages/service/ServiceReg";

function ServiceRouter() {
  return (
    <Switch>
      <PrivateRoute path="/service/reg" component={ServiceReg} />
      <PrivateRoute path="/service/result" component={ServiceResult} />
      <PrivateRoute path="/service/start" component={ServiceStart} />
      <PrivateRoute path="/service/list/info" component={ServiceListInfo} />
      <PrivateRoute path="/service/list" component={ServiceList} />
      <Redirect path="/service/*" to="/service/list" />
    </Switch>
  );
}

export default ServiceRouter;
