import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import ServiceService from '../../services/ServiceService';
import StockService from '../../services/StockService';
import FilterItem from "../../layout/FilterItem";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const StockList = () => {
  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", ""]);
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 검색버튼 클릭 핸들러
  const onClickSearch = () => {
    setCurrentPage(1);
  }

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize
    };
    Object.assign(param, location.state);
    try {
      const result = await StockService.fetchItems(param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: location.state ? location.state.product_group : '',
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [location]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    if (location.state && location.state.model_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        upper_child_product_code: location.state ? location.state.model_code : '',
        level: 1
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setProductList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    if (location.state && location.state.product_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.product_code : '',
        level: 2
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setAssyList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    if (location.state && location.state.assy_code) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.product_group : '',
        parent_product_code: location.state ? location.state.model_code : '',
        upper_child_product_code: location.state ? location.state.assy_code : '',
        level: 3
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setPartList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);

    let locationState = location.state || {};

    if (idx === 0) { // 품목군
      locationState.product_group = '';
    }
    if (idx === 1) { // 단계
      locationState.level = '';
    }
    if (idx === 2) { // 모델
      locationState.model_code = '';
    }
    if (idx === 3) { // 제품
      locationState.product_code = '';
    }
    if (idx === 4) { // 아세이
      locationState.assy_code = '';
    }
    if (idx === 5) { // 부품
      locationState.part_code = '';
    }
    if (idx === 6) { // 키워드
      locationState.search_word = '';
    }

    if (idx === 4) {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 3) {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else if (idx === 2) {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: locationState.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: locationState.product_group || '',
          level: locationState.level || '',
          model_code: locationState.model_code || '',
          product_code: locationState.product_code || '',
          assy_code: locationState.assy_code || '',
          part_code: locationState.part_code || '',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    history.replace({
      pathname: '/stock/list',
      state: {
        product_group: '', //품목군
        level: '', //단계(레벨)
        model_code: '', //모델명
        product_code: '', //제품명
        assy_code: '', //아세이명
        part_code: '', //부품명
        search_word: '' //키워드
      }
    });
  }, [history]);

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.search_word = event.target.value;

    history.replace({
      pathname: '/stock/list',
      state: {
        product_group: location.state.product_group || '', //품목군
        level: location.state.level || '', //단계(레벨)
        model_code: location.state.model_code || '', //모델명
        product_code: location.state.product_code || '', //제품명
        assy_code: location.state.assy_code || '', //아세이명
        part_code: location.state.part_code || '', //부품명
        search_word: location.state.search_word || '' //키워드
      }
    });
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};
    const name = event.target.name;
    locationState[name] = event.target.value;

    if (name === 'product_group') {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else if (name === 'assy_code') {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: location.state.product_code || '',
          assy_code: location.state.assy_code || '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else if (name === 'product_code') {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: location.state.product_code || '',
          assy_code: '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else if (name === 'model_code') {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: '',
          assy_code: '',
          part_code: '',
          search_word: location.state.search_word || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: location.state.product_group || '',
          level: location.state.level || '',
          model_code: location.state.model_code || '',
          product_code: location.state.product_code || '',
          assy_code: location.state.assy_code || '',
          part_code: location.state.part_code || '',
          search_word: location.state.search_word || ''
        }
      });
    }
  }

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회
    searchAssyList(); // 아세이 리스트 조회
    searchPartList(); // 부품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList, searchAssyList, searchPartList]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(currentPage + 1);
        }
      }
    }
  }, [loading, currentPage, totalCount]);

  // 재고 상세
  const stockInfoOpen = (info) => {
    if (!info) {
      return
    }
    history.push({
      pathname: '/stock/info',
      state: {
        child_product_code: info.child_product_code,
        product_group: info.product_group,
        crm_product_name: info.crm_product_name,
        product_standard: info.product_standard,
        quantity: info.quantity,
        thmbn_file_url: info.thmbn_file_url,
        level: info.level
      }
    });
  }

  // 스크롤 최상단으로 이동
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      const scrollT = ('scroll', e.srcElement.scrollingElement.scrollTop);
      setScroll({ scrollT });

      onMore(); // 더보기
    }
  }, [setScroll, onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/stock/list',
        state: {
          product_group: '', //품목군
          level: '', //단계(레벨)
          model_code: '', //모델코드명
          product_code: '', //제품코드명
          assy_code: '', //아세이코드명
          part_code: '', //부품코드명
          search_word: '' //키워드
        }
      });
    }

    if (state) {
      const productGroup = state.product_group;
      const level = state.level;
      const modelCode = state.model_code;
      const productCode = state.product_code;
      const assyCode = state.assy_code;
      const partCode = state.part_code;
      const searchWord = state.search_word;

      // 품목군
      if (productGroup) {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = location.state.product_group;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = '';
          return state;
        });
      }

      // 단계
      if (level) {
        let levelName = '';
        switch (level) {
          case '1':
            levelName = '제품';
            break;
          case '2':
            levelName = '아세이';
            break;
          case '3':
            levelName = '부품';
            break;
          default:
            levelName = '';
            break;
        }

        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = levelName;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = '';
          return state;
        });
      }

      // 모델
      if (modelCode) {
        const $model_code = document.querySelector("[name=model_code]");

        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $model_code.options[$model_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 제품
      if (productCode) {
        const $product_code = document.querySelector("[name=product_code]");

        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $product_code.options[$product_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 아세이
      if (assyCode) {
        const $assy_code = document.querySelector("[name=assy_code]");

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $assy_code.options[$assy_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 부품
      if (partCode) {
        const $part_code = document.querySelector("[name=part_code]");

        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $part_code.options[$part_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 키워드
      if (searchWord) {
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[6] = searchWord;
          return state;
        });
      } else {
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[6] = '';
          return state;
        });
      }
    }

  }, [location, history, modelList, productList, assyList, partList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchList();
  }, [currentPage, searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
  }, []);

  return (
    <>
      <div className="page-stock">
        <div className="top-filter">
          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>
          <button type="button" className="btn c-black-line" onClick={() => Util.modal(filter, setFilter)}><span className="txt ft">필터</span></button>

          <div className={`filter-container ${filter ? '_openFilter' : null}`}>
            <header className="filter-header flex">
              <strong className="tit">필터</strong>
              <nav>
                <button type="button" className="btn c-black-line" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
                <button type="button" className="btn-close" onClick={() => Util.modal(filter, setFilter)}><span className="a11y">닫기</span></button>
              </nav>
            </header>


            <div className="select-container" data-selector="selectContainer">
              {
                searchTag.join("") === ""
                  ? <p className="dec">선택 필터가 없습니다.</p>
                  : (
                    searchTag.map((item, index) => {
                      return (
                        item
                          ?
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                          :
                          null
                      );
                    })
                  )
              }
            </div>

            <div className="dl-wrap t2">
              <FilterItem
                productGroup={location.state && location.state.product_group}
                productGroupList={productGroupList}
                modelList={modelList}
                productList={productList}
                assyList={assyList}
                partList={partList}
                level={location.state && location.state.level}
                model={location.state && location.state.model_code}
                product={location.state && location.state.product_code}
                assy={location.state && location.state.assy_code}
                part={location.state && location.state.part_code}
                keyword={keyword}
                setKeyword={setKeyword}
                onBlurKeyword={onBlurKeyword}
                onChangeFilter={onChangeFilter}
              />

              <button type="button" data-action="search" className="btn c-blue s1" onClick={() => { Util.modal(filter, setFilter); onClickSearch() }}>검색하기</button>
            </div>
          </div>
        </div>
        {/* 20개씩 페이징 처리 */}
        <div className="prd-list" style={{ paddingBottom: '10px' }}>
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <dl className="prd-dl" key={index} onClick={() => stockInfoOpen(item)}>
                    <dt>
                      <div className="tit">
                        <div className="img-box" style={{ backgroundImage: item.thmbn_file_url ? `url(${item.thmbn_file_url})` : 'none' }}></div>
                        {item.product_group}
                      </div>
                      <span className="cnt">{item.quantity}</span>
                    </dt>
                    <dd>
                      <dl className="dl-wrap">
                        <dt>품목</dt>
                        <dd dangerouslySetInnerHTML={{
                          __html: item.crm_product_name ? item.crm_product_name : '-'
                        }}></dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>규격</dt>
                        <dd>{item.product_standard ? item.product_standard : '-'}</dd>
                      </dl>
                      <dl className="dl-wrap">
                        <dt>단계</dt>
                        <dd>
                          {
                            item.level === '1' && '제품'
                          }
                          {
                            item.level === '2' && '아세이'
                          }
                          {
                            item.level === '3' && '부품'
                          }
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                )
              })
          }
        </div>
        {/* ./20개씩 페이징 처리 */}
      </div>

      {
        scroll.scrollT > 0 &&
        <button type="button" className="btn-page-top" onClick={scrollTop}><span className="a11y">최상단으로 이동</span></button>
      }

      {
        loading &&
        <Progress
          loading={loading}
          fixed={filter} // 화면 전체 레이어로 덮는 형태
        />
      }
    </>
  )
}

export default StockList;