import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Notification from "../pages/notification/Notification";

function NotificationRouter() {
  return (
    <Switch>
      <PrivateRoute path="/notification" component={Notification} />
      <Redirect path="/notification/*" to="/notification" />
    </Switch>
  );
}

export default NotificationRouter;
