import React, { useState } from "react";
import Alert from "../../layout/Alert";
import Util from '../../helpers/Util';

const ProductAdd = (props) => {
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  // 감소 버튼 핸들러
  const onDecrease = () => {
    if (props.item.quantity < props.quantity - 1) {
      setAlertDec('출고 수량을 초과할 수 없습니다.');
      alertOpen();
      props.setQuantity(props.item.quantity);
      return;
    }

    if (props.quantity) {
      props.setQuantity(props.quantity - 1);
    }
  }

  // 증가 버튼 핸들러
  const onIncrease = () => {
    if (props.item.quantity <= props.quantity) {
      setAlertDec('출고 수량을 초과할 수 없습니다.');
      alertOpen();
      return;
    }

    props.setQuantity(props.quantity + 1);
  }

  // 수량 변경
  const onChangeQuantity = (event) => {
    if (props.item.quantity < Number(event.target.value)) {
      setAlertDec('출고 수량을 초과할 수 없습니다.');
      alertOpen();
      return;
    }
    Util.numberMaxLength(event); props.setQuantity(Number(event.target.value));
  }

  // 알럿
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }

  return (
    <>
      <div className={`load-container _t2${props.prodAdd ? " _open" : ""}`}>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-box reg-box pop-unit-cnt">
            <dl className="reg-container">
              <dt>
                <span className="a11y">요청 수량</span>
                <button type="button" className="btn-close" onClick={props.addOpen}><span className="a11y">닫기</span></button>
              </dt>
              <dd>
                <div className="box">
                  {
                    props.prodStatus === "receive" &&
                    <strong className="tit">입고 수량을 입력하세요.</strong>
                  }
                  {
                    props.prodStatus === "as" &&
                    <strong className="tit">공장 AS 요청 수량을 입력하세요.</strong>
                  }
                  {
                    props.prodStatus === "return" &&
                    <strong className="tit">반품 요청 수량을 입력하세요.</strong>
                  }
                  <label className="in-label">
                    <p className="dec under" dangerouslySetInnerHTML={{
                      __html: props.item.crm_product_name ? props.item.crm_product_name : '-'
                    }}></p>

                    <div className="cnt-container" data-scelector="cntContainer">
                      <input type="number" pattern="\d*" value={Util.numberCheck(props.quantity)} maxLength="4"
                        onKeyUp={(event) => Util.onlyNumber(event)}
                        onChange={(event) => onChangeQuantity(event)}
                      />
                      <button type="button" className="btn minus" data-action="cal" data-sid="minus" onClick={onDecrease}><span className="a11y">감소</span></button>
                      <button type="button" className="btn plus" data-action="cal" data-sid="plus" onClick={onIncrease}><span className="a11y">증가</span></button>
                    </div>
                  </label>

                  {
                    props.prodStatus === "return" &&
                    <>
                      <div className="label-flex flex">
                        <label className="chk-label">
                          <input type="radio" name="b1" checked={props.returnType === 'G'} onChange={(event) => props.setReturnType(event.target.checked ? 'G' : 'B')} />
                          <span className="txt">양품 반품</span>
                        </label>
                        <label className="chk-label">
                          <input type="radio" name="b1" checked={props.returnType === 'B'} onChange={(event) => props.setReturnType(event.target.checked ? 'B' : 'G')} />
                          <span className="txt">불량 반품</span>
                        </label>
                      </div>
                    </>
                  }
                </div>
              </dd>
            </dl>
            {
              props.prodStatus === "receive"
                ? <ul className="btn-flex t3">
                  <li className="col1"><button type="button" className="btn c-gray" onClick={props.addOpen}>취소</button></li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={props.finishOpen}>입고 완료</button></li>
                </ul>
                : <ul className="btn-flex t3">
                  <li className="col1"><button type="button" className="btn c-gray" onClick={props.addOpen}>취소</button></li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={props.awaitOpen}>발송 대기</button></li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={props.finishOpen}>발송 완료</button></li>
                </ul>
            }
          </section>
        </div>
      </div>

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}
    </>
  )
}

export default ProductAdd;
