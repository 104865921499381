import React from "react";

const MarketingTerms = () => {
  return (
    <>
      <div>
        <h3>** 본 이벤트 참여 고객은 개인 정보 마케팅 활용 동의를 거부할 권리가 있으며, 비 동의 시 이벤트 참여 및 경품 수령이 불가합니다.</h3>
        <div className="divider"></div>
        <p>1. 수집 주체 : ‘(주)세인홈시스’</p>
        <p>2. 수집 항목 : 이름, 주소, 휴대폰 번호, 서비스 내용</p>
        <p>3. 수집 목적 : ‘(주)세인홈시스’에서 제공하는 서비스 및 이벤트 정보 안내, 경품 및 사은품 발송, 서비스 개선을 위한 통계 및 분석 자료 활용</p>
        <p>4. 보유 기간 : 개인 정보의 수집·이용 목적 달성 시까지, 또는 고객이 자신의 개인 정보제공의 동의를 철회한 때. 단, 관련 법령의 규정에 의하여 개인 정보를 보유할 필요가 있는 경우, 해당 법령에서 정한 기간까지.</p>
        <div className="divider"></div>
        <h3>** ‘(주)세인홈시스’는 고객의 신뢰를 중요시하며 고객의 개인 정보를 보호하기 위해 최선을 다하고 있습니다. 당사의 개인 정보보호 정책 및 이용약관을 확인해 주세요.</h3>
      </div>
    </>
  )
}

export default MarketingTerms;