// 로딩화면 사용법
// <Progress
//   loading={true}
//   fixed={true} // 화면 전체 레이어로 덮는 형태
// />

const Progress = (props) => {
  return (
    <>
      {
        props.loading &&
        <>
          <div className={`progress-wrap${props.fixed? " fixed" : ""}`}>
            <svg className="progress-svg" viewBox="22 22 44 44">
              <circle className="progress-circle" cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
            </svg>
          </div>
        </>
      }
    </>
  );
}

export default Progress;