import React, { useState, useEffect, useCallback, useRef } from 'react';
import UserService from '../../services/UserService';
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";
import Progress from "../../layout/Progress";
import Util from '../../helpers/Util';

const Profile = () => {
  const [profile, setProfile] = useState("");
  const [passwordCur, setPasswordCur] = useState("");
  const [passwordNew, setPasswordNew] = useState(["", ""]);
  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");
  const pwCur = useRef('');
  const pwNow1 = useRef('');
  const pwNow2 = useRef('');

  // 프로필 이미지 변경
  const onChangePhoto = (event) => {
    if (event.target.files[0]) {
      updateImg(event.target.files[0]);
    }
  }

  // 프로필 정보 조회
  const searchProfile = useCallback(async () => {
    setLoading(true);
    try {
      const result = await UserService.fetchItems();
      const data = result.data;
      setProfile(data.userInfo);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const updateImg = useCallback(async (img) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('profile_img', img);
    try {
      const result = await UserService.updateProfile(formData);
      const data = result.result;
      if (data < 1) {
        searchProfile();
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [searchProfile]);

  // 비밀번호 변경 컨펌창
  const confirmOpen = () => {
    const minLength = 6;

    if (confirm) {
      setConfirm(false);

    } else {
      if (!passwordCur) {
        setAlertDec("현재 비밀번호를 입력하세요.");
        alertOpen();
        return;
      } else {
        if (passwordCur.length < minLength) {
          setAlertDec("현재 비밀번호는 6자리 이상 입력해주세요.");
          alertOpen();
          return;
        }
      }

      if (!passwordNew[0] || !passwordNew[1]) {
        setAlertDec("변경 비밀번호를 입력하세요.");
        alertOpen();
        return;
      } else {
        if (passwordNew[0].length < minLength || passwordNew[1].length < minLength) {
          setAlertDec("변경 비밀번호는 6자리 이상 입력해주세요.");
          alertOpen();
          return;
        }

        if (passwordNew[0] !== passwordNew[1]) {
          setAlertDec("변경 비밀번호가 일치하지 않습니다.");
          alertOpen();
          return;
        }
      }

      if (passwordCur === passwordNew[0]) {
        setAlertDec("현재 비밀번호와 변경 비밀번호가 일치합니다.");
        alertOpen();
        return;
      }

      setConfirm(true);
    }
  }

  // 알럿 열기/닫기
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }

  // 변경 비밀번호 저장
  const savePassword = async () => {
    setConfirm(false);
    setLoading(true);

    const param = {
      password_cur: passwordCur,
      password_new: passwordNew[0]
    };
    try {
      const result = await UserService.updatePassword(param);
      if (result.result === 0) {
        setPassword(false);

        setAlertDec("비밀번호가 변경되었습니다.");
        setAlert(true);
        setPasswordCur('');
        setPasswordNew(['', '']);

      } else if (result.result < 0) {
        setAlertDec(result.message);
        alertOpen();
      }
    } catch (error) {
      if (error.response) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } else {
        setAlertDec('비정상적인 오류가 발생하였습니다. 네트워크를 확인하고 앱을 완전히 종료후 재실행하세요. 재실행 후에도 오류 발생시 관리자에게 문의바랍니다.')
        setAlert(true);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    searchProfile(); // 프로필 정보 조회
  }, [searchProfile]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
  }, [])

  return (
    <>
      <div className="page-my">
        <section className="sec-pic">
          <label className="file-label" data-selector="uploadContainer">
            <div className="null-img img-box"><p className="a11y">사진첨부</p></div>
            <div className="img-box temp-img" style={{ backgroundImage: `url(${profile ? profile.profile_img_url : null})` }}></div>
            <input type="file" name="profile_img" onChange={(event) => onChangePhoto(event)} />
            {
              profile && profile.profile_img_url
                ? null
                : <span className="btn-add"><span className="a11y">이미지추가</span></span>
            }
          </label>
        </section>

        <section className="sec-info">
          <div className="m-main">
            <dl className="dl-tbl">
              <dt>아이디</dt>
              <dd>{profile && profile.user_id}</dd>
            </dl>
            <dl className="dl-tbl">
              <dt>비밀번호</dt>
              <dd><button type="button" className="btn c-blue-line t3 s3" onClick={() => Util.modal(password, setPassword)}>변경</button></dd>
            </dl>
            <dl className="dl-tbl">
              <dt>이름</dt>
              <dd dangerouslySetInnerHTML={{
                __html: profile && profile.name
              }}></dd>
            </dl>
            <dl className="dl-tbl">
              <dt>설치센터점</dt>
              <dd>{profile && profile.center_name}</dd>
            </dl>
            <dl className="dl-tbl">
              <dt>휴대폰번호</dt>
              <dd>{profile && Util.formatPhoneNumber(profile.cell_phone)}</dd>
            </dl>
          </div>
        </section>
      </div>

      {/* 비밀번호 변경 레이어 팝업 */}
      {
        password &&
        <div className={`layer${password ? " _open" : ""}`}>
          <div className="pop-container pop-changePsw" data-selector="pop-changePsw">
            <div className="pop-content">
              <header className="pop-header">
                <h2 className="pop-tit">비밀번호 변경</h2>
                <button type="button" className="pop-close" onClick={() => Util.modal(password, setPassword)}><span className="a11y">팝업닫기</span></button>
              </header>
              <div className="pop-inner" data-action="popAppend">
                <dl className="sn-dl">
                  <dt><strong className="req">현재 비밀번호</strong></dt>
                  <dd>
                    <input type="number" inputMode="numeric" name="password_cur" pattern="\d*" maxLength="20" placeholder="비밀번호를 입력하세요."
                      onKeyUp={(event) => Util.onlyNumber(event)}
                      onChange={(event) => { Util.numberMaxLength(event); setPasswordCur(event.target.value) }}
                      ref={pwCur}
                    />
                  </dd>
                </dl>
                <dl className="sn-dl">
                  <dt><strong className="req">변경 비밀번호 </strong></dt>
                  <dd>
                    <input type="number" inputMode="numeric" name="password_new1" pattern="\d*" maxLength="20" placeholder="비밀번호를 6자리 이상 입력"
                      onKeyUp={(event) => Util.onlyNumber(event)}
                      onChange={(event) => {
                        Util.numberMaxLength(event);
                        setPasswordNew(thisState => {
                          let state = [...thisState];
                          state[0] = event.target.value;
                          return state;
                        })
                      }}
                      ref={pwNow1}
                    />
                    <input type="number" inputMode="numeric" name="password_new2" pattern="\d*" maxLength="20" placeholder="비밀번호를 한번 더 입력하세요."
                      onKeyUp={(event) => Util.onlyNumber(event)}
                      onChange={(event) => {
                        Util.numberMaxLength(event);
                        setPasswordNew(thisState => {
                          let state = [...thisState];
                          state[1] = event.target.value;
                          return state;
                        })
                      }}
                      ref={pwNow2}
                    />
                  </dd>
                </dl>

                <ul className="btn-flex t2">
                  <li className="col1"><button type="button" className="btn c-gray s0" onClick={() => Util.modal(password, setPassword)}>취소</button></li>
                  <li className="col2"><button type="button" className="btn c-sky s0" onClick={confirmOpen}>저장</button></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="dim"></div>
        </div>
      }

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec="비밀번호를 변경하시겠습니까?"
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={savePassword}
        />
      }

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true}
        />
      }
    </>
  )
}

export default Profile;