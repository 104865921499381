import { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { AppContext } from '../../AppContext';

const PrivateRoute = ({ ...rest }) => {
  const { auth } = useContext(AppContext);
  const location = useLocation();

  return (
    <>
      {auth.user ? <Route {...rest} /> : <Redirect to={{ pathname: "/login", state: { from: location.pathname } }} />}
    </>
  );
}

export default PrivateRoute;