import React, { useState, useEffect, useCallback } from 'react';
import BoardService from '../../services/BoardService';
import Util from '../../helpers/Util';
import Alert from "../../layout/Alert";

const Notice = () => {
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트 
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  const detailOpen = (event, item, index) => {
    const $dropBox = document.querySelectorAll(".drop-box");
    const $dropBoxItem = event.target.closest(".drop-box");
    const $open = event.target.closest("._open");

    $dropBox.forEach((item) => {
      item.classList.remove("_open");
    });

    if ($open) {
      $dropBoxItem.classList.remove("_open");
    } else {
      readNotice(item, index); // 읽음처리
      $dropBoxItem.classList.add("_open");
    }
  }

  // 읽음처리
  const readNotice = async (item, index) => {
    setLoading(true);
    const param = {
      notice_seq: item.board_seq
    };
    try {
      await BoardService.fetchReadNotice(param);
      setList(thisState => {
        let state = [...thisState];
        state[index].is_read = 1;
        return state;
      });
    } catch (error) {
      if (error.response) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } else {
        setAlertDec('비정상적인 오류가 발생하였습니다. 네트워크를 확인하시고 앱을 완전히 종료후 재실행하세요. 재실행 후에도 오류 발생시 관리자에게 문의바랍니다.')
        setAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    const param = {
      board_type: 'NOTICE',
      is_display: 1,
      is_del: 0,
      current_page: currentPage,
      page_size: pageSize
    };
    try {
      const result = await BoardService.fetchItems('notices', param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
      if (error.response) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } else {
        setAlertDec('비정상적인 오류가 발생하였습니다. 네트워크를 확인하고 앱을 완전히 종료후 재실행하세요.재실행 후에도 오류 발생시 관리자에게 문의바랍니다.')
        setAlert(true);
      }
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  // 더보기 버튼 핸들러
  const onMore = () => {
    setCurrentPage(currentPage + 1);
  }

  // 알럿
  const alertOpen = useCallback(() => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }, [alert]);

  useEffect(() => {
    searchList(); // 리스트 조회
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      setCurrentPage(1);
    }
  }, [])

  return (
    <>
      <section className="bbs-container">
        <ul className="bbs-list" data-selector="allDrop">
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <li data-selector="dropContainer" className="drop-box" key={index}>
                    <div className={`item ${item.is_read ? '' : '_new'}`}>
                      <p className="tit" dangerouslySetInnerHTML={{ __html: item.title }}></p>
                      <span className="date">{Util.formatDate(item.send_datetime)}</span>
                      <button type="button" className="cover" onClick={(event) => detailOpen(event, item, index)}><span className="a11y">상세보기</span></button>
                    </div>
                    <div className="drop-wrap" dangerouslySetInnerHTML={{ __html: Util.unescapeHtml(item.content) }}>
                    </div>
                  </li>
                );
              })
          }
        </ul>
      </section>

      {
        !loading && totalCount > pageSize * currentPage ?
          <div className="btn-wrap" style={{ marginTop: "30px" }}>
            <button type="button" className="btn c-sky w100" onClick={onMore}>더보기</button>
          </div>
          :
          <></>
      }

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
    </>
  )
}

export default Notice;