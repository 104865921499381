import React, { useState } from "react";

const Gallery = (props) => {
  const [current, setCurrent] = useState(1);

  const photoChange = (event) => {
    const idx = event.target.dataset.img;
    setCurrent(idx);
  }

  return (
    <>
      <div className={`load-container${props.gallery ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={props.galleryOpen}><span className="a11y">닫기</span></button>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-gallery">
            <div className="img-wrap">
              <figure className="img-box">
                <img src={props.photoData[current - 1] && props.photoData[current - 1].file_url} alt="" data-scelector="big" />
              </figure>
              <p className="cnt"><span className="c1">{current}</span>/{props.photoData.length}</p>
            </div>
            <div className="thumb-container">
              <div className="thumb-list">
                {
                  props.photoData.map((item, index) => {
                    return (
                      <button type="button" key={index} className={`img-box${Number(current) === (index + 1) ? " _active" : ""}`} style={{ backgroundImage: `url(${item.thmbn_file_url})` }} data-img={index + 1} onClick={photoChange}></button>
                    )
                  })
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Gallery;