import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import OrderList from "../pages/order/OrderList";

function OrderRouter() {
  return (
    <Switch>
      <PrivateRoute path="/order/list" component={OrderList} />
      <Redirect path="/order/*" to="/order/list" />
    </Switch>
  );
}

export default OrderRouter;
