import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import StockService from '../../services/StockService';
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const StockInfo = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트
  const [scroll, setScroll] = useState(0);
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize
    };
    Object.assign(param);
    try {
      const result = await StockService.fetchItem(location.state.child_product_code, param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(currentPage + 1);
        }
      }
    }
  }, [loading, currentPage, totalCount]);

  // 스크롤 최상단으로 이동
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      const scrollT = ('scroll', e.srcElement.scrollingElement.scrollTop);
      setScroll({ scrollT });

      onMore(); // 더보기
    }
  }, [setScroll, onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  return (
    <>
      <div className="page-stock">
        <div className="prd-list">
          <dl className="prd-dl">
            <dt>
              <div className="tit">
                <div className="img-box" style={{ backgroundImage: location.state.thmbn_file_url ? `url(${location.state.thmbn_file_url})` : 'none' }}></div>
                {location.state.product_group}
              </div>
              <span className="cnt">{location.state.quantity}</span>
            </dt>
            <dd>
              <dl className="dl-wrap">
                <dt>품목</dt>
                <dd>{Util.unescapeHtml(location.state.crm_product_name)}</dd>
              </dl>
              <dl className="dl-wrap">
                <dt>규격</dt>
                <dd>{location.state.product_standard || '-'}</dd>
              </dl>
              <dl className="dl-wrap">
                <dt>단계</dt>
                <dd>
                  {
                    location.state.level === '1' && '제품'
                  }
                  {
                    location.state.level === '2' && '아세이'
                  }
                  {
                    location.state.level === '3' && '부품'
                  }
                </dd>
              </dl>
            </dd>
          </dl>
        </div>

        <dl className="list-dl">
          <dt>재고 상세</dt>
          <dd>
            <ul className="list">
              {
                !list.length ?
                  loading ? <></>
                    :
                    <p className="no-data">검색 결과가 없습니다.</p>
                  :
                  list.map((item, index) => {
                    return (
                      <li className={item.level !== '1' ? 'list-incoming' : ''} key={index}>
                        {
                          item.level !== '1'
                            ?
                            <>
                              {/* 부품 재고 상세 */}
                              <strong className="tit" dangerouslySetInnerHTML={{
                                __html: item.reg_user_name || '-'
                              }}></strong>
                              <p className="dec">{Util.formatDateTimeHHMM(item.reg_datetime)}</p>
                              <span className="btn c-mint">{item.quantity}</span>
                            </>
                            :
                            <>
                              {/* 제품 재고 상세 */}
                              <div className="col-dec">
                                <strong className="tit" dangerouslySetInnerHTML={{
                                  __html: item.product_serial_number || '-'
                                }}></strong>
                                <p className="dec" style={{ maxWidth: 'none' }} dangerouslySetInnerHTML={{
                                  __html: item.reg_user_name || '-'
                                }}></p>
                                <span className="date" style={{ marginLeft: 0 }}>{item.reg_datetime ? Util.formatDateTimeHHMM(item.reg_datetime) : '-'}</span>
                              </div>
                            </>
                        }
                      </li>
                    )
                  })
              }
            </ul>
          </dd>
        </dl>
      </div>

      {
        scroll.scrollT > 0 &&
        <button type="button" className="btn-page-top" onClick={scrollTop}><span className="a11y">최상단으로 이동</span></button>
      }

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true} // 화면 전체 레이어로 덮는 형태
        />
      }
    </>
  )
}

export default StockInfo;