const Terms = () => {
  return (
    <div className="page-term">
      <section className="sec-term">
        <div data-selector="termLoad">
          약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용약관 내용 약관 내용
        </div>
      </section>
    </div>
  )
}

export default Terms;