import { useEffect, useState } from "react";
import axios from 'axios';
import LoginService from '../services/LoginService';

const excludePathList = [
  '/login/join' // 회원가입
];

const AuthStore = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const pathname = window.location.pathname;
    let filteredPathList = excludePathList.filter((path) => {
      if (path.indexOf(pathname) > -1) {
        return false;
      } else {
        return true;
      }
    });
    if (!filteredPathList) {
      window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
    }

    window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
      window.localStorage.removeItem('getUserInfo');
      window.localStorage.setItem('getUserInfo', JSON.stringify(response));
      getUserInfo().then((userInfo) => {
        setUser(userInfo);
        setIsLogin(true);
      }).catch(() => {
      });
    }

  }, []);

  useEffect(() => {
    syncAxioHeaderToken(user);
  }, [user]);

  const getUserInfo = () => {
    return new Promise(function (resolve, reject) {
      const userInfo = window.localStorage.getItem('getUserInfo');
      if (userInfo) {
        resolve(JSON.parse(userInfo));
      }
    })
  }

  // 로그아웃
  const logout = async () => {
    let userInfo = window.localStorage.getItem('getUserInfo');
    userInfo = JSON.parse(userInfo);

    if (userInfo) {
      const param = {
        device_token: userInfo.dev_token
      };
      try {
        const result = await LoginService.logout(param);
        if (result.result < 0) {
          alert(result.message + '\n관리자에게 문의하세요.');
          window.location.reload();
        }
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
          alert(message);
          window.location.reload();
        }
      } finally {
        window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
        window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
          window.localStorage.removeItem('getUserInfo');
          setUser(null);
          setIsLogin(false);
        }
      }
    } else {
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
        setUser(null);
        setIsLogin(false);
      }
    }
  }

  // 최초 실행값 수정
  const setIsStart = (status) => {
    window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
    window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
      window.localStorage.removeItem('getUserInfo');
      window.localStorage.setItem('getUserInfo', JSON.stringify(response));
      getUserInfo().then((userInfo) => {
        if (userInfo.user_sno) {
          userInfo.is_start = status;
          setUser(userInfo);
        }
      }).catch(() => {
      });
    }
  }

  // 사용자 토큰정보 헤더에 설정
  const syncAxioHeaderToken = (user) => {
    if (user) {
      axios.defaults.headers.common['x-token'] = user.token;
      axios.defaults.headers.common['user_seq'] = user.user_seq;
      axios.defaults.headers.common['user_grade'] = user.app_user_grade;
      axios.defaults.headers.common['user_type'] = user.user_type;
      axios.defaults.headers.common['user_center_seq'] = user.center_seq;
    } else {
      delete axios.defaults.headers.common['x-token'];
      delete axios.defaults.headers.common['user_seq'];
      delete axios.defaults.headers.common['user_grade'];
      delete axios.defaults.headers.common['user_type'];
      delete axios.defaults.headers.common['user_center_seq'];
    }
  }

  return { user, setIsStart, logout, isLogin };
}

export default AuthStore;