import Service from './Index';

const MainService = {
  // 읽지않은 알림 조회
  fetchNewNotis: () => {
    return Service.get('main/newNotis');
  },

  // 메인 정보 조회
  fetchMainInfoInfo: () => {
    return Service.get('main/info');
  },

  // 앱 정보 조회
  fetchAppInfoInfo: () => {
    return Service.get('main/app-version');
  },

};

export default MainService;
