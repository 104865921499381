import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import MainService from '../services/MainService';
import Progress from "./Progress";

const Gnb = (props) => {
  const history = useHistory();
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const [appInfo, setAppInfo] = useState(null);
  const [logout, setLogout] = useState(false);
  const [notiCount, setNotiCount] = useState(0);
  const [loading, setLoading] = useState(false);  // 로딩여부

  // 앱 정보 조회
  const searchAppInfo = useCallback(async () => {
    if (auth && auth.user && auth.user.os) {
      try {
        const result = await MainService.fetchAppInfoInfo();
        const list = result.data.list;
        const newVersionList = list.filter(element => {
          return element.os === auth.user.os
        });
        setAppInfo(newVersionList[0]);
      } catch (error) {
      } finally {
      }
    }
  }, [auth]);

  // 읽지않은 알림 카운트 조회
  const searchNotificationCount = useCallback(async () => {
    try {
      const result = await MainService.fetchNewNotis();
      const data = result.data;
      setNotiCount(data.new_notis);
    } catch (error) {
    } finally {
    }
  }, []);

  const logoutOpen = () => {
    if (logout) {
      setLogout(false);
    } else {
      setLogout(true);
    }
  }

  const goProfile = () => {
    history.push({
      pathname: "/mypage/profile"
    });
    props.menuOpen();
  }

  // 고객센터
  const onCallClient = (contact) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
      tel: contact
    }, "");
  }

  // 앱 업데이트
  const onUpdate = () => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
      url: appInfo && appInfo.url
    }, "");
  }

  // 로그아웃 핸들러
  const onLogout = () => {
    setLoading(true);
    auth.logout();
  }

  useEffect(() => {
    searchAppInfo();
    searchNotificationCount();
  }, [searchAppInfo, searchNotificationCount]);

  return (
    <>
      <dl className="gnb-container">
        <dt className="gnb-top flex">
          <div className="name-wrap" onClick={goProfile}>
            <div className="pic">
              <div className="img-box" style={{ backgroundImage: `url(${props.profile ? props.profile : ""})` }}></div>
            </div>
            <p className="txt"
              dangerouslySetInnerHTML={{
                __html: user && user.user_name
              }}></p>
          </div>
          <Link to="/" className="home" onClick={props.menuOpen}></Link>
          <button type="button" className="logout" onClick={logoutOpen}>로그아웃</button>
        </dt>
        <dt className="gnb-nav">
          <nav>
            <Link to="/board/notice" className="nav-item ico10" onClick={props.menuOpen}><span className="txt">공지사항</span></Link>	  
            <Link to="/notification" className="nav-item ico1" onClick={props.menuOpen}><span className={`txt${notiCount ? " _new" : null}`}>알림 내역</span></Link>
            <Link to="/service/list" className="nav-item ico2" onClick={props.menuOpen}><span className="txt">서비스 종합 조회</span></Link>
            <Link to="/service/reg" className="nav-item ico3" onClick={props.menuOpen}><span className="txt">서비스 신규 접수</span></Link>
            {
              user && user.app_user_grade !== 'A' &&
              <>
                <Link to="/stock/list" className="nav-item ico4" onClick={props.menuOpen}><span className="txt">재고 관리</span></Link>
                <Link to="/stock/release/list" className="nav-item ico5" onClick={props.menuOpen}><span className="txt">출고 조회</span></Link>
                <Link to="/stock/receiving/list" className="nav-item ico6" onClick={props.menuOpen}><span className="txt">입고 조회</span></Link>
                <Link to="/stock/as/list" className="nav-item ico7" onClick={props.menuOpen}><span className="txt">공장AS요청 조회</span></Link>
                <Link to="/stock/return/list" className="nav-item ico8" onClick={props.menuOpen}><span className="txt">반품요청 조회</span></Link>
                <Link to="/order/list" className="nav-item ico9" onClick={props.menuOpen}><span className="txt">제품 발주</span></Link>
              </>
            }
            <button type="button" className="nav-item ico11">
              <span className="txt">버전 v{user && user.app_version}</span>
              {
                user && user.app_version === appInfo && appInfo.version ?
                  <span className="ver">최신버전</span>
                  :
                  <span className="ver" onClick={onUpdate}>v{appInfo && appInfo.version}</span>
              }
            </button>
          </nav>
          <footer className="flex">
            <strong className="tit">(주)세인홈시스</strong>
            <button type="button" className="tel" onClick={() => onCallClient("1688-7992")}>1688-7992</button>
          </footer>
        </dt>
      </dl>

      <div className={`layer${logout ? " _open" : ""}`}>
        <div className="pop-container pop-confirm " data-selector="pop-confirm">
          <div className="pop-content">
            <div className="pop-inner" data-action="popAppend">
              <div className="dec-wrap">로그아웃 하시겠습니까?</div>
              <ul className="btn-flex t2">
                <li className="col1"><button type="button" className="btn c-gray s0" onClick={logoutOpen}>취소</button></li>
                <li className="col2"><button type="button" className="btn c-sky s0" onClick={onLogout}>확인</button></li>
              </ul>
            </div>
            <button type="button" className="pop-close" onClick={logoutOpen}><span className="a11y">팝업닫기</span></button>
          </div>
        </div>
        <div className="dim"></div>
      </div>

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true}
        />
      }
    </>
  );
}

export default Gnb;
