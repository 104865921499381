import DaumPostcode from 'react-daum-postcode';

const PostCode = (props) => {
  const address = props.address;

  const postCode = (postData) => {
    let fullAddress = postData.address;
    let extraAddress = '';

    if (postData.addressType === 'R') {
      if (postData.bname !== '') {
        extraAddress += postData.bname;
      }
      if (postData.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${postData.buildingName}` : postData.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    props.postResult(fullAddress, postData.zonecode, postData.bcode);
    props.postOpen();
  }

  return (
    <>
      <div className={`load-container${props.post ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={props.postOpen}><span className="a11y">닫기</span></button>
        <div data-selector="load" className="load-wrap address-wrap">
          {
            props.post ?
              <DaumPostcode
                onComplete={postCode}
                defaultQuery={address}
                height="100%"
                autoClose={true}
              />
              : <></>
          }
        </div>
      </div>
    </>
  )
}

export default PostCode;
