const ProductImage = (props) => {
  return (
    <>
      <div className={`load-container${props.isOpen ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={props.imgPopOpen}><span className="a11y">닫기</span></button>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-product">
            <header>
              <p className="under" dangerouslySetInnerHTML={{
                __html: props.product && props.product.crm_product_name
              }}></p>
            </header>
            <div className="img-wrap">
              {
                props.product && props.product.file_url ?
                  <div className="img-box" style={{ backgroundImage: `url(${props.product && props.product.file_url})` }}></div>
                  :
                  <div className="result-box">
                    <p className="no-data">제품 이미지가 없습니다.</p>
                  </div>
              }
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default ProductImage;