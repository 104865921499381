import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import StockList from "../pages/stock/StockList";
import StockInfo from "../pages/stock/StockInfo";
import ReleaseList from "../pages/stock/ReleaseList";
import ReceivingList from "../pages/stock/ReceivingList";
import ReceivingInfo from "../pages/stock/ReceivingInfo";
import AsList from "../pages/stock/AsList";
import AsReg from "../pages/stock/AsReg";
import ReturnList from "../pages/stock/ReturnList";
import ReturnReg from "../pages/stock/ReturnReg";
import ReceivingTempList from "../pages/stock/ReceivingTempList";

function StockRouter() {
  return (
    <Switch>
      <PrivateRoute path="/stock/receiving/tempList" component={ReceivingTempList} />
      <PrivateRoute path="/stock/return/reg" component={ReturnReg} />
      <PrivateRoute path="/stock/return/list" component={ReturnList} />
      <PrivateRoute path="/stock/as/reg" component={AsReg} />
      <PrivateRoute path="/stock/as/list" component={AsList} />
      <PrivateRoute path="/stock/receiving/list" component={ReceivingList} />
      <PrivateRoute path="/stock/receiving/info" component={ReceivingInfo} />
      <PrivateRoute path="/stock/release/list" component={ReleaseList} />
      <PrivateRoute path="/stock/list" component={StockList} />
      <PrivateRoute path="/stock/info" component={StockInfo} />
      <Redirect path="/stock/*" to="/stock/list" />
    </Switch>
  );
}

export default StockRouter;
