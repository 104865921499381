
import axios from 'axios';
import { API_ROOT, VERSION, BUILD_NO } from '../config';

axios.defaults.headers.common['X-Client-Version'] = VERSION;
axios.defaults.headers.common['X-Client-Build'] = BUILD_NO;
axios.defaults.headers.common['Cache-Control'] = "no-cache";
axios.defaults.headers.common['Access-Control-Allow-Header'] = "Accept, Content-Type";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

const Service = {
  get: async (endpoint, data) => {
    try {
      const result = await axios.get(API_ROOT + endpoint, { params: data });
      if (result.data.result === -100) { // 토큰만료
        alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
        window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
        window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
          window.localStorage.removeItem('getUserInfo');
        }
      } else if (result.data.message === "토큰 정보가 일치하지 않습니다. 다시 로그인해주세요.") { // 토큰만료
        alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
        window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
        window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
          window.localStorage.removeItem('getUserInfo');
        }
      } else {
        return result.data;
      }

    } catch (e) {
      const error_code = e.response.data.error_code;
      const message = e.response.data.message;

      if (e.response.status === 401) {
        if (error_code === -100) {
          alert(message);
          window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
        }
        throw e;
      }
      throw e;
    }
  },
  post: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }

    const result = await axios.post(API_ROOT + endpoint, body, config);
    if (result.data.result === -100) { // 토큰만료
      alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
      }
    } else if (result.data.message === "토큰 정보가 일치하지 않습니다. 다시 로그인해주세요.") { // 토큰만료
      alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
      }
    } else {
      return result.data;
    }
  },
  put: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }
    const result = await axios.put(API_ROOT + endpoint, body, config);
    if (result.data.result === -100) { // 토큰만료
      alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
      }
    } else if (result.data.message === "토큰 정보가 일치하지 않습니다. 다시 로그인해주세요.") { // 토큰만료
      alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
      }
    } else {
      return result.data;
    }
  },
  delete: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }
    const result = await axios.delete(API_ROOT + endpoint, { data: body }, config);
    if (result.data.result === -100) { // 토큰만료
      alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
      }
    } else if (result.data.message === "토큰 정보가 일치하지 않습니다. 다시 로그인해주세요.") { // 토큰만료
      alert('로그인 기한이 만료되었습니다.\n다시 로그인 해주세요.');
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
        window.localStorage.removeItem('getUserInfo');
      }
    } else {
      return result.data;
    }
  },
};

export default Service;
