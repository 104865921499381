import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import 'moment/locale/ko';
import moment from 'moment';
import StockService from '../../services/StockService';
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";

const ReceivingTempList = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]); // 리스트
  const [prodCode, setProdCode] = useState(''); // 리스트
  const [prodQuantity, setProdQuantity] = useState(''); // 리스트
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState('');
  const [confirmDec, setConfirmDec] = useState('');

  // 입고 바코드 스캐너
  const barcodeScanner = (event) => {
    const locationState = location.state || {};

    if (!window.nativeApi.isWeb) {
      window.nativeApi.execute(window.NativeApiMethod.OPEN_MULTI_SCANNER, {
        name: '입고 스캔',
        product_group: (locationState && locationState.product_group) || '',
        product_name: (locationState && locationState.crm_product_name) || '',
        standard: (locationState && locationState.product_standard) || '',
        image: (locationState && locationState.image) || '',
        list: list
      }, window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER);
      window[window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER] = function (response) {
        const list = response.list;
        window.localStorage.removeItem('getReceivingTemp');
        window.localStorage.setItem('getReceivingTemp', JSON.stringify(list));

        history.replace({
          pathname: '/stock/receiving/tempList',
          state: {
            product_group: (locationState && locationState.product_group) || '',
            child_product_code: (locationState && locationState.crm_product_code) || prodCode,
            crm_product_name: (locationState && locationState.crm_product_name) || '',
            product_standard: (locationState && locationState.product_standard) || '',
            release_seq: (locationState && locationState.release_seq) || '',
            quantity: (locationState && locationState.quantity) || prodQuantity,
            image: (locationState && locationState.image) || '',
          }
        });

        getReceivingTemp().then((receivingTemp) => {
          setList(thisState => {
            let state = [];
            state = receivingTemp;
            return state;
          });
        }).catch(() => {
        });
      }
    }
  }

  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  const alertOpen = () => {
    if (alert) {
      setAlert(false);
    } else {
      setConfirm(false);
      setAlert(true);
    }
  }

  // 목록 삭제
  const delTemp = useCallback((event, index) => {
    setList(thisState => {
      let state = [...thisState];
      state.splice(index, 1);
      return state;
    });
    window.localStorage.removeItem('getReceivingTemp');
  }, []);

  // 입고 완료
  const onSaveReceiving = () => {
    setConfirmDec('');
    setAlertDec('');
    if (list.length) {
      if (Number(prodQuantity) !== list.length) {
        setConfirmDec('공장 출고 정보와<br />입고수량이 다릅니다.<br />수량을 확인하시기 바랍니다.<br />입고 완료하시겠습니까?');
        confirmOpen();
      } else {
        setConfirmDec('입고 완료하시겠습니까?');
        confirmOpen();
      }
    } else {
      setAlertDec('스캔 결과가 없습니다.');
      alertOpen();
    }
  }

  // 입고 완료 (제품)
  const regProduct = async () => {
    setLoading(true);
    let scanList = list.map((item) => { return item.is_scan ? '1' : '0' }).join();
    let serialList = list.map((item) => { return item.barcode === '확인 불가' ? '' : item.barcode }).join();
    const param = {
      child_product_code: prodCode,
      is_scan_list: scanList,
      product_serial_number_list: serialList,
      release_date: Util.formatDate(moment())
    };
    try {
      const result = await StockService.registProduct(param);
      setAlertDec('');
      setAlert(true);
      if (result.result === 0) {
        setAlertDec('저장되었습니다.');
        history.push({
          pathname: '/stock/receiving/list',
        });
      } else {
        setAlertDec('다시 시도해주세요.');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const getReceivingTemp = () => {
    return new Promise(function (resolve, reject) {
      window.localStorage.getItem('getReceivingTemp');
      const receivingTemp = window.localStorage.getItem('getReceivingTemp');
      if (receivingTemp) {
        resolve(JSON.parse(receivingTemp));
      }
    })
  }

  useEffect(() => {
    if (!list.length) {
      getReceivingTemp().then((receivingTemp) => {
        setList(receivingTemp);
      }).catch(() => {
      });
    }
  }, [list]);

  useEffect(() => {
    const locationState = location.state || {};

    if (locationState) {
      setProdCode(locationState.child_product_code);
      setProdQuantity(locationState.quantity);
    }
  }, [location.state]);

  return (
    <>
      <div className="page-stock">
        <div className="prd-list">
          <dl className="prd-dl">
            <dt>
              <div className="tit">
                <div className="img-box" style={{ backgroundImage: location.state.image ? `url(${location.state.image})` : 'none' }}></div>
                {location.state.product_group}
              </div>
              <span className="cnt">{list.length}</span>
            </dt>
            <dd>
              <dl className="dl-wrap">
                <dt>품목</dt>
                <dd>{location.state.crm_product_name}</dd>
              </dl>
              <dl className="dl-wrap">
                <dt>규격</dt>
                <dd>{location.state.product_standard}</dd>
              </dl>
            </dd>
          </dl>
        </div>

        <dl className="list-dl" style={{ paddingBottom: '55px' }}>
          <dt>목록</dt>
          <dd>
            <ul className="list t3">
              {
                !list.length ?
                  <p className="no-data"> 스캔 결과가 없습니다.</p>
                  :
                  list.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="col-dec">
                          <strong className="tit">{item.barcode}</strong>
                        </div>
                        <button type="button" className="btn-del" onClick={(event) => delTemp(event, index)}><span className="a11y">삭제</span></button>
                      </li>
                    )
                  })
              }
            </ul>

            <div className="barcode-wrap t2">
              <button type="button" className="barcode" onClick={barcodeScanner}><span className="a11y">바코드</span></button>
            </div>

            <div className="btn-wrap fixed">
              <button type="button" className="btn c-sky w100" onClick={onSaveReceiving}>입고 완료</button>
            </div>
          </dd>
        </dl>
      </div>

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true} // 화면 전체 레이어로 덮는 형태
        />
      }

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec={confirmDec}
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={regProduct}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
      {/* ./알럿창 */}
    </>
  )
}

export default ReceivingTempList;