import React, { useState, useEffect } from "react";
import RequestService from '../../services/RequestService';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import Util from '../../helpers/Util';
import RegFinish3 from "./RegFinish3";
import Confirm from "../Confirm";
import Alert from "../Alert";
import Progress from "../Progress";

const RegTempList = (props) => {
  const [item, setItem] = useState(null);
  const [scanList, setScanList] = useState([]);
  const [awaitReg, setAwaitReg] = useState(false);
  const [sendReg, setSendReg] = useState(false);
  const [finishReg, setFinishReg] = useState(false);
  const [prodAwait, setProdAwait] = useState(false);
  const [prodSend, setProdSend] = useState(false);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  // 스캔 삭제
  const onDeleteScan = (index) => {
    setScanList(thisState => {
      let state = [...thisState];
      state.splice(index, 1);
      return state;
    });
  }

  // 바코드  
  const onClickAddScan = () => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_MULTI_SCANNER, {
      name: "공장AS요청 스캔",
      product_group: item.product_group,
      product_name: item.crm_product_name,
      standard: item.product_standard,
      image: item.thmbn_file_url,
    }, window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER);
    window[window.NativeApiCallbackMethod.OPEN_MULTI_SCANNER] = function (response) {
      setScanList(thisState => {
        let state = [
          ...thisState,
          ...response.list
        ];
        return state;
      });
    }
  }

  // 발송 대기 요청
  const awaitOpen = () => {
    if (awaitReg) {
      setProdAwait(false);
      setAwaitReg(false);
    } else {
      if (!scanList.length) {
        setAlertDec("바코드를 스캔하세요.");
        alertOpen();

      } else {
        // 시리얼넘버 ',' 문자 있는지 확인
        var isOk = false;
        scanList.forEach((scanItem) => {
          if (scanItem.barcode.indexOf(',') > -1) {
            isOk = true;
            return;
          }
        });

        if (isOk) {
          setAlertDec('S/N에 \',\'(콤마) 문자는 사용 할 수 없습니다.');
          alertOpen();
          return;
        }

        setProdAwait(true);
        setAwaitReg(true);
      }
    }
  }

  // 요청 등록
  const onSave = async () => {
    setLoading(true);

    let product_serial_number_list = '';
    let is_scan_list = '';
    scanList.forEach((scanItem, index) => {
      if (index !== 0) {
        product_serial_number_list += ',';
        is_scan_list += ',';
      }
      product_serial_number_list += scanItem.barcode;
      is_scan_list += scanItem.is_scan ? '1' : '0';
    });
    var param = {
      request_type: 'A',
      request_status: 'R',
      child_product_code: item.child_product_code,
      product_serial_number_list,
      is_scan_list
    };
    try {
      await RequestService.insertRequest(param);
      awaitOpen();
      confirmAlertOpen();
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 발송 완료
  const sendOpen = () => {
    if (sendReg) {
      setProdSend(false);
      setSendReg(false);
    } else {
      if (!scanList.length) {
        setAlertDec("바코드를 스캔하세요.");
        alertOpen();
      } else {
        // 시리얼넘버 ',' 문자 있는지 확인
        var isOk = false;
        scanList.forEach((scanItem) => {
          if (scanItem.barcode.indexOf(',') > -1) {
            isOk = true;
            return;
          }
        });

        if (isOk) {
          setAlertDec('S/N에 \',\'(콤마) 문자는 사용 할 수 없습니다.');
          alertOpen();
          return;
        }
        setProdSend(true);
        setSendReg(true);
      }
    }
  }

  // 출고 완료 레이어 팝업
  const finishOpen = () => {
    if (finishReg) {
      setFinishReg(false);
    } else {
      setProdSend(false);
      setSendReg(false);
      setFinishReg(true);
    }
  }

  const finishAlertOpen = () => {
    const $body = document.body;

    if (alert) {
      $body.classList.remove("_noscroll");
      setProdAwait(false);
      setAwaitReg(false);
      setProdSend(false);
      setSendReg(false);
      setFinishReg(false);
      setAlert(false);
    } else {
      setConfirm(false);
      setAlert(true);
    }
  }

  // 발송완료 컨펌창 열기/닫기
  const confirmOpen = () => {
    if (scanList.length) {
      setConfirm(!confirm);
    } else {
      if (!scanList.length) {
        setAlertDec("바코드를 입력하세요.");
      }
      alertOpen();
    }
  }

  // 알럿
  const alertOpen = () => {
    setAlert(!alert);
  }

  // 저장 완료 알럿
  const confirmAlertOpen = () => {
    if (confirmAlert) {
      setConfirmAlert(false);
      props.finishOpen();
      props.onSaveCallback();
    } else {
      setConfirmAlert(true);
    }
  }

  useEffect(() => {
    setItem(props.item);
    setScanList(props.scanList);
  }, [props]);

  return (
    <>
      <div className={`load-container${props.tempListReg ? " _open" : ""}`}>
        <button type="button" className="btn-close" onClick={() => Util.modal(props.tempListReg, props.setTempListReg)}>
          <span className="a11y">닫기</span>
        </button>
        <div className="page-stock">
          <div className="prd-list">
            <dl className="prd-dl">
              <dt>
                <div className="tit"><div className="img-box" style={{ backgroundImage: `url(${item ? item.thmbn_file_url : null})` }}></div> {item ? item.product_group : '-'} </div>
                <span className="cnt">{scanList.length}</span>
              </dt>
              <dd>
                <dl className="dl-wrap">
                  <dt>자품목명</dt>
                  <dd dangerouslySetInnerHTML={{
                    __html: item ? item.crm_product_name : '-'
                  }}></dd>
                </dl>
                <dl className="dl-wrap">
                  <dt>규격</dt>
                  <dd dangerouslySetInnerHTML={{
                    __html: item ? item.product_standard : '-'
                  }}></dd>
                </dl>
              </dd>
            </dl>
          </div>

          <dl className="list-dl">
            <dt>목록</dt>
            <dd>
              <ul className="list t3">
                {
                  scanList.map((scanItem, index) => {
                    return (
                      <li key={index}>
                        <div className="col-dec">
                          <strong className="tit" dangerouslySetInnerHTML={{
                            __html: scanItem.barcode
                          }}></strong>
                        </div>
                        <button type="button" className="btn-del" onClick={() => { onDeleteScan(index) }}><span className="a11y">삭제</span></button>
                      </li>
                    );
                  })
                }
              </ul>

              <div className="barcode-wrap t2">
                <span className="barcode" onClick={onClickAddScan}><span className="a11y">바코드</span></span>
              </div>

              <div className="m-main btn-main">
                <ul className="btn-flex">
                  <li><button type="button" className="btn c-sky" onClick={awaitOpen}>발송 대기</button></li>
                  <li><button type="button" className="btn c-sky" onClick={sendOpen}>발송 완료</button></li>
                </ul>
              </div>
            </dd>
          </dl>
        </div>
      </div>

      {/* 공장 AS 요청 완료 레이어 팝업 */}
      <RegFinish3
        item={item}
        prodStatus="as"
        scanList={scanList}
        finishReg={finishReg}
        setFinishReg={setFinishReg}
        finishOpen={finishOpen}
        onSaveCallback={confirmAlertOpen}
      />
      {/* ./공장 AS 요청 완료 레이어 팝업 */}

      {/* 발송 대기 */}
      {
        awaitReg
          ? <Confirm
            dec="공장AS 대기 상태로 저장하시겠습니까?"
            confirm={prodAwait}
            confirmOpen={awaitOpen}
            alertOpen={onSave}
          />
          : null
      }
      {/* ./발송 대기 */}

      {/* 발송 완료 */}
      {
        sendReg
          ? <Confirm
            dec="공장AS 요청하시겠습니까?"
            confirm={prodSend}
            confirmOpen={sendOpen}
            alertOpen={finishOpen}
          />
          : null
      }
      {/* ./발송 완료 */}

      {/* 컨펌창 */}
      <Confirm
        dec="저장하시겠습니까?"
        confirm={confirm}
        confirmOpen={confirmOpen}
        alertOpen={finishAlertOpen}
      />
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      <Alert
        dec="저장되었습니다."
        alert={confirmAlert}
        alertOpen={confirmAlertOpen}
      />
      {/* ./알럿창 */}

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default RegTempList;