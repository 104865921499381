import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Alert from "../../layout/Alert";
import Confirm from "../../layout/Confirm";
import PostCode from "../../layout/PostCode";
import Progress from "../../layout/Progress";
import ServiceService from '../../services/ServiceService';

const StartFinish = (props) => {
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");
  const [isInstall, setIsInstall] = useState(false);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState(false);
  const [serviceSeq, setServiceSeq] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [address, setAddress] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [addressConfirm, setAddressConfirm] = useState(false);

  // 완료 알럿 닫기
  const closeCompleteAlert = () => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
    history.replace('/service/result', { service_seq: serviceSeq });
  }

  // 알럿 열기/닫기
  const alertOpen = () => {
    if (alert) {
      setAlert(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      }
    } else {
      setAlert(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setAlert(false);
      }
    }
  }

  // 서비스 완료 팝업 확인버튼 핸들러 
  const onClickConfirm = () => {
    if (serviceType === '01') {
      if (!isInstall) {
        setAlertDec('신규 설치 여부를 체크하세요.');
        alertOpen();
        return;
      }
    }
    confirmOpen();
  }

  // 주소 검색 레이어 팝업
  const postOpen = () => {
    if (post) {
      setPost(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
        value: 'N'
      }, '');
    } else {
      setPost(true);
      window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
        value: 'Y'
      }, '');
    }
  };

  // 주소 검색 결과
  const postResult = (val, zip, bcode) => {
    setAddress(val);
    setAreaCode(bcode);
  };

  // 이전설치 주소확인
  const onAddressConfirm = () => {
    if (!areaCode || !address) {
      setAlertDec('주소를 검색하세요.');
      alertOpen();
      return;
    }
    addressConfirmOpen();
  }

  // 완료컨펌 확인 처리
  const confirmAlertOpen = () => {
    confirmOpen();
    props.complete();
  }

  // 이전철거 주소확인 완료컨펌 확인 처리
  const addressConfirmAlertOpen = async () => {
    if (confirm) {
      setConfirm(false);
      setLoading(true);
      // 이전 주소 등록
      let param = {
        relocate_address: address,
        relocate_areacode: areaCode
      };
      try {
        await ServiceService.updateServiceInfo(props.serviceInfo.service_seq, param);
        props.complete();
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  // 완료컨펌 열기/닫기
  const confirmOpen = () => {
    if (confirm) {
      setConfirm(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        props.setExitConfirm(true);
      }
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setConfirm(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          props.setExitConfirm(true);
        }
      }
      setConfirm(true);
    }
  }

  // 이전철거 주소확인 완료컨펌 열기/닫기
  const addressConfirmOpen = () => {
    if (confirm) {
      setAddressConfirm(false);
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        props.setExitConfirm(true);
      }
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setAddressConfirm(false);
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          props.setExitConfirm(true);
        }
      }
      setAddressConfirm(true);
    }
  }

  useEffect(() => {
    if (props.serviceInfo) {
      setServiceSeq(props.serviceInfo.service_seq);
      setServiceType(props.serviceInfo.service_type);
      setAddress(props.serviceInfo.relocate_address);
      if (props.serviceInfo.relocate_address) {
        let geocoder = new window.kakao.maps.services.Geocoder();
        geocoder.addressSearch(props.serviceInfo.relocate_address, (result, status) => {
          if (status === window.kakao.maps.services.Status.OK) {
            setAreaCode(result[0].address.b_code);
          }
        });
      }
    }
  }, [props.serviceInfo])

  return (
    <>
      <div className={`load-container _t2${props.finish ? " _open" : ""}`}>
        <div data-selector="load" className="load-wrap">
          <section className="sec sec-box reg-box pop-unit-cnt">
            <dl className="reg-container">
              <dt>
                <span className="a11y">설치를 완료</span>
                <button type="button" className="btn-close" onClick={props.finishOpen}><span className="a11y">닫기</span></button>
              </dt>
              <dd>
                <header>
                  {
                    serviceType === '01' &&   // 설치
                    <strong className="tit">수고하셨습니다. <br />신규 설치를 완료하셨습니까?</strong>
                  }
                  {
                    serviceType === '03' &&   // 철거
                    <strong className="tit">수고하셨습니다. <br />철거 후 제품을 수거하셨습니까?</strong>
                  }
                  {
                    serviceType === '05' &&   // 이전철거
                    <strong className="tit">수고하셨습니다. <br />이전철거를 완료하시겠습니까?</strong>
                  }
                </header>
                {
                  serviceType === '01' || serviceType === '03' ?
                    <div className="box t2">
                      {
                        serviceType === '01' &&   // 설치
                        <label className="chk-label">
                          <input type="checkbox" onChange={(event) => setIsInstall(event.target.checked)} checked={isInstall} />
                          <span className="txt">신규 설치</span>
                        </label>
                      }
                      {
                        serviceType === '03' &&   // 철거
                        <label className="chk-label">
                          <input type="checkbox" onChange={(event) => props.setIsDemolition(event.target.checked)} checked={props.isDemolition} />
                          <span className="txt">수거완료</span>
                        </label>
                      }
                    </div>
                    :
                    <></>
                }

                {
                  serviceType === '01' &&   // 설치
                  <div className="tip">
                    <p>신규 설치가 아닌 <br />AS나 이전설치의 경우 서비스 단계에서 <br /> 서비스 유형을 변경해주세요.</p>
                  </div>
                }
                {
                  serviceType === '03' &&   // 철거
                  <div className="tip">
                    <p>철거 제품을 수거하지 않는 경우 <br />위 박스를 체크하지 말고 확인을 누르세요. <br /> 렌탈 고객은 제품 수거가 필요합니다.</p>
                  </div>
                }
                {
                  serviceType === '05' &&   // 이전철거
                  <>
                    <div className="tip t2">
                      <p>
                        이전설치 주소를 확인해주세요.<br />주소확인 시 이전 설치점에<br />자동 배정됩니다.
                      </p>
                    </div>
                    <label className="search-label">
                      <input type="text" placeholder="주소 검색" value={address} onChange={event => setAddress(event.target.value)} />
                      <button type="button" className="btn-search" data-action="search" onClick={postOpen}><span className="a11y">검색</span></button>
                    </label>
                  </>
                }
              </dd>
            </dl>

            {
              serviceType === '05' ?   // 이전철거
                <ul className="btn-flex">
                  <li><button type="button" className="btn c-blue" onClick={onClickConfirm}>이전설치 보류</button></li>
                  <li><button type="button" className="btn c-sky" onClick={onAddressConfirm}>주소확인</button></li>
                </ul>
                :
                <ul className="btn-flex t2">
                  <li className="col1"><button type="button" className="btn c-gray" onClick={props.finishOpen}>취소</button></li>
                  <li className="col2"><button type="button" className="btn c-sky" onClick={onClickConfirm}>확인</button></li>
                </ul>
            }
          </section>
        </div>
      </div>

      {/* 주소 검색 레이어 팝업 */}
      <PostCode
        post={post}
        address={address}
        postResult={postResult}
        postOpen={postOpen}
      />
      {/* ./주소 검색 레이어 팝업 */}

      {/* 완료 알럿창 */}
      <Alert
        dec="완료되었습니다."
        alert={props.isCompleteOpen}
        alertOpen={closeCompleteAlert}
      />
      {/* ./완료 알럿창 */}

      {/* 확인 컨펌창 */}
      <Confirm
        dec="서비스를 완료하시겠습니까?"
        confirm={confirm}
        confirmOpen={confirmOpen}
        alertOpen={confirmAlertOpen}
      />
      {/* ./확인 컨펌창 */}

      {/* 이전철거 주소확인 컨펌창 */}
      <Confirm
        dec="서비스를 완료하시겠습니까?"
        confirm={addressConfirm}
        confirmOpen={addressConfirmOpen}
        alertOpen={addressConfirmAlertOpen}
      />
      {/* ./이전철거 주소확인 컨펌창 */}

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
      {/* ./알럿창 */}

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default StartFinish;