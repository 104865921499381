import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import MainService from '../../services/MainService';
import Progress from "../../layout/Progress";
import Util from '../../helpers/Util';
import Alert from "../../layout/Alert";

const Home = () => {
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emergency, setEmergency] = useState(0);
  const [notProceed, setNotProceed] = useState(0);
  const [delayInstall, setDelayInstall] = useState(0);
  const [delayAs, setDelayAs] = useState(0);
  const [todayInstall, setTodayInstall] = useState(0);
  const [todayAs, setTodayAs] = useState(0);
  const [newInstall, setNewInstall] = useState(0);
  const [newAs, setNewAs] = useState(0);
  const [notice, setNotice] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertDec, setAlertDec] = useState("");

  // 메인 정보 조회
  const searchInfo = useCallback(async () => {
    setLoading(true);
    try {
      const result = await MainService.fetchMainInfoInfo();
      const data = result.data;
      setEmergency(data.emergency);
      setNotProceed(data.not_proceed);
      setDelayInstall(data.delay_install);
      setDelayAs(data.delay_as);
      setTodayInstall(data.today_install);
      setTodayAs(data.today_as);
      setNewInstall(data.new_install);
      setNewAs(data.new_as);
      setNotice(data.last_notice);
    } catch (error) {
      if (error.response) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } else {
        setAlertDec('비정상적인 오류가 발생하였습니다. 네트워크를 확인하고 앱을 완전히 종료후 재실행하세요. 재실행 후에도 오류 발생시 관리자에게 문의바랍니다.')
        setAlert(true);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // 서비스 종합 
  const onGoServiceList = () => {
    history.push({
      pathname: '/service/list',
      state: {
        search_date_type: 'C',
        search_date_division: 'C',
        search_date: '',
        counseling_type: '',
        service_counseling_type: '',
        service_type: '',
        service_status: '',
        is_emergency: '',
        is_manager: '',
        is_approval: '',
        search_word: ''
      }
    });
  }

  // 긴급 서비스 클릭 핸들러
  const onEmergency = () => {
    history.push('/service/list', {
      service_status: 'N,P',
      is_emergency: '1',
    });
  }

  // 미입력 서비스(담당자 미배정) 클릭 핸들러
  const onNotProceed = () => {
    history.push('/service/list', {
      search_date_type: 'C',
      search_date_division: 'C',
      search_date: '',
      counseling_type: '',
      service_counseling_type: '00',
      service_type: '',
      service_status: 'N',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 지연된 서비스 (설치)
  const onDelayInstall = () => {
    history.push('/service/list', {
      search_date_type: 'V',
      search_date_division: 'B',
      search_date: Util.addDaysDt('YYYY-MM-DD', -1),
      counseling_type: '03',
      service_counseling_type: '00,01,02,03',
      service_type: '',
      service_status: 'N,P',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 지연된 서비스 (AS)
  const onDelayAs = () => {
    history.push('/service/list', {
      search_date_type: 'V',
      search_date_division: 'B',
      search_date: Util.addDaysDt('YYYY-MM-DD', -1),
      counseling_type: '04,05,06,08,09',
      service_counseling_type: '00,01,02,03',
      service_type: '',
      service_status: 'N,P',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 오늘의 스케줄 (설치)
  const onTodayInstall = () => {
    history.push('/service/list', {
      search_date_type: 'V',
      search_date_division: 'C',
      search_date: Util.todayDt('YYYY-MM-DD'),
      counseling_type: '03',
      service_counseling_type: '',
      service_type: '',
      service_status: 'N,P',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 오늘의 스케줄 (AS)
  const onTodayAs = () => {
    history.push('/service/list', {
      search_date_type: 'V',
      search_date_division: 'C',
      search_date: Util.todayDt('YYYY-MM-DD'),
      counseling_type: '04,05,06,08,09',
      service_counseling_type: '',
      service_type: '',
      service_status: 'N,P',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 신규 배정 (설치)
  const onNewInstall = () => {
    history.push('/service/list', {
      search_date_type: 'C',
      search_date_division: 'C',
      search_date: Util.todayDt('YYYY-MM-DD'),
      counseling_type: '03',
      service_counseling_type: '',
      service_type: '',
      service_status: '',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 신규 배정 (AS)
  const onNewAs = () => {
    history.push('/service/list', {
      search_date_type: 'C',
      search_date_division: 'C',
      search_date: Util.todayDt('YYYY-MM-DD'),
      counseling_type: '04,05,06,08,09',
      service_counseling_type: '',
      service_type: '',
      service_status: '',
      is_emergency: '',
      is_manager: '',
      is_approval: '',
      search_word: ''
    });
  }

  // 알럿
  const alertOpen = useCallback(() => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }, [alert]);

  useEffect(() => {
    searchInfo();
  }, [searchInfo])

  useEffect(() => {
    if (history.length > 1) {
      history.replace('/home', null);
    } else {
      /* 바로가기 설정 */
      if (auth.user) {
        // 공지사항
        const noticeSeq = window.localStorage.getItem('notice_seq');
        if (noticeSeq) {
          window.localStorage.removeItem('notice_seq');
          history.push('/board/notice', {
            notice_seq: noticeSeq
          });
        }
        // 서비스
        const serviceSeq = window.localStorage.getItem('service_seq');
        const serviceStatus = window.localStorage.getItem('service_status');
        if (serviceSeq) {
          window.localStorage.removeItem('service_seq');
          window.localStorage.removeItem('service_status');
          if (serviceStatus === 'C') {
            history.push("/service/result", {
              service_seq: serviceSeq
            });
          } else {
            history.push("/service/list/info", {
              service_seq: serviceSeq
            });
          }
        }
      }
    }
  }, [history, auth.user])

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      searchInfo();
    }
  }, [searchInfo]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_BACK_KEY, {
      value: 'N'
    }, '');
  }, []);

  return (
    <>
      <div className="page-main">
        <section className="sec sec1 flex">
          <dl className={`col ` + (emergency > 0 ? `col1` : `col2`)}>
            <dt>긴급 서비스</dt>
            <dd>
              <button type="button" className="round" onClick={onEmergency}>
                <span className="emergency">긴급</span>
                <p className="cnt"><strong>{Util.formatNumber(emergency)}</strong>건</p>
              </button>
            </dd>
          </dl>
          <dl className={`col ` + (notProceed > 0 ? `col1` : `col2`)}>
            <dt>담당자 미배정</dt>
            <dd>
              <button type="button" className="round" onClick={onNotProceed}>
                <span className="not">미입력</span>
                <p className="cnt"><strong>{Util.formatNumber(notProceed)}</strong>건</p>
              </button>
            </dd>
          </dl>
        </section>

        <section className="sec sec2">
          <dl>
            <dt>지연된 서비스</dt>
            <dd>
              <ul className="flex">
                <li>
                  <button type="button" className={`box ` + (delayInstall <= 0 && `_disable`)} onClick={onDelayInstall}>
                    <span className="txt">설치</span>
                    <strong className="cnt">{Util.formatNumber(delayInstall)} <small>건</small></strong>
                  </button>
                </li>
                <li>
                  <button type="button" className={`box ` + (delayAs <= 0 && `_disable`)} onClick={onDelayAs}>
                    <span className="txt">AS</span>
                    <strong className="cnt">{Util.formatNumber(delayAs)} <small>건</small></strong>
                  </button>
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>오늘의 스케줄</dt>
            <dd>
              <ul className="flex">
                <li>
                  <button type="button" className={`box ` + (todayInstall <= 0 && `_disable`)} onClick={onTodayInstall}>
                    <span className="txt">설치</span>
                    <strong className="cnt">{Util.formatNumber(todayInstall)} <small>건</small></strong>
                  </button>
                </li>
                <li>
                  <button type="button" className={`box ` + (todayAs <= 0 && `_disable`)} onClick={onTodayAs}>
                    <span className="txt">AS</span>
                    <strong className="cnt">{Util.formatNumber(todayAs)} <small>건</small></strong>
                  </button>
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>신규 배정</dt>
            <dd>
              <ul className="flex">
                <li>
                  <button type="button" className={`box ` + (newInstall <= 0 && `_disable`)} onClick={onNewInstall}>
                    <span className="txt">설치</span>
                    <strong className="cnt">{Util.formatNumber(newInstall)} <small>건</small></strong>
                  </button>
                </li>
                <li>
                  <button type="button" className={`box ` + (newAs <= 0 && `_disable`)} onClick={onNewAs}>
                    <span className="txt">AS</span>
                    <strong className="cnt">{Util.formatNumber(newAs)} <small>건</small></strong>
                  </button>
                </li>
              </ul>
            </dd>
          </dl>
        </section>

        <section className="sec sec3">
          <div className="btn-wrap" >
            <button className="btn c-blue-line s2 w100" onClick={onGoServiceList}>서비스 종합 조회</button>
          </div>
          {
            user && user.app_user_grade !== 'A' &&
            <ul className="btn-flex">
              <li><Link to="/stock/list" className="btn c-sky s2">재고 관리</Link></li>
              <li><Link to="/order/list" className="btn c-sky s2">제품 발주</Link></li>
            </ul>
          }
        </section>

        <section className="sec sec4">
          <div className="notice-container">
            {
              notice
                ?
                <Link to="/board/notice" className="ellip txt" dangerouslySetInnerHTML={{
                  __html: '[공지] ' + notice.title
                }}></Link>
                :
                <span className="ellip txt">공지사항이 없습니다.</span>
            }
          </div>
        </section>
      </div>

      <Progress
        loading={loading}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />

      {/* 알럿창 */}
      <Alert
        dec={alertDec}
        alert={alert}
        alertOpen={alertOpen}
      />
    </>

  )
}

export default Home;