import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Notice from "../pages/board/Notice";

function NoticeRouter() {
  return (
    <Switch>
      <PrivateRoute path="/board/notice" component={Notice} />
      <Redirect path="/board/*" to="/board/notice" />
    </Switch>
  );
}

export default NoticeRouter;
