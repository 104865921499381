import Service from './Index';

const UserService = {
  // 프로필 정보 조회
  fetchItems: () => {
    return Service.get('user');
  },
  // 비밀번호 변경
  updatePassword: (param) => {
    return Service.put('user/updateUserPassword', param);
  },
  // 프로필 이미지 변경
  updateProfile: (FormData) => {
    return Service.put('user/updateUserProfile', FormData);
  },
  // 회원가입
  joinUserInfo: (param) => {
    return Service.post('user/join', param);
  },
};

export default UserService;
