import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import Home from "../pages/home/Home";

function HomeRouter() {
  return (
    <Switch>
      <PrivateRoute path="/home" component={Home} />
      <Redirect path="/home/*" to="/home" />
    </Switch>
  );
}

export default HomeRouter;
