import Service from './Index';

const RequestService = {
  // 요청 리스트 조회
  fetchItems: (param) => {
    return Service.get('request', param);
  },

  // 요청 삭제 
  deleteRequest: (request_seq, param) => {
    return Service.delete('request/' + request_seq, param);
  },

  // 배송 완료 
  completeRequest: (param) => {
    return Service.post('request/complete', param);
  },

  // 요청 등록
  insertRequest: (param) => {
    return Service.post('request', param);
  },

  // 요청 등록(배송 완료) 
  insertCompleteRequest: (param) => {
    return Service.post('request/insertComplete', param);
  },











};

export default RequestService;

