import { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../AppContext';
import Progress from "../../layout/Progress";

const Login = () => {
  const history = useHistory();
  const { auth } = useContext(AppContext);

  useEffect(() => {
    if (window.nativeApi.isIOS) {
      window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
      window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
        if (!response.user_seq) {
          auth.logout();
        } else {
          history.push('/home');
        }
      }
    }
  }, [auth, history]);

  return (
    <>
      <Progress
        loading={true}
        fixed={true} // 화면 전체 레이어로 덮는 형태
      />
    </>
  )
}

export default Login;