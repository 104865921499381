import React, { useState, useEffect } from "react";
import OrderService from '../../services/OrderService';
import ReqProductReg from "../../layout/order/ReqProductReg";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";
import Confirm from "../../layout/Confirm";
import Alert from "../../layout/Alert";

const OrderReg = (props) => {
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [productCode, setProductCode] = useState("");  // 품목코드
  const [usage, setUsage] = useState(""); // 용도
  const [isFree, setIsFree] = useState(""); // 매입/위탁
  const [productName, setProductName] = useState("");  // 품목명
  const [standard, setStandard] = useState("");  // 규격
  const [supplyPrice, setSupplyPrice] = useState(0);  // 공급가
  const [quantity, setQuantity] = useState(0);  // 수량
  const [remark, setRemark] = useState("");  // 비고
  const [prodReq, setProdReq] = useState(false);
  const [productList, setProductList] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [valid, setValid] = useState(false);
  const [confirmDec, setConfirmDec] = useState("");
  const [alertDec, setAlertDec] = useState("");

  const onClickSave = () => {
    setAlertDec('');
    if (!usage) {
      setAlertDec('용도를 선택하세요');
      setAlert(true);
      setValid(false);
      return
    }
    if (!isFree) {
      setAlertDec('매입/위탁을 선택하세요');
      setAlert(true);
      setValid(false);
      return
    }
    if (!productName) {
      setAlertDec('품목명을 입력하세요');
      setAlert(true);
      setValid(false);
      return
    }
    if (!quantity && quantity < 1) {
      setAlertDec('수량을 입력하세요');
      setAlert(true);
      setValid(false);
      return
    }

    setValid(true);
    confirmOpen();
  }

  const confirmOpen = () => {
    setConfirmDec('저장하시겠습니까?');
    if (confirm) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }

  const alertOpen = () => {
    setAlertDec('');
    setAlertDec('저장되었습니다.');
    if (alert) {
      setAlert(false);
      if (valid) {
        onSaveOrder();
      }
    } else {
      setConfirm(false);
      setAlert(true);
    }
  }

  // 제품 발주 요청
  const onSaveOrder = async () => {
    setLoading(true);
    const param = {
      child_product_code: productCode,
      is_free: isFree,
      order_usage_type: usage,
      quantity: quantity,
      remark: remark
    };
    try {
      const result = await OrderService.regOrder(param);
      if (result.result === 0) {
        document.body.classList.remove("_noscroll");
        props.orderRegOpen();
        props.onListUpdate();
      }
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 품목 검색 레이어 팝업
  const reqOpen = (event, selectedList) => {
    const $body = document.body;

    if (selectedList) {
      setProductList(selectedList);
    }

    if (prodReq) {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        props.orderRegOpen();
      }
      $body.classList.remove("_noscroll");
      setProdReq(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
          isLayer: true
        }, window.NativeApiCallbackMethod.IS_LAYER);
        window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
          props.orderRegOpen();
        }
        $body.classList.remove("_noscroll");
        setProdReq(false);
      }
      $body.classList.add("_noscroll");
      setProdReq(true);
    }
  }

  // 품목 검색 레이어 팝업 완료 버튼 핸들러
  const onClickCompleteAddProduct = (selectedList) => {
    const $body = document.body;
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: true
    }, window.NativeApiCallbackMethod.IS_LAYER);
    window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
      props.orderRegOpen();
    }
    $body.classList.remove("_noscroll");
    setProductList(selectedList);
    setProdReq(false);
  }

  useEffect(() => {
    let prodList = productList[0];
    if (productList.length && prodList.quantity) {
      setProductCode(prodList.child_product_code);
      setProductName(Util.unescapeHtml(prodList.crm_product_name));
      setSupplyPrice(prodList.supply_price);
      setQuantity(prodList.quantity);
      setStandard(prodList.product_standard);
    } else {
      setProductCode('');
      setProductName('');
      setSupplyPrice(0);
      setQuantity(0);
      setStandard('');
    }
  }, [productList]);

  return (
    <>
      <div className={`load-container${props.orderReg ? " _open" : ""}`}>
        <p className="page-tit">제품발주 요청</p>
        <button type="button" className="btn-close" onClick={() => Util.modal(props.orderReg, props.setOrderReg)}>
          <span className="a11y">닫기</span>
        </button>

        <div className="page-stock">
          <div className="order-wrap">
            <div className="sn-wrap">
              <dl className="sn-dl col1">
                <dt><strong className="req">용도</strong></dt>
                <dd>
                  <select name="order_usage_type_name" onChange={(event) => { setUsage(event.target.value) }} value={usage}>
                    <option value="">선택</option>
                    <option value="I">설치</option>
                    <option value="A">AS</option>
                  </select>
                </dd>
              </dl>
              <dl className="sn-dl col2">
                <dt><strong className="req">매입/위탁</strong></dt>
                <dd>
                  <select name="is_free" onChange={(event) => { setIsFree(event.target.value) }} value={isFree}>
                    <option value="">선택</option>
                    <option value="0">매입</option>
                    <option value="1">위탁</option>
                  </select>
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong className="req">품목명</strong></dt>
                <dd>
                  <label className="search-label">
                    <input type="text" value={productName && Util.unescapeHtml(productName)} readOnly />
                    <button type="button" className="btn-search" onClick={reqOpen}><span className="a11y">검색</span></button>
                  </label>
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong>규격</strong></dt>
                <dd>
                  <input type="text" value={standard && standard} readOnly />
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong>공급가</strong></dt>
                <dd>
                  <label className="txt-label">
                    <input type="text" value={supplyPrice ? Util.formatNumber(supplyPrice) : '0'} readOnly />
                    <span className="bar">원</span>
                  </label>
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong className="req">수량</strong></dt>
                <dd>
                  <input type="number" pattern="\d*" value={quantity} maxLength="4"
                    onKeyUp={(event) => Util.onlyNumber(event)}
                    onChange={(event) => { Util.numberMaxLength(event); setQuantity(event.target.value) }}
                    readOnly
                  />
                </dd>
              </dl>
            </div>

            <div className="sn-wrap">
              <dl className="sn-dl">
                <dt><strong>비고</strong></dt>
                <dd>
                  <textarea placeholder="내용을 입력하세요."
                    onChange={(event) => { Util.numberMaxLength(event); setRemark(event.target.value) }}
                    value={remark} maxLength="1000" autoCapitalize="off"
                  ></textarea>
                </dd>
              </dl>
            </div>

            <ul className="btn-flex t2">
              <li className="col1"><button type="button" className="btn c-gray" onClick={() => Util.modal(props.orderReg, props.setOrderReg)}>취소</button></li>
              <li className="col2"><button type="button" className="btn c-sky" onClick={onClickSave}>저장</button></li>
            </ul>
          </div>
        </div>
      </div>

      {/* 소요부품 검색 레이어 팝업 */}
      {
        prodReq &&
        <ReqProductReg
          prodReq={prodReq}
          reqOpen={reqOpen}
          productList={productList}
          complete={onClickCompleteAddProduct}
        />
      }
      {/* ./소요부품 검색 레이어 팝업 */}

      {
        loading &&
        <Progress
          loading={loading}
          fixed={true} // 화면 전체 레이어로 덮는 형태
        />
      }

      {/* 컨펌창 */}
      {
        confirm &&
        <Confirm
          dec={confirmDec}
          confirm={confirm}
          confirmOpen={confirmOpen}
          alertOpen={alertOpen}
        />
      }
      {/* ./컨펌창 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }
    </>
  )
}

export default OrderReg;