import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import ServiceService from '../../services/ServiceService'
import StockService from '../../services/StockService'
import FilterItem from "../../layout/FilterItem";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const ReceivingList = () => {
  const history = useHistory();
  const location = useLocation();
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", "", ""]);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState(false);
  const [productGroupList, setProductGroupList] = useState([]); // 품목군 리스트
  const [modelList, setModelList] = useState([]); // 모델 리스트
  const [productList, setProductList] = useState([]); // 제품 리스트
  const [assyList, setAssyList] = useState([]); // 아세이 리스트
  const [partList, setPartList] = useState([]); // 부품 리스트

  // 검색버튼 클릭 핸들러
  const onClickSearch = () => {
    setCurrentPage(1);
  }

  // 품목군 리스트 조회
  const searchProductGroupList = useCallback(async () => {
    // setLoading(true);
    const param = {};
    try {
      const result = await ServiceService.fetchProductGroups(param);
      const data = result.data;
      setProductGroupList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, []);

  // 모델명 리스트 조회
  const searchModelList = useCallback(async () => {
    // setLoading(true);
    const param = {
      product_group: location.state ? location.state.query_product_group : '',
      level: 0
    };
    try {
      const result = await ServiceService.fetchParentProducts(param);
      const data = result.data;
      setModelList(data.list);
    } catch (error) {
      const result = error.response.data.result;
      const message = error.response.data.message;
      if (result < 0) {
        console.error(message);
      }
    } finally {
      // setLoading(false);
    }
  }, [location]);

  // 제품명 리스트 조회
  const searchProductList = useCallback(async () => {
    if (location.state && location.state.query_model_name) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.query_product_group : '',
        upper_child_product_code: location.state ? location.state.query_model_name : '',
        level: 1
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setProductList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 아세이 리스트 조회
  const searchAssyList = useCallback(async () => {
    if (location.state && location.state.query_product_name) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.query_product_group : '',
        parent_product_code: location.state ? location.state.query_model_name : '',
        upper_child_product_code: location.state ? location.state.query_product_name : '',
        level: 2
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setAssyList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 부품 리스트 조회
  const searchPartList = useCallback(async () => {
    if (location.state && location.state.query_asei_name) {
      // setLoading(true);
      const param = {
        product_group: location.state ? location.state.query_product_group : '',
        parent_product_code: location.state ? location.state.query_model_name : '',
        upper_child_product_code: location.state ? location.state.query_asei_name : '',
        level: 3
      };
      try {
        const result = await ServiceService.fetchChildProducts(param);
        const data = result.data;
        setPartList(data.list);
      } catch (error) {
        const result = error.response.data.result;
        const message = error.response.data.message;
        if (result < 0) {
          console.error(message);
        }
      } finally {
        // setLoading(false);
      }
    }
  }, [location]);

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize,
      incoming_status: "R"
    };
    Object.assign(param, location.state);
    try {
      const result = await StockService.fetchReleaseAllItems(param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 출고시작일
  const onChangeStartDate = (event) => {
    history.replace({
      pathname: '/stock/release/list',
      state: {
        query_start_release: Util.formatDate(event) || '', //출고시작일
        query_end_release: location.state.query_end_release || '', //출고종료일
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '',
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 출고종료일
  const onChangeEndDate = (event) => {
    history.replace({
      pathname: '/stock/release/list',
      state: {
        query_start_release: location.state.query_start_release, //출고시작일
        query_end_release: Util.formatDate(event) || '', //출고종료일
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '',
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);
    let locationState = location.state || {};

    if (idx === 0) { //시작, 종료일
      locationState.query_start_release = '';
      locationState.query_end_release = '';
    }
    if (idx === 1) { // 품목군
      locationState.query_product_group = '';
    }
    if (idx === 2) { // 단계
      locationState.query_level = '';
    }
    if (idx === 3) { // 모델
      locationState.query_model_name = '';
    }
    if (idx === 4) { // 제품
      locationState.query_product_name = '';
    }
    if (idx === 5) { // 아세이
      locationState.query_asei_name = '';
    }
    if (idx === 6) { // 부품
      locationState.query_part_name = '';
    }
    if (idx === 7) { // 키워드
      locationState.query_release_keyword = '';
    }

    if (idx === 5) {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: locationState.query_product_name || '',
          query_asei_name: locationState.query_asei_name || '',
          query_part_name: '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    } else if (idx === 4) {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: locationState.query_product_name || '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    } else if (idx === 3) {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: locationState.query_start_release || '',
          query_end_release: locationState.query_end_release || '',
          query_product_group: locationState.query_product_group || '',
          query_level: locationState.query_level || '',
          query_model_name: locationState.query_model_name || '',
          query_product_name: locationState.query_product_name || '',
          query_asei_name: locationState.query_asei_name || '',
          query_part_name: locationState.query_part_name || '',
          query_release_keyword: locationState.query_release_keyword || ''
        }
      });
    }
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    history.replace({
      pathname: '/stock/release/list',
      state: {
        query_start_release: '', //요청시작일
        query_end_release: '', //요청종료일
        query_product_group: '', //품목군
        query_level: '', //단계(레벨)
        query_model_name: '', //모델명
        query_product_name: '', //제품명
        query_asei_name: '', //아세이명
        query_part_name: '', //부품명
        query_release_keyword: '' //키워드
      }
    });
  }, [history]);

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.query_release_keyword = event.target.value;

    history.replace({
      pathname: '/stock/release/list',
      state: {
        query_start_release: location.state.query_start_release || '', //출고시작일
        query_end_release: location.state.query_start_release || '', //출고종료일
        query_product_group: location.state.query_product_group || '', //품목군
        query_level: location.state.query_level || '',
        query_model_name: location.state.query_model_name || '', //모델명
        query_product_name: location.state.query_product_name || '', //제품명
        query_asei_name: location.state.query_asei_name || '', //아세이명
        query_part_name: location.state.query_part_name || '', //부품명
        query_release_keyword: location.state.query_release_keyword || '' //키워드
      }
    });
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};

    let name = '';

    switch (event.target.name) {
      case 'product_group':
        name = 'query_product_group';
        break;
      case 'level':
        name = 'query_level';
        break;
      case 'model_code':
        name = 'query_model_name';
        break;
      case 'product_code':
        name = 'query_product_name';
        break;
      case 'assy_code':
        name = 'query_asei_name';
        break;
      case 'part_code':
        name = 'property';
        break;
      default:
        break;
    }

    locationState[name] = event.target.value;

    if (name === 'product_group') {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: '',
          query_product_name: '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else if (name === 'assy_code') {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: location.state.query_product_name || '',
          query_asei_name: location.state.query_asei_name || '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else if (name === 'product_code') {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: location.state.query_product_name || '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else if (name === 'model_code') {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: '',
          query_asei_name: '',
          query_part_name: '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    } else {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: location.state.query_start_release || '',
          query_end_release: location.state.query_end_release || '',
          query_product_group: location.state.query_product_group || '',
          query_level: location.state.query_level || '',
          query_model_name: location.state.query_model_name || '',
          query_product_name: location.state.query_product_name || '',
          query_asei_name: location.state.query_asei_name || '',
          query_part_name: location.state.query_part_name || '',
          query_release_keyword: location.state.query_release_keyword || ''
        }
      });
    }
  }

  useEffect(() => {
    searchProductGroupList(); // 품목군 리스트 조회
    searchModelList();  // 모델 리스트 조회
    searchProductList();  // 제품 리스트 조회 
    searchAssyList(); // 아세이 리스트 조회
    searchPartList(); // 부품 리스트 조회
  }, [searchProductGroupList, searchModelList, searchProductList, searchAssyList, searchPartList]);

  // 목록 상세 보기
  const detailOpen = (event) => {
    const $over = document.querySelectorAll(".over-container");
    const $overItem = event.target.closest(".over-container");
    const $open = event.target.closest("._open");

    $over.forEach((item) => {
      item.classList.remove("_open");
    });

    if ($open) {
      $overItem.classList.remove("_open");
    } else {
      $overItem.classList.add("_open");
    }
  }

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(thisState => {
            return thisState + 1
          });
        }
      }
    }
  }, [loading, totalCount, currentPage]);

  // 스크롤 최상단으로 이동
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      const scrollT = ('scroll', e.srcElement.scrollingElement.scrollTop);
      setScroll({ scrollT });

      onMore(); // 더보기
    }
  }, [setScroll, onMore]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/stock/release/list',
        state: {
          query_start_release: '', //출고시작일
          query_end_release: '', //출고종료일
          query_product_group: '', //품목군
          query_level: '', //레벨
          query_model_name: '', //모델명
          query_product_name: '', //제품명
          query_asei_name: '', //아세이명
          query_part_name: '', //부품명
          query_release_keyword: '' //키워드
        }
      });
    }

    if (state) {
      const startIncoming = state.query_start_release;
      const endIncoming = state.query_end_release;
      const productGroup = state.query_product_group;
      const level = state.query_level;
      const modelCode = state.query_model_name;
      const productCode = state.query_product_name;
      const assyCode = state.query_asei_name;
      const partCode = state.query_part_name;
      const releaseKeyword = state.query_release_keyword;

      // 출고일
      if (startIncoming && endIncoming) {
        let receiveDate = '';
        receiveDate = `출고일 ${startIncoming + ' ~ ' + endIncoming}`;

        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = receiveDate;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[0] = '';
          return state;
        });
      }

      // 품목군
      if (productGroup) {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = location.state.query_product_group;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[1] = '';
          return state;
        });
      }

      // 단계
      if (level) {
        let levelName = '';

        switch (level) {
          case '1':
            levelName = '제품';
            break;
          case '2':
            levelName = '아세이';
            break;
          case '3':
            levelName = '부품';
            break;
          default:
            levelName = '';
            break;
        }

        setSearchTag(thisState => {
          let state = [...thisState];
          state[2] = levelName;
          return state;
        });
      } else {
        setSearchTag(thisState => {
          let state = [...thisState];
          state[2] = '';
          return state;
        });
      }

      // 모델
      if (modelCode) {
        const $model_code = document.querySelector("[name=model_code]");

        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $model_code.options[$model_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 제품
      if (productCode) {
        const $product_code = document.querySelector("[name=product_code]");

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $product_code.options[$product_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 아세이
      if (assyCode) {
        const $assy_code = document.querySelector("[name=assy_code]");

        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $assy_code.options[$assy_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 부품
      if (partCode) {
        const $part_code = document.querySelector("[name=part_code]");

        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = $part_code.options[$part_code.selectedIndex].text;
          return state;
        });

      } else {
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 키워드
      if (releaseKeyword) {
        setKeyword(releaseKeyword);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[7] = releaseKeyword;
          return state;
        });
      } else {
        setKeyword(releaseKeyword);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[7] = '';
          return state;
        });
      }
    }

  }, [location, history, modelList, productList, assyList, partList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, '');
  }, []);

  return (
    <>
      <div className="page-stock">
        <div className="top-filter">
          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>
          <button type="button" className="btn c-black-line" onClick={() => Util.modal(filter, setFilter)}><span className="txt ft">필터</span></button>

          <div className={`filter-container ${filter ? '_openFilter' : null}`}>
            <header className="filter-header flex">
              <strong className="tit">필터</strong>
              <nav>
                <button type="button" className="btn c-black-line" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
                <button type="button" className="btn-close" onClick={() => Util.modal(filter, setFilter)}><span className="a11y">닫기</span></button>
              </nav>
            </header>


            <div className="select-container" data-selector="selectContainer">
              {
                searchTag.join("") === ""
                  ? <p className="dec">선택 필터가 없습니다.</p>
                  : (
                    searchTag.map((item, index) => {
                      return (
                        item
                          ? (
                            <span className="tag" data-selector="tag" key={index}>
                              {item}
                              <button type="button" className="del" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                            </span>
                          ) : null
                      );
                    })
                  )
              }
            </div>

            <div className="dl-wrap t2">
              <dl className="dl-item">
                <dt>출고일</dt>
                <dd className="flex">
                  <div className="col">
                    <Datetime
                      inputProps={{
                        id: "startDate",
                        name: "startDate",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      onChange={onChangeStartDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.query_start_release} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                  <div className="col">
                    <Datetime
                      className={(location.state && location.state.query_start_release) ? "" : "disabled"}
                      inputProps={{
                        id: "endDate",
                        name: "endDate",
                        readOnly: true,
                        disabled: (location.state && location.state.query_start_release) ? false : true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      isValidDate={(event) => Util.validDt(event, location.state && location.state.query_start_release)}
                      onChange={onChangeEndDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.query_end_release} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                </dd>
              </dl>

              <FilterItem
                productGroup={location.state && location.state.query_product_group}
                productGroupList={productGroupList}
                modelList={modelList}
                productList={productList}
                assyList={assyList}
                partList={partList}
                level={location.state && location.state.query_level}
                model={location.state && location.state.query_model_name}
                product={location.state && location.state.query_product_name}
                assy={location.state && location.state.query_asei_name}
                part={location.state && location.state.query_part_name}
                keyword={keyword}
                setKeyword={setKeyword}
                onBlurKeyword={onBlurKeyword}
                onChangeFilter={onChangeFilter}
              />

              <button type="button" data-action="search" className="btn c-blue s1" onClick={() => { Util.modal(filter, setFilter); onClickSearch() }}>검색하기</button>
            </div>
          </div>
        </div>
        <div className="prd-list t2">
          {
            !list.length ?
              loading ? <></>
                :
                <p className="no-data">검색 결과가 없습니다.</p>
              :
              list.map((item, index) => {
                return (
                  <dl className="prd-dl release" key={index}>
                    <dt>
                      <span className="txt">
                        <p>품목군</p>
                        <strong className="prd">{item.product_group}</strong>
                      </span>
                    </dt>
                    <dd>
                      <div className="over-container" data-selector="overContainer">
                        <div className="over-wrap">
                          <dl className="dl-wrap">
                            <dt>품목</dt>
                            <dd dangerouslySetInnerHTML={{
                              __html: item.crm_product_name ? item.crm_product_name : '-'
                            }}></dd>
                          </dl>
                          <dl className="dl-wrap">
                            <dt>규격</dt>
                            <dd>{item.product_standard ? item.product_standard : '-'}</dd>
                          </dl>
                          <dl className="dl-wrap">
                            <dt>S/N</dt>
                            <dd dangerouslySetInnerHTML={{
                              __html: item.product_serial_number || '-'
                            }}></dd>
                          </dl>
                          <dl className="dl-wrap">
                            <dt>출고일</dt>
                            <dd>{item.release_date ? Util.formatDate(item.release_date) : '-'}</dd>
                          </dl>
                          <dl className="dl-wrap">
                            <dt>입고지</dt>
                            <dd dangerouslySetInnerHTML={{
                              __html: item.incoming_client_name ? item.incoming_client_name + '(' + item.service_number + ')' : item.incoming_center_name
                            }} ></dd>
                          </dl>
                        </div>
                        <div className="over-btt">
                          <button type="button" className="btn-over" onClick={(event) => detailOpen(event)}><span className="a11y">더보기</span></button>
                          <dl className="dl-wrap">
                            <dt dangerouslySetInnerHTML={{
                              __html: item.app_name ? item.app_name : '-'
                            }}></dt>
                            <dd>{item.reg_datetime ? Util.formatDateTimeHHMM(item.reg_datetime) : '-'}</dd>
                          </dl>
                        </div>
                      </div>
                    </dd>
                  </dl>
                );
              })
          }
          {
            loading &&
            <Progress
              loading={loading}
              fixed={filter}
            />
          }
        </div>
      </div>

      {
        scroll.scrollT > 0 &&
        <button type="button" className="btn-page-top" onClick={scrollTop}><span className="a11y">최상단으로 이동</span></button>
      }
    </>
  )
}

export default ReceivingList;