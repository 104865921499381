import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from '../../AppContext';
import { useHistory, useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import moment from 'moment';
import ServiceCounselingReg from "../../layout/service/ServiceCounselingReg";
import ManagerReg1 from "../../layout/service/ManagerReg1";
import ManagerReg2 from "../../layout/service/ManagerReg2";
import ServiceService from '../../services/ServiceService';
import Alert from "../../layout/Alert";
import ServiceListInfoModal from "./ServiceListInfoModal";
import ServiceResultModal from "./ServiceResultModal";
import Util from '../../helpers/Util';
import Progress from "../../layout/Progress";

const ServiceList = () => {
  const { auth } = useContext(AppContext);
  const user = auth.user;
  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = useState(false);
  const [baseInput, setBaseInput] = useState(false);
  const [searchTag, setSearchTag] = useState(["", "", "", "", "", "", "", "", ""]);
  const [keyword, setKeyword] = useState("");
  const [serviceCounseling, setServiceCounseling] = useState(false);
  const [reg1, setReg1] = useState(false);
  const [reg2, setReg2] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트 
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(1);  // 현재페이지
  const pageSize = 20;
  const [checkList, setCheckList] = useState([]); // 담당자 체크 리스트
  const [checkStatus, setCheckStatus] = useState(false); // 담당자 일괄체크 상태
  const [alertDec, setAlertDec] = useState("");
  const [alert, setAlert] = useState(false);
  const [excelAlert, setExcelAlert] = useState(false);
  const [startDate, setStartDate] = useState(Util.todayDt('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(Util.todayDt('YYYY-MM-DD'));
  const [infoModal, setInfoModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [serviceSeq, setServiceSeq] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  // 리스트 조회
  const searchList = useCallback(async () => {
    setLoading(true);
    setTotalCount(0);
    if (currentPage === 1) {
      setList([]);
    }
    const param = {
      current_page: currentPage,
      page_size: pageSize
    };
    Object.assign(param, location.state);
    try {
      const result = await ServiceService.fetchItems(param);
      const data = result.data;
      setTotalCount(data.total_count);

      if (currentPage > 1) {
        setList(thisState => {
          let state = [
            ...thisState,
            ...data.list
          ];
          return state;
        });
      } else {
        setList(data.list);
        setCheckList([]);
        setCheckStatus(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.state]);

  // 더보기 버튼 핸들러
  const onMore = useCallback(() => {
    let scrollHeight = Math.round(document.documentElement.scrollHeight);
    let scrollTop = Math.round(document.documentElement.scrollTop);
    let clientHeight = Math.round(document.documentElement.clientHeight);

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!loading) {
        if (totalCount > pageSize * currentPage) {
          setCurrentPage(thisState => {
            return thisState + 1
          });
        }
      }
    }
  }, [loading, totalCount, currentPage]);

  // 필터 검색하기
  const onFilterSearch = () => {
    filterOpen();
  }

  // 필터 레이어 팝업
  const filterOpen = () => {
    const $body = document.body;

    if (filter) {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
      window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
        if (currentPage === 1) {
          searchList();
        } else {
          setCurrentPage(1);
        }
      }
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, '');
      $body.classList.remove("_noscroll");
      setFilter(false);

      onBlurKeyword();
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'N' }, '');
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
        window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
          if (currentPage === 1) {
            searchList();
          } else {
            setCurrentPage(1);
          }
        }
        $body.classList.remove("_noscroll");
        setFilter(false);
      }
      $body.classList.add("_noscroll");
      setFilter(true);
    }
  }

  // 서비스 기준일 유형 선택
  const onChangeSearchDateType = (event) => {
    let locationState = location.state || {};

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: event.target.value === '예정일' ? 'V' : event.target.value === '접수일' ? 'C' : 'T',
        search_date_division: locationState.search_date_division,
        search_date: locationState.search_date,
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 서비스 기준일 구분 유형 선택
  const onChangeSearchDateDivision = (event) => {
    let locationState = location.state || {};

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: locationState.search_date_type,
        search_date_division: event.target.value,
        search_date: locationState.search_date,
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 서비스 기준일 날짜 선택(변경)
  const onChangeSearchDate = (event) => {
    let locationState = location.state || {};

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: locationState.search_date_type,
        search_date_division: locationState.search_date_division,
        search_date: moment(event).format('YYYY-MM-DD'),
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 서비스 기준일 미입력
  const resetSearchDate = (event) => {
    const $rdt = document.querySelector(".rdt");
    if (baseInput) {
      $rdt.classList.remove("disabled");
      setBaseInput(false);
    } else {
      $rdt.classList.add("disabled");
      setBaseInput(true);
      let locationState = location.state || {};

      history.replace({
        pathname: '/service/list',
        state: {
          search_date_type: 'C',
          search_date_division: 'C',
          search_date: '',
          counseling_type: locationState.counseling_type || '',
          service_counseling_type: locationState.service_counseling_type || '',
          service_type: locationState.service_type || '',
          service_status: locationState.service_status || '',
          is_emergency: locationState.is_emergency || '',
          is_manager: locationState.is_manager || '',
          is_approval: locationState.is_approval || '',
          search_word_type: locationState.search_word_type || 'N',
          search_word: locationState.search_word || ''
        }
      });
    }
  }

  // 필터 싱글 선택 핸들러
  const onChangeFilter = (event) => {
    let locationState = location.state || {};

    const name = event.target.name;

    locationState[name] = event.target.value;

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: locationState.search_date_type || 'C',
        search_date_division: locationState.search_date_division || 'C',
        search_date: locationState.search_date || '',
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 필터 멀티 선택 핸들러
  const onChangeMultiFilter = (event) => {
    let locationState = location.state || {};

    const name = event.target.name;

    if (event.target.value) {
      if (locationState[name].indexOf(event.target.value) > -1) {
        let list = locationState[name].split(',');
        for (let i = 0; i < list.length; i++) {
          if (list[i] === event.target.value) {
            list.splice(i, 1);
            i--;
          }
        }
        locationState[name] = list.join(',');
      } else {
        locationState[name] = (locationState[name] ? locationState[name] + ',' + event.target.value : event.target.value);
      }
    } else {
      locationState[name] = '';
    }

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: locationState.search_date_type || 'C',
        search_date_division: locationState.search_date_division || 'C',
        search_date: locationState.search_date || '',
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 키워드 유형 선택
  const onChangeSearchWordType = (event) => {
    let locationState = location.state || {};

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: event.target.value === '예정일' ? 'V' : event.target.value === '접수일' ? 'C' : 'T',
        search_date_division: locationState.search_date_division,
        search_date: locationState.search_date,
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: event.target.value === '고객명' ? 'N' : event.target.value === '연락처' ? 'C' : 'A',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 필터 태그 삭제
  const delFilter = (event) => {
    setCurrentPage(1);

    let idx = Number(event.target.dataset.selector);

    let locationState = location.state || {};

    if (idx === 0) { // 서비스 기준일
      locationState.search_date_type = 'C';
      locationState.search_date = '';
    }
    if (idx === 1) { // 접수 유형
      locationState.counseling_type = '';
    }
    if (idx === 2) { // 서비스상담 유형
      locationState.service_counseling_type = '';
    }
    if (idx === 3) { // 서비스 유형
      locationState.service_type = '';
    }
    if (idx === 4) { // 서비스 결과
      locationState.service_status = '';
    }
    if (idx === 5) { // 긴급여부
      locationState.is_emergency = '';
    }
    if (idx === 6) { // 배정여부
      locationState.is_manager = '';
    }
    if (idx === 7) { // 승인여부
      locationState.is_approval = '';
    }
    // if (idx === 8) { // 키워드 타입
    //   locationState.search_word_type = '';
    // }
    if (idx === 8) { // 키워드
      locationState.search_word = '';
    }

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: locationState.search_date_type || 'C',
        search_date_division: locationState.search_date_division || 'C',
        search_date: locationState.search_date || '',
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  // 필터 선택 초기화
  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    setBaseInput(false);
    const $check = document.querySelector("input[data-action=date]");
    $check.checked = false;

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: 'C',
        search_date_division: 'C',
        search_date: '',
        counseling_type: '',
        service_counseling_type: '',
        service_type: '',
        service_status: '',
        is_emergency: '',
        is_manager: '',
        is_approval: '',
        search_word_type: 'N',
        search_word: ''
      }
    });
  }, [history]);

  // 담당자 개별 등록 레이어 팝업
  const regOpen1 = (item) => {
    setSelectItem(item);
    Util.modal(reg1, setReg1);
  }

  // 담당자 개별 등록 저장
  const onRegSave1 = () => {
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  // 담당자 일괄 등록 레이어 팝업
  const regOpen2 = () => {
    if (checkList.length) {
      Util.modal(reg2, setReg2);
    } else {
      setAlertDec("담당자 미배정 항목을 선택하세요.");
      alertOpen();
    }
  }

  // 담당자 일괄 등록 저장
  const onRegSave2 = () => {
    setCheckList([]);
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  // 일괄 선택
  const allCheck = () => {
    const unassigned_checks = document.getElementsByName("unassigned_check");
    unassigned_checks.forEach((item) => {
      item.checked = true;

      setCheckList(thisState => {
        let state = [...thisState];
        state.push(item.value);
        const set = new Set(state); // 중복제거
        state = [...set];
        return state;
      });

      setCheckStatus(true);
    });
  }

  // 일괄 해제
  const allUnCheck = () => {
    const unassigned_checks = document.getElementsByName("unassigned_check");
    unassigned_checks.forEach((item) => {
      item.checked = false;
      setCheckList([]);
      setCheckStatus(false);
    });
  }

  // 담당자 미배정 체크박스 선택
  const onManagerCheck = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) { // 추가
      setCheckList(thisState => {
        let state = [...thisState];
        state.push(value);
        const set = new Set(state); // 중복제거
        state = [...set];
        return state;
      });
    } else {  // 제거
      setCheckList(thisState => {
        let state = [...thisState];
        state.splice(state.indexOf(value), 1);
        return state;
      });
    }
  }

  // 상담 결과 등록 레이어 팝업
  const serviceCounselingOpen = (item) => {
    setSelectItem(item);

    const $body = document.body;
    if (serviceCounseling) {
      $body.classList.remove("_noscroll");
      setServiceCounseling(false);
    } else {
      $body.classList.add("_noscroll");
      setServiceCounseling(true);
    }
  }

  // 서비스 상담 결과 등록
  const onServiceCounselingSave = () => {
    if (currentPage === 1) {
      searchList();
    } else {
      setCurrentPage(1);
    }
  }

  // 서비스 상세정보 이동하기
  const goServiceInfo = (item) => {
    if (item.service_status === 'C' || item.service_status === 'I') {
      // history.push("/service/result", {
      //   service_seq: item.service_seq
      // });
      setServiceSeq(item.service_seq);
      openResultModal();
    } else {
      setServiceSeq(item.service_seq);
      openInfoModal();
    }
  }

  // 전화
  const onCallClient = (contact) => {
    window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
      tel: contact
    }, "");
  }

  // 스크롤 최상단으로 이동
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = useCallback((e) => {
    if (e.srcElement.scrollingElement !== undefined) {
      const scrollT = ('scroll', e.srcElement.scrollingElement.scrollTop);
      setScroll({ scrollT });

      onMore(); // 더보기
    }
  }, [setScroll, onMore]);

  // 알럿
  const alertOpen = () => {
    Util.modal(alert, setAlert);
  }

  // 네이버 지도 열기
  const onNaverMap = (address) => {
    if (address) {
      window.nativeApi.execute(window.NativeApiMethod.OPEN_NAVER_MAP, {
        address: encodeURI(address)
      }, "");
    }
  }

  // 엑셀 다운로드
  const onExcel = () => {
    if (!startDate || !endDate) {
      setAlertDec('조회기간을 선택해주세요.');
      alertOpen();
      return;
    }

    let start = moment(startDate);
    let end = moment(endDate);
    let diffDays = end.diff(start, 'days');
    if (diffDays >= 366) {
      setAlertDec('엑셀 다운로드 조회 기간은 최대 1년 입니다.');
      alertOpen();
      return;
    }

    var queryString = Object.keys(location.state).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(location.state[key])
    }).join('&');
    let url = '/api/v1/service/excel?user_grade='
      + (user && user.app_user_grade) + '&center_seq=' + (user && user.center_seq)
      + '&start_date=' + startDate + '&end_date=' + moment(endDate).add(1, 'days').format('YYYY-MM-DD')
      + '&' + queryString;

    saveAs(url, '');

    setAlertDec('다운로드중입니다.\n잠시만 기다리세요.');
    alertOpen();

    excelAlertOpen();
  }

  // 서비스 상세 모달 열기
  const openInfoModal = () => {
    Util.modal(infoModal, setInfoModal);
  }

  // 서비스 결과 모달 열기
  const openResultModal = () => {
    Util.modal(resultModal, setResultModal);
  }

  // 엑셀 다운로드 알럿창
  const excelAlertOpen = () => {
    if (excelAlert) {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: false
      }, '');
      setExcelAlert(false);
    } else {
      window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
        isLayer: true
      }, window.NativeApiCallbackMethod.IS_LAYER);
      window[window.NativeApiCallbackMethod.IS_LAYER] = function () {
        setExcelAlert(false);
      }
      setExcelAlert(true);
    }
  }

  const dayValid = (current) => {
    const minDay = moment(startDate).subtract(1, 'days');
    return current.isAfter(minDay);
  }

  const startChange = (event) => {
    setStartDate(moment(event).format('YYYY-MM-DD'));
  }

  const endChange = (event) => {
    setEndDate(moment(event).format('YYYY-MM-DD'));
  }

  // 검색 필터 키워드 변경 핸들러
  const onBlurKeyword = (event) => {
    let locationState = location.state || {};
    locationState.search_word = keyword;

    history.replace({
      pathname: '/service/list',
      state: {
        search_date_type: locationState.search_date_type || 'C',
        search_date_division: locationState.search_date_division || 'C',
        search_date: locationState.search_date || '',
        counseling_type: locationState.counseling_type || '',
        service_counseling_type: locationState.service_counseling_type || '',
        service_type: locationState.service_type || '',
        service_status: locationState.service_status || '',
        is_emergency: locationState.is_emergency || '',
        is_manager: locationState.is_manager || '',
        is_approval: locationState.is_approval || '',
        search_word_type: locationState.search_word_type || 'N',
        search_word: locationState.search_word || ''
      }
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    const state = location.state;

    if (!state) {
      history.replace({
        pathname: '/service/list',
        state: {
          search_date_type: 'C',
          search_date_division: 'C',
          search_date: '',
          counseling_type: '',
          service_counseling_type: '',
          service_type: '',
          service_status: '',
          is_emergency: '',
          is_manager: '',
          is_approval: '',
          search_word_type: '',
          search_word: ''
        }
      });
    }

    if (state) {
      if (!state.search_date_type) {
        history.replace({
          pathname: '/service/list',
          state: {
            search_date_type: 'C',
            search_date_division: 'C',
            search_date: state.search_date || '',
            counseling_type: state.counseling_type || '',
            service_counseling_type: state.service_counseling_type || '',
            service_type: state.service_type || '',
            service_status: state.service_status || '',
            is_emergency: state.is_emergency || '',
            is_manager: state.is_manager || '',
            is_approval: state.is_approval || '',
            search_word_type: state.search_word_type || '',
            search_word: state.search_word || ''
          }
        });
      }

      const searchDateType = state.search_date_type;
      const searchDateDivision = state.search_date_division;
      const searchDate = state.search_date;
      const counselingType = state.counseling_type;
      const serviceCounselingType = state.service_counseling_type;
      const serviceType = state.service_type;
      const serviceStatus = state.service_status;
      const isEmergency = state.is_emergency;
      const isManager = state.is_manager;
      const isApproval = state.is_approval;
      const searchWordType = state.search_word_type;
      const searchWord = state.search_word;

      // 예정일
      if (searchDateType === 'V' && searchDate) {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '예정일 ' + searchDate + (searchDateDivision === 'C' ? ' 당일' : searchDateDivision === 'A' ? ' 이후' : ' 이전');
          return state;
        });
      }

      // 접수일
      if (searchDateType === 'C' && searchDate) {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '접수일 ' + searchDate + (searchDateDivision === 'C' ? ' 당일' : searchDateDivision === 'A' ? ' 이후' : ' 이전');
          return state;
        });
      }

      // 처리일
      if (searchDateType === 'T' && searchDate) {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '처리일 ' + searchDate + (searchDateDivision === 'C' ? ' 당일' : searchDateDivision === 'A' ? ' 이후' : ' 이전');
          return state;
        });
      }

      if (!searchDate) {
        const id = 0;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 접수 유형
      if (counselingType) {
        let counselingTypeName = '';
        let counselingTypeList = counselingType.split(',');
        counselingTypeList.forEach((item, index) => {
          if (index > 0) counselingTypeName += ',';
          switch (item) {
            case '03':
              counselingTypeName += '설치요청';
              break;
            case '04':
              counselingTypeName += 'AS요청';
              break;
            case '05':
              counselingTypeName += '철거요청';
              break;
            case '06':
              counselingTypeName += '이전요청';
              break;
            case '08':
              counselingTypeName += '이전설치요청';
              break;
            case '09':
              counselingTypeName += '이전철거요청';
              break;
            default:
              break;
          }
        });

        const id = 1;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = counselingTypeName;
          return state;
        });
      } else {
        const id = 1;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 서비스 상담 결과
      if (serviceCounselingType) {
        let serviceCounselingTypeName = '';
        let serviceCounselingTypeList = serviceCounselingType.split(',');
        serviceCounselingTypeList.forEach((item, index) => {
          if (index > 0) serviceCounselingTypeName += ',';
          switch (item) {
            case '00':
              serviceCounselingTypeName += '미입력';
              break;
            case '01':
              serviceCounselingTypeName += '방문약속완료';
              break;
            case '02':
              serviceCounselingTypeName += '일정조율중';
              break;
            case '03':
              serviceCounselingTypeName += '통화불가';
              break;
            case '04':
              serviceCounselingTypeName += '서비스불가';
              break;
            case '05':
              serviceCounselingTypeName += '유선상해결';
              break;
            default:
              break;
          }
        });

        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = serviceCounselingTypeName;
          return state;
        });
      } else {
        const id = 2;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 서비스상담 유형
      if (serviceType) {
        let serviceTypeName = '';
        let serviceTypeList = serviceType.split(',');
        serviceTypeList.forEach((item, index) => {
          if (index > 0) serviceTypeName += ',';
          switch (item) {
            case '01':
              serviceTypeName += '설치';
              break;
            case '02':
              serviceTypeName += 'AS';
              break;
            case '03':
              serviceTypeName += '철거';
              break;
            case '04':
              serviceTypeName += '이전설치';
              break;
            case '05':
              serviceTypeName += '이전철거';
              break;
            default:
              break;
          }
        });

        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = serviceTypeName;
          return state;
        });
      } else {
        const id = 3;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 서비스 결과(상태)
      if (serviceStatus) {
        let serviceStatusName = '';
        let serviceStatusList = serviceStatus.split(',');
        serviceStatusList.forEach((item, index) => {
          if (index > 0) serviceStatusName += ',';
          switch (item) {
            case 'N':
              serviceStatusName += '미진행';
              break;
            case 'P':
              serviceStatusName += '진행중';
              break;
            case 'I':
              serviceStatusName += '진행불가';
              break;
            case 'C':
              serviceStatusName += '완료';
              break;
            default:
              break;
          }
        });

        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = serviceStatusName;
          return state;
        });
      } else {
        const id = 4;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 긴급여부
      if (isEmergency) {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = isEmergency === '1' ? '긴급' : '일반';
          return state;
        });
      } else {
        const id = 5;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 배정여부
      if (isManager) {
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = isManager === '1' ? '배정완료' : '미배정';
          return state;
        });
      } else {
        const id = 6;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // 승인여부
      if (isApproval) {
        const id = 7;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = isApproval === '1' ? '승인' : '미승인';
          return state;
        });
      } else {
        const id = 7;
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }

      // if (searchWordType) {
      //   const id = 8;
      //   setSearchTag(thisState => {
      //     let state = [...thisState];
      //     state[id] = searchWordType === 'N' ? '고객명' : searchWordType === 'C' ? '연락처' : '주소';
      //     return state;
      //   });
      // } else {
      //   const id = 8;
      //   setSearchTag(thisState => {
      //     let state = [...thisState];
      //     state[id] = '';
      //     return state;
      //   });
      // }

      // 키워드
      if (searchWord) {
        const id = 8;
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = searchWord;
          return state;
        });
      } else {
        const id = 8;
        setKeyword(searchWord);
        setSearchTag(thisState => {
          let state = [...thisState];
          state[id] = '';
          return state;
        });
      }
    }
  }, [location, history]);

  useEffect(() => {
    if (isUpdate) {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
      setIsUpdate(false);
    }
  }, [isUpdate, currentPage, searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_RELOAD, { value: 'Y' }, window.NativeApiCallbackMethod.IS_RELOAD);
    window[window.NativeApiCallbackMethod.IS_RELOAD] = function () {
      if (currentPage === 1) {
        searchList();
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage, searchList]);

  useEffect(() => {
    searchList();
  }, [searchList]);

  useEffect(() => {
    window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {
      isLayer: false
    }, null);
  }, []);

  return (
    <>
      <div className="page-service">
        <div className="top-filter">
          <div className="select-container" data-selector="selectContainer">
            {
              searchTag.join("") === ""
                ? <p className="dec">선택 필터가 없습니다.</p>
                : (
                  searchTag.map((item, index) => {
                    return (
                      item
                        ? (
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                        ) : null
                    );
                  })
                )
            }
          </div>
          <button type="button" className="btn c-black-line" data-action="filter" data-sid="open" onClick={filterOpen}><span className="txt ft">고객검색</span></button>

          <div className={`filter-container ${filter ? '_openFilter' : null}`} data-selector="filterContainer">
            <header className="filter-header flex">
              <strong className="tit">고객검색</strong>
              <nav>
                <button type="button" className="btn c-black-line" data-action="reset" onClick={resetFilter}><span className="txt refresh">선택초기화</span></button>
                <button type="button" className="btn-close" data-action="filter" data-sid="close" onClick={filterOpen}><span className="a11y">닫기</span></button>
              </nav>
            </header>


            <div className="select-container" data-selector="selectContainer">
              {
                searchTag.join("") === ""
                  ? <p className="dec">선택 필터가 없습니다.</p>
                  : (
                    searchTag.map((item, index) => {
                      return (
                        item
                          ?
                          <span className="tag" data-selector="tag" key={index}>
                            {item}
                            <button type="button" className="del" data-action="delTag" data-selector={index} onClick={delFilter}><span className="a11y">삭제</span></button>
                          </span>
                          :
                          null
                      );
                    })
                  )
              }
            </div>

            <div className="dl-wrap t2">
              <dl className="dl-item">
                <dt>서비스 기준일</dt>
                <dd className="flex">
                  <div className="col service-standard">
                    <select
                      onChange={onChangeSearchDateType}
                      disabled={baseInput}
                      value={
                        location.state ?
                          location.state.search_date_type === 'C' ? "접수일"
                            : location.state.search_date_type === 'V' ? "예정일" : "처리일"
                          : "접수일"
                      }>
                      <option value="접수일">접수일</option>
                      <option value="예정일">예정일</option>
                      <option value="처리일">처리일</option>
                    </select>

                    <select onChange={onChangeSearchDateDivision} disabled={baseInput} value={location.state && location.state.search_date_division}>
                      <option value="C">당일</option>
                      <option value="B">이전</option>
                      <option value="A">이후</option>
                    </select>
                  </div>
                  <div className="col">
                    <Datetime
                      inputProps={{
                        id: "baseDate",
                        name: "baseDate",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                      onChange={onChangeSearchDate}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return <>
                          <input {...props} value={location.state && location.state.search_date} disabled={baseInput} />
                          <button type="button" className="btn-close" onClick={closeCalendar}>
                            <span className="a11y">날짜 선택 닫기</span>
                          </button>
                        </>
                      }}
                    />
                  </div>
                  <div className="col w100">
                    <label className="chk-label">
                      <input type="checkbox" data-action="date" onClick={resetSearchDate} />
                      <span className="txt">미입력</span>
                    </label>
                  </div>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>접수유형</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value=""
                          checked={location.state && location.state.counseling_type === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value="03"
                          checked={location.state && location.state.counseling_type && location.state.counseling_type.indexOf('03') > -1 ? true : false} />
                        <span className="txt">설치요청</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value="04"
                          checked={location.state && location.state.counseling_type && location.state.counseling_type.indexOf('04') > -1 ? true : false} />
                        <span className="txt">AS요청</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value="05"
                          checked={location.state && location.state.counseling_type && location.state.counseling_type.indexOf('05') > -1 ? true : false} />
                        <span className="txt">철거요청</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value="06"
                          checked={location.state && location.state.counseling_type && location.state.counseling_type.indexOf('06') > -1 ? true : false} />
                        <span className="txt">이전요청</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value="06"
                          checked={location.state && location.state.counseling_type && location.state.counseling_type.indexOf('08') > -1 ? true : false} />
                        <span className="txt">이전설치요청</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="counseling_type"
                          onChange={onChangeMultiFilter}
                          value="06"
                          checked={location.state && location.state.counseling_type && location.state.counseling_type.indexOf('09') > -1 ? true : false} />
                        <span className="txt">이전철거요청</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>서비스 상담 결과</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value=""
                          checked={location.state && location.state.service_counseling_type === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value="00"
                          checked={location.state && location.state.service_counseling_type && location.state.service_counseling_type.indexOf('00') > -1 ? true : false} />
                        <span className="txt">미입력</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value="01"
                          checked={location.state && location.state.service_counseling_type && location.state.service_counseling_type.indexOf('01') > -1 ? true : false} />
                        <span className="txt">방문약속완료</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value="02"
                          checked={location.state && location.state.service_counseling_type && location.state.service_counseling_type.indexOf('02') > -1 ? true : false} />
                        <span className="txt">일정조율중</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value="03"
                          checked={location.state && location.state.service_counseling_type && location.state.service_counseling_type.indexOf('03') > -1 ? true : false} />
                        <span className="txt">통화불가</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value="04"
                          checked={location.state && location.state.service_counseling_type && location.state.service_counseling_type.indexOf('04') > -1 ? true : false} />
                        <span className="txt">서비스불가</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_counseling_type"
                          onChange={onChangeMultiFilter}
                          value="05"
                          checked={location.state && location.state.service_counseling_type && location.state.service_counseling_type.indexOf('05') > -1 ? true : false} />
                        <span className="txt">유선상해결</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>서비스 유형</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_type"
                          onChange={onChangeMultiFilter}
                          value=""
                          checked={location.state && location.state.service_type === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_type"
                          onChange={onChangeMultiFilter}
                          value="01"
                          checked={location.state && location.state.service_type && location.state.service_type.indexOf('01') > -1 ? true : false} />
                        <span className="txt">설치</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_type"
                          onChange={onChangeMultiFilter}
                          value="02"
                          checked={location.state && location.state.service_type && location.state.service_type.indexOf('02') > -1 ? true : false} />
                        <span className="txt">AS</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_type"
                          onChange={onChangeMultiFilter}
                          value="03"
                          checked={location.state && location.state.service_type && location.state.service_type.indexOf('03') > -1 ? true : false} />
                        <span className="txt">철거</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_type"
                          onChange={onChangeMultiFilter}
                          value="05"
                          checked={location.state && location.state.service_type && location.state.service_type.indexOf('05') > -1 ? true : false} />
                        <span className="txt">이전철거</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_type"
                          onChange={onChangeMultiFilter}
                          value="04"
                          checked={location.state && location.state.service_type && location.state.service_type.indexOf('04') > -1 ? true : false} />
                        <span className="txt">이전설치</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>서비스 결과</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_status"
                          onChange={onChangeMultiFilter}
                          value=""
                          checked={location.state && location.state.service_status === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_status"
                          onChange={onChangeMultiFilter}
                          value="N"
                          checked={location.state && location.state.service_status && location.state.service_status.indexOf('N') > -1 ? true : false} />
                        <span className="txt">미진행</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_status"
                          onChange={onChangeMultiFilter}
                          value="P"
                          checked={location.state && location.state.service_status && location.state.service_status.indexOf('P') > -1 ? true : false} />
                        <span className="txt">진행중</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_status"
                          onChange={onChangeMultiFilter}
                          value="I"
                          checked={location.state && location.state.service_status && location.state.service_status.indexOf('I') > -1 ? true : false} />
                        <span className="txt">진행불가</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="service_status"
                          onChange={onChangeMultiFilter}
                          value="C"
                          checked={location.state && location.state.service_status && location.state.service_status.indexOf('C') > -1 ? true : false} />
                        <span className="txt">완료</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>긴급 서비스</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_emergency"
                          onChange={onChangeFilter}
                          value=""
                          checked={location.state && location.state.is_emergency === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_emergency"
                          onChange={onChangeFilter}
                          value="1"
                          checked={location.state && location.state.is_emergency && location.state.is_emergency.indexOf('1') > -1 ? true : false} />
                        <span className="txt">긴급</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_emergency"
                          onChange={onChangeFilter}
                          value="0"
                          checked={location.state && location.state.is_emergency && location.state.is_emergency.indexOf('0') > -1 ? true : false} />
                        <span className="txt">일반</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>배정여부</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_manager"
                          onChange={onChangeFilter}
                          value=""
                          checked={location.state && location.state.is_manager === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_manager"
                          onChange={onChangeFilter}
                          value="0"
                          checked={location.state && location.state.is_manager && location.state.is_manager.indexOf('0') > -1 ? true : false} />
                        <span className="txt">미배정</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_manager"
                          onChange={onChangeFilter}
                          value="1"
                          checked={location.state && location.state.is_manager && location.state.is_manager.indexOf('1') > -1 ? true : false} />
                        <span className="txt">배정완료</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>승인상태</dt>
                <dd>
                  <ul className="flex chk">
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_approval"
                          onChange={onChangeFilter}
                          value=""
                          checked={location.state && location.state.is_approval === "" ? true : false} />
                        <span className="txt">전체</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_approval"
                          onChange={onChangeFilter}
                          value="0"
                          checked={location.state && location.state.is_approval && location.state.is_approval.indexOf('0') > -1 ? true : false} />
                        <span className="txt">미승인</span>
                      </label>
                    </li>
                    <li>
                      <label className="chk-label t-btn">
                        <input type="checkbox" name="is_approval"
                          onChange={onChangeFilter}
                          value="1"
                          checked={location.state && location.state.is_approval && location.state.is_approval.indexOf('1') > -1 ? true : false} />
                        <span className="txt">승인</span>
                      </label>
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="dl-item">
                <dt>키워드</dt>
                <dd className="flex">
                  <div style={{ width: '30%', marginRight: 8 }}>
                    <select
                      onChange={onChangeSearchWordType}
                      disabled={baseInput}
                      value={
                        location.state ?
                          location.state.search_word_type === 'N' ? "고객명"
                            : location.state.search_word_type === 'C' ? "연락처"
                              : location.state.search_word_type === 'A' ? "주소" : "고객명"
                          : "고객명"
                      }>
                      <option value="고객명">고객명</option>
                      <option value="연락처">연락처</option>
                      <option value="주소">주소</option>
                    </select>
                  </div>
                  <div style={{ width: 'calc(70% - 8px)' }}>
                    <input type="text" name="" data-selector="text"
                      placeholder="고객명, 연락처 또는 주소를 입력하세요."
                      onChange={(event) => setKeyword(event.target.value)}
                      value={keyword} />
                  </div>
                </dd>
              </dl>
            </div>

            <button type="button" data-action="search" className="btn c-blue s1" onClick={onFilterSearch}>검색하기</button>
          </div>
        </div>

        <ul className="top-btn-container flex">
          {
            user.app_user_grade !== 'E' ?
              <li><button type="button" className="btn c-blue-line" onClick={excelAlertOpen}>엑셀<br />다운로드</button></li>
              :
              null
          }
          {
            checkStatus ?
              <li><button type="button" className="btn c-blue-line" onClick={allUnCheck}>담당자<br />선택 해제({checkList.length})</button></li>
              :
              <li><button type="button" className="btn c-blue-line" onClick={allCheck}>담당자<br />일괄 선택</button></li>
          }
          <li><button type="button" className="btn c-blue-line" onClick={regOpen2}>담당자<br />일괄 배정</button></li>
          {/* <li><Link to="/service/reg" className="btn c-blue-line">서비스<br />신규등록</Link></li> */}
        </ul>

        <section className="sec sec-box">
          <div><strong>총 {Util.formatNumber(totalCount)}건</strong></div>
          {
            !list.length ?
              loading ? <></>
                :
                <>
                  <p className="no-data">검색 결과가 없습니다.</p>
                </>
              :
              list.map((item, index) => {
                return (
                  <dl className={`box-wrap${(Util.getServiceStatus(item)) ? " _" + Util.getServiceStatus(item) : ""}`} key={index}>
                    <dt onClick={() => goServiceInfo(item)}>
                      <span className={`status${(item.is_emergency) ? " emergency" : ""}`}>{Util.getCounselingStatusName(item)}</span>
                      <span className="date">
                        {Util.getServiceDdayName(item)}
                        {
                          item.service_status === "I" && <span className="txt">진행불가</span>
                        }
                      </span>
                    </dt>
                    <dd>
                      <div type="button" className="top-wrap">
                        <strong className="name">{item.name} 고객</strong>
                        <span className="tel">{item.contacts ? Util.formatPhoneNumber(item.contacts.split(',')[0]) : '-'}</span>
                        <button type="button" className="btn-tel" onClick={() => onCallClient(item.contacts ? item.contacts.split(',')[0] : '')}><span className="a11y">전화걸기</span></button>
                      </div>
                      <div className="dl-wrap">
                        <dl className="dl-item">
                          <dt>모델</dt>
                          <dd dangerouslySetInnerHTML={{
                            __html: item.crm_product_name ? item.product_group + (item.crm_product_name ? ' / ' + item.crm_product_name : '') + (item.product_standard ? ' / ' + item.product_standard : '') + ' / ' + Util.unescapeHtml(item.app_exposure_sales) : '-'
                          }}></dd>
                        </dl>
                        <div className="dl-item date-wrap">
                          <dl>
                            <dt>예정일시</dt>
                            <dd>
                              {item.visit_datetime ? Util.formatDateTimeHHMM(item.visit_datetime) : '-'}
                            </dd>
                            {
                              (item.service_status !== 'C' && item.service_status !== 'I') && item.is_approval === false ?
                                item.manager_seq ?
                                  (item.visit_datetime ?
                                    <></>
                                    :
                                    <dd>
                                      <button type="button" className="btn c-blue-line" onClick={() => serviceCounselingOpen(item)}>입력</button>
                                    </dd>)
                                  :
                                  null
                                :
                                null
                            }
                          </dl>
                          <dl>
                            <dt>접수일시</dt>
                            <dd>
                              {item.counseling_datetime ? Util.formatDateTimeHHMM(item.counseling_datetime) : '-'}
                            </dd>
                          </dl>
                        </div>
                        <div className="dl-item address-wrap">
                          <dl>
                            <dt>주소</dt>
                            <dd>
                              <p className="address" dangerouslySetInnerHTML={{
                                __html: item.address ? item.address : '-'
                              }}></p>
                            </dd>
                          </dl>
                          <button type="button" className="btn btn-map" onClick={() => onNaverMap(item.address)}><span className="a11y">지도</span></button>
                        </div>
                        <div className="dl-item address-wrap">
                          <dl>
                            <dt>담당자</dt>
                            {
                              item.manager_seq ?
                                <dd dangerouslySetInnerHTML={{
                                  __html: item.manager_name
                                }}></dd>
                                :
                                user.app_user_grade !== 'E' ?
                                  (item.service_status !== 'C' && item.service_status !== 'I') && item.is_approval === false ?
                                    <dd>
                                      <label className="chk-label">
                                        <input type="checkbox" name="unassigned_check" value={item.service_seq} onChange={(event) => onManagerCheck(event)} />
                                        <span className="txt">미배정</span>
                                      </label>
                                    </dd>
                                    :
                                    <dd>-</dd>
                                  :
                                  null
                            }

                          </dl>
                          {
                            user.app_user_grade !== 'E' ?
                              (item.service_status !== 'C' && item.service_status !== 'I') && item.is_approval === false ?
                                item.manager_seq
                                  ? <button type="button" className="btn c-blue-line" onClick={() => regOpen1(item)}>변경</button>
                                  : <button type="button" className="btn c-blue-line" onClick={() => regOpen1(item)}>배정</button>
                                :
                                null
                              :
                              null
                          }
                        </div>
                        {
                          user.app_user_grade !== 'E' ?
                            item.status === "as" || item.status === "remove"
                              ?
                              null
                              :
                              <dl className="dl-item">
                                <dt>승인상태</dt>
                                <dd>{item.is_approval ? '승인' : '미승인'}</dd>
                              </dl>
                            :
                            null
                        }
                      </div>

                      <button type="button" className="btn c-blue-line w100" onClick={() => goServiceInfo(item)}>상세보기</button>
                    </dd>
                  </dl>
                );
              })
          }
          {
            loading &&
            <Progress
              loading={loading}
              fixed={filter}
            />
          }
        </section>
      </div>

      {/* 담당자 개별 등록 */}
      {
        reg1 &&
        <ManagerReg1
          reg={reg1}
          regOpen={regOpen1}
          onRegSave={onRegSave1}
          serviceInfo={selectItem}
        />
      }
      {/* ./담당자 개별 등록 */}

      {/* 담당자 일괄 등록 */}
      {
        reg2 &&
        <ManagerReg2
          reg={reg2}
          regOpen={regOpen2}
          onRegSave={onRegSave2}
          serviceList={checkList}
        />
      }
      {/* ./담당자 일괄 등록 */}

      {/* 상담 결과 등록 */}
      {
        serviceCounseling &&
        <ServiceCounselingReg
          serviceCounseling={serviceCounseling}
          serviceCounselingOpen={serviceCounselingOpen}
          onServiceCounselingSave={onServiceCounselingSave}
          serviceInfo={selectItem}
        />
      }
      {/* ./상담 결과 등록 */}

      {/* 알럿창 */}
      {
        alert &&
        <Alert
          dec={alertDec}
          alert={alert}
          alertOpen={alertOpen}
        />
      }

      {/* 서비스 상세 페이지 */}
      <ServiceListInfoModal
        state={infoModal}
        setState={setInfoModal}
        open={openInfoModal}
        serviceSeq={serviceSeq}
        setIsUpdate={setIsUpdate}
        title="서비스 상세"
      />

      {/* 서비스 결과 페이지 */}
      <ServiceResultModal
        state={resultModal}
        setState={setResultModal}
        open={openResultModal}
        serviceSeq={serviceSeq}
        setIsUpdate={setIsUpdate}
        title="서비스 상세"
      />

      {/* 엑셀 다운로드 알럿 */}
      {
        excelAlert &&
        <div className={`layer${excelAlert ? " _open" : ""}`} style={{ zIndex: 0 }}>
          <div className="pop-container pop-confirm pop-excel" data-selector="pop-confirm">
            <div className="pop-content">
              <div className="pop-inner" data-action="popAppend">
                <div className="dec-wrap">엑셀 다운로드</div>
                <div className="dl-wrap">
                  <dl className="dl-item">
                    <dt>접수일</dt>
                    <dd className="flex">
                      <div className="col">
                        <Datetime
                          inputProps={{
                            id: "startDate",
                            name: "startDate",
                            readOnly: true
                          }}
                          locale="kr"
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          closeOnSelect
                          onChange={startChange}
                          renderInput={(props, openCalendar, closeCalendar) => {
                            return <>
                              <input {...props} value={(startDate) ? startDate : ''} />
                              <button type="button" className="btn-close" onClick={closeCalendar}>
                                <span className="a11y">날짜 선택 닫기</span>
                              </button>
                            </>
                          }}
                        />
                      </div>
                      <div className="col">
                        <Datetime
                          inputProps={{
                            id: "endDate",
                            name: "endDate",
                            readOnly: true
                          }}
                          locale="kr"
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          closeOnSelect
                          isValidDate={dayValid}
                          onChange={endChange}
                          renderInput={(props, openCalendar, closeCalendar) => {
                            return <>
                              <input {...props} value={(endDate) ? endDate : ''} />
                              <button type="button" className="btn-close" onClick={closeCalendar}>
                                <span className="a11y">날짜 선택 닫기</span>
                              </button>
                            </>
                          }}
                        />
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="btn-flex">
                  <button type="button" className="btn c-sky s0" onClick={onExcel}>다운로드</button>
                </div>
              </div>
              <button type="button" className="pop-close" onClick={excelAlertOpen}><span className="a11y">팝업닫기</span></button>
            </div>
          </div>
          <div className="dim"></div>
        </div>
      }
      {/* 엑셀 다운로드 알럿 */}

      {
        scroll.scrollT > 0 &&
        <button type="button" className="btn-page-top" onClick={scrollTop}><span className="a11y">최상단으로 이동</span></button>
      }

    </>

  )
}

export default ServiceList;